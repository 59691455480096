<template>
  <v-dialog persistent v-model="show" max-width="900" @keydown.esc="show = false" content-class="pa-0 role-dialog">
    <v-card color="#f6f8fa" class="pb-4">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <h1 class="text-center my-4">{{$t('AddEventDialog.headline')}}</h1>
            <div class="text-center my-8">
              <div class="d-inline-block" :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 20%'">
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'castingOnline') && step++" :color="(type == 'castingOnline') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">mdi-camera-front</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('castingOnline')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </div>
              <div class="d-inline-block" :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 20%'">
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'callbackOnline') && step++" :color="(type == 'callbackOnline') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">mdi-camera-front-variant</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('callbackOnline')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </div>
              <div class="d-inline-block" :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 20%'">
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'callbackOffline') && step++" :color="(type == 'callbackOffline') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">mdi-backup-restore</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('callbackOffline')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </div>
              <div class="d-inline-block" :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 20%'">
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'fitting') && step++" :color="(type == 'fitting') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">mdi-hanger</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('fitting')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </div>
              <div class="d-inline-block" :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 20%'">
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'shooting') && step++" :color="(type == 'shooting') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">movie</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('shooting')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </div>
            </div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="2">
          <v-card-text>
            <h1 class="text-center my-4">{{eventTypeName}}</h1>
            <v-form ref="eventForm" class="pt-2" style="width: 60%; margin: auto;">
              <v-text-field v-model="name" class="mb-4" :label="$t('AddEventDialog.name')" autofocus></v-text-field>
              <div>
                <div :style="type == 'shooting' ? 'float: left' : ''">
                  <v-menu :close-on-content-click="false" v-model="menu" lazy transition="scale-transition" offset-y full-width min-width="290px">
                    <template v-slot:activator="{on}">
                      <v-text-field v-on="on" v-model="computedDateFormatted" :label="$t('AddEventDialog.date')" :rules="[v => !!v || 'Vyžadované pole']" readonly></v-text-field>
                    </template>
                    <v-date-picker :locale="$i18n.locale() == 'en' ? 'en-ww' : 'cs-cz'" no-title v-model="date" @input="menu = false"></v-date-picker>
                  </v-menu>
                </div>
                <div v-if="type == 'shooting'" style="float: right; height: 68px; position: relative;">
                  <v-btn-toggle v-model="shootingType" mandatory class="elevation-0" borderless>
                    <v-btn style="border: 1px solid rgba(0,0,0,0.2)" :color="shootingType == 0 ? `#65bcff` : null" :class="shootingType == 0 ? `white--text elevation-0` : `v-btn--outline elevation-0`">Denní</v-btn>
                    <v-btn style="border: 1px solid rgba(0,0,0,0.2)" :color="shootingType == 1 ? `#65bcff` : null" :class="shootingType == 1 ? `white--text elevation-0` : `v-btn--outline elevation-0`">Posunuté</v-btn>
                    <v-btn style="border: 1px solid rgba(0,0,0,0.2)" :color="shootingType == 2 ? `#65bcff` : null" :class="shootingType == 2 ? `white--text elevation-0` : `v-btn--outline elevation-0`">Noční</v-btn>
                  </v-btn-toggle>
                </div>
                <div style="clear: both"></div>
              </div>
            </v-form>
            <div class="text-center pt-2">
              <v-btn class="elevation-0 next" color="primary" @click="nextStep" :disabled="!date">{{$t('next')}}</v-btn>
              <v-btn class="elevation-0 back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="3">
          <v-card-text>
            <h1 class="text-center my-4">{{$t('AddEventDialog.roleSelect')}}</h1>
            <section style="width: 90%; margin: auto;">
              <v-expansion-panels v-for="(role, i) in roles" :key="i" :value="role.enabled ? [0] : []" multiple class="shadow mb-2" :class="role.enabled ? `role-active` : ``">
                <v-expansion-panel hide-default-footer readonly :value="role.enabled" style="border-radius: 4px;">
                  <v-expansion-panel-header>
                    <div class="px-3 my-3" :class="role.enabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click="role.enabled = !role.enabled">
                      <div class="square-checkbox d-flex"><v-icon color="#65bcff" class="ma-0">done</v-icon></div>
                      <div class="salary-header-text">{{role.roleName}}</div>
                      <div style="clear: both"></div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card color="white" class="elevation-0">
                      <v-card-text class="pt-0 role-content">
                        <v-textarea v-model="role.instructions" rows="3" :label="`Instrukce pro roli ` + role.roleName.toLowerCase() + ` na ` + eventTypeName.toLowerCase()" class="mt-3"></v-textarea>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </section>
            <div class="text-center pt-2">
              <v-btn class="elevation-0 next" color="primary" @click="save" :loading="saving" :disabled="enabledRoles < 1">{{$t('createEvent')}}</v-btn>
              <v-btn class="elevation-0 back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.role-dialog .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 8px !important;
}
.role-dialog h1 {
  font-weight: 400;
  font-size: 32px;
}
#app .big-icon-btn {
  height: initial;
  padding: 0px;
}

#app .big-icon-btn .v-btn__content {
  display: initial;
  min-width: 120px;
}
#app .role-dialog .v-btn.next {
  margin: 28px auto 0px auto;
  padding: 14px 41px;
  height: 56px;
  font-size: 16px;
  display: block;
}
#app .role-dialog .v-btn.back {
  margin: 24px auto 0px auto;
  font-weight: 300;
  display: block;
}
#app .role-dialog .v-expansion-panel-header {
  padding: 0px;
}
#app .role-dialog .role-active .v-expansion-panel-header {
  background-color: #65bcff;
  border-radius: 3px;
}
#app .role-dialog .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
#app .role-dialog .salary-header-text {
  float: left;
  line-height: 32px;
  font-size: 16px;
  margin-left: 0px;
}
#app .role-dialog .role-active .salary-header-text {
  color: white;
}
#app .role-dialog .salary-header-total {
  float: right;
  color: white;
  font-size: 18px;
  width: 110px;
  text-align: right;
  border-left: 1px solid rgba(255,255,255,.3);
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.role-dialog .increment-button {
  border-radius: 3px !important; 
  float: right; 
  width: 32px; 
  height: 32px;
}
.role-dialog .v-btn--icon:before {
  border-radius: 3px !important; 
}

#app .role-dialog .square-checkbox {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: #eeeff0;
  box-shadow: inset 0px 1px 5px #babcbe;
  display: flex;
  border-radius: 3px;
  float: left;
}
#app .role-dialog .square-checkbox i {
  margin: 4px !important;
}
#app .checked .square-checkbox {
  background-color: white;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as api from '@/libs/api'

export default {
  name: 'AddEventDialog',
  props: {
    saveRole: {
      type: Function,
      default: (role) => {
        console.log(role);
      }
    }
  },
  data: () => {
    return {
      step: 1,
      type: null,
      name: null,
      date: null,
      dateFormatted: null,
      shootingType: 0,
      menu: false,
      roles: [],
      requiredFieldRule: v => !!v || 'Vyžadované pole',
      saving: false
    }
  },
  watch: {
    show(val) {
      //this.$refs.eventForm.reset();
      if (val == true) {
        this.step = 1;
        this.type = null;
        this.name = null;
        this.updateRoles();
      }
    },
    date() {
      this.dateFormatted = this.formatDate(this.date)
    },
    type(val) {
      this.name = this.$t(val);
    }
  },
  computed: {
    show: {
      get: function() { return this.$store.state.dialogs.newEvent.show; },
      set: function(value) { return this.$store.state.dialogs.newEvent.show = value }
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    eventTypeName() {
      if (!this.type)
        return "Událost";
      
      switch (this.type) {
        case "castingOnline": return "Online casting";
        case "callbackOnline": return "Online callback";
        case "callbackOffline": return "Offline callback";
        case "shooting": return "Natáčení";
        case "other": return "Událost";
      }

      return "Událost";
    },
    enabledRoles() {
      let count = 0;
      for (let role of this.roles)
        if (role.enabled)
          count++;
      return count;
    }
  },
  methods: {
    ...mapActions(['addEventToCurrentProject']),
    ...mapGetters(['getCurrentProject']),
    nextStep() {
      if (!this.$refs.eventForm.validate()) 
        return;
      this.step++;
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    updateRoles() {
      let project = this.getCurrentProject();
      let result = [];
      let roles = project.roles;
      for (let role of roles) {
        result.push({
          enabled: false,
          roleType: role.roleType,
          roleName: role.roleName,
          roleId: role.roleId,
          instructions: null
        });
      }

      this.roles = result;
    },
    async save() {
      this.saving = true;
      if (!this.$refs.eventForm.validate()) return;
      let roles = this.roles.filter(role => role.enabled);
      let project = this.getCurrentProject();
      let event = {
        eventType: this.type,
        eventName: this.name,
        eventDate: this.date,
        eventRoles: roles,
        instructions: this.instructions
      };
      if (this.type == 'shooting')
        event.shootingType = this.shootingType;
      let dbEvent = await api.addEvent(project.projectId, event);
      this.addEventToCurrentProject(dbEvent);
      this.show = false;
      // todo omg... fixnout pomoci refcountingu
      window.setTimeout(() => {
        window.location.reload();
      }, 600);
    }
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="500" @keydown.esc="show = false" content-class="elevation-0 pa-0">
    <v-card color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">Zadejte e-mail uživatele, kterého chcete přidat do vašeho účtu</v-card-title>
      <v-card-text>
        <v-form ref="inviteForm">
          <v-text-field autofocus v-model="email" label="E-mail" :rules="[v => !!v || 'Vyžadované pole']"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="show = false">Storno</v-btn>
        <v-btn class="elevation-0" color="primary" @click="sendInvite">Zažádat o přístup</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>
import * as api from '@/libs/api'

export default {
  name: 'InviteDialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
  },
  data: () => {
    return {
      email: null
    }
  },
  computed: {
    show: {
      get: function() { return this.$store.state.dialogs.inviteDialog.show; },
      set: function(value) { return this.$store.state.dialogs.inviteDialog.show = value }
    }
  },
  methods: {
    async sendInvite() {
      if (!this.email) return;
      
      await api.inviteUser(this.email);

      this.show = false;
    }
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="800" @keydown.esc="show = false" content-class="pa-0 role-dialog">
    <v-card color="#f6f8fa" class="pb-4">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
        <v-card-text>
        <h1 class="text-center my-4">{{$t('AddEventRolesDialog.headline')}}</h1>
        <section style="width: 90%; margin: auto;">
            <v-expansion-panels v-for="(role, i) in roles" :key="i" class="shadow mb-2" :class="role.enabled ? `role-active` : ``">
            <v-expansion-panel hide-default-footer readonly :value="role.enabled" style="border-radius: 4px;">
                <v-expansion-panel-header>
                  <div class="px-3 my-3" :class="role.enabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click="role.enabled = !role.enabled">
                      <div class="square-checkbox d-flex"><v-icon color="#65bcff" class="ma-0">done</v-icon></div>
                      <div class="salary-header-text">{{role.roleName}}</div>
                      <div style="clear: both"></div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card color="white">
                    <v-card-text class="pt-0 role-content">
                        <v-textarea v-model="role.instructions" rows="3" :label="`Instrukce pro roli ` + role.roleName.toLowerCase() + ` na ` + event.eventType.toLowerCase()" class="mt-3"></v-textarea>
                    </v-card-text>
                  </v-card>
                </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>
        </section>
        <div class="text-center pt-2">
            <v-btn class="elevation-0 next" color="primary" @click="save" :disabled="enabledRoles < 1">{{$t('save')}}</v-btn>
            <v-btn class="elevation-0 back" color="#8f969a" text @click="show=false">{{$t('cancel')}}</v-btn>
        </div>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.role-dialog .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 8px !important;
}
.role-dialog h1 {
  font-weight: 400;
  font-size: 32px;
}
#app .big-icon-btn {
  height: initial;
  padding: 0px;
}

#app .big-icon-btn .v-btn__content {
  display: initial;
  min-width: 120px;
}
#app .role-dialog .v-btn.next {
  margin: 28px auto 0px auto;
  padding: 14px 41px;
  height: 56px;
  font-size: 16px;
  display: block;
}
#app .role-dialog .v-btn.back {
  margin: 24px auto 0px auto;
  font-weight: 300;
  display: block;
}
#app .role-dialog .v-expansion-panel-header {
  padding: 0px;
}
#app .role-dialog .role-active .v-expansion-panel-header {
  background-color: #65bcff;
  border-radius: 3px;
}
#app .role-dialog .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
#app .role-dialog .salary-header-text {
  float: left;
  line-height: 32px;
  font-size: 16px;
  margin-left: 0px;
}
#app .role-dialog .role-active .salary-header-text {
  color: white;
}
#app .role-dialog .salary-header-total {
  float: right;
  color: white;
  font-size: 18px;
  width: 110px;
  text-align: right;
  border-left: 1px solid rgba(255,255,255,.3);
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.role-dialog .increment-button {
  border-radius: 3px !important; 
  float: right; 
  width: 32px; 
  height: 32px;
}
.role-dialog .v-btn--icon:before {
  border-radius: 3px !important; 
}

#app .role-dialog .square-checkbox {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: #eeeff0;
  box-shadow: inset 0px 1px 5px #babcbe;
  display: flex;
  border-radius: 3px;
  float: left;
}
#app .role-dialog .square-checkbox i {
  margin: 4px !important;
}
#app .checked .square-checkbox {
  background-color: white;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
</style>

<script>
import { mapGetters } from 'vuex'
import * as api from '@/libs/api'

export default {
  name: 'AddEventDialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    event: {
      type: Object,
      default: () => null
    }
  },
  data: () => {
    return {
      roles: [],
      requiredFieldRule: v => !!v || 'Vyžadované pole'
    }
  },
  watch: {
    show(val) {
      if (val == true) {
        this.updateRoles();
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    enabledRoles() {
      let count = 0;
      for (let role of this.roles)
        if (role.enabled)
          count++;
      return count;
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    updateRoles() {
      let project = this.getCurrentProject();
      let result = [];
      // Find roles that are not part of the event yet
      let roles = project.roles.filter(role => this.event.eventRoles.findIndex(eventRole => role.roleId === eventRole.roleId) < 0);
      for (let role of roles) {
        result.push({
          enabled: false,
          roleType: role.roleType,
          roleName: role.roleName,
          roleId: role.roleId,
          instructions: null
        });
      }

      this.roles = result;
    },
    async save() {
      let project = this.getCurrentProject();
      let existingRoles = project.roles.filter(role => this.event.eventRoles.findIndex(eventRole => role.roleId === eventRole.roleId) > -1);
      for (let r in existingRoles) {
        if (existingRoles[r].picks)
          delete existingRoles[r].picks;
      }
      let roles = this.roles.filter(role => role.enabled);
      await api.updateEvent({ projectId: this.event.projectId, eventId: this.event.eventId, eventRoles: [...existingRoles, ...roles] });
      this.show = false;
      window.setTimeout(() => {
        window.location.reload();
      }, 600);
    }
  }
}
</script>
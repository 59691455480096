<template>
<div style="height: 100%" class="shared-search">
  <v-app-bar app color="white" fixed clipped-left class="elevation-0 main-toolbar">
    <v-toolbar-title><a href="https://aircasting.cz/productions" target="_blank"><img src="https://prod.aircasting.cz/logo.svg" style="margin: 8px 24px 0px 0px;" height="32" /></a></v-toolbar-title>
    <v-toolbar-items v-if="!loading && allowSearch">
      <v-btn class="mx-0" text :to="{path: '/roles/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{shareName}}</span><v-icon v-else>mdi-apps</v-icon></v-btn>
      <v-btn class="mx-0" text :to="{path: '/search/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{$t('Layout.search')}}</span><v-icon v-else>search</v-icon></v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="!showNameDialog && !loading">
      <v-menu v-model="langMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-if="$vuetify.breakpoint.mdAndUp" v-bind="attrs" v-on="on" class="mt-4 mr-2" color="secondary" text-color="white">
            <v-avatar left large><v-icon>account_circle</v-icon></v-avatar>
            <span>{{name}}</span>
            <v-icon small right style="border-left: 1px solid white">mdi-dots-vertical</v-icon>
          </v-chip>
          <v-btn v-else v-bind="attrs" v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item :class="$i18n.locale() == 'cz' ? 'v-item--active v-list-item--active px-2' : 'px-2'" @click="setLocale('cz')">
              <v-list-item-icon class="mr-3"><img width="24" src="https://prod.aircasting.cz/assets/cz.svg" /></v-list-item-icon>
              <v-list-item-content><v-list-item-title>Čeština</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-list-item :class="$i18n.locale() == 'en' ? 'v-item--active v-list-item--active px-2' : 'px-2'" @click="setLocale('en')">
              <v-list-item-icon class="mr-3"><img width="24" src="https://prod.aircasting.cz/assets/us.svg" /></v-list-item-icon>
              <v-list-item-content><v-list-item-title>English</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
  <div v-if="loading" style="height: 100%">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  <section v-else-if="allowSearch" :style="$vuetify.breakpoint.smAndDown ? 'margin-top: 56px' : ''">
    <Search :getRoles="getRoles" :addPick="addPick" :removePick="removePick" :allowAddingRoles="false" :showProjectManagement="false" :showSearchByImage="false" :shareId="id"></Search>
  </section>
  <v-btn v-if="!loading" fixed fab large bottom right color="secondary" class="hidden-md-and-up" :to="{path: '/roles/' + this.id}"><v-icon large>mdi-apps</v-icon></v-btn>
  <v-dialog max-width="400" v-model="showNameDialog" persistent>
    <v-card flat tile color="#ebf7ff" class="pt-3">
      <v-card-text>
        <span style="font-size: 18px">Uveďte, prosím, své jméno</span>
        <v-text-field class="mt-4" v-model="name" solo :label="$t('SharedEvent.name')"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="saveName" :disabled="name === null || name.length < 3" color="primary">{{$t('closeOk')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<style>
#app.mobile .shared-search .main-toolbar .v-toolbar__content {
  padding-left: 6px;
}
</style>

<script>
import * as api from '@/libs/api'
import Search from '@/pages/Search'
import { mapActions } from 'vuex'

export default {
  name: 'SharedSearch',
  components: {
    Search
  },
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      loading: true,
      sharedRoles: null,
      allowSearch: true,
      name: null,
      showNameDialog: false,
    }
  },
  async mounted() {
    if (!this.sharedRoles) {
      let share = await api.getSharedRoles(this.id);
      if (share.roles)
        this.sharedRoles = share.roles;
      if (share.shareName)
        this.shareName = share.shareName;
      if (!share.allowSearch)
        this.allowSearch = false;
    }
    this.name = localStorage.getItem("shareName");
    if (!this.name)
      this.showNameDialog = true;
    this.loading = false;
  },
  methods: {
    ...mapActions(['showInfo']),
    getRoles() {
      return this.sharedRoles;
    },
    async addPick(pick, role) {
      pick.roleState = 'share-added';      
      if (await api.updateSharedRoleState(this.id, role.roleId, pick.userId, 'add', this.name)) {
        role.picks.push(pick);
      }
      else {
        this.showInfo({title: 'Tento herec byl již produkcí do tohoto projektu obsazen.'});
      }
    },
    async removePick(pick, role) {
      if (!role.picks || !role.picks.length)
        return;

      if (await api.updateSharedRoleState(this.id, role.roleId, pick.userId, 'remove', this.name)) {
        let i = role.picks.findIndex(p => p.userId === pick.userId);
        if (i < 0)
          return;

        role.picks.splice(i, 1);
      }
      else {
        this.showInfo({title: 'Tohoto herce nemůžete z projektu odstranit.'});
      }

    },
    saveName() {
      localStorage.setItem("shareName", this.name);
      this.showNameDialog = false;
    },
    setLocale(locale) {
      this.$i18n.set(locale);
      localStorage.setItem("locale", locale);
      typeof window !== 'undefined' && window.location.reload();
    },
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="800" @keydown.esc="show = false" content-class="elevation-0 pa-0">
    <v-card color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">{{title}}</v-card-title>
      <v-card-text class="pt-0">
        <v-form ref="noteForm">
          <v-textarea v-model="note" rows="10" :rules="[v => !!v || 'Vyžadované pole']"></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="show = false">{{$t('cancel')}}</v-btn>
        <v-btn class="elevation-0" color="primary" @click="saveInternal">{{$t('create')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
</style>

<script>

export default {
  name: 'AddNoteDialog',
  props: {
    title: {
      type: String,
      default: () => "Přidat popis projektu"
    },
    value: {
      type: Boolean,
      default: () => false
    },
    save: {
      type: Function,
      default: (note) => {
        console.log(note);
      }
    }
  },
  data: () => {
    return {
      name: 'O projektu',
      note: null,
      share: ['Všechny role']
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$refs.noteForm.reset(); this.$emit('input', value); }
    }
  },
  methods: {
    saveInternal: function() {
      if (!this.$refs.noteForm.validate()) return;
      this.save({
        type: 'text',
        note: {
          name: this.name, 
          text: this.note, 
        },
        share: this.share
      });
      this.show = false;
    }
  }
}
</script>
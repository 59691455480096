<template>
  <div style="height: 100%; background-color: rgb(235, 247, 255);">
    <v-app-bar app color="white" fixed clipped-left class="elevation-0 main-toolbar">
      <v-toolbar-title><a href="https://aircasting.cz/productions" target="_blank"><img src="https://prod.aircasting.cz/logo.svg" style="margin: 8px 24px 0px 0px;" height="32" /></a></v-toolbar-title>
      <v-toolbar-items v-if="!loading && allowSearch">
        <v-btn class="mx-0" text :to="{path: '/roles/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{shareName}}</span><v-icon v-else>mdi-apps</v-icon></v-btn>
        <v-btn class="mx-0" text :to="{path: '/search/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{$t('Layout.search')}}</span><v-icon v-else>search</v-icon></v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
    </v-app-bar>
    <ProfileWidget :userId="userId" :full-width="false"></ProfileWidget>
  </div>
  </template>
  
  <style>
  </style>

  <script>
  /*eslint-disable vue/no-unused-components*/
  import ProfileWidget from '@/widgets/Profile'

  export default {
    name: 'ProfilePage',
    props: {
      userId: {
        type: String,
        default: () => null
      },
    },
    components: {
      ProfileWidget,
    },
    
    data() {
      return {
        loading: false
      }
    },
    computed: {
     
    },
    methods: {
      
    }
  }
  </script>
  
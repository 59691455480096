<template>
<div style="height: 100%" class="shared-roles pb-5">
  <v-app-bar app color="white" fixed clipped-left class="elevation-0 main-toolbar">
    <v-toolbar-title><a href="https://aircasting.cz/productions" target="_blank"><img src="https://prod.aircasting.cz/logo.svg" style="margin: 8px 24px 0px 0px;" height="32" /></a></v-toolbar-title>
    <v-toolbar-items v-if="!loading && allowSearch">
      <v-btn class="mx-0" text :to="{path: '/roles/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{shareName}}</span><v-icon v-else>mdi-apps</v-icon></v-btn>
      <v-btn class="mx-0" text :to="{path: '/search/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{$t('Layout.search')}}</span><v-icon v-else>search</v-icon></v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="!showNameDialog && !loading">
      <v-menu v-model="langMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-if="$vuetify.breakpoint.mdAndUp" v-bind="attrs" v-on="on" class="mt-4 mr-2" color="secondary" text-color="white">
            <v-avatar left large><v-icon>account_circle</v-icon></v-avatar>
            <span>{{name}}</span>
            <v-icon small right style="border-left: 1px solid white">mdi-dots-vertical</v-icon>
          </v-chip>
          <v-btn v-else v-bind="attrs" v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item :class="$i18n.locale() == 'cz' ? 'v-item--active v-list-item--active px-2' : 'px-2'" @click="setLocale('cz')">
              <v-list-item-icon class="mr-3"><img width="24" src="https://prod.aircasting.cz/assets/cz.svg" /></v-list-item-icon>
              <v-list-item-content><v-list-item-title>Čeština</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-list-item :class="$i18n.locale() == 'en' ? 'v-item--active v-list-item--active px-2' : 'px-2'" @click="setLocale('en')">
              <v-list-item-icon class="mr-3"><img width="24" src="https://prod.aircasting.cz/assets/us.svg" /></v-list-item-icon>
              <v-list-item-content><v-list-item-title>English</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
  <div v-if="loading" style="height: 100%">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  <v-container v-else-if="sharedRoles" fluid grid-list-md  class="pt-3 pb-5 shared" :class="$vuetify.breakpoint.smAndDown ? 'px-2' : 'px-5'" :style="'max-width: 100%;' + ($vuetify.breakpoint.smAndDown ? 'margin-top: 56px' : '')">
    <h1 class="mb-5">{{shareName}}</h1>
    <template v-for="(role, r) in sharedRoles">
      <section :key="r" class="px-3 pt-3 pb-4 mb-5 role_section shadow white" v-if="role.picks && role.picks.length">
        <h2 style="font-size: 22px; font-weight: normal">
          <v-icon @click="role.hide = !role.hide" class="mr-2">{{role.hide ? 'expand_less' : 'expand_more'}}</v-icon>
          <span>{{role.roleName}}</span> <span class="grey--text text--lighten-2">&bull;</span> <span class="grey--text text--lighten-1">{{filteredActorsCount(role)}}</span>
          <v-menu v-model="role.filterMenu" :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="elevation-0 mt-1" :outlined="!role.filter.length" small :color="!role.filter.length ? null : 'secondary'" :style="!role.filter.length ? 'float: right; margin-left: 6px;' : 'float: right'" v-bind="attrs" v-on="on"><v-icon left>filter_alt</v-icon>{{$t('SharedRoles.filter')}}</v-btn>
            </template>
            <v-card>
              <v-list dense>
                <v-list-item-group color="primary" multiple>
                  <v-list-item v-for="(state, i) in role.availableStates" :key="i" :class="isFilterSet(role.filter, state.value) ? 'v-item--active v-list-item--active' : ''" @click="updateRoleFilter(role.filter, state.value)">
                    <v-list-item-icon class="mr-2">
                      <v-icon v-text="getStateIcon(state.value)"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title v-text="state.text"></v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-menu>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-icon v-on="on" style="margin-top: 6px; float: right" :color="!role.filter.length ? 'black' : 'black'" @click="selectFilter(role)">{{ !isAnySelectionInRole(role) ? 'radio_button_unchecked' : 'check_circle_outline' }}</v-icon>
            </template>
            <span>{{role.filter.length > 0 ? $t('SharedRoles.selectActorsFilter') : $t('SharedRoles.selectActors')}}</span>
          </v-tooltip>
          <div style="clear: both"></div>
        </h2>
        <v-layout row wrap style="min-width: 100%" v-if="!role.hide">
          <template v-for="(profile, i) in role.picks">
            <v-flex xs12 md3 lg2 xl2 class="pa-2" :key="i" v-if="!role.filter || !role.filter.length || isFilterSet(role.filter, profile.roleState) || (profile.likes && profile.likes.length > 0 && isFilterSet(role.filter, 'like'))">
              <MiniProfile @showProfile="showProfileDetail" @check="profile.checked = !profile.checked" :checked="!!profile.checked" :profile="profile" :showControls="false" :state="{state: profile.roleState, sex: profile.sex}" >
                <template v-slot:footer="props">
                  <div style="width: 100%; font-size: 16px; font-weight: 400;" :class="(profile.roleState == 'winner') ? 'white--text' : null">
                    <div style="float: left">
                      <span>{{profile.fullname}}</span>
                      <div :class="((profile.roleState == 'winner' || props.checked) ? 'white' : getStateColor(profile.roleState)) + `--text`" style="font-size: 12px">
                        <v-icon size="16" :color="(profile.roleState == 'winner' || props.checked) ? 'white' : getStateColor(profile.roleState)">{{getStateIcon(profile.roleState)}}</v-icon>
                        {{ getStateText(profile.roleState, profile.sex) }}
                      </div>
                    </div>
                    <div style="float: right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                          <v-badge :value="typeof profile.likes != 'undefined' && profile.likes.length > 0" right overlap :color="(props.checked || profile.roleState == 'winner') ? 'black' : 'primary'" :class="(!profile.likes || !profile.likes.length) ? 'show-on-hover' : null">
                            <template v-slot:badge>{{ profile.likes ? profile.likes.length : 0 }}</template>
                            <v-btn v-on="on" icon small class="ma-0" @click.stop="like(profile, role)"><v-icon :color="(props.checked || profile.roleState == 'winner') ? 'white' : (liked(profile) ? 'secondary' : 'black')">{{liked(profile) ? `mdi-thumb-up` : `mdi-thumb-up-outline`}}</v-icon></v-btn>
                          </v-badge>
                        </template>
                        <span v-if="profile.likes && profile.likes.length">
                          <div v-for="(like, l) in profile.likes" :key="l">{{like}}</div>
                        </span>
                        <span v-else>{{$t('Files.notRankedYet')}}</span>
                      </v-tooltip>
                    </div>
                    <div style="clear: both"></div>
                  </div>
                </template>
              </MiniProfile>
            </v-flex>
          </template>
        </v-layout>
      </section>
    </template>
    <ProfileDetail v-model="profileDialog.show" :userProfile="profileDialog.profile" :showControls="false" />
  </v-container>
  <v-alert :value="itemsSelected" dark color="#b3d4fc" class="share-bar black--text">
    <template v-if="itemsSelected">
      <v-avatar v-for="(selected, s) in selectedAvatars" :key="s" color="white" size="40" class="mr-1">
        <v-img v-if="selected.url" :src="selected.url"></v-img>
        <template v-else>{{selected.count}}</template>
      </v-avatar>
      <div class="button_row">
        <div class="content">
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn v-on="on" small fab light class="ml-1 mr-1 elevation-0" @click="showShareDialog()">
                <v-icon size="24" color="black">share</v-icon>
              </v-btn>
            </template>
            <span>{{$t('SharedRoles.share')}}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{on}">
              <v-btn v-on="on" small fab light class="ml-1 mr-1 elevation-0" @click="clearSelection">
                <v-icon color="black">close</v-icon>
              </v-btn>
            </template>
            <span>{{$t('SharedRoles.removeSelection')}}</span>
          </v-tooltip>
        </div>
      </div>
    </template>
  </v-alert>
  <div class="fixedChatButton">
    <v-badge v-if="!loading && name && !showChat" :content="newMessages" :value="newMessages" overlap left bordered color="green">
      <v-btn fab large bottom right color="primary" @click="showChat = true"><v-icon large>chat</v-icon></v-btn>
    </v-badge>
  </div>
  <v-navigation-drawer v-if="!loading && name" v-model="showChat" right width="350" class="pa-0" fixed style="height: calc(100% - 64px); top: 64px; z-index: 20050;">
    <v-btn absolute fab top left small class="dialog_close_alt" @click="showChat=false"><v-icon size="24">close</v-icon></v-btn>
    <SharedChat :id="id" :userName="name" :getMessages="getMessages" :sendMessage="sendMessage" :shown="showChat"></SharedChat>
  </v-navigation-drawer>
  <v-dialog max-width="400" v-model="showNameDialog" persistent>
    <v-card flat tile color="#ebf7ff" class="pt-3">
      <v-card-text>
        <span style="font-size: 18px">{{$t('SharedRoles.nameTitle')}}</span>
        <v-text-field class="mt-4" v-model="name" solo :label="$t('SharedEvent.name')"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="saveName" :disabled="name === null || name.length < 3" color="primary">{{$t('closeOk')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import * as api from '@/libs/api'
import * as states from '@/libs/states'
import MiniProfile from '@/widgets/MiniProfile'
import ProfileDetail from '@/dialogs/ProfileDetail'
import SharedChat from '@/panels/SharedChat'
import { mapGetters } from 'vuex'
import { EventBus } from '@/libs/eventBus.js'

export default {
  name: 'SharedRoles',
  components: {
    MiniProfile,
    ProfileDetail,
    SharedChat
  },
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      loading: true,
      sharedRoles: null,
      allowSearch: false,
      name: null,
      showNameDialog: false,
      showChat: false,
      newMessages: 0,
      langMenu: false,
      profileDialog: {
        show: false,
        profile: null
      },
      selectedProfile: null,
    }
  },
  async mounted() {
    if (!this.sharedRoles) {
      let share = await api.getSharedRoles(this.id);
      
      if (share.roles) {
        for (let role of share.roles) {
          role.hide = false;
          role.filter = [];
          role.filterMenu = false;

          let availableStates = {}; // use object for performance of indexing keys
          for (let pick of role.picks) {
            pick.checked = false;
            if (!(pick.roleState in availableStates))
              availableStates[pick.roleState] = this.$t(states.getStateText(pick.roleState));

            if (pick.likes && pick.likes.length && !('like' in availableStates))
              availableStates['like'] = 'Like';
          }
          role.availableStates = [];
          for (const [key, value] of Object.entries(availableStates)) {
            role.availableStates.push({
              value: key,
              text: value
            });
          }
          role.availableStates.sort((a, b) => states.stateToValue(b.value) - states.stateToValue(a.value));
        }
        this.sharedRoles = share.roles;
      }
      if (share.shareName)
        this.shareName = share.shareName;
      if (share.allowSearch != null)
        this.allowSearch = share.allowSearch;
    }

    this.updateNewMessages();

    setInterval(async () => {
      this.updateNewMessages();
    }, 30000);

    EventBus.$on('chatMessagesRead', () => {
      this.updateNewMessages();
    });

    this.name = localStorage.getItem("shareName");
    if (!this.name)
      this.showNameDialog = true;
    this.loading = false;
  },
  computed: {
    itemsSelected() {
      if (!this.sharedRoles || !this.sharedRoles.length)
        return false;
      for (let role of this.sharedRoles) {
        if (!role.picks || !role.picks.length)
          continue;
        if (role.picks.findIndex(pick => pick.checked === true) >= 0)
          return true;
      }
      return false;
    },
    selectedAvatars() {
      let result = [];
      let count = 0
      let max = this.$vuetify.breakpoint.lgAndUp ? 10 : (this.$vuetify.breakpoint.mdAndUp ? 5 : 2);
      for (let role of this.sharedRoles) {
        if (!role.picks || !role.picks.length)
          continue;
        for (let pick of role.picks) {
          if (pick.checked) {
          
          if (++count <= max)
            result.push({url: this.getImageUrl(pick, pick.mainPhoto, 40)});            
          }
        }
      }

      if (count > max)
        result.push({count: '+' + (count - max)});

      return result;
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    showProfileDetail(profile) {
      this.profileDialog.profile = null;
      this.$nextTick(() => {
        this.profileDialog.profile = profile;
        this.profileDialog.show = true;
      });
    },
    saveName() {
      localStorage.setItem("shareName", this.name);
      this.showNameDialog = false;
    },
    async getMessages() {
      return await api.getSharedMessages(this.id);
    },
    async sendMessage(text, userName) {
      return await api.sendSharedMessage(this.id, text, userName);
    },
    async updateNewMessages() {
      try {
        let newMessages = 0;
        let messages = await this.getMessages();
        let last = localStorage.getItem("lastMessage-" + this.id);
        if (!last)
          last = 0;
        if (messages) {
          for (let message of messages)
            if (message.time > last && message.userName != this.name)
              newMessages++;      
        }
        this.newMessages = newMessages;
      }
      catch (err) {
        console.log(err);
      }
    },
    getStateText(state, sex) {
      return this.$t(states.getStateText(state, sex));
    },
    getStateIcon(state) {
      if (state == 'like')
        return 'mdi-thumb-up-outline';
      if (state == 'added')
        return 'mdi-account';
      return states.getStateIcon(state);
    },
    getStateColor(state) {
      if (state == 'added')
        return 'gray';
      return states.getStateColor(state);
    },
    liked(profile) {
      if (!profile || !profile.likes)
        return false;

      return profile.likes.findIndex(like => like === this.name) > -1;
    },
    async like(profile) {
      if (!profile)
        return;

      if (!profile.likes)
        profile.likes = [];

      let index = profile.likes.findIndex(like => like === this.name);
      if (index > -1) {
        profile.likes.splice(index, 1);
        await api.likeSharedRoleProfile(this.id, profile.userId, this.name, false);
      }
      else {
        profile.likes.push(this.name);
        await api.likeSharedRoleProfile(this.id, profile.userId, this.name, true);          
      }

      /*if (role)
        this.sort(role.picks);*/
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    clearSelection() {
      for (let role of this.sharedRoles) {
        if (!role.picks || !role.picks.length)
          continue;
        for (let pick of role.picks)
          pick.checked = false;
      }
    },
    isFilterSet(filter, state) {
      if (!filter || !state)
        return false;

      return filter.findIndex(f => f === state) != -1;
    },
    updateRoleFilter(filter, state) {
      if (!filter || !state)
        return;

      let i = filter.findIndex(f => f === state);
      if (i > -1)
        filter.splice(i, 1);
      else
        filter.push(state);
    },
    filteredActorsCount(role) {
      if (!role.filter || !role.filter.length)
        return role.picks.length;

      let count = 0;
      for (let pick of role.picks) {
        if (this.isFilterSet(role.filter, pick.roleState, pick.likes)) {
          count++;
        }
      }

      return count;
    },
    isAnySelectionInRole(role) {
      return role.picks.findIndex(pick => pick.checked) > -1;
    },
    selectFilter(role) {
      let checked = role.picks.findIndex(pick => pick.checked) > -1;
      for (let pick of role.picks) {
        if (checked) // clear regardless of filter, if any checked
          pick.checked = false;
        else if (!role.filter || !role.filter.length || this.isFilterSet(role.filter, pick.roleState) || (pick.likes && pick.likes.length > 0 && this.isFilterSet(role.filter, 'like'))) {
          pick.checked = !checked;
        }
      }
    },
    sort(actors) {
      actors.sort((a, b) => {
        let va = ((1000 * (a.likes ? a.likes.length : 0)) + states.stateToValue(a.roleState, a.winner === true));
        let vb = ((1000 * (b.likes ? b.likes.length : 0)) + states.stateToValue(b.roleState, b.winner === true));
        return vb - va;
      });

      return actors;
    },
    setLocale(locale) {
      this.$i18n.set(locale);
      localStorage.setItem("locale", locale);
    },
    showShareDialog() {
      let roles = [];
      for (let role of this.sharedRoles) {
        let item = { picks: [] }
        for (let pick of role.picks) {
          if (pick.checked)
            item.picks.push(pick);
        }

        if (!item.picks.length)
          continue;

        item.projectId = role.projectId;
        item.roleId = role.roleId;
        item.roleName = role.roleName;
        roles.push(item);
      }

      if (roles.length)
        this.$store.state.dialogs.shareProfiles = { show: true, allowSearch: this.allowSearch, roles: roles, shareName: this.shareName + ' - edit', shareId: this.id, userName: this.name};
    }
  }
}
</script>


<style>
.v-alert.share-bar {
  position: fixed;
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 20000;
}
.v-alert.share-bar .v-avatar {
  margin-left: -14px;
}
#app.mobile .v-alert.share-bar {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.share-bar .v-avatar {
  margin-left: -24px;
}
.v-alert.share-bar .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
  width: 100%;
}

.v-alert.share-bar .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.share-bar .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
  right: 0px;
}
.v-alert.share-bar .close_alert .v-btn {
  margin-top: 12px;
}

.shared-roles .v-btn--left.v-btn--top.v-btn--absolute.v-size--small.dialog_close_alt {
  top: 4px !important;
  left: 4px;
  border-radius: 50% !important;
  box-shadow: inherit !important;
}
h1 {
  font-weight: 300;
}
.show-on-hover {
  visibility: hidden;
}

.v-card:hover .show-on-hover {
  visibility: visible;
}
#app.mobile .show-on-hover {
  visibility: visible;
  opacity: 1;
}
.check_header {
  margin-left: -36px;
}
#app.desktop .role_section .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: hidden;
}
#app.desktop .role_section:hover .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: visible;
}
.v-alert.bottom_alert {
  position: fixed; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 2;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
}
.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
.files_page .v-chip .v-chip__content {
  cursor: pointer !important;
}
.shared .v-badge__badge {
  width: 18px;
  height: 18px;
}
.shared-comments-dialog .v-list__tile {
  padding: 0px;
}
.shared-comments-dialog .v-list--two-line .v-list__tile {
  height: initial !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.shared-comments-dialog .v-list__tile__sub-title {
  white-space: pre-wrap;
}
.fixedChatButton {
  position: fixed;
  bottom: 16px;
  right: 12px;
  z-index: 20001;
}
.fixedChatButton .v-badge__badge {
  inset: auto calc(100% - 22px) calc(100% - 18px) auto !important;
}
.shared-roles .mini-profile .v-badge__badge {
  margin: 2px;
}
#app.mobile .shared-roles .main-toolbar .v-toolbar__content {
  padding-left: 6px;
}
</style>
<template>
  <v-app>
    <v-app-bar app v-if="!share && $vuetify.breakpoint.mdAndUp && isAuthenticated" color="white" fixed clipped-left class="elevation-0 main-toolbar">
      <v-toolbar-title @click="navigate('search')"><img src="logo.svg" style="margin: 8px 24px 0px 0px;" height="32" /></v-toolbar-title>
      <template v-if="$vuetify.breakpoint.smAndDown">
        <v-spacer></v-spacer>
        <v-btn fab small @click="hamburgerMenu = true" color="white" class="elevation-0">
          <v-icon size="24">menu</v-icon>
        </v-btn>
      </template>
      <template v-else>
        <v-toolbar-items>
          <v-btn class="mx-0" text :to="{path: '/project'}">{{$t('Layout.projectSummary')}}</v-btn>
          <v-btn class="mx-0" v-if="project" text :to="{path: '/roles'}">{{$t('Layout.roles')}}</v-btn>
          <v-btn class="mx-0" text :to="{path: '/search'}">{{$t('Layout.search')}}</v-btn>
          <v-btn class="mx-0" v-if="project && user && !user.lite" text :to="{path: '/messages'}">{{$t('Layout.communication')}}</v-btn>
        </v-toolbar-items>
        <v-spacer></v-spacer>
        <v-toolbar-items class="hidden-sm-and-down">
          <v-btn v-if="project && user && !user.lite" text @click="projectDrawer = true">
            <v-avatar v-if="project.logo" size="36px" class="mr-2">
              <img :src="project.logo" />
            </v-avatar>
            <span>{{project.client}}</span> 
            <span v-if="project.client" class="ml-1 mr-1 grey--text text--lighten-2">&bull;</span> 
            <span>{{project.projectName}}</span> 
            <v-icon small class="ml-2">fa-angle-down</v-icon>
          </v-btn>
          <v-btn text @click="accountDrawer = true"><v-icon large class="ml-2 mr-2">account_circle</v-icon> {{ user ? user.fullname : `` }} <v-icon small class="ml-2">fa-angle-down</v-icon></v-btn>
        </v-toolbar-items>
      </template>
    </v-app-bar>
    <v-content :class="$vuetify.breakpoint.smAndDown ? `pt-0` : ``">
      <router-view></router-view>
    </v-content>
    <v-toolbar v-if="!share && $vuetify.breakpoint.smAndDown && isAuthenticated" color="white" clipped-left app class="pa-0 elevation-0 main-toolbar" style="outline: 2px solid rgba(0,0,0,0.1); bottom: 0px !important; position: fixed; width: 100%; top: initial;">
      <div class="mx-auto">
        <v-btn v-if="user && !user.lite" fab small @click="hamburgerMenu = true" color="white" class="elevation-0 ma-1">
          <v-icon size="24">menu</v-icon>
        </v-btn>
        <v-btn v-if="user && !user.lite" fab small @click="navigate('project')" color="white" class="elevation-0 ma-1">
          <v-icon size="24">fas fa-edit</v-icon>
        </v-btn>
        <v-btn v-if="user && !user.lite" fab small @click="navigate('roles')" color="white" class="elevation-0 ma-1">
          <v-icon size="24">fas fa-users</v-icon>
        </v-btn>
        <v-btn fab small @click="navigate('search')" color="white" class="elevation-0 ma-1">
          <v-icon size="24">fas fa-search</v-icon>
        </v-btn>
      </div>
    </v-toolbar>
    <v-navigation-drawer v-if="project && user && !user.lite" v-model="projectDrawer" touchless fixed right :width="$vuetify.breakpoint.smAndDown ? 250 : 400" temporary>
      <ProjectsList @input="projectDrawer = $event"></ProjectsList>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="accountDrawer" touchless fixed right :width="$vuetify.breakpoint.smAndDown ? 250 : 400" temporary>
      <AccountPanel></AccountPanel>
    </v-navigation-drawer>
    <v-navigation-drawer v-model="hamburgerMenu" fixed left width="250" temporary>
      <v-list class="ma-0 pa-0">
        <v-list-item style="background-color: #f6f8fa; height: 48px; line-height: 48px; border-bottom: 1px solid rgb(228,228,228)">
          <v-list-item-title class="pl-2 pr-2 pb-0" style="font-weight: bold">{{$t('Layout.menu')}}</v-list-item-title>
        </v-list-item>
        <v-list-item @click="showAccountDrawer">
          <v-list-item-avatar>
            <v-avatar size="36px">
              <v-icon large>account_circle</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content><v-list-item-title>{{ user ? user.fullname : `` }}</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item @click="showProjectDrawer" v-if="project">
          <v-list-item-avatar>
            <v-avatar v-if="project.logo" size="36px">
              <img :src="project.logo" />
            </v-avatar>
            <div v-else-if="project.client" class="project-icon">{{project.client[0]}}</div>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{project.client}}</v-list-item-title>
            <v-list-item-subtitle>{{project.projectName}}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-3"></v-divider>
        <v-list-item  v-if="user && !user.lite" :to="{path: '/project'}">
          <v-list-item-avatar>
            <v-avatar size="36px">
              <v-icon :size="20">fas fa-edit</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content><v-list-item-title>{{$t('Layout.projectSummary')}}</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item v-if="project && user && !user.lite" :to="{path: '/roles'}">
          <v-list-item-avatar>
            <v-avatar size="36px">
              <v-icon :size="20">fas fa-users</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content><v-list-item-title>{{$t('Layout.roles')}}</v-list-item-title></v-list-item-content>
        </v-list-item>
        <v-list-item :to="{path: '/search'}">
          <v-list-item-avatar>
            <v-avatar size="36px">
              <v-icon :size="20">fas fa-search</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content><v-list-item-title>{{$t('Layout.search')}}</v-list-item-title></v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import router from '@/router'
import ProjectsList from '@/panels/ProjectsList'
import AccountPanel from '@/panels/Account'

export default {
  components: {
    ProjectsList,
    AccountPanel
  },
  data: () => {
    return {
      email: '',
      projectDrawer: false,
      accountDrawer: false,
      hamburgerMenu: false
    }
  },
  computed: {
    project: function() {
      return this.getCurrentProject();
    },
    isAuthenticated: function() {
      return this.getLoggedUser() !== null;
    },
    user() {
      return this.getLoggedUser();
    },
    share() {
      let paths = window.location.pathname.split('/');
      if (paths.length && paths.length > 2 && (paths[1] === 'share' || paths[1] === 'profile' || paths[1] === 'profiles' || paths[1] === 'event' || paths[1] === 'roles' || paths[1] === 'search'))
        return true;

      return false;
    }
  },
  methods: {
    ...mapActions(['setLoggedUser']),
    ...mapGetters(['getCurrentProject', 'getLoggedUser']),
    navSignOut: function () {
      this.$store.dispatch('signOut').then(() => {
        this.setLoggedUser(null);
        router.push('/signin')
      });
    },
    navigate: function (path) {
      router.push('/' + path)
    },
    showProjectDrawer: function() {
      this.hamburgerMenu = false;
      this.projectDrawer = true;
    },
    showAccountDrawer: function() {
      this.hamburgerMenu = false;
      this.accountDrawer = true;
    },
  }
}
</script>
<style>
 .toolbar__title {
   padding: 6px 18px;
   cursor: pointer;
   font-size: 24px;
 }

 .toolbar__title span {
   font-weight: bold;
 }
.main-toolbar .v-btn {
  color: rgba(0,0,0,.47) !important;
}
.main-toolbar .v-btn--active:before{
  background-color: transparent;
  border-bottom: 0px solid transparent;
}
.main-toolbar .v-btn--active .v-btn__content{
  margin-top: 2px;
}
.main-toolbar .v-btn--active {
  color: rgba(0,0,0,.87) !important;
  border-bottom: 3px solid #65bcff;
  border-top: 1px solid white;
  border-radius: 0px !important;
}

.main-toolbar .v-toolbar__content {
  border-bottom: 1px solid rgb(224, 224, 224)
}
#app.mobile .main-toolbar .v-toolbar__content {
  padding: 0px;
}
.project-icon {
  border-radius: 50%;
  background-color: #002e99;
  color: white;
  font-weight: 400 !important;
  font-size: 1.4em;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: inline-block;
}
</style>

<template>
  <li v-if="isFolder">
    <v-btn  @click="toggle" text class="my-2"><v-icon left small>{{open ? 'fa-angle-down' : 'fa-angle-right' }}</v-icon>{{model.name}}</v-btn>
    <ul v-if="open">
      <CategoryTreeItem class="item mb-2" v-for="(submodel, key) in model.children" :openParent="open" :key="key" :dbParent="db" :db="key" :category="model.name" :model="submodel"></CategoryTreeItem>
    </ul>
  </li>
  <v-btn v-else @click="checked = !checked" :color="checked? `primary` : `secondary`" class="elevation-0">{{model}}</v-btn>
</template>

<style>
.item {
  cursor: pointer;
}
.bold {
  font-weight: bold;
}
li {
  list-style-type: none;
}
.v-input--checkbox.category-check {
  margin-top: 4px;
  margin-left: 25px !important;
}
.v-input--checkbox.category-check .v-input__control {
  height: 32px !important;
}
.v-input--checkbox.category-check.search label {
  background-color: #ffffb0;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CategoryTreeItem',
  props: {
    model: [Object, String],
    openParent: Boolean,
    category: {
      type: String,
      default: () => null
    },
    db: {
      type: String,
      default: () => null
    },
    dbParent: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      open: false,
      search: false
    }
  },
  computed: {
    isFolder: function () {
      return this.model.children
    },
    currentSearch: function() {
      return this.getCurrentSearch();
    },
    checked: {
      get: function() {
        if (this.openParent) { // performance optimalization 
          let name = (this.category ? (this.category.toLowerCase() + `:`) : ``) + this.model.toLowerCase()
          return this.isSearchTagSet()(name);
        }
        return false;
      },
      set: function(val) {
        if (!this.dbParent || !this.db)
          return;
        let name = (this.category ? (this.category.toLowerCase() + `:`) : ``) + this.model.toLowerCase()
        if (val === true) {
          let db = {}
          db[this.dbParent] = this.db; 
          this.addSearchTag({name: name, db: db});
        }
        else
          this.removeSearchTag(name);
      }
    }
  },
  methods: {
    ...mapActions(['addSearchTag', 'removeSearchTag']),
    ...mapGetters(['getCurrentSearch', 'isSearchTagSet']),
    toggle: function () {
      if (this.isFolder) {
        this.open = !this.open
      }
    },
    childrenContains: function(search) {
      if (!this.isFolder)
        return this.model.name.toLowerCase().search(search) != -1;

      for (let child of this.model.children) {
        if (child.name.toLowerCase().search(search) != -1)
          return true;
      }
      return false;
    }
  }
}
</script>
<template>
  <v-card class="elevation-0" style="height: 100%;">
    <v-card-text class="pa-0" style="height: 100%;">
      <div class="role-summary" :class="rolesSelected ? `roles-selected` : ``" v-on:click="selectRoles">
        <v-avatar :color="rolesSelected ? `white` : `#6dc2ff`" :size="38" class="role-summary-icon"><v-icon :color="rolesSelected ? `#6dc2ff` : `white`">fas fa-users</v-icon></v-avatar>
        <div class="role-summary-caption">{{$t('Events.rolesSummary')}}</div>
        <div class="triangle"></div>
      </div>
      <v-timeline align-top dense class="mr-3" style="height: calc(100% - 84px); overflow-y: initial; margin-left: -13px">
        <v-timeline-item right v-for="(event, i) in events" :color="!rolesSelected && i === selectedEventId ? `primary` : `white`" class="my-0" :icon="getIcon(event)" :icon-color="!rolesSelected && i === selectedEventId ? `white` : `primary`" :key="i" fill-dot>
          <v-card hover :color="!rolesSelected && i === selectedEventId ? `primary` : `white`" class="shadow-2" style="font-size: 1.1em">
            <v-card-title v-on:click="selectEvent(i)" class="pl-3 pt-1 pr-0 pb-0" :class="!rolesSelected && i === selectedEventId ? `white--text` : `black--text`">
              {{event.eventName ? event.eventName : $t(event.eventType)}}
              <v-tooltip top v-if="event.eventType == 'shooting'">
                <template v-slot:activator="{on}">
                  <v-icon v-on="on" small :color="!rolesSelected && i === selectedEventId ? `white` : `black`" class="ml-2">
                    <template v-if="event.shootingType==2">mdi-weather-night</template>
                    <template v-else-if="event.shootingType==1">mdi-theme-light-dark</template>
                    <template v-else>mdi-weather-sunny</template>
                  </v-icon>
                </template>
                <span>{{event.shootingType == 2 ? 'noční' : (event.shootingType == 1 ? 'posunuté' : 'denní')}}</span>
              </v-tooltip>
              <v-icon v-if="isDone(event)" small :color="!rolesSelected && i === selectedEventId ? `white` : `black`" class="ml-2">fa-check-circle</v-icon>
              <v-spacer></v-spacer>
              <v-menu bottom left light offset-y>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" small icon dark class="elevation-0 my-0">
                    <v-icon size="24" :color="!rolesSelected && i === selectedEventId ? `white` : `black`" style="opacity: .6">more_vert</v-icon>
                  </v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item @click="confirmDelete = {event: event, show: true}">
                    <v-list-item-title>{{$t('delete')}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <v-card-text style="opacity: .6; font-size: 14px;" v-on:click="selectEvent(i)" class="pl-3 pt-0 pr-3 pb-1" :class="!rolesSelected && i === selectedEventId ? `white--text` : `black--text`">
              {{formatDate(event)}} 
              <span><v-icon small class="ml-3" :color="!rolesSelected && i === selectedEventId ? `white` : `black`">group</v-icon>{{event.attendees ? event.attendees.length : 0}}</span>
              <span v-if="event.videos"><v-icon small class="ml-2" :color="!rolesSelected && i === selectedEventId ? `white` : `black`">play_arrow</v-icon>{{event.videos}}</span>
            </v-card-text>
          </v-card>
        </v-timeline-item>
        <v-timeline-item right color="white" class="my-0" icon="add" icon-color="primary" fill-dot>
          <v-card hover color="white" class="shadow-2" style="font-size: 1.1em">
            <v-card-text v-on:click="$store.state.dialogs.newEvent.show=true" class="pl-3 pr-1 black--text" style="padding-top: 12px; padding-bottom: 12px;">
              <span class="primary--text">{{$t('Events.addEvent')}}</span>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
    <footer class="pane_footer" v-if="false">
      <div class="left">
        <v-btn class="elevation-0" color="primary" text @click="$store.state.dialogs.newEvent.show=true"><v-icon size="18" class="mr-2">fa-plus-circle</v-icon> {{$t('Events.addEvent')}}</v-btn>
      </div>
      <div class="right">
        <v-btn @click="$emit('collapse')" small fab color="white" class="elevation-0"><v-icon color="primary">fa-angle-left</v-icon></v-btn>
      </div>
      <div style="clear: both"></div>
    </footer>
    <v-dialog v-if="confirmDelete.event" max-width="500" v-model="confirmDelete.show">
      <v-card flat tile>
        <v-card-text>
          <div style="font-size: 18px">Opravdu chcete smazat {{ confirmDelete.event.eventType.toLowerCase() }}?</div>
          <div style="font-size: 14px; margin-top: 18px">{{$t('Events.deleteEventAlert')}}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="confirmDelete.show = false" color="success">{{$t('no')}}</v-btn>
          <v-btn class="elevation-0" @click="deleteEvent(confirmDelete.event)" color="error">{{$t('yes')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style scoped>
footer.pane_footer {
  border-top: 1px solid rgb(228,228,228); 
  width: 100%; 
  height: 64px; 
  position: absolute; 
  bottom: 0px;
}
#app.mobile footer.pane_footer{
  bottom: 0px;
}
#app.desktop footer.pane_footer .left {
  float: left;
  width: 228px;
  height: 100%;
  border-right: 1px solid rgb(228,228,228);
}
#app.desktop footer.pane_footer .right {
  float: right;
  height: 100%;
  width: 68px;
}
footer.pane_footer .v-btn {
  margin: 12px 0px 0px 11px;
}
.v-timeline--align-top .v-timeline-item .v-card:before {
  top: 10px;
}
.role-summary {
  margin-bottom: -6px;
  width: 100%;
  height: 84px;
  background-image: url('https://prod.aircasting.cz/assets/event-tile-2.png');
  background-repeat: repeat-x;
  background-position: left bottom;
  position: relative;
  cursor: pointer;
}
.roles-selected {
  background-color: #6dc2ff;
  color: white;
}
.role-summary .triangle {
  width: 52px;
  height: 19px;
  background-image: url('https://prod.aircasting.cz/assets/event-tile-1.png');
  background-repeat: no-repeat;
  background-position: -1px bottom;
  position: absolute;
  bottom: 0;
  left: 0;
}
.role-summary.roles-selected .triangle {
  background-image: url('https://prod.aircasting.cz/assets/event-tile-1h.png');
}
.role-summary-caption {
  position: absolute;
  left: 70px;
  top: 23px;
  font-size: 16px;
}
.role-summary-icon {
  position: absolute;
  left: 16px;
  top: 16px;
}
#app .theme--light.v-timeline .v-timeline-item .v-card:before {
  border-right-color: rgba(0,0,0,.37);
}
</style>

<script>
import * as api from '@/libs/api'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Timeline',
  data() {
    return {
      rolesSelected: true,
      confirmDelete: {
        show: false,
        event: null
      }
    }
  },
  computed: {
    events: function() {
      let events = this.getEvents();
      for (let e in events) {
        let count = 0;
        for (let attendee of events[e].attendees) {
          if (attendee.eventState === 'uploaded')
            count++;
        }
        events[e].videos = count;
      }
      events.sort((a, b) => a.eventDate == b.eventDate ? 0 : (a.eventDate < b.eventDate ? -1 : 1));
      return events;
    },
    selectedEventId() {
      return this.$store.state.selectedEvent;
    }
  },
  methods: {
    ...mapActions(['selectEventInCurrentProject', 'removeEventFromCurrentProject']),
    ...mapGetters(['getEvents']),
    getIcon(event) {
      switch (event.eventType) {
        case 'castingOnline': return 'mdi-camera-front';
        case 'callbackOnline': return 'mdi-camera-front-variant';
        case 'callbackOffline': return 'mdi-backup-restore';
        case 'fitting': return 'mdi-hanger';
        case 'shooting': return 'movie';
      }

      return 'date_range';
    },
    isDone(event) {
      let now = new Date();
      let date = new Date(event.eventDate);
      return (now.getTime() > date.getTime());
    },
    formatDate(event) {
      const [year, month, day] = event.eventDate.split('-')
      return `${day}. ${month}. ${year}`
    },
    selectEvent(i) {
      this.$emit('rolesSelected', false);
      this.rolesSelected = false;
      this.selectEventInCurrentProject(i);
    },
    selectRoles() {
      this.$emit('rolesSelected', true);
      this.rolesSelected = true;
    },
    async deleteEvent(event) {
      try {
        await api.deleteEvent(event);
      }
      catch (err) {
        console.log(err);
        return;
      }
      this.removeEventFromCurrentProject(event);
      this.confirmDelete.show = false;
    }
  }
}
</script>

<template>
  <v-dialog persistent v-model="show" max-width="800" @keydown.esc="actorsDialog.show ? actorsDialog.show = false : show = false" content-class="elevation-0 pa-0 notify_actors_dialog">
    <v-card v-if="show" color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24">close</v-icon></v-btn>
      <v-card-title class="headline">{{$t('NotifyActorsDialog.headline')}}</v-card-title>
      <v-card-text>
        <h4 class="mb-3">{{$t('NotifyActorsDialog.infoShownToActor')}}</h4>
        <p>{{$t('NotifyActorsDialog.infoNotShownToActor')}}</p>
        <v-expansion-panels :value="roles.length > 1 ? null : 0" class="elevation-0 expansion-panel-spaced" :key="render">
          <v-expansion-panel v-for="(role, i) in roles" :key="i" class="shadow-2">
            <v-expansion-panel-header>
              <div>
                <div style="float:left; line-height:32px; font-weight: bold; margin-right: 6px;">
                  <v-icon color="white" @click.stop="toggleDisabled(role.roleId)">{{disabledRoles[role.roleId] ? 'mdi-circle-outline' : 'mdi-check-circle'}}</v-icon>
                </div>
                <div style="float:left; line-height:34px; font-weight: bold;">
                  {{role.roleName}}
                </div>
                <div style="float:right">
                  <v-avatar size="28" v-for="(pick, p) in role.picks" :key="p" style="margin-left: -14px">
                    <img :src="getImageUrl(pick, pick.mainPhoto, 28)">
                  </v-avatar>
                </div>
                <div style="clear:both"></div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <v-card-text class="pl-4 pt-0">
                  <div v-if="project.client" class="mt-3 mb-4">
                    <b>{{$t('AddProjectDialog.client')}}</b><br />
                    <span>{{project.client}}</span>
                  </div>
                  <div v-if="project.brand" class="mt-3 mb-4">
                    <b>{{$t('AddProjectDialog.brand')}}</b><br />
                    <span>{{project.brand}}</span>
                  </div>
                  <div v-if="shootings(role).length" class="mt-3 mb-4">
                    <b>{{shootings(role).length > 1 ? `Termíny` : `Termín`}}</b><br />
                    <span>{{shootings(role).join(', ')}}</span>
                  </div>
                  <div>
                    <b>{{$t('NotifyActorsDialog.roleDescription')}}</b>
                    <v-textarea autofocus style="font-size: 14px" class="mt-0 mb-2 pt-0" v-model="descriptions[role.roleId]" rows="2" :rules="[v => !!v || 'Vyžadované pole']"></v-textarea>
                  </div>
                  <div v-for="(note, n) in role.notes" :key="n">
                    <b>{{note.note.name}}</b><br />
                    <span>{{note.note.text}}</span>
                    <v-divider class="my-3"></v-divider>
                  </div>
                  <div>
                    <b>{{$t('salary')}}</b> <br />
                    <span v-if="role.roleType == 'extra'">1800 Kč{{shootings(role).length > 1 ? (' x' + shootings(role).length) : ''}}</span>
                    <v-list v-else two-line>
                      <template v-for="(payment, index) in role.payments">
                        <v-list-item :key="index">
                          <v-list-item-content>
                            <v-list-item-title>{{getSalaryText(payment)}}</v-list-item-title>
                            <v-list-item-subtitle>{{formatCurrency(payment.salary)}}{{(payment.type == 'dailySalary' && shootings(role).length) > 0 ? (' x ' + shootings(role).length) : ''}}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <v-divider class="mt-4 mb-2"></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title class="black--text">{{$t('NotifyActorsDialog.total')}}</v-list-item-title>
                          <v-list-item-subtitle class="black--text" style="font-weight: bold">{{formatCurrency(getBudget(role))}}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider class="mt-3 mb-3"></v-divider>
                  </div>
                  <div>
                    <b>{{$t('NotifyActorsDialog.selectedActors')}}
                      <v-tooltip right>
                        <template v-slot:activator="{on}">
                          <v-btn v-on="on" small icon class="elevation-0 my-0" @click="showActorsDialog(role.roleName + ' - oslovení herci', role.picks)">
                            <v-icon small>fa-expand</v-icon>
                          </v-btn>
                        </template>
                        <span>{{$t('NotifyActorsDialog.showBigThumbnail')}}</span>
                      </v-tooltip>
                    </b> <br />
                    <v-chip color="#b3d4fc" v-for="(pick, p) in role.picks" :key="p" @click="showProfileDetail(pick)" class="pl-1" style="cursor: pointer; margin: 0px 1px 2px 0px;">
                      <v-avatar class="mr-1">
                        <img :src="getImageUrl(pick, pick.mainPhoto, 28)">
                      </v-avatar>
                      <span>{{pick.name}}</span>
                    </v-chip>
                  </div>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">{{$t('cancel')}}</v-btn>
        <v-btn class="elevation-0" color="primary" @click="notifyActors">Oslovit</v-btn>
      </v-card-actions>
    </v-card>
    <AddNoteDialog v-model="showAddNoteDialog" :title="$t('addNote')" :save="addNoteToCurrentProject" />
    <ProfileMultiView v-model="actorsDialog.show" :title="actorsDialog.title" :actors="actorsDialog.actors" state="roleState"></ProfileMultiView>
  </v-dialog>
</template>

<style>
.notify_actors_dialog .v-chip .v-chip__content {
  cursor: pointer !important;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions, mapGetters } from 'vuex'
import AddNoteDialog from './AddNoteDialog'
import ProfileMultiView from '@/dialogs/ProfileMultiView'

export default {
  name: 'NotifyActorsDialog',
  components: {
    AddNoteDialog,
    ProfileMultiView
  },
  data: () => {
    return {
      render: 0,
      tree: [],
      items: [],
      disabledRoles: {},
      showAddNoteDialog: false,
      descriptions: {},
      actorsDialog: {
        show: false,
        title: null,
        actors: []
      },
    }
  },
  computed: {
    roles: function() {
      let project = this.getCurrentProject();
      let result = [];
      let roles = JSON.parse(JSON.stringify(project.roles)); // Deep copying in JS FTW *facepalm*
      for (let role of roles) {
        let item = role;
        let picks = []
        for (let pick of role.picks) {
          if (pick.roleState != 'added' && pick.roleState != 'share-added')
            continue;
          picks.push(pick);
        }

        if (picks.length == 0)
          continue;

        item.picks = picks;
        item.notes = [];
        for (let note of project.notes) {
          if (!note.share)
            continue;
          for (let share of note.share) {
            if ((share == role.roleName || share == 'Všechny role') && note.type == 'text')
              item.notes.push(note);
          }
        }
        result.push(item);
      }

      return result;
    },
    informations: function() {
      let project = this.getCurrentProject();
      let info = [];

      for (let note of project.notes) {
        if (note.share == null || note.share.length == 0)
          continue;

        info.push(note);
      }

      return info;
    },
    show: {
      get: function() { return this.$store.state.dialogs.notifyActors.show; },
      set: function(val) { return this.$store.state.dialogs.notifyActors.show = val; }
    },
    project() { return this.getCurrentProject(); },
    showClient() { return this.project.projectType == 'ad' || this.project.projectSubType == 'comercialPhoto' || this.project.projectSubType == 'charityPhoto'; },
    showBrand() { return this.project.projectType == 'ad' || this.project.projectSubType == 'comercialPhoto' || this.project.projectSubType == 'charityPhoto'; },
    showCampaing() { return this.project.projectType == 'ad' || this.project.projectSubType == 'comercialPhoto' || this.project.projectSubType == 'charityPhoto'; },
    showPieceName() { return this.project.projectType == 'movie' || this.project.projectSubType == 'artPhoto'; },
    showStation() { return this.project.projectSubType == 'series'; },
    showInterpret() { return this.project.projectSubType == 'clip'; },
    showPhotographer() { return this.project.projectType == 'photo'; },
  },
  watch: {
    show(val) {
      if (val === true) {
        let project = this.getCurrentProject();
        if (project == null)
          return;
        let items = [];
        let id = 1;
        for (let role of project.roles) {
          this.descriptions[role.roleId] = role.description;
          let children = [];
          for (let pick of role.picks) {
            if (pick.roleState != 'added' && pick.roleState != 'share-added')
              continue;
            children.push({
              id: id++,
              name: pick.name,
              avatar: this.getImageUrl(pick, pick.mainPhoto, 28)
            });
          }
          if (children.length) {
            items.push({
              id: id++,
              name: role.roleName,
              children: children
            });
          }

          this.disabledRoles = {};
        }
        for (let i = 1; i < id + 1; ++i) {
          this.tree.push(i);
        }
        this.items = items;
      }
    }
  },
  methods: {
    ...mapActions(['updateCurrentProject', 'showProfileDetail', 'addNoteToCurrentProject', 'showProfileDetail', 'showInfo']),
    ...mapGetters(['getCurrentProject']),
    updateProject() {
      this.updateCurrentProject(this.project);
    },
    async notifyActors() {
      let project = this.getCurrentProject();
      for (let role of project.roles) {
        if (this.disabledRoles[role.roleId])
          continue;
        // Check whether there are any actors to notify in this role
        if (role.picks.findIndex(pick => pick.roleState === "added" || pick.roleState === "share-added") === -1)
          continue;

        let dialogDescription = this.descriptions[role.roleId];
        if (!dialogDescription || !dialogDescription.length) {
          this.showInfo({title: ('U role ' + role.roleName + ' je nutné vyplnit popis.')});
          return;
        }

        if (this.getBudget(role) == 0) {
          this.showInfo({title: ('U role ' + role.roleName + ' je nutné zadat mzdu.')});
          this.show = false;
          return;
        }

        try {
          if (role.description != dialogDescription)
            await api.updateRole({
              projectId: role.projectId,
              roleId: role.roleId,
              description: dialogDescription
            });
        }
        catch (err) {
          console.error(err);
        }
        for (let pick of role.picks) {
          if (pick.roleState && pick.roleState !== "added" && pick.roleState !== "share-added")
            continue;
          else {
            pick.roleState = "addressed";
            await api.updatePickState(role, pick.userId, pick.roleState);
          }
        }

        this.showInfo({title: 'Herci byli úspěšně osloveni.'});
        //location.reload();
      }
      this.updateCurrentProject(project);
      this.show = false;
    },
    shootings(role) {
      let project = this.getCurrentProject();
      let events = [];
      for (let event of project.events) {
        if (event.eventType != 'shooting')
          continue;

        if (event.eventRoles.findIndex(r => r.roleId === role.roleId) < 0)
          continue;

        events.push(this.formatDate(event.eventDate));
      }

      return events;
    },
    formatCurrency: function(amount) {
      const formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
      });

      let result = formatter.format(amount);
      return result;
    },
    getImageUrl(user, filename, size) {
      let id = user.manager || user.userId;
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + id + '/' + filename;
    },
    showActorsDialog(title, actors) {
      this.actorsDialog.show = true;
      this.actorsDialog.title = title;
      this.actorsDialog.actors = actors;
    },
    getBudget(role) {
      if (!role || !role.payments || !role.payments.length)
        return 0;

      let budget = 0;

      for (let payment of role.payments) {
        budget += (payment.salary * (payment.type == 'dailySalary' ? Math.max(1,this.shootings(role).length) : 1));
      }

      return budget;
    },
    getSalaryText(salary) {
      switch (salary.type) {
        case 'dailySalary': return this.$t(salary.type);
        case 'buyout': return this.$t(salary.type) + ' ' + (salary.country || '') + (salary.country && salary.country.length ? ' ' : '') + (salary.medium || '') + ((salary.length && salary.unit) ? ' na ' + salary.length + ' ' + salary.unit + '.' : '');
        case 'other': return (salary.name || '') + (salary.name && salary.name.length ? ' ' : '') + salary.description;
      }
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    toggleDisabled(roleId) {
      this.$set(this.disabledRoles, roleId, !this.disabledRoles[roleId]);
    }
  }
}
</script>
<template>
  <ul class="pl-1">
    <div v-for="(category, key) in treeData" :key="category.name">
      <CategoryTreeItem class="item" :db="key" :model="category"></CategoryTreeItem>  
    </div>
  </ul>
</template>

<style>
ul {
  padding-left: 25px;
  line-height: 1.5em;
  list-style-type: none;
}
</style>

<script>
import Categories from './Categories'
import CategoriesEn from './CategoriesEn'
import CategoryTreeItem from './CategoryTreeItem'

export default {
  name: 'CategoryTree',
  components: {
    CategoryTreeItem
  },
  data() {
    return {
      treeData: this.$i18n.locale() == 'en' ? CategoriesEn : Categories
    }
  }
}
</script>
<template>
  <v-container style="max-width: 500px">
    <v-text-field v-model="newItem" :label="newItemText" solo @keydown.enter="create">
      <v-fade-transition slot="append">
        <v-icon v-if="newItem" @click="create">add_circle</v-icon>
      </v-fade-transition>
    </v-text-field>
    <v-divider v-if="items.length" class="mb-3"></v-divider>
    <v-card v-if="items.length > 0">
      <v-slide-y-transition class="py-0" group tag="v-list">
        <template v-for="(item, i) in items">
          <v-divider v-if="i !== 0" :key="`${i}-divider`"></v-divider>
          <v-list-item :key="`${i}-${item}`">
            <v-list-item-content>
              <v-list-item-title>{{item}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon small ripple @click.stop="remove(i)">
                <v-icon small color="grey lighten-1">close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </template>
      </v-slide-y-transition>
    </v-card>
  </v-container>
</template>

<style>
</style>

<script>
export default {
    name: 'DynamicContainer',
    props: {
      value: {
        type: Array,
        default: () => []
      },
      newItemText: {
        type: String,
        default: () => "Zadejte email"
      }
    },
    data() {
        return {
          newItem: null
        }
    },
    computed: {
      items: {
        get: function() { return this.value; },
        set: function(value) { this.$emit('input', value); }
      },
    },
    methods: {
      remove: function(i) {
        this.items.splice(i, 1);
      },
      create () {
        this.items.push(this.newItem)
        this.newItem = null
      }
    }
}
</script>
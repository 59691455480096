<template>
  <v-container fluid fill-height class="pa-3">
    <v-layout justify-center align-center class="mb-5">
      <v-flex xl12 lg12 md12 sm12 xs12>
        <v-layout row justify-center>
          <v-flex xl3 lg4 md6 sm8 xs12>
			<v-card class="elevation-6" style="min-width: 440px;">
			  <v-card-title primary-title>
				<div>
				  <h4 class="headline mb-0">{{$t('Forgot.passForgotten')}}</h4>
				  <h4 class="subheading mb-0">{{$t('Forgot.enterEmail')}}</h4>
				</div>
			  </v-card-title>
			  <v-card-text>
				<v-form v-model="valid" ref="form">
				  <v-text-field v-model="email" prepend-icon="person" label="E-mail" :rules="[emailrules.required, emailrules.email]" required></v-text-field>
				</v-form>
				<v-flex xs12 class="mt-2 text-xs-right">
				  <v-btn :loading="loading" @click.native="reset" :disabled="!valid" class="mt-3 mb-3 white--text elevation-0" color="primary">
					{{$t('Forgot.passReset')}}
					<span slot="loader">{{$t('Forgot.resetting')}}</span>
				  </v-btn>
				</v-flex>
			  </v-card-text>
			</v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style>
</style>

<script>

export default {
    name: 'Forgot',
    data() {
        return {
          email: '',
          emailrules: {
            required: (value) => !!value || 'Zadejte e-mail',
            email: (value) => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Zadejte validní e-mail'
            }
          },
          valid: false,
          loading: false
        }
    },
    methods: {
      reset: function() {
        this.$store.dispatch('forgotPassword', {
          username: this.email
        }).then(() => {
          console.log("Successfully sent");
          // Todo updatnout GUI - 'uspesne odeslano'
        }).catch((err) => {
          console.log("Error: ", err);
        });
      }
    }
}
</script>
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","max-width":"800","content-class":"elevation-0 pa-0"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.show = false}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[(_vm.show)?_c('v-card',{attrs:{"color":"#f6f8fa"}},[_c('v-btn',{staticClass:"dialog_close",attrs:{"absolute":"","fab":"","top":"","right":"","small":"","text":""},on:{"click":function($event){_vm.show=false}}},[_c('v-icon',{staticStyle:{"line-height":"40px"},attrs:{"size":"24"}},[_vm._v("close")])],1),_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('SendMessageDialog.header')))]),_c('v-card-text',[_c('v-timeline',{attrs:{"align-top":"","dense":""}},[_c('v-timeline-item',{attrs:{"right":"","fill-dot":"","color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('strong',{staticClass:"primary--text"},[_vm._v("1")])]},proxy:true}],null,false,3190665300)},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.$t('SendMessageDialog.chooseActors')))]),_c('div',[_c('v-menu',{attrs:{"offset-y":"","max-height":"475"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"actors-chips-input",style:(_vm.selectedActors.length > 108 ? 'height: 66px;' : (_vm.selectedActors.length > 162 ? 'height: 88px;' : ''))},on),[(_vm.selectedActors && _vm.selectedActors.length < 5)?_vm._l((_vm.selectedActors),function(actor,i){return _c('v-chip',{key:i},[_c('v-avatar',{key:i},[_c('img',{attrs:{"src":actor.photo}})]),_c('span',{domProps:{"textContent":_vm._s(actor.name)}})],1)}):(_vm.selectedActors && _vm.selectedActors.length < 35)?_vm._l((_vm.selectedActors),function(actor,j){return _c('v-avatar',{key:j,staticStyle:{"margin-top":"6px","margin-right":"-10px"},attrs:{"size":"30"}},[_c('img',{attrs:{"src":actor.photo}})])}):(_vm.selectedActors)?_vm._l((_vm.selectedActors),function(actor,k){return _c('v-avatar',{key:k,staticStyle:{"margin-right":"-7px"},attrs:{"size":"20"}},[_c('img',{attrs:{"src":actor.photo}})])}):_vm._e()],2)]}}],null,false,3316170445)},[_c('div',{staticClass:"pa-2 white"},[_c('v-treeview',{attrs:{"selectable":"","items":_vm.tree},scopedSlots:_vm._u([{key:"label",fn:function(ref){
var item = ref.item;
return [(item.children)?_c('span',[_vm._v(_vm._s(item.name))]):_c('v-chip',[(item.photo)?_c('v-avatar',[_c('img',{attrs:{"src":item.photo}})]):_vm._e(),_c('span',{domProps:{"textContent":_vm._s(item.name)}})],1),_c('span')]}}],null,false,300087954),model:{value:(_vm.treeSelected),callback:function ($$v) {_vm.treeSelected=$$v},expression:"treeSelected"}})],1)])],1)]),_c('v-timeline-item',{attrs:{"right":"","fill-dot":"","color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('strong',{staticClass:"primary--text"},[_vm._v("2")])]},proxy:true}],null,false,1254712247)},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.$t('SendMessageDialog.message')))]),_c('v-textarea',{staticClass:"pt-0",attrs:{"autofocus":"","rows":"10"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-timeline-item',{attrs:{"right":"","fill-dot":"","color":"white"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('strong',{staticClass:"primary--text"},[_vm._v("3")])]},proxy:true}],null,false,866255894)},[_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(_vm.$t('SendMessageDialog.answers')))]),_c('v-combobox',{attrs:{"filter":_vm.filter,"hide-no-data":!_vm.search,"items":_vm.items,"search-input":_vm.search,"hide-selected":"","multiple":"","small-chips":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('span',{staticClass:"ml-3"},[_vm._v(_vm._s(_vm.$t('SendMessageDialog.more')))]),_c('v-chip',{attrs:{"color":"secondary","label":"","small":""}},[_vm._v(_vm._s(_vm.search))]),_c('span',[_vm._v(_vm._s(_vm.$t('SendMessageDialog.byPressing'))),_c('v-chip',{attrs:{"color":"silver","label":"","small":""}},[_vm._v(_vm._s(_vm.$t('SendMessageDialog.enter')))])],1)],1)]},proxy:true},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({attrs:{"color":"secondary","input-value":selected,"label":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(item.text))]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"secondary","dark":"","label":""}},[_vm._v(_vm._s(item.text))])]}}],null,false,530951729),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"primary","disabled":!_vm.selectedActors.length || !_vm.message || !_vm.message.length,"loading":_vm.loading},on:{"click":_vm.send}},[_vm._v(_vm._s(_vm.$t('SendMessageDialog.send')))])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app id="app" :class="$vuetify.breakpoint.mdAndUp ? `desktop` : `mobile`">
    <Layout />
    <ProfileDetail v-model="$store.state.dialogs.profileDetail.show" :userId="$store.state.dialogs.profileDetail.noRefresh ? null : $store.state.dialogs.profileDetail.profile" :userProfile="$store.state.dialogs.profileDetail.noRefresh ? $store.state.dialogs.profileDetail.profile : null" />
    <VideoPlayer />
    <AddProjectDialog />
    <AddEventDialog />
    <AddRoleDialog v-model="$store.state.dialogs.roleDialog.show" :saveRole="addRole" />
    <NotifyActorsDialog />
    <ShareVideosDialog />
    <InviteDialog />
    <SendMessageDialog />
    <ShareRolesDialog />
    <ShareProfilesDialog />
    <VideoUploadDialog />
    <SendEmail />
    <v-dialog max-width="500" v-model="$store.state.dialogs.infoDialog.show">
      <v-card flat tile>
        <v-card-text class="pt-3">
          <div v-if="$store.state.dialogs.infoDialog.title" style="font-size: 18px" :class="$store.state.dialogs.infoDialog.title && $store.state.dialogs.infoDialog.text ? `mb-3` : ``">{{$store.state.dialogs.infoDialog.title}}</div>
          <div style="font-size: 14px">{{$store.state.dialogs.infoDialog.text}}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="$store.state.dialogs.infoDialog.show = false" color="primary">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Amplify from "aws-amplify";
import config from "./../config/aws";
import store from './store'
import router from './router'

import Layout from './pages/Layout'
import AddRoleDialog from './dialogs/AddRoleDialogEx'
import ProfileDetail from './dialogs/ProfileDetail'
import VideoPlayer from './dialogs/VideoPlayer'
import AddProjectDialog from './dialogs/AddProjectDialog'
import AddEventDialog from './dialogs/AddEventDialogEx'
import NotifyActorsDialog from './dialogs/NotifyActorsDialog'
import ShareVideosDialog from './dialogs/ShareVideosDialog'
import InviteDialog from './dialogs/InviteDialog'
import SendMessageDialog from './dialogs/SendMessageDialog'
import ShareRolesDialog from './dialogs/ShareRolesDialog'
import ShareProfilesDialog from './dialogs/ShareProfilesDialog'
import VideoUploadDialog from './dialogs/VideoUpload'
import SendEmail from './dialogs/SendEmail'

router.beforeEach((to, from, next) => {
  router.app.$ga.page(to);  
  let liteUser = (router.app.$store && router.app.$store.state.user) ? router.app.$store.state.user.lite : false;
  const signinNotNecessarry = ["Register", "SignIn", "Forgot", "SharedVideos", "SharedProfiles", "SharedEvent", "SharedRoles", "SharedSearch", "Profile"];
  if (signinNotNecessarry.includes(to.name))
    next();
  else {
    Amplify.Auth.currentCredentials().then(() => {
    });
    Amplify.Auth.currentAuthenticatedUser().then(() => {
      if (!liteUser || to.path == "/search")
        next();
    })
    .catch(() => {
      next("/signin");
    });
  }
});
Amplify.configure({
  Auth: {
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "production-users",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "projects",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "search",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      },
      {
        name: "admin",
        endpoint: "https://akh4kvvhm5.execute-api.eu-central-1.amazonaws.com/prod",
        region: config.apiGateway.REGION
      },
      {
        name: "productionExt",
        endpoint: "https://s9vl7clzod.execute-api.eu-central-1.amazonaws.com/prod",
        region: config.apiGateway.REGION
      }
    ]
  }
});

import { mapActions, mapGetters } from 'vuex'
import * as api from '@/libs/api'

export default {
  name: 'app',
  store,
  router,
  components: {
      Layout,
      AddRoleDialog,
      ProfileDetail,
      VideoPlayer,
      AddProjectDialog,
      AddEventDialog,
      NotifyActorsDialog,
      ShareVideosDialog,
      InviteDialog,
      SendMessageDialog,
      ShareRolesDialog,
      ShareProfilesDialog,
      VideoUploadDialog,
      SendEmail
  },
  data() {
      return {       
      }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    ...mapActions(['addRoleToCurrentProject', 'removeRoleFromCurrentProject', 'updateRole', 'addNoteToCurrentProject', 'removeNoteFromCurrentProject']),
    addRole: async function(role, newRole) {
      role.budget = 0;
      for (let payment of role.payments) {
        role.budget += payment.salary;
      }

      let project = this.getCurrentProject();
      if (project) {
        if (newRole) {
          let dbRole = await api.addRole(project.projectId, role);
          this.addRoleToCurrentProject(dbRole);
        }
        else {
          this.updateRole(role);
        }
        location.reload();
      }
    },
  }
}
</script>

<style>
#app .v-btn {
  letter-spacing: 0px;
  margin-left: 8px;
  margin-right: 8px;
}
#app .v-list-item__action .v-btn,
#app .v-btn-toggle .v-btn {
  margin-left: 0px;
  margin-right: 0px;
}
#app .v-expansion-panel-content__wrap {
  padding: 0px;
}
#app .v-overlay--active:before {
    opacity: 1;
}
#app .v-overlay:before {
    background-color: transparent;
    background-image: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.65));
}
.theme--light.v-application {
    background-color: #f6f8fa !important;
}
#app.mobile .theme--light.v-application {
  background-color: #d0eaff !important;
}
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
}
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close_alt {
  top: -20px;
  right: -20px;
}
#app.desktop .overflow_visible {
    overflow: visible !important;
}
#app.desktop .inner-drawer {
    top: 64px;
}
#app .v-card {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
#app .shadow {
  border-radius: 3px !important;
  box-shadow: 0 1px 4px 0 rgba(19, 29, 45, 0.1) !important;
}
#app .shadow-2 {
  box-shadow: 0 1px 4px 0 rgba(19, 29, 45, 0.25) !important;
}
#app .v-btn {
  text-transform: initial;
}
#app .v-tabs__div {
  text-transform: initial;
}

#app .add-photo-button {
  background-color: rgba(143, 150, 154, 0.1);
  border-radius: 4px;
  cursor: pointer;
}
.expansion-panel-spaced > .v-expansion-panel {
  margin-bottom: 16px;
  border: none !important;
}
.expansion-panel-spaced > .v-expansion-panel > .v-expansion-panel-header {
  background-color: #6dc2ff;
  color: white;
}
.expansion-panel-spaced > .v-expansion-panel > .v-expansion-panel-header > .v-expansion-panel-header__icon i {
  color: white !important;
}
.v-expansion-panel.elevation-0:before {
  box-shadow: initial !important;
}
#app .v-card__title {
  flex-wrap: initial;
  font-size: initial;
  font-weight: initial;
  letter-spacing: initial;
  line-height: initial;
  word-break: initial;
}
#app .v-tab {
  text-transform: initial;
  letter-spacing: initial;
  min-width: initial;
  max-width: initial;
}

#app .v-application--is-ltr .v-list-group--sub-group .v-list-group__header {
  padding: 0px;
}

#app .container.grid-list-xl .layout:not(:only-child) {
  margin: auto 0px;
}

#app h3 {
  font-size: 1.1em;
}
#app .v-list-item__subtitle, #app .v-list-item__title {
  white-space: normal;
  text-overflow: initial;
}
#app .v-card__subtitle, 
#app .v-card__text {
  line-height: initial;
}

#app .v-image__image--preload {
  filter: blur(0px) !important;
}
</style>
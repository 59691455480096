<template>
  <v-container fill-height>
    <v-layout row wrap align-center>
      <v-flex xs12 sm12 md4>
        <v-card class="text-center pa-2 pt-3 ma-3" hover to="/projects">
          <v-responsive aspect-ratio="1.3">
            <div class="big_button_content">
              <v-icon size="80" color="primary" class="mb-5">add</v-icon>
              <h2 class="headline" style="color: #002e99">Vytvořit projekt</h2>
            </div>
          </v-responsive>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md4>
        <v-card class="text-center pa-2 pt-3 ma-3" hover to="/search">
          <v-responsive aspect-ratio="1.3">
            <div class="big_button_content">
              <v-icon size="80" color="primary" class="mb-5">account_circle</v-icon>
              <h2 class="headline" style="color: #002e99">Hledat profily</h2>
            </div>
          </v-responsive>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md4>
        <v-card class="text-center pa-2 pt-3 ma-3" hover>
          <v-responsive aspect-ratio="1.3">
            <div class="big_button_content">
              <v-icon size="80" color="primary" class="mb-5">settings</v-icon>
              <h2 class="headline" style="color: #002e99">Nastavení</h2>
            </div>
          </v-responsive>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<style>
.big_button_content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
</style>

<script>

export default {
    name: 'Home',
    data() {
        return {
        }
    }    
}
</script>
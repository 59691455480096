<template>
  <v-dialog persistent v-model="show" max-width="800" @keydown.esc="show = false" content-class="elevation-0 pa-0">
    <v-card v-if="show" color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">{{$t('SendMessageDialog.header')}}</v-card-title>
      <v-card-text>
        <v-timeline align-top dense>
          <v-timeline-item right fill-dot color="white">
            <template v-slot:icon><strong class="primary--text">1</strong></template>
            <span style="font-size: 18px">{{$t('SendMessageDialog.chooseActors')}}</span>
            <div>
              <v-menu offset-y max-height="475">
                <template v-slot:activator="{ on }">
                  <div class="actors-chips-input" :style="selectedActors.length > 108 ? 'height: 66px;' : (selectedActors.length > 162 ? 'height: 88px;' : '')" v-on="on">
                    <template v-if="selectedActors && selectedActors.length < 5">
                      <v-chip v-for="(actor, i) in selectedActors" :key="i">
                        <v-avatar :key="i"><img :src="actor.photo"></v-avatar>
                        <span v-text="actor.name"></span>
                      </v-chip>
                    </template>
                    <template v-else-if="selectedActors && selectedActors.length < 35">
                      <v-avatar size="30" v-for="(actor, j) in selectedActors" style="margin-top: 6px;margin-right: -10px;" :key="j"><img :src="actor.photo"></v-avatar>
                    </template>
                    <template v-else-if="selectedActors">
                      <v-avatar size="20" v-for="(actor, k) in selectedActors" style="margin-right: -7px;" :key="k"><img :src="actor.photo"></v-avatar>
                    </template>
                  </div>
                </template>
                <div class="pa-2 white">
                  <v-treeview selectable :items="tree" v-model="treeSelected">
                    <template v-slot:label="{ item }">
                      <span v-if="item.children">{{item.name}}</span>
                      <v-chip v-else>
                        <v-avatar v-if="item.photo"><img :src="item.photo"></v-avatar>
                        <span v-text="item.name"></span>
                      </v-chip>
                      <span></span>
                    </template>
                  </v-treeview>
                </div>
              </v-menu>
            </div>
          </v-timeline-item>
          <v-timeline-item right fill-dot color="white">
            <template v-slot:icon><strong class="primary--text">2</strong></template>
            <span style="font-size: 18px">{{$t('SendMessageDialog.message')}}</span>
            <v-textarea autofocus rows="10" class="pt-0" v-model="message"></v-textarea>
          </v-timeline-item>
          <v-timeline-item right fill-dot color="white">
            <template v-slot:icon><strong class="primary--text">3</strong></template>
            <span style="font-size: 18px">{{$t('SendMessageDialog.answers')}}</span>
            <v-combobox v-model="model" :filter="filter" :hide-no-data="!search" :items="items" :search-input.sync="search" hide-selected multiple small-chips>
              <template v-slot:no-data>
                <v-list-item>
                  <span class="ml-3">{{$t('SendMessageDialog.more')}}</span>
                  <v-chip color="secondary" label small>{{ search }}</v-chip>
                  <span>{{$t('SendMessageDialog.byPressing')}}<v-chip color="silver" label small>{{$t('SendMessageDialog.enter')}}</v-chip></span>
                </v-list-item>
              </template>
              <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip v-if="item === Object(item)" v-bind="attrs" color="secondary" :input-value="selected" label>
                  <span class="pr-2">{{ item.text }}</span>
                  <v-icon small @click="parent.selectItem(item)">close</v-icon>
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-chip color="secondary" dark label>{{ item.text }}</v-chip>
              </template>
            </v-combobox>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="show = false">{{$t('cancel')}}</v-btn>
        <v-btn class="elevation-0" color="primary" :disabled="!selectedActors.length || !message || !message.length" @click="send" :loading="loading">{{$t('SendMessageDialog.send')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.notify_actors_dialog .v-chip .v-chip__content {
  cursor: pointer !important;
}
.actors-chips-input {
  width: 100%; 
  height: 44px; 
  border-bottom: 1px solid rgba(0,0,0,0.42); 
  cursor: pointer; 
  overflow: hidden;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SendMessageDialog',
  data: () => {
    return {
      loading: false,
      empty: [],
      treeSelected: [],
      items: [
        { header: 'Vyberte z možností níže, nebo napište novou možnost a stiskněte enter ↵' },
        { text: 'Ano' },
        { text: 'Ne' },
        { text: 'Možná' },
      ],
      model: [
        { text: 'Ano' },
        { text: 'Ne' },
      ],
      search: null,
      message: null
    }
  },
  watch: {
    model(val, prev) {
      if (val.length === prev.length) return;

      this.model = val.map(v => {
        if (typeof v === 'string') {
          v = {
            text: v,
          }

          this.items.push(v);
        }

        return v
      })
    },
  },
  computed: {
    show: {
      get() { return this.$store.state.dialogs.sendMessage.show; },
      set(val) { return this.$store.state.dialogs.sendMessage.show = val; }
    },
    searchActors: {
      get() { return null; },
      set() {}
    },
    project() {
      return this.getCurrentProject();
    },
    tree() {
      let tree = [];
      let id = 1;
      let project = this.project;
      if (!project || !project.roles)
        return [];
      for (let role of project.roles) {
        if (!role.picks || !role.picks.length)
          continue;
        
        let item = {
          id: id++,
          name: role.roleName,
          children: []
        }

        let winners = {
          id: id++,
          name: "Vítěz",
          children: []
        };
        let accepted = {
          id: id++,
          name: "Přijali",
          children: []
        };
        let rejected = {
          id: id++,
          name: "Odmítli",
          children: []
        };
        let addressed = {
          id: id++,
          name: "Neodpověděli",
          children: []
        };
        let added = {
          id: id++,
          name: "Neosloveni",
          children: []
        };

        for (let pick of role.picks) {
          (pick.roleState == "addressed" ? addressed : (pick.roleState == "rejected" ? rejected : (pick.roleState == "accepted" ? accepted : (pick.roleState == "winner" ? winners : added)))).children.push({ 
            id: id++, 
            name: pick.name, 
            photo: this.getImageUrl(pick, pick.mainPhoto, 36), 
            userId: pick.userId 
          });
        }

        if (winners.children.length)
          item.children.push(winners);
        if (accepted.children.length)
          item.children.push(accepted);
        if (rejected.children.length)
          item.children.push(rejected);
        if (addressed.children.length)
          item.children.push(addressed);
        if (added.children.length)
          item.children.push(added);

        tree.push(item);
      }

      return tree;
    },
    selectedActors() {
      let actors = [];
      let tree = this.tree;
      for (let id of this.treeSelected) {
        let found = this.findMatchInArray(id, tree);
        if (found)
          actors.push(found);
      }
      return actors;
    }
  },
  methods: {
    ...mapActions(['showInfo']),
    ...mapGetters(['getCurrentProject']),
    filter (item, queryText, itemText) {
      if (item.header) return false

      const hasValue = val => val != null ? val : ''

      const text = hasValue(itemText)
      const query = hasValue(queryText)

      return text.toString()
        .toLowerCase()
        .indexOf(query.toString().toLowerCase()) > -1
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    findMatchInArray(id, arr) {
      if (!arr) {
        return null;
      }
      for (let item of arr) {
        if (item.id === id && !item.children)
          return item;
        
        if (item.children && item.children.length) {
          let found = this.findMatchInArray(id, item.children)
          if (found)
            return found;
        }
      }
      return null;
    },
    async send() {
      this.loading = true;
      let userIds = [];
      let availableAnswers = [];

      for (let actor of this.selectedActors) {
        userIds.push(actor.userId);
      }

      for (let answer of this.model) {
        availableAnswers.push(answer.text);
      }
      await api.addQuestion(this.project.projectId, this.message, availableAnswers, userIds);
      this.loading = false;
      this.show = false;
      this.showInfo({title: 'Dotaz byl úspěšně odeslán.'});
      location.reload();
    }
  }
}
</script>
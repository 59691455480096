import { API, Storage } from "aws-amplify";

////////////////////////////////////////////////////////
// Roles
////////////////////////////////////////////////////////
export async function addRole(projectId, role) {
  try {
    return await API.post("projects", "/projects/" + projectId + "/roles", {
      body: role
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getRole(projectId, roleId) {
  try {
    return await API.get("projects", "/projects/" + projectId + "/roles/" + roleId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function deleteRole(role) {
  try {
    if (!role.projectId || !role.roleId) {
      console.log("deleteRole: projectId or roleId not specified");
      return;
    }

    await API.del("projects", "/projects/" + role.projectId + "/roles/" + role.roleId);
  }
  catch (err) {
    console.log(err);
  }
}

export async function updateRole(role) {
  try {
    if (!role.projectId || !role.roleId) {
      console.log("updateRole: projectId or roleId not specified");
      return;
    }

    await API.put("projects", "/projects/" + role.projectId + "/roles/" + role.roleId, {
      body: role
    });
  }
  catch (err) {
    console.log(err);
  }
}

export async function createSharedRoles(projectId, roles, name, allowSearch, shareId, userName) {
  try {
    if (!projectId || !roles)
      return;
      
    return await API.post("productionExt", "/share-roles", {
      body: {
        projectId: projectId,
        roles: roles,
        shareName: name,
        allowSearch: allowSearch,
        shareId: shareId,
        userName: userName
      }
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getSharedRoles(shareId) {
  try {
    return await API.get("productionExt", "/share-roles/" + shareId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function updateSharedRoleState(shareId, roleId, userId, operation, shareUser) {
  try {
    await API.put("productionExt", "/share-roles/" + shareId + "/" + roleId + "/" + userId, {
      body: { 
        operation: operation,
        shareUser: shareUser 
      }
    });

    return true;
  }
  catch (err) {
    return false;
  }
}

export async function sendSharedMessage(shareId, text, userName) {
  try {
    if (!text || !userName)
      return null;

    return await API.put("productionExt", "/share-roles-messages/" + shareId, {
      body: { 
        text: text,
        userName: userName 
      }
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getSharedMessages(shareId) {
  try {
    return await API.get("productionExt", "/share-roles-messages/" + shareId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function likeSharedRoleProfile(eventId, userId, name, like) {
  try {
    let body = {
      name: name,
      like: like
    }
    await API.put("productionExt", "/share-role-profile-like/" + eventId + "/" + userId, {
      body: body
    });
  }
  catch (err) {
    console.log(err);
    return false;
  }

  return true;
}

////////////////////////////////////////////////////////
// Role picks
////////////////////////////////////////////////////////
export async function addPickToRole(role, pick) {
  try {
    return await API.post("projects", "/projects/" + role.projectId + "/roles/" + role.roleId + "/picks/" + pick.userId, {
      body: pick
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getRolePicks(role) {
  try {
    return await API.get("projects", "/projects/" + role.projectId + "/roles/" + role.roleId + "/picks");
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function deletePickFromRole(role, pick) {
  try {
    if (!role.projectId || !role.roleId) {
      console.log("deletePickFromRole: projectId or roleId not specified");
      return;
    }

    await API.del("projects", "/projects/" + role.projectId + "/roles/" + role.roleId + "/picks/" + pick.userId);
  }
  catch (err) {
    console.log(err);
  }
}

export async function updatePickState(role, userId, state) {
  try {
    if (!role.projectId || !role.roleId) {
      console.log("updateRole: projectId or roleId not specified");
      return;
    }

    await API.put("projects", "/projects/" + role.projectId + "/roles/" + role.roleId + "/picks/" + userId, {
      body: { roleState: state }
    });
  }
  catch (err) {
    console.log(err);
  }
}

export async function setWinner(roleObj, userId, winner) {
  try {
    let role = roleObj.role ? roleObj.role : roleObj;
    if (!role.projectId || !role.roleId) {
      console.log("setWinner: projectId or roleId not specified");
      return;
    }

    await API.put("projects", "/projects/" + role.projectId + "/roles/" + role.roleId + "/picks/" + userId, {
      body: { 
        roleState: 'winner',
        winner: winner === true 
      }
    });
  }
  catch (err) {
    console.log(err);
  }
}

////////////////////////////////////////////////////////
// Events
////////////////////////////////////////////////////////
export async function addEvent(projectId, event) {
  try {
    return await API.post("projects", "/projects/" + projectId + "/events", {
      body: event
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getEvent(projectId, eventId) {
  try {
    return await API.get("projects", "/projects/" + projectId + "/events/" + eventId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function deleteEvent(event) {
  try {
    if (!event.projectId || !event.eventId) {
      console.log("deleteRole: projectId or eventId not specified");
      return;
    }

    await API.del("projects", "/projects/" + event.projectId + "/events/" + event.eventId);
  }
  catch (err) {
    console.log(err);
  }
}

export async function updateEvent(event) {
  try {
    if (!event.projectId || !event.eventId) {
      console.log("updateEvent: projectId or eventId not specified");
      return;
    }

    console.log(event);

    await API.put("projects", "/projects/" + event.projectId + "/events/" + event.eventId, {
      body: event
    });
  }
  catch (err) {
    console.log(err);
  }
}

export async function uploadVideo(filename, event, role, userId) {
  try {
    let payload = { 
      filename: filename,
      projectId: event.projectId,
      eventId: event.eventId,
      roleId: role.roleId
    };
    
    if (userId)
      payload.targetUserId = userId;

    return await API.post("productionExt", "/video", { body: payload });
  }
  catch (err) {
    console.log(err);
  }
}

////////////////////////////////////////////////////////
// Event attendees
////////////////////////////////////////////////////////
export async function addAttendeeToEvent(event, pick) {
  try {
    pick.eventState = "added"
    return await API.post("projects", "/projects/" + event.projectId + "/events/" + event.eventId + "/attendees/" + pick.userId, {
      body: pick
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getEventAttendees(event) {
  try {
    return await API.get("projects", "/projects/" + event.projectId + "/events/" + event.eventId + "/attendees");
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function deleteAttendeeFromEvent(event, pick) {
  try {
    if (!event.projectId || !event.eventId) {
      console.log("deleteAttendeeFromEvent: projectId or eventId not specified");
      return;
    }

    await API.del("projects", "/projects/" + event.projectId + "/events/" + event.eventId + "/attendees/" + pick.userId);
  }
  catch (err) {
    console.log(err);
  }
}

export async function updateAttendeeState(event, pick) {
  try {
    if (!event.projectId || !event.eventId) {
      console.log("updateRole: projectId or eventId not specified");
      return;
    }

    await API.put("projects", "/projects/" + event.projectId + "/events/" + event.eventId + "/attendees/" + pick.userId, {
      body: event
    });
  }
  catch (err) {
    console.log(err);
  }
}


////////////////////////////////////////////////////////
// Projects
////////////////////////////////////////////////////////
export async function addProject(project) {
  try {
    let createdProject = await API.post("projects", "/projects", {
      body: project
    });

    localStorage.setItem("currentProjectId", createdProject.projectId);

    try {
      let createdRoles = [];
      if (project.roles) {
        for (let role of project.roles) {
          createdRoles.push(await addRole(createdProject.projectId, role));
        }
      }
      if (project.events) {
        for (let event of project.events) {
          event.eventRoles = createdRoles;
          await addEvent(createdProject.projectId, event);
        }
      }
    } catch (err) {
      console.error(err);
    }    

    return createdProject;
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getUpdatedActor(actor) {
  if (!actor)
    return;
  if (!actor.age || isNaN(actor.age)) {
    let ageDate = new Date(Date.now() - new Date(actor.dob));
    let age = Math.abs(ageDate.getUTCFullYear() - 1970);
    actor.age = age;
  }
  actor.location = { city: actor.address };
  actor.name = actor.fullname;
  actor.picture = { large: ''}

  let getS3URL = async (key, bucket, actor, slice = true) => await Storage.get(slice ? key.slice(58) : key, { level: 'private', bucket: bucket, provider: 'AWSS3', identityId: (actor.manager || actor.userId), expires: 86400 });

  if (actor.video && actor.video === "COMPLETED") {
    try {
      actor.videoThumbnail = await getS3URL("thumb-videomp4/t00001.png", "video-uploads.aircasting", actor, false);// await Storage.get("thumb-videomp4/t00001.png", { level: 'private', identityId: (actor.manager || actor.userId), bucket: 'video-uploads.aircasting', provider: 'AWSS3' });
      actor.videoUrl = await getS3URL("video.mp4", "video-uploads.aircasting", actor, false);// await Storage.get("video.mp4", { level: 'private', identityId: (actor.manager || actor.userId), bucket: 'video-uploads.aircasting', provider: 'AWSS3' });
    }
    catch (err) {
      console.error(err)
    }
  }

  if (actor.videos && actor.videos.length) {
    let files = actor.videos;
    if (files && files.length) {
      for (let f in files) {
        let key = files[f].key;
        let thumb = files[f].thumbnail;
                
        if (!key || !thumb || key.length < 59 || thumb.length < 59)
          continue;
        try {
          files[f].videoThumbnail = await getS3URL(thumb, "video-uploads.aircasting", actor);//await Storage.get(thumb.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: actor.userId });
          files[f].videoUrl = await getS3URL(key, "video-uploads.aircasting", actor);// await Storage.get(key.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: actor.userId });
        }
        catch (err) {
          console.error(err);
        }
      }
    }
  }

  if (actor.audios && actor.audios.length) {
    let audios = [];
    let audiosFilenames = actor.audios;
    if (audiosFilenames && audiosFilenames.length) {
      for (let filename of audiosFilenames) {
        audios.push({
          key: filename,
          url: await getS3URL(filename, 'audio-uploads.aircasting', actor, false)
        })
      }
    }
    actor.audios = audios;
  }

  return actor;
}

export async function getProject(projectId) {
  try {
    let project = await API.get("projects", "/projects/" + projectId);
    if (project.roles) {
      let roles = project.roles.map(async (role) => {
        if (!role.picks)
          return [];
        let picks = role.picks.map(async (pick) => {
          return await getUpdatedActor(pick);
        });
        role.picks = await Promise.all(picks);
        return role;
      });
      project.roles = await Promise.all(roles);
    }
    console.log(project);
    return project;
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function listProjects() {
  try {
    return await API.get("projects", "/projects");
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function updateProject(project) {
  try {
    return await API.put("projects", "/projects/" + project.projectId, {
      body: project
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function listDocuments(projectId) {
  try {
    return await API.get("productionExt", "/projects/" + projectId + "/documents");
  }
  catch (err) {
    console.log(err);
  }

  return [];
}

////////////////////////////////////////////////////////
// Search
////////////////////////////////////////////////////////
export async function search(searchTerm, startKey, sort, shareId) {
  let termMap = new Map();
  try {
    if (searchTerm)  {
      for (let item of searchTerm) {
        if (!item.db)
          continue;

        for (let key in item.db) {
          let value = termMap.get(key);
          if (value && Array.isArray(value))
            value.push(item.db[key]);
          else
            value = [item.db[key]];

          termMap.set(key, value);
          break;
        }
      }
    }

    let body = {};
    for (let [key, value] of termMap) {
      body[key] = JSON.stringify(value);
    }
    if (startKey)
      body['startKey'] = startKey;
    if (sort)
      body.sort = sort;
    if (shareId)
      body.shareId = shareId
    
    let results = await API.post("search", "/search", {
      body: body
    });

    return results;
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

////////////////////////////////////////////////////////
// Video share
////////////////////////////////////////////////////////
export async function shareVideos(projectId, eventId, shareName, videos) {
  try {
    let body = {
      shareName: shareName,
      projectId: projectId,
      eventId: eventId,
      videos: videos
    }
    return await API.post("projects", "/share", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getSharedVideos(shareId) {
  try {
    return await API.get("projects", "/share/" + shareId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function shareEvent(projectId, eventId) {
  try {
    let body = {}
    return await API.post("productionExt", "/projects/" + projectId + "/events/" + eventId + "/share", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getSharedEvent(shareId) {
  try {
    return await API.get("productionExt", "/events/share/" + shareId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

////////////////////////////////////////////////////////
// Actors
////////////////////////////////////////////////////////
export async function getActor(userId) {
  try {
    return await API.get("projects", "/actors/" + userId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getActorPrivate(userId) {
  try {
    return await API.get("projects", "/actors-private/" + userId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

////////////////////////////////////////////////////////
// Find faces
////////////////////////////////////////////////////////
export async function findFaces(filename, userId) {
  try {
    let body = {
      filename: filename,
      userId: userId
    }
    return await API.post("projects", "/find-faces", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

////////////////////////////////////////////////////////
// Like video
////////////////////////////////////////////////////////
export async function likeVideo(eventId, userId, name, like) {
  try {
    let body = {
      name: name,
      like: like
    }
    return await API.put("productionExt", "/events/" + eventId + "/attendees/" + userId + "/like", {
      body: body
    });
  }
  catch (err) {
    return false;
  }
}

////////////////////////////////////////////////////////
// Comment video
////////////////////////////////////////////////////////
export async function commentVideo(eventId, userId, name, comment, date) {
  try {
    let body = {
      name: name,
      comment: comment,
      date: date
    }
    return await API.put("productionExt", "/events/" + eventId + "/attendees/" + userId + "/comment", {
      body: body
    });
  }
  catch (err) {
    return false;
  }
}

////////////////////////////////////////////////////////
// Share profiles
////////////////////////////////////////////////////////
export async function shareProfiles(shareName, profiles) {
  try {
    let body = {
      shareName: shareName,
      profiles: profiles
    }
    return await API.post("productionExt", "/share-profiles", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getSharedProfiles(shareId) {
  try {
    return await API.get("productionExt", "/share-profiles/" + shareId);
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

////////////////////////////////////////////////////////
// Productions
////////////////////////////////////////////////////////
export async function inviteUser(email) {
  try {
    let body = {
      email: email
    }
    return await API.post("productionExt", "/invite", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function register(email, phone, fullname, companyName) {
  try {
    let body = {
      email: email,
      phone: phone,
      fullname: fullname,
      companyName: companyName
    }
    return await API.post("production-users", "/production-users", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

////////////////////////////////////////////////////////
// Likes / Dislikes
////////////////////////////////////////////////////////
export async function likeProfile(userId, remove) {
  try {
    let body = {
      remove: remove
    }
    return await API.put("productionExt", "/like/" + userId, {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getLikedProfiles() {
  try {
    return await API.get("productionExt", "/like");
  }
  catch (err) {
    console.log(err);
  }

  return [];
}

export async function dislikeProfile(userId, remove) {
  try {
    let body = {
      remove: remove
    }
    return await API.put("productionExt", "/dislike/" + userId, {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function getDislikedProfiles() {
  try {
    return await API.get("productionExt", "/dislike");
  }
  catch (err) {
    console.log(err);
  }

  return [];
}

////////////////////////////////////////////////////////
// Questions
////////////////////////////////////////////////////////
export async function addQuestion(projectId, message, availableAnswers, userIds) {
  try {
    let body = {
      message: message,
      availableAnswers: availableAnswers,
      userIds: userIds
    }
    return await API.post("productionExt", "/projects/" + projectId + "/questions", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function sendMessage(users, message, replyEmail, subject) {
  try {
    let body = {
      users: users,
      message: message,
      subject: subject,
      replyEmail: replyEmail
    }
    return await API.put("productionExt", "/actors/send-message", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

////////////////////////////////////////////////////////
// Meetings
////////////////////////////////////////////////////////
export async function joinMeeting(meetingId) {
  try {
    let body = {}
    if (meetingId)
      body.meetingId = meetingId;

    return await API.post("productionExt", "/meetings", {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function createMeeting() {
  return await joinMeeting(null);
}

export async function updateActorApprovalState(approveState, userId) {
  try {
    let body = {
      approveState: approveState
    }
    return await API.put("admin", "/actor/update/" + userId, {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}

export async function updateActorRating(rating, userId) {
  try {
    let body = {
      rating: rating
    }
    return await API.put("admin", "/actor/update/" + userId, {
      body: body
    });
  }
  catch (err) {
    console.log(err);
  }

  return null;
}
<template>
  <v-dialog persistent v-model="show" max-width="800" @keydown.esc="show = false" content-class="elevation-0 pa-0 share-profiles-dialog">
    <v-card color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline pb-1">{{$t('ShareProfilesDialog.title')}}</v-card-title>
      <v-card-text class="pt-1">
        <p>{{$t('ShareProfilesDialog.subtitle')}}</p>
        <section v-if="!shareId" style="width: 95%; margin: auto;">
          <v-text-field class="my-5 pt-5" v-model="name" :label="$t('ProfileMultiView.sharingName')"></v-text-field>

          <v-expansion-panels v-model="expandedRoles" class="elevation-0 expansion-panel-spaced">
            <v-expansion-panel v-for="(role, i) in roles" :key="i" class="shadow">
              <v-expansion-panel-header>
                <div>
                  <div style="float:left; line-height:34px; font-weight: bold;">
                    {{role.roleName}}
                  </div>
                  <div style="float:right">
                    <v-avatar size="28" v-for="(avatar, i) in getAvatars(role)" :key="i" color="white" class="black--text" style="margin-left: -14px">
                      <img v-if="avatar.url" :src="avatar.url">
                      <template v-else>{{avatar.count}}</template>
                    </v-avatar>
                  </div>
                  <div style="clear:both"></div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card>
                  <v-card-text class="pa-4">
                    <draggable v-model="role.picks" :options="{group: { name:'picks' }, disabled: true }" :move="onDragDrop">
                      <v-chip @click="$store.state.dialogs.profileDetail = { show: true, profile: pick, noRefresh: true }" class="ma-1" color="rgb(179, 212, 252)" v-for="(pick, p) in role.picks" :key="p"><v-avatar left size="28" color="white"><img :src="getImageUrl(pick, pick.mainPhoto, 28)" /></v-avatar>{{pick.fullname}}</v-chip>
                    </draggable>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-checkbox color="secondary" v-if="allowSearchAllowed" v-model="allowSearch" :label="$t('ShareProfilesDialog.subtitle')"></v-checkbox>
        </section>
        <section v-else>
          <v-text-field class="my-5 pt-5" :value="shareURL" label="URL" id="shareRoleURL">
            <v-icon slot="append" @click="copyURL">{{copying ? `check` : `far fa-clipboard`}}</v-icon>
          </v-text-field>
        </section>
        <div class="text-center pt-3">
          <v-btn v-if="!shareId" class="elevation-0 next mb-0" color="primary" @click="save" :loading="loading">{{$t('ShareProfilesDialog.createLink')}}</v-btn>
          <v-btn v-if="!shareId" class="elevation-0 back mt-1" color="#8f969a" text @click="show=false">{{$t('cancel')}}</v-btn>
          <v-btn v-if="shareId" class="elevation-0 next mb-0" color="primary" @click="show=false">OK</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.share-profiles-dialog .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 8px !important;
}
.share-profiles-dialog h1 {
  font-weight: 400;
  font-size: 32px;
}
#app .share-profiles-dialog .v-btn.next {
  margin: 28px auto 0px auto;
  padding: 14px 41px;
  height: 56px;
  font-size: 16px;
  display: block;
}
#app .share-profiles-dialog .v-btn.back {
  margin: 24px auto 0px auto;
  font-weight: 300;
  display: block;
}
#app .share-profiles-dialog .role-active .v-expansion-panel-header {
  background-color: #65bcff;
  border-radius: 3px;
}
#app .share-profiles-dialog .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.share-profiles-dialog .v-btn--icon:before {
  border-radius: 3px !important; 
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions } from 'vuex'
import draggable from 'vuedraggable'


export default {
  name: 'ShareRolesDialog',
  components: {
    draggable
  },
  data: () => {
    return {
      name: null,
      allowSearch: true,
      loading: false,
      copying: false,
      shareId: null,
      expandedRoles: [],
      ownSort: false
    }
  },
  watch: {
    show(val) {
      if (val == true) {
        this.loading = false;
        this.shareId = null;
        this.allowSearch = true;
        this.ownSort = false;
        this.name = this.$store.state.dialogs.shareProfiles.shareName;
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.$store.state.dialogs.shareProfiles.show; },
      set: function(value) { return this.$store.state.dialogs.shareProfiles.show = value }
    },
    roles() {
      return this.$store.state.dialogs.shareProfiles.roles;
    },
    allowSearchAllowed() {
      return this.$store.state.dialogs.shareProfiles.allowSearch;
    },
    shareURL() {
      return window.location.origin + "/roles/" + this.shareId;
    },
    parentId() {
      return this.$store.state.dialogs.shareProfiles.shareId;
    },
    userName() {
      return this.$store.state.dialogs.shareProfiles.userName;
    }
  },
  methods: {
    ...mapActions([
      'showProfileDetail',
    ]),
    fallbackCopyTextToClipboard() {
      let el = document.getElementById('shareRoleURL');
      el.focus();
      el.select();
      document.execCommand('copy');
    },
    copyURL() {
      this.copying = true;
      if (navigator.clipboard)
        navigator.clipboard.writeText(this.shareURL);
      else
        this.fallbackCopyTextToClipboard();
        
      setInterval(()=>{this.copying = false}, 3000);
    },
    getImageUrl(user, filename, size) {
      let id = user.manager || user.userId;
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + id + '/' + filename;
    },
    getAvatars(role) {
      if (!role.picks || !role.picks.length)
        return [];
        
      let result = [];
      let count = 0
      let max = this.$vuetify.breakpoint.lgAndUp ? 10 : (this.$vuetify.breakpoint.mdAndUp ? 5 : 2);
      
      for (let pick of role.picks) {
        if (++count <= max)
          result.push({url: this.getImageUrl(pick, pick.mainPhoto, 28)});
      }

      if (count > max)
        result.push({count: '+' + (count - max)});

      return result;
    },
    onDragDrop() {
      this.ownSort = true;
      return true;
    },
    async save() {
      this.loading = true;
      let projectId = null;
      let roles = [];
      for (let role of this.roles) {
        if (!projectId && role.projectId)
          projectId = role.projectId;
        
        if (!role.picks.length)
          continue;

        let picks = [];
        for (let pick of role.picks) {
          if (pick.userId)
            picks.push(pick.userId);
        }

        roles.push({
          roleId: role.roleId,
          picks: picks
        });
      }

      this.shareId = await api.createSharedRoles(projectId, roles, this.name, this.allowSearch, this.parentId, this.userName);
      this.loading = false;
    }
  }
}
</script>
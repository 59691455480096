<template>
  <v-dialog v-model="show" @keydown.esc="show = false" max-width="500" class="pa-0 overflow_visible">
    <v-card class="text-center py-5">
      <v-btn absolute fab text top right small class="dialog_close" @click="show=false">
        <v-icon size="24" style="line-height: 40px">close</v-icon>
      </v-btn>
      <v-card-text>
        <div>
          <div class="main-message">{{$t('AddLogoDialog.headline')}}</div>
          <div class="content pt-0" id="photo-step-container" style="margin-bottom: 24px;">
            <input type="file" ref="inputfile" name="image" accept="image/*" style="display: none" @change="setImage" />
            <v-scale-transition origin="center center">
              <div :style="`width: ` + getContainerWidth() + `px; max-width: ` + getContainerHeight() + `px; height: ` + getContainerHeight() + `px;`" class="mx-auto" :class="(imgSrc && !cropImg) ? `` : `hidden`">
                <vue-cropper
                  ref="cropper"
                  :guides="false"
                  :view-mode="2"
                  drag-mode="crop"
                  :auto-crop-area="1"
                  :aspect-ratio="1"
                  :min-container-width="250"
                  :min-container-height="180"
                  :background="false"
                  :rotatable="true"
                  :src="imgSrc"
                  :img-style="{ 'width': getContainerWidth() + 'px', 'height': getContainerHeight() + 'px' }"
                ></vue-cropper>
              </div>
            </v-scale-transition>
            <v-scale-transition origin="center center">
              <v-avatar size="200px" v-if="cropImg">
                <img :src="cropImg" style="width: 200px" />
              </v-avatar>
            </v-scale-transition>
            <div>
              <v-btn @click="pickFile" v-if="!imgSrc || cropImg" class="d-inline-block mx-auto elevation-0" :class="cropImg ? `mt-2` : `mt-5`" color="primary" :text="cropImg !== null" large>{{$t('pickPhoto')}}</v-btn>
              <v-btn @click="rotate(90)" v-if="imgSrc && !cropImg" class="d-inline-block mt-3 mr-2 round" icon color="white" large>
                <v-icon small>fa-sync</v-icon>
              </v-btn>
              <v-btn @click="cancel" v-if="imgSrc && !cropImg" class="d-inline-block mx-auto elevation-0 mt-3 mr-2" color="white" large>{{$t('cancel')}}</v-btn>
              <v-btn @click="cropImage" v-if="imgSrc && !cropImg" class="d-inline-block mx-auto elevation-0 mt-3" color="primary" large>{{$t('usePhoto')}}</v-btn>
            </div>
          </div>
          <slot name="navigation">
            <v-btn v-if="cropImg" :loading="uploading" class="next elevation-0" color="primary" large @click="saveInternal">{{$t('save')}}</v-btn>
          </slot>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close_alt {
  top: -20px;
  right: -20px;
  border-radius: 50% !important;
}
.hidden {
  display: none;
}
</style>

<script>

export default {
  name: "PhotoUploadDialog",
  components: {
    VueCropper: typeof window !== 'undefined' && (() => import('vue-cropperjs'))
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    save: {
      type: Function,
      default: (image) => {
        console.log(image);
      }
    }
  },
  data: () => {
    return {
      imgName: '',
      imgSrc: null,
      cropImg: null,
      sourceFile: null,
      croppedFile: null,
      uploading: false
    };
  },
  computed: {
    show: {
      get: function() {
        return this.value;
      },
      set: function(value) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    saveInternal: function() {
      this.save(this.cropImg);
      this.show = false;
    },
    pickFile() {
      this.imgSrc = null;
      this.cropImg = null;
      this.sourceFile = null;
      this.croppedFile = null;
      this.$refs.inputfile.click()
    },
    setImage(e) {
      if (!e.target.files[0].type.includes('image/')) {
        return;
      }

      this.sourceFile = e.target.files[0];

      const reader = new FileReader();
      reader.onload = function(event) {
        this.imgSrc = event.target.result;
        this.$refs.cropper.replace(event.target.result);
      }.bind(this);
      reader.readAsDataURL(this.sourceFile);
    },
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas({width: 200, height: 200}).toDataURL();
      let binStr = atob(this.cropImg.split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      this.croppedFile = new File([arr], this.sourceFile.name, { type: this.sourceFile.type })
    },
    getContainerWidth() {
      let elem = document.getElementById("photo-step-container");
      if (elem)
        return elem.offsetWidth - 2

      return 0
    },
    getContainerHeight() {
      return Math.min(window.innerHeight / 3, 350);
    },
    cancel() {
      this.imgSrc = null
      this.sourceFile = null
    },
    rotate(deg) {
      let rotate = this.$refs.cropper.rotate;
      rotate(deg)
    }
  }
};
</script>
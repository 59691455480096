import Vue from 'vue'
import Vuex from 'vuex'
import { API, Auth } from "aws-amplify";
import * as api from './../libs/api.js'
import { EventBus } from '@/libs/eventBus.js'

Vue.use(Vuex)

const state = {
    user: null,
    loadingProject: true,
    dialogs: {
      newProject: { show: false },
      newEvent: { show: false },
      roleDialog: {show: false, profile: null},
      profileDetail: { show: false, profile: null, noRefresh: false },
      videoPlayer: { show: false, profile: null },
      notifyActors: { show: false },
      shareVideos: { show: false },
      infoDialog: { show: false, title: null, text: null },
      inviteDialog: { show: false },
      sendMessage: {show: false},
      shareRoles: {show: false},
      shareProfiles: { show: false, allowSearch: false, roles: [], shareName: null, shareId: null, userName: null},
      videoUploadDialog: { show: false, event: null, role: null },
      sendEmail: { show: false, actors: [] }
    },
    searchTags: [],
    searchFilter: {
        age: [0, 90],
        weight: [0, 200], 
        height: [0, 250],
        salary: [0, 1000000]
    },
    currentSearch: "",
    project: null,
    selectedEvent: 0,
    projects: [],
    selectedProject: "",
    liked: [],
    disliked: [],
    sort: 'rating',
    lastSearch: null
}

const mutations = {
    ADD_PROJECT(state, project) { state.project = project },
    SELECT_PROJECT(state, id) { state.selectedProject = id },
    SHOW_PROFILE_DETAIL(state, profile) { state.dialogs.profileDetail = {show: true, profile: profile} },
    SHOW_VIDEO_PLAYER(state, profile) { state.dialogs.videoPlayer = {show: true, profile: profile} },
    SHOW_ROLE_DIALOG(state, profile) { state.dialogs.roleDialog = {show: true, profile: profile} },
    SET_SEARCH_TAGS(state, search) { state.searchTags = search; },
    ADD_SEARCH_TAG(state, search) { 
        let i = state.searchTags.findIndex(cat => cat.name === search.name);
        if (i >= 0)
            state.searchTags.splice(i, 1);
        state.searchTags.push(search); 
    },
    REMOVE_SEARCH_TAG(state, search) { state.searchTags.splice(state.searchTags.findIndex(cat => cat.name === search), 1); },
    SET_CURRENT_SEARCH(state, search) { state.currentSearch = search; },
    UPDATE_CURRENT_PROJECT(state, project) { if (!state.project) return; state.project = project; },
    ADD_ROLE(state, role) { if (!state.project) return; state.project.roles.push(role);},
    UPDATE_ROLE(state, role) { 
      if (!state.project) 
        return; 
      let i = state.project.roles.findIndex(r => r.roleId === role.roleId);
      if (i >= 0)
        state.project.roles[i] = role;      
    },
    REMOVE_ROLE(state, role) { 
      if (!state.project) 
        return; 
      state.project.roles.splice(state.project.roles.findIndex(r => r.roleId === role.roleId), 1); 
    },
    ADD_PICK_TO_ROLE(state, val) {
      if (!state.project) 
        return;
      // Don't allow adding user to multiple roles
      if (state.project.roles.findIndex(role => role.picks.findIndex(pick => pick.userId == val.pick.userId) > -1) > -1)
        return;
      let i = state.project.roles.findIndex(role => role.roleId === val.role.roleId);
      if (i >= 0) {
        state.project.roles[i].picks.push(val.pick);
        EventBus.$emit('picksUpdated');
      }
    },
    REMOVE_PICK_FROM_ROLE(state, val) {
      if (!state.project) 
        return; 
      let roleIndex = state.project.roles.findIndex(role => role.roleId === val.role.roleId);
      if (roleIndex < 0)
        return;

      let pickIndex = state.project.roles[roleIndex].picks.findIndex(pick => pick.userId === val.pick.userId);
      if (pickIndex < 0)
        return;

      state.project.roles[roleIndex].picks.splice(pickIndex, 1);
    },
    SET_SEARCH_FILTER(state, filter) { state.searchFilter = filter; },
    /*put projects/id*/
    ADD_NOTE(state, note) { if (!state.project) return; state.project.notes.push(note);},
    /*put projects/id*/
    REMOVE_NOTE(state, name) { 
      if (!state.project)
          return; 
      let i = state.project.notes.findIndex(note => {
          return note.note ? note.note.name === name : false;
      });
      if (i >= 0)
        state.project.notes.splice(i, 1); 
    },
    ADD_EVENT(state, event) { 
        if (!state.project) 
            return; 
        // If event in selected date already exists, first delete it
        // ToDo: improve - notifications to actors etc etc
        let existingEvent = state.project.events.findIndex(e => e.eventDate === event.eventDate);
        if (existingEvent >= 0)
          state.project.events.splice(existingEvent, 1);
        
        state.project.events.push(event); 
        state.project.events.sort(function(eventA, eventB) {
            let dateA = new Date(eventA.eventDate);
            let dateB = new Date(eventB.eventDate);
            return dateA.getTime() - dateB.getTime();
        });
        // ToDo: Needs to be reflected on backend
        state.selectedEvent = state.project.events.findIndex(e => e.eventDate == event.eventDate);
    },
    REMOVE_EVENT(state, event) { 
      if (!state.project) 
        return; 
      let i = state.project.events.findIndex(e => e.eventId === event.eventId);
      if (i < 0)
        return;
      state.project.events.splice(i, 1); 
    },
    UPDATE_EVENT(state, event) { 
      if (!state.project) 
        return; 
      let i = state.project.events.findIndex(e => e.eventId === event.eventId);
      if (i >= 0)
        state.project.events[i] = event;      
    },
    SELECT_EVENT(state, id) { if (!state.project) return; state.selectedEvent = id; },
    ADD_ATTENDEE(state, nvp) {
      let i = state.project.events.findIndex(e => e.eventId == nvp.event.eventId);
      if (i < 0) return;
      state.project.events[i].attendees.push(nvp.pick);    
    },
    REMOVE_ATTENDEE(state, nvp) {
      let i = state.project.events.findIndex(e => e.eventId == nvp.event.eventId);
      if (i < 0) return;
      state.project.events[i].attendees.splice(state.project.events[i].attendees.findIndex(attendee => attendee.userId === nvp.pick.userId), 1);
    },
    ADD_INSTRUCTION(state, instruction) {
        let event = getters.getCurrentEvent(state);
        if (!event.instructions)
            return;
        event.instructions.push(instruction);
    },
    /*put projects/id/events/id*/
    REMOVE_INSTRUCTION(state, name) {
        let event = getters.getCurrentEvent(state);
        if (!event.instructions)
            return;
        event.instructions.splice(event.instructions.findIndex(instruction => instruction.note.name === name), 1);
    },
    /*todo*/
    LIKE_PROFILE(state, profile) { profile.liked = true; state.liked.push(profile);},
    /*todo*/
    DISLIKE_PROFILE(state, profile) { state.disliked.push(profile);},
    /*todo*/
    SET_LIKED_PROFILES(state, profiles) { state.liked = profiles;},
    /*todo*/
    SET_DISLIKED_PROFILES(state, profiles) { state.disliked = profiles;},
    SET_LOGGED_USER(state, user) { state.user = user;},
    SET_SEARCH_SORT(state, sort) { state.sort = sort;},
    SET_LAST_SEARCH(state, last) { state.lastSearch = last;},
    SHOW_INFO(state, info) { state.dialogs.infoDialog = {show: true, title: info. title, text: info.text}; }
}

const actions = {
    addProject: ({commit}, project) => { commit('ADD_PROJECT', project); },
    selectProject: ({commit}, id) => { 
      commit('SELECT_PROJECT', id);
      localStorage.setItem('currentProjectId', id)
    },
    showProfileDetail: ({commit}, profile) => { commit('SHOW_PROFILE_DETAIL', profile.userId); },
    showVideoPlayer: ({commit}, profile) => { commit('SHOW_VIDEO_PLAYER', profile); },
    showRoleDialog: ({commit}, profile) => { commit('SHOW_ROLE_DIALOG', profile); },
    setSearchTags: ({commit}, search) => { commit('SET_SEARCH_TAGS', search); },
    addSearchTag: ({commit}, search) => { commit('ADD_SEARCH_TAG', search); },
    removeSearchTag: ({commit}, search) => { commit('REMOVE_SEARCH_TAG', search); },
    setCurrentSearch: ({commit}, search) => { commit('SET_CURRENT_SEARCH', search); },
    updateCurrentProject: ({commit}, project) => { commit('UPDATE_CURRENT_PROJECT', project); },
    addRoleToCurrentProject: ({commit}, role) => { commit('ADD_ROLE', role); },
    updateRole: ({commit}, role) => { commit('UPDATE_ROLE', role); },
    removeRoleFromCurrentProject: ({commit}, role) => { commit('REMOVE_ROLE', role); },
    addPickToCurrentProject: (context, val) => { 
      if (!context.state.project) 
        return; 

      // Don't allow adding user to multiple roles
      if (context.state.project.roles.findIndex(role => role.picks.findIndex(pick => pick.userId == val.pick.userId) > -1) > -1) {
        context.commit('SHOW_INFO', {title: 'Jednoho herce nelze přiřadit do více než jedné role.'});
        return;
      }

      let i = context.state.project.roles.findIndex(role => role.roleId === val.role.roleId);
      if (i == -1)
        return;
      if (state.project.roles[i].picks.findIndex(pick => pick.userId === val.pick.userId) >= 0)
        return;

      let budget = 0;
      if (context.state.project && context.state.project.roles)
        for (let payment of context.state.project.roles[i].payments)
          budget += payment.salary;

      if ((val.pick.minSalary && context.state.project.roles[i].budget) && (val.pick.minSalary < budget))
        context.commit('SHOW_INFO', {title: 'Role nesplňuje požadavky na minimální honorář vybraného herce'});
      else
        context.commit('ADD_PICK_TO_ROLE', val); 
    },
    removePickFromRole: ({commit}, val) => { commit('REMOVE_PICK_FROM_ROLE', val); },
    setSearchFilter: ({commit}, filter) => { commit('SET_SEARCH_FILTER', filter); },
    addNoteToCurrentProject: ({commit}, note) => { commit('ADD_NOTE', note); },
    removeNoteFromCurrentProject: ({commit}, name) => { commit('REMOVE_NOTE', name); },
    addEventToCurrentProject: ({commit}, event) => { commit('ADD_EVENT', event); },
    removeEventFromCurrentProject: ({commit}, event) => { commit('REMOVE_EVENT', event); },
    updateEvent: ({commit}, event) => { commit('UPDATE_EVENT', event); },
    selectEventInCurrentProject: ({commit}, id) => { commit('SELECT_EVENT', id); },
    addAttendeeToEvent: ({commit}, nvp) => { commit('ADD_ATTENDEE', nvp); },
    removeAttendeeFromEvent: ({commit}, nvp) => { commit('REMOVE_ATTENDEE', nvp); },
    addInstructionToCurrentEvent: ({commit}, note) => { commit('ADD_INSTRUCTION', note); },
    removeInstructionFromCurrentEvent: ({commit}, name) => { commit('REMOVE_INSTRUCTION', name); },
    likeProfile: ({commit}, profile) => { commit('LIKE_PROFILE', profile); },
    dislikeProfile: ({commit}, profile) => { commit('DISLIKE_PROFILE', profile); },
    setLikedProfiles: ({commit}, profiles) => { commit('SET_LIKED_PROFILES', profiles); },
    setDislikeProfiles: ({commit}, profiles) => { commit('SET_DISLIKED_PROFILES', profiles); },
    setLoggedUser: ({commit}, user) => { commit('SET_LOGGED_USER', user); },
    setSearchSort: ({commit}, sort) => { commit('SET_SEARCH_SORT', sort); },
    setLastSearch: ({commit}, last) => { commit('SET_LAST_SEARCH', last); },
    showInfo: ({commit}, info) => { commit('SHOW_INFO', info); },
}

const getters = {
    getAllProjects: (state) => { return state.projects; },
    getSearchTags: (state) => { return state.searchTags; },
    getSearchSort: (state) => { return state.sort; },
    getLastSearch: (state) => { return state.lastSearch; },
    getCurrentSearch: (state) => { return state.currentSearch; },
    getCurrentProject: (state) => { return state.project; },
    getCurrentProjectId: (state) => { return state.selectedProject; },
    getSearchFilter: (state) => { return state.searchFilter; },
    isSearchTagSet: (state) => (name) => { return state.searchTags.find(tag => tag.name == name) != undefined; },
    getEvents: (state) => { 
      if (!state.project)
        return [];
      return state.project.events; 
    },
    getCurrentEvent: (state) => { 
      if (!state.project || !state.project.events.length) 
        return null;
      let i = state.selectedEvent ? state.selectedEvent : 0;
      return state.project.events[i];
    },
    getLikedProfiles: (state) => { return state.liked; },
    getDislikedProfiles: (state) => { return state.disliked; },
    getLoggedUser: (state) => { return state.user; },
    getRole: (state) => (roleId) => { 
      if (!state.project) 
        return null; 
      let i = state.project.roles.findIndex(r => r.roleId === roleId);
      if (i >= 0)
        return state.project.roles[i];
      return null;
    },
}

function updateProject(store) {
  Auth.currentAuthenticatedUser().then(async () => {
    API.get("production-users", "/production-users").then((response) => {
      store.state.user = response;
    }).catch(() => {
      store.state.user = null;
    });

    let currentProjectId = localStorage.getItem("currentProjectId");
    if (currentProjectId)
      store.state.project = await api.getProject(currentProjectId);

    if (!store.state.project) {
      let projects = await api.listProjects();
      if (projects.length) {
        let project = await api.getProject(projects[projects.length - 1].projectId);
        store.state.project = project;
      }
    }
    console.log(store.state.project);
    store.state.loadingProject = false;
  }).catch(() => {
    store.state.loadingProject = false;
    store.state.user = null;
  });
}

const apiPlugin = async function(store) {
  store.state.loading = true;
  setTimeout(() => { updateProject(store); }, 500);
  setInterval(() => { updateProject(store); }, 90000);

  store.subscribe(async function(mutation) {
    switch (mutation.type) {
      case 'ADD_PROJECT': 
          await api.addProject(mutation.payload);
        break;
      case 'SELECT_PROJECT': 
          store.state.loadingProject = true;
          try {
            store.state.project = await api.getProject(mutation.payload); 
          }
          catch (err) {
            console.log(err);
          }
          store.state.loadingProject = false;
        break;
      case 'SHOW_PROFILE_DETAIL': break;
      /*case 'UPDATE_CURRENT_PROJECT': 
          await api.updateProject(mutation.payload); 
        break;*/
      case 'UPDATE_ROLE':
          await api.updateRole(mutation.payload);  
        break;
      case 'REMOVE_ROLE': 
          await api.deleteRole(mutation.payload); 
          store.dispatch('showInfo', {title: 'Role byla úspěšně smazána', text: 'Všichni účastníci role byli notifikováni'});
        break;
      case 'ADD_PICK_TO_ROLE': 
          await api.addPickToRole(mutation.payload.role, mutation.payload.pick)
        break;
      case 'REMOVE_PICK_FROM_ROLE': 
          await api.deletePickFromRole(mutation.payload.role, mutation.payload.pick);
          //store.dispatch('showInfo', {title: 'Herec byl úspěšně odstraněn z role'});
        break;
      case 'ADD_EVENT': break;
      case 'REMOVE_EVENT': break;
      case 'UPDATE_EVENT':
          await api.updateEvent(mutation.payload);  
        break;
      case 'REMOVE_INSTRUCTION':
      case 'ADD_INSTRUCTION': 
      {
        let event = getters.getCurrentEvent(state);
        await api.updateEvent(event);
        break;
      }
      case 'LIKE_PROFILE': break;
      case 'DISLIKE_PROFILE': break;
      case 'SET_LIKED_PROFILES': break;
      case 'SET_DISLIKED_PROFILES': break;
    }  
  })

} 

const localStoragePlugin = store => {
    const likedKey = 'liked';
    const dislikedKey = 'disliked';
    const selectedEventKey = 'selectedEvent';

    let liked = localStorage.getItem(likedKey);
    if (liked)
      store.state.liked = JSON.parse(liked);

    let disliked = localStorage.getItem(dislikedKey);
    if (disliked)
      store.state.disliked = JSON.parse(disliked);

    let selectedEvent = localStorage.getItem(selectedEventKey);
    if (selectedEvent)
      store.state.selectedEvent = JSON.parse(selectedEvent);
  
    store.subscribe((mutation) => {
        if (mutation.type === 'LIKE_PROFILE' || mutation.type === 'SET_LIKED_PROFILES') {
            setTimeout(() => { window.localStorage.setItem(likedKey, JSON.stringify(store.state.liked)); }, 500);
        }
        else if (mutation.type === 'DISLIKE_PROFILE' || mutation.type === 'SET_DISLIKED_PROFILES') {
            setTimeout(() => { window.localStorage.setItem(dislikedKey, JSON.stringify(store.state.disliked)); }, 500);
        }
        else if (mutation.type === 'SELECT_EVENT') {
          window.localStorage.setItem(selectedEventKey, JSON.stringify(mutation.payload));
        }
    })
  } 

export default new Vuex.Store({
    state,
    actions,
    mutations,
    getters,
    plugins: [ apiPlugin, localStoragePlugin ]
})

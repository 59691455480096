<template>
  <v-dialog v-model="show" v-if="items && items.length" :max-width="vh2px(87)" @keydown.esc="confirm ? confirm = false : show = false" @keydown.left="prev" @keydown.right="next" content-class="pa-0 overflow_visible gallery">
    <v-card flat tile color="black">
      <v-btn absolute fab top right small class="dialog_close_alt" @click="show=false"><v-icon size="24">close</v-icon></v-btn>
      <v-window v-model="pos" style="position: relative; box-shadow: 0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)">
        <template v-for="(item,i) in items">
          <v-window-item v-if="item" :key="`card-${i}`">
            <v-img :src="getImageUrl(profile, item)" :lazy-src="i == 0 ? getImageUrl(profile, item, 600) : getImageUrl(profile, item, 300)" aspect-ratio="1" contain>
              <v-btn absolute fab bottom right small class="download-image elevation-0" @click="downloadImage(getImageUrl(profile, item), (profile && profile.fullname) ? (profile.fullname + '.png') : 'aircasting.png')"><v-icon size="24" style="color: rgba(255,255,255,0.66)">mdi-cloud-download</v-icon></v-btn>
            </v-img>
          </v-window-item>
        </template>
        <v-btn icon large @click="prev" style="position: absolute; left: 5px; top: 50%; transform: translateY(-50%)">
          <v-icon size="46" color="white">chevron_left</v-icon>
        </v-btn>
        <v-btn icon large @click="next" style="position: absolute; right: 5px; top: 50%; transform: translateY(-50%)">
          <v-icon size="46" color="white">chevron_right</v-icon>
        </v-btn>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<style>
.gallery {
  box-shadow: initial
}
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close_alt {
  top: 0px;
  right: -20px;
  border-radius: 50% !important;
}

.v-btn--fab.v-size--small.v-btn--absolute.v-btn--bottom.download-image {
  bottom: 16px;
  background-color: rgba(0,0,0,0.15) !important;
}
.gallery .v-responsive.v-image.v-carousel__item {
  height: 100% !important;
}
</style>

<script>

import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Gallery',
  props: {
    value: {
      type: Number,
      default: () => -1
    },
    profile: {
      type: Object,
      default: () => null
    }
  },
  data: () => {
    return {
      pos: 0,
      confirm: false
    }
  },
  computed: {
    show: {
      get: function() { return this.value > -1; },
      set: function(value) { if (value === false) this.$emit('input', -1); }
    }
  },
  asyncComputed: {
    async items() {

      if (!this.profile)
        return []

      let actor = this.profile;

      let items = [];
      if (actor.mainPhoto)
        items.push(actor.mainPhoto);

      if (actor.photos && actor.photos.length)
        return [...items, ...actor.photos]
      
      return items
    }
  },
  watch: {
    value(val) {
      if (val > -1)
        this.pos = val;
    }
  },
  methods: {
    ...mapGetters(['getValue']),
    ...mapActions(['setValue']),
    vh2px(value) {
      let height = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
      if (height === 0)
        return 500;

      return height * (value / parseFloat(100));
    },
    next () {
      this.pos = this.pos + 1 === this.items.length ? 0 : this.pos + 1
    },
    prev () {
      this.pos = this.pos - 1 < 0 ? this.items.length - 1 : this.pos - 1
    },
    getImageUrl(user, filename, size) {
      if (!user)
        return "";
      if (size)
        return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
        
      return 'https://d3d0zdy5deiwj.cloudfront.net/private/' + (user.manager || user.userId) + '/' + filename;
    },
    toDataURL(url) {
      return fetch(url).then((response) => {
        console.log(response)
            return response.blob();
      }).then(blob => {
        console.log(blob)
          return URL.createObjectURL(blob);
      });
    },
    async downloadImage(url, downloadName) {
        const a = document.createElement("a");
        a.href = await this.toDataURL(url);
        a.download = downloadName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    },
  }
}
</script>
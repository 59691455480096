import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/Home.vue'
import Search from '@/pages/Search.vue'
import SharedSearch from '@/pages/SharedSearch.vue'
import Project from '@/pages/Projects.vue'
import Roles from '@/pages/Roles.vue'
import Messages from '@/pages/Messages.vue'
import Files from '@/pages/Files.vue'
import LikedProfiles from '@/pages/LikedProfiles.vue'
import DislikedProfiles from '@/pages/DislikedProfiles.vue'
import SharedVideos from '@/pages/SharedVideos.vue'
import SharedProfiles from '@/pages/SharedProfiles.vue'
import SharedEvent from '@/pages/SharedEvent.vue'
import SharedRoles from '@/pages/SharedRoles.vue'
import VideoCall from '@/pages/VideoCall.vue'
import ProfileDetail from '@/pages/ProfileDetail.vue'
import Productions from '@/pages/Productions.vue'
/* authentication */
import SignIn from '@/pages/Signin'
import Forgot from '@/pages/Forgot'
import Register from '@/pages/Register'

Vue.use(Router)

export default new Router({
  routes: [
    { path: '/', name: 'Root', component: Project },
    { path: '/home', name: 'Home', component: Home },
    { path: '/search', name: 'Search', component: Search },
    { path: '/search/:id', name: 'SharedSearch', component: SharedSearch, props: true },
    { path: '/project', name: 'Project', component: Project },
    { path: '/project/:id', name: 'Project', component: Project, props: true },
    { path: '/roles', name: 'Roles', component: Roles },
    { path: '/roles/:id', name: 'SharedRoles', component: SharedRoles, props: true },
    { path: '/messages', name: 'Messages', component: Messages },
    { path: '/files', name: 'Files', component: Files },
    { path: '/productions', name: 'Productions', component: Productions },
    { path: '/liked', name: 'LikedProfiles', component: LikedProfiles },
    { path: '/disliked', name: 'DislikedProfiles', component: DislikedProfiles },
    { path: '/share/:id', name: 'SharedVideos', component: SharedVideos, props: true },
    { path: '/event/:id', name: 'SharedEvent', component: SharedEvent, props: true },
    { path: '/profiles/:id', name: 'SharedProfiles', component: SharedProfiles, props: true },
    { path: '/profile/:userId', name: 'Profile', component: ProfileDetail, props: true },
    { path: '/call', name: 'VideoCall', component: VideoCall },
    { path: '/call/:id', name: 'VideoCall', component: VideoCall, props: true },
    /* Authentication */
    { path: '/signin', name: 'SignIn', component: SignIn },
    { path: '/forgot', name: 'Forgot', component: Forgot },
    { path: '/register', name: 'Register', component: Register },
  ],
  mode: 'history',
});

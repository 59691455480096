<template>
  <v-dialog persistent v-model="show" max-width="550" @keydown.esc="dialog ? dialog = false : show = false" @keydown.enter="addRole(editedProfile.roleName)">
    <v-card>
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">{{!newProfile ? `Upravit roli` : `Vytvořit roli`}}</v-card-title>
      <v-card-text>
        <v-form ref="roleForm" @submit.prevent="submit">
          <v-tabs v-model="active" color="#f6f8fa" class="black--text">
            <v-tabs-slider></v-tabs-slider>
            <v-tab ripple>Obecné</v-tab>
            <v-tab ripple>Mzda</v-tab>
          </v-tabs>
          <v-tabs-items v-model="active">
            <v-tab-item class="pa-3 role-tab-item">
              <v-radio-group v-model="editedProfile.roleType" row class="mt-0 ml-2">
                <v-radio label="Hlavní role" value="leading"></v-radio>
                <v-radio label="Vedlejší role" value="supporting"></v-radio>
              </v-radio-group>
              <v-text-field v-model="editedProfile.roleName" autofocus label="Název role*" solo required :rules="[v => !!v || 'Vyžadované pole']"></v-text-field>
              <v-textarea  solo label="Popis role" :rows="10" v-model="editedProfile.description" auto-grow ></v-textarea>
            </v-tab-item>
            <v-tab-item class="pa-3 role-tab-item">
              <v-data-table :headers="headers" :items="editedProfile.payments" :hide-default-footer="editedProfile.payments==null || editedProfile.payments.length <= 5" class="elevation-1">
                <template slot="items" slot-scope="props">
                  <td>{{ props.item.type }}</td>
                  <td class="text-xs-right">{{ props.item.salary }}</td>
                  <td class="justify-center layout">
                    <v-icon small @click="deleteItem(props.item)">delete</v-icon>
                  </td>
                </template>
                <template slot="no-data">
                  &nbsp;
                </template>
              </v-data-table>
              <v-dialog v-model="dialog" @keydown.enter="save" hide-overlay max-width="600">
                <v-btn absolute fab top right small text class="dialog_close" @click="close"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" color="primary" text class="elevation-0 mt-3"><v-icon small class="mr-2">add</v-icon> Přidat mzdovou položku</v-btn>
                </template>
                <v-card color="#f6f8fa">
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container grid-list-md>
                      <v-layout wrap row>
                        <v-flex xs12 sm6>
                          <v-select v-model="editedItem.type" :items="['Denní nasazení', 'Kostýmní zkouška', 'Kamerová zkouška', 'Doprava', 'Výkup práv', 'Jiné']" label="Typ" solo></v-select>
                        </v-flex>
                        <v-flex xs12 sm6>
                          <v-text-field v-model="editedItem.salary" label="Mzda" mask="#######" suffix="Kč" solo></v-text-field>
                        </v-flex>
                        <v-flex xs12 v-if="editedItem.type=='Jiné'">
                          <v-text-field v-model="editedItem.description" label="Popis" solo></v-text-field>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click.native="close">Storno</v-btn>
                    <v-btn text color="primary" @click.native="save">Uložit</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="show = false">Storno</v-btn>
        <v-btn class="elevation-0" color="primary" @click="addRole">{{newProfile ? `Vytvořit roli` : `Uložit`}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.role-tab-item {
  background-color: #f6f8fa; 
  border-top: 1px solid rgb(228,228,228); 
  min-height: 451px;
}
</style>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'AddRoleDialog',
    props: {
      value: {
        type: Boolean,
        default: () => false
      },
      saveRole: {
        type: Function,
        default: (role) => {
          console.log(role);
        }
      }
    },
    data: () => {
      return {
        active: 0,
        editedProfile: {
          roleName: '',  
          description: '',
          roleType: 'leading',
          budget: 0,
          picks: [],
          payments: []
        },
        newProfile: true,
        type: null,
        dialog: false,
        headers: [
          {
            text: 'Typ',
            align: 'left',
            sortable: false,
            value: 'type'
          },
          { text: 'Mzda', value: 'salary' }
        ],
        editedIndex: -1,
        editedItem: {
          type: '',
          salary: null
        },
        defaultItem: {
          type: '',
          salary: null
        }
      }
    },
    computed: {
      show: {
        get: function() { return this.value; },
        set: function(value) {
          this.$emit('input', value); 
        }
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nová položka mzdy' : 'Upravit položku mzdy'
      }
    },
    watch: {
      show: function(value) {
        if (value == false)
          return;
        
        // ToDo: if uncommented, resets value in list in new project dialog (probably caused by shalow copy of the object)
        //this.$refs.roleForm.reset();
        this.editedProfile = {
          roleName: '',  
          description: '',
          roleType: 'leading',
          budget: 0,
          picks: [],
          payments: []
        }

        this.active = 0;

        if (this.$store.state.dialogs.roleDialog.profile != null) {
          this.editedProfile = this.$store.state.dialogs.roleDialog.profile;
          this.newProfile = false;
        }
        else {
          this.editedProfile = {
            roleName: '',  
            description: '',
            roleType: 'leading',
            budget: 0,
            picks: [],
            payments: []
          };
          this.newProfile = true;
        }
      }
    },
    methods: {
    ...mapActions(['addRoleToCurrentProject', 'removeRoleFromCurrentProject']),
    addRole: function() {
      if (!this.$refs.roleForm.validate()) return;
      this.saveRole(this.editedProfile, this.newProfile);
      this.$store.state.dialogs.roleDialog.profile = null;
      this.show = false;
    },
    editItem (item) {
      this.editedIndex = this.payments.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },
    deleteItem (item) {
      const index = this.editedProfile.payments.indexOf(item)
      confirm('Opravdu chcete odstranit tuto položku?') && this.editedProfile.payments.splice(index, 1)
    },
    close () {
      this.dialog = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 300)
    },
    save () {
      this.editedItem.salary = parseInt(this.editedItem.salary);
      if (this.editedIndex > -1) {
        Object.assign(this.editedProfile.payments[this.editedIndex], this.editedItem)
      } else {
        this.editedProfile.payments.push(this.editedItem)
      }
      this.close()
    },
    submit() {

    }
  }
}
</script>
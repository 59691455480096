<template>
  <v-dialog v-model="show" max-width="700px" @keydown.esc="show = false" content-class="pt-0">
    <v-card class="pb-6" v-if="actors && actors.length">
      <v-card-title>
        <div>
          <div class="headline d-block">{{uploading ? $t('uploadingVideo') : $t('profile.addVideo')}}</div>
          <div class="d-block">{{uploading ? $t('uploadingMessage') : ''}}</div>
        </div>
      </v-card-title>
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24">close</v-icon></v-btn>
      <div v-if="sourceFile" class="text-center">{{ sourceFile.name }} <v-btn v-if="!uploading" icon small style="margin-bottom: 8px" @click="sourceFile = null"><v-icon small>close</v-icon></v-btn></div>
      <v-card-text class="pb-0 pt-2 text-center">
        <div v-if="!sourceFile" style="font-size: 18px;">
          <p style="color: #8f969a" class="pb-3">Nahrajte video za konkrétního herce</p>
          <v-select v-model="actor" :items="actors" item-text="name" item-value="userId" label="Vyberte herce" outlined></v-select>
        </div>
        <input type="file" ref="inputfile" name="video" accept="video/mp4,video/x-m4v,video/*" style="display: none" @change="setFile" />
        <v-btn v-if="sourceFile === null" @click="pickFile" :disabled="!actor" class="d-inline-block mx-1 elevation-0 mt-3" color="primary" large><v-icon left>fas fa-hdd</v-icon>{{$t('pickVideo')}}</v-btn>
        <v-btn v-if="sourceFile && progress < 1" :loading="uploading" @click="uploadFile"  class="d-block mx-auto elevation-0 mt-3" color="primary" large><v-icon left>cloud_upload</v-icon>Nahrát</v-btn>
        <div v-if="sourceFile && progress > 0" class="px-5 text-center">
          <v-progress-linear :value="progress" :indeterminate="progress == 100"></v-progress-linear>
          <span>{{progress == 100 ? `Převádíme video do kompatibilního formátu` : `Video se nahrává na server`}}</span>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-btn--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.v-btn--fab.v-size--small.v-btn--absolute.v-btn--top.v-btn--right.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
</style>

<script>
import * as api from '@/libs/api.js'
import { Storage/*, API*/ } from "aws-amplify";
import { EventBus } from '@/libs/eventBus.js';

export default {
  name: 'EventVideoUploadDialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    event: {
      type: Object,
      default: () => null
    },
    role: {
      type: Object,
      default: () => null
    },
    actors: {
      type: Array,
      default: () => null
    }
  },
  data: () => {
    return {
      sourceFile: null,
      uploading: false,
      progress: null,
      actor: null,
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.uploading = false; this.sourceFile = null; this.progress = null; this.$emit('input', value); }
    },
  },
  mounted() {
    EventBus.$on('uploadProgress', (progress) => { this.progress = progress; });
  },
  methods: {
    pickFile() {
      this.sourceFile = null;
      this.$refs.inputfile.click()
    },
    setFile(e) {
      this.sourceFile = e.target.files[0];

      if (!this.sourceFile.type.includes('video/')) {
        return;
      }
    },
    async uploadFile() {
      try {
        await this.uploadVideo();
      }
      catch (err) {
        //TODO: Show error message
        console.error(err);
        this.uploading = false;
        this.sourceFile = null;
      }      
    },
    async uploadVideo() {
      if (!this.actor)
        return;
      try {
        this.uploading = true;
        let filename = this.sourceFile.name;
        let userId = null;
        let role = this.role;
        let event = this.event;
        await Storage.put("uploads/" + filename, this.sourceFile, { 
            level: 'private', 
            bucket: 'video-uploads.aircasting', 
            contentType: this.sourceFile.type,
            identityId: userId,
            progressCallback(progress) {
              EventBus.$emit('uploadProgress', Math.floor(100 * progress.loaded / progress.total));
            },
          });

        let result = await api.uploadVideo(filename, event, role, this.actor);

        this.checkVideoStatus(result.filename);
      }
      catch (error) {
        console.log(error);
      }
    },
    checkVideoStatus(filename) {
      setTimeout(async () => {
        try {
          if (!this.event || !this.role)
            return;
          
          let event = await api.getEvent(this.event.projectId, this.event.eventId);
          if (!event.eventRoles || !event.eventRoles.length)
            throw 'no roles';

          let role = event.eventRoles.find(role => role.roleId == this.role.roleId);
          if (!role)
            throw 'role not found'

          if (role.video && role.video.key && role.video.key == filename) {
            this.uploading = false;
            this.sourceFile = null;
            this.show = false;
            location.reload();
          }         
          else {
            this.checkVideoStatus(filename);
          }
        } catch(err) {
          //TODO: Show error message
          this.uploading = false;
          this.sourceFile = null;
          console.log(err);
        }
      }, 2000);
    }
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="1100" @keydown.esc="showNewRoleDialog ? showNewRoleDialog = false : (showLogoDialog ? showLogoDialog = false : show)" content-class="pa-0 project-dialog">
    <v-card color="#f6f8fa" class="pb-4">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <h1 class="text-center my-5 py-5">{{$t('AddProjectDialog.step1Headline')}}</h1>
            <v-layout row wrap align-center class="mx-auto pt-5" style="max-width: 80%">
              <v-flex xs12 sm12 md4>
                <v-card class="text-center pa-2 ma-1" hover @click="(type = 'ad') && (selectedSubType = {value: 'comercialAd', text: 'Komerční reklama'}) && (step += 2)" :color="(type == 'ad') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1.25">
                    <div class="big_button_content">
                      <v-icon size="50" color="primary" class="my-2">mdi-television-classic</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('ads')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-card class="text-center pa-2 ma-1" hover @click="(type = 'movie') && step++" :color="(type == 'movie') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1.25">
                    <div class="big_button_content">
                      <v-icon size="50" color="primary" class="my-2">mdi-movie</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('movies')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-flex>
              <v-flex xs12 sm12 md4>
                <v-card class="text-center pa-2 ma-1" hover @click="(type = 'photo') && step++" :color="(type == 'photo') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1.25">
                    <div class="big_button_content">
                      <v-icon size="50" color="primary" class="my-2">mdi-camera</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('photoshoot')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-flex>
            </v-layout>
            <div style="width: 100%; text-align: center; margin: 38px 0px 16px 0px; font-size: 16px;">{{$t('AddProjectDialog.otherProjectType')}}<a href="mailto:aircasting@aircasting.cz" target="_blank">{{$t('AddProjectDialog.getInTouch')}}</a></div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="2">
          <v-card-text>
            <h1 class="text-center my-4">{{type == 'ad' ? 'Reklama' : (type == 'movie' ? 'Film' : 'Focení')}}</h1>
            <div class="content mx-auto text-center" style="max-width: 650px">
              <v-btn v-for="(option, o) in subTypes" :key="o" :color="(selectedSubType && selectedSubType.value == option.value) ? `secondary` : `white`" class="primary--text shadow big-icon-btn px-1" large @click="(selectedSubType = option) && step++">
                <div style="height: 44px; line-height: 44px;">{{option.text}}</div>
              </v-btn>
            </div>
            <div class="text-center pt-2">
              <v-btn class="back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="3">
          <v-card-text>
            <h1 class="text-center my-4">{{selectedSubType ? selectedSubType.text : ``}}</h1>
            <v-form ref="projectForm" class="pt-2" style="width: 60%; margin: auto;">
              <v-text-field v-model="client" :label="$t('AddProjectDialog.client')" :rules="[requiredFieldRule]" autofocus append-outer-icon="add_a_photo" @click:append-outer="showLogoDialog = true"></v-text-field>
              <v-text-field v-if="showBrand" v-model="brand" class="mb-4" :label="$t('AddProjectDialog.brand')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showCampaing" v-model="name" class="mb-4" :label="$t('AddProjectDialog.campaignName')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showAgency" v-model="agency" class="mb-4" :label="$t('AddProjectDialog.agency')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showDirector" v-model="director" class="mb-4" :label="$t('AddProjectDialog.director')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showPieceName" v-model="name" class="mb-4" :label="$t('AddProjectDialog.projectName')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showProducer" v-model="producer" class="mb-4" :label="$t('AddProjectDialog.producer')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showStation" v-model="station" class="mb-4" :label="$t('AddProjectDialog.tvStation')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showInterpret" v-model="interpret" class="mb-4" :label="$t('AddProjectDialog.interpret')" :rules="[requiredFieldRule]"></v-text-field>
              <v-text-field v-if="showPhotographer" v-model="photographer" class="mb-4" :label="$t('AddProjectDialog.photographer')" :rules="[requiredFieldRule]"></v-text-field>
              <v-textarea v-model="description" class="mb-4" :label="$t('AddProjectDialog.briefProjectDescription')" :rules="[requiredFieldRule]" :hint="$t('AddProjectDialog.briefProjectDescriptionHint')" persistent-hint></v-textarea>
            </v-form>
            <div class="text-center pt-2">
              <v-btn class="next" color="primary" @click="nextStep">{{$t('next')}}</v-btn>
              <v-btn class="back" color="#8f969a" text @click="type == 'ad' ? (step -= 2) : (step--)">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="4">
          <v-card-text>
            <h1 class="text-center my-4">{{$t('role')}}</h1>
            <section style="width: 80%; margin: auto;">
              <template v-for="(roleType, r) in roleTypes">
                <v-expansion-panels :key="r" class="shadow mb-2" :value="roleType.roles.length > 0 ? [0] : []" multiple :class="roleType.roles.length > 0 ? `role-active` : ``">
                  <v-expansion-panel hide-default-footer :value="roleType.roles.length > 0" style="border-radius: 4px;">
                    <v-expansion-panel-header hide-actions>
                      <div class="px-3 my-3" style="line-height: 32px; height: 32px">
                        <div class="role-header-text">{{$t('roleType.' + roleType.type)}}</div>
                        <v-btn @click.stop="addRole(r)" icon color="rgba(0,0,0,0.07)" class="increment-button ml-2 my-0 mr-0"><v-icon :color="roleType.roles.length > 0 ? `white` : `secondary`">mdi-plus</v-icon></v-btn>
                        <div v-if="roleType.roles.length > 0" class="role-header-count">{{roleType.roles.length}}</div>
                        <v-btn v-if="roleType.roles.length > 0" @click.stop="deleteRole(r, roleType.roles.length - 1)" icon color="rgba(0,0,0,0.07)" class="increment-button ml-0 my-0 mr-2"><v-icon :color="roleType.roles.length > 0 ? `white` : null">mdi-minus</v-icon></v-btn>
                        <div style="clear: both"></div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card color="transparent" class="elevation-0">
                        <v-card-text class="pt-0 role-content">
                          <div v-for="(name, i) in roleType.roles" :key="i">
                            <v-text-field :value="name" @input="updateRoleName(r, i, $event)" class="mr-1" hide-details append-outer-icon="close" @click:append-outer="deleteRole(r, i)"></v-text-field>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </section>
            <div class="text-center pt-2">
              <v-btn class="next" color="primary" @click="updateEventRoles() || nextStep()" :disabled="rolesCount < 1">{{$t('next')}}</v-btn>
              <v-btn class="back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="5">
          <v-card-text>
            <h1 class="text-center my-4">{{ (type == 'photo') ? $t('AddProjectDialog.photoShootingDays') : $t('AddProjectDialog.shootingDays')}}</h1>
            <section style="width: 80%; margin: auto;">
              <v-card v-for="(date, d) in dates" :key="d" color="white" class="shadow py-1 px-3 mx-0 my-1">
                <v-card-text class="pa-0">
                  <div style="width: 290px; float:left;">
                    <v-menu :close-on-content-click="false" v-model="dates[d].menu" lazy transition="scale-transition" offset-y full-width min-width="290px">
                      <template v-slot:activator="{on}">
                        <v-text-field v-on="on" :value="formatDate(dates[d].date)" width="290" :placeholder="(type == 'photo') ? $t('AddProjectDialog.photoShootingDate') : $t('AddProjectDialog.shootingDate')" readonly></v-text-field>
                      </template>
                      <v-date-picker locale="cs-cz" no-title v-model="dates[d].date" @input="dates[d].menu = false"></v-date-picker>
                    </v-menu>
                  </div>
                  <div style="float: right; height: 40px; position: relative;">
                    <div style="position:absolute; right: 32px; top:50%; transform:translateY(-50%);">
                      <v-btn-toggle v-model="dates[d].type" mandatory class="elevation-0 mr-5">
                        <v-btn medium style="height: 36px; border: 1px solid rgba(0,0,0,0.2)" :color="dates[d].type == 0 ? `#65bcff` : null" :class="dates[d].type == 0 ? `white--text elevation-0` : `v-btn--outline elevation-0`">{{$t('AddProjectDialog.dailyShift')}}</v-btn>
                        <v-btn medium style="height: 36px; border: 1px solid rgba(0,0,0,0.2)" :color="dates[d].type == 1 ? `#65bcff` : null" :class="dates[d].type == 1 ? `white--text elevation-0` : `v-btn--outline elevation-0`">{{$t('AddProjectDialog.shiftedShift')}}</v-btn>
                        <v-btn medium style="height: 36px; border: 1px solid rgba(0,0,0,0.2)" :color="dates[d].type == 2 ? `#65bcff` : null" :class="dates[d].type == 2 ? `white--text elevation-0` : `v-btn--outline elevation-0`">{{$t('AddProjectDialog.nightShift')}}</v-btn>
                      </v-btn-toggle>
                    </div>
                    <v-btn @click="deleteEvent(d)" class="ma-0 d-block" small icon style="position:absolute; right: 0px; top:50%; transform:translateY(-50%);"><v-icon color="grey lighten-1">close</v-icon></v-btn>
                  </div>
                  <div style="clear: both"></div>
                  <v-expansion-panels class="elevation-0" v-if="dates[d].roles && dates[d].roles.length">
                    <v-expansion-panel class="elevation-0">
                      <v-expansion-panel-header>
                        <div style="color: rgba(0,0,0,.5) !important">{{$t('AddProjectDialog.selectedRoles')}}<b>{{countEventRoles(dates[d].roles)}}</b></div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-card class="elevation-0">
                          <v-card-text>
                            <div v-for="(role, i) in dates[d].roles" :key="i" style="float: left; width: 50%;">
                              <v-checkbox class="pa-0 ma-0" :label="role.name" v-model="dates[d].roles[i].enabled"></v-checkbox>
                            </div>
                            <div style="clear: both"></div>
                          </v-card-text>
                        </v-card>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
              <v-btn @click="dates.push({date: null, menu: false, type: 0, extras: 0, coordinator: false, roles: getEventRoles()})" outlined class="mx-0 mt-2" style="width: 100%; border-color: rgba(0,0,0,.1);" color="grey" large><v-icon class="mr-1">add</v-icon>{{$t('AddProjectDialog.addDate')}}</v-btn>
            </section>
            <div class="text-center pt-2">
              <v-btn class="next" color="primary" @click="nextStep" :disabled="filledDates < 1">{{$t('next')}}</v-btn>
              <v-btn class="back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="6">
          <v-card-text class="px-0 py-5">
            <h1 class="text-center mt-4 mb-4 pb-5">{{$t('AddProjectDialog.assistanceLevel')}}</h1>
            <section style="width: 90%; margin: auto;" class="pt-5 pb-4">
              <v-layout row wrap>
                <v-flex xs4>
                  <v-card color="white" class="shadow pa-0 mx-2">
                    <div class="card-header">{{$t('AddProjectDialog.selfService')}}</div>
                    <v-card-text class="pa-0" :style="pricePackage.management === 0? `border: 1px solid #65bcff` : ``">
                      <div class="px-3">
                        <v-list class="pa-0 ma-0">
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.selfServiceTick1')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.selfServiceTick2')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.selfServiceTick3')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </div>
                      <v-divider v-if="false"></v-divider>
                      <div class="pa-3">
                        <div style="float: left; font-size: 18px; line-height: 44px; font-weight: 500;">
                          <span></span>
                        </div>
                        <div style="float: right">
                          <v-btn large color="primary" class="ma-0 elevation-0" @click="(pricePackage.management = 0) || step++">{{$t('select')}}</v-btn>
                        </div>
                        <div style="clear: both"></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs4>
                  <v-card color="white" class="shadow pa-0 mx-2">
                    <div class="card-header">{{$t('AddProjectDialog.assisted')}}</div>
                    <v-card-text class="pa-0" :style="pricePackage.management === 1? `border: 1px solid #65bcff` : ``">
                      <div class="px-3">
                        <v-list class="pa-0 ma-0">
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.assistedTick1')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.assistedTick2')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.assistedTick3')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </div>
                      <div class="pa-3">
                        <div style="float: left; font-size: 18px; line-height: 44px; font-weight: 500;">
                          <span></span>
                        </div>
                        <div style="float: right">
                          <v-btn large color="primary" class="ma-0 elevation-0" @click="(pricePackage.management = 1) && step++">{{$t('select')}}</v-btn>
                        </div>
                        <div style="clear: both"></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs4>
                  <v-card color="white" class="shadow pa-0 mx-2">
                    <div class="card-header">{{$t('AddProjectDialog.fullService')}}</div>
                    <v-card-text class="pa-0" :style="pricePackage.management === 2? `border: 1px solid #65bcff` : ``">
                      <div class="px-3">
                        <v-list class="pa-0 ma-0">
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.fullServiceTick1')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.fullServiceTick2')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.fullServiceTick3')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </div>
                      <div class="pa-3">
                        <div style="float: left; font-size: 18px; line-height: 44px; font-weight: 500;">
                          <span></span>
                        </div>
                        <div style="float: right">
                          <v-btn large color="primary" class="ma-0 elevation-0" @click="(pricePackage.management = 2) && step++">{{$t('select')}}</v-btn>
                        </div>
                        <div style="clear: both"></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
              <div class="text-center pt-2">
                <v-btn class="back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
              </div>
            </section>
            <v-divider></v-divider>
            <section style="width: 100%;" class="pa-3">
              <div style="width: 80%; margin: auto">
                <div style="float: right; width: 225px;">
                  <h3 style="font-weight: 500">{{$t('AddProjectDialog.callUs')}}</h3>
                  <div class="pt-2">
                    <div style="float: left; padding: 12px 12px 0px 0px">
                      <v-icon color="secondary">phone</v-icon>
                    </div>
                    <div style="float: left">
                      <div style="font-weight: bold">{{$t('AddProjectDialog.phoneNumber')}}</div>
                      <div>{{$t('AddProjectDialog.workingHours')}}</div>
                    </div>
                    <div style="clear: left"></div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </section>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="7">
          <v-card-text class="px-0">
            <h1 class="text-center mt-4 mb-5 pb-5">{{$t('AddProjectDialog.callbackType')}}</h1>
            <section style="width: 80%; margin: auto;" class="pb-4 pt-5">
              <v-layout row wrap>
                <v-flex xs4>
                  <v-card color="white" class="shadow pa-0 mx-3">
                    <div class="card-header">{{$t('AddProjectDialog.callbackLive')}}</div>
                    <v-card-text class="pa-0"  :style="pricePackage.offlineCallback === true? `border: 1px solid #65bcff` : ``">
                      <div class="px-3">
                        <v-list class="pa-0 ma-0">
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.callbackLiveTick1')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.callbackLiveTick2')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.callbackLiveTick3')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </div>
                      <v-divider></v-divider>
                      <div class="pa-3">
                        <div style="float: right">
                          <v-btn large color="primary" class="ma-0 elevation-0" @click="((pricePackage.offlineCallback=true) || (pricePackage.noCallback=false)) && step++">{{$t('select')}}</v-btn>
                        </div>
                        <div style="clear: both"></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs4>
                  <v-card color="white" class="shadow pa-0 mx-3">
                    <div class="card-header">{{$t('AddProjectDialog.callbackOnline')}}</div>
                    <v-card-text class="pa-0" :style="pricePackage.offlineCallback === false && pricePackage.noCallback === false? `border: 1px solid #65bcff` : ``">
                      <div class="px-3">
                        <v-list class="pa-0 ma-0">
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.callbackOnlineTick1')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.callbackOnlineTick2')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.callbackOnlineTick3')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </div>
                      <v-divider></v-divider>
                      <div class="pa-3">
                        <div style="float: right">
                          <v-btn large color="primary" class="ma-0 elevation-0" @click="(pricePackage.offlineCallback=false) || (pricePackage.noCallback=false) || step++">{{$t('select')}}</v-btn>
                        </div>
                        <div style="clear: both"></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs4>
                  <v-card color="white" class="shadow pa-0 mx-3">
                    <div class="card-header">{{$t('AddProjectDialog.noCallback')}}</div>
                    <v-card-text class="pa-0" :style="pricePackage.offlineCallback === false && pricePackage.noCallback === true? `border: 1px solid #65bcff` : ``">
                      <div class="px-3">
                        <v-list class="pa-0 ma-0">
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.noCallbackTick1')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.noCallbackTick2')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item>
                            <v-list-item-content class="tick">
                              <v-list-item-title>{{$t('AddProjectDialog.noCallbackTick3')}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                        </v-list>
                      </div>
                      <v-divider></v-divider>
                      <div class="pa-3">
                        <div style="float: right">
                          <v-btn large color="primary" class="ma-0 elevation-0" @click="((pricePackage.offlineCallback=false) || (pricePackage.noCallback=true)) && step++">{{$t('select')}}</v-btn>
                        </div>
                        <div style="clear: both"></div>
                      </div>
                    </v-card-text>
                  </v-card>
                </v-flex>
              </v-layout>
              <div class="text-center pt-2">
                <v-btn class="back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
              </div>
            </section>
            <v-divider></v-divider>
            <section style="width: 100%;" class="pa-3">
              <div style="width: 80%; margin: auto">
                <div style="float: right; width: 225px;">
                  <h3 style="font-weight: 500">{{$t('AddProjectDialog.callUs')}}</h3>
                  <div class="pt-2">
                    <div style="float: left; padding: 12px 12px 0px 0px">
                      <v-icon color="secondary">phone</v-icon>
                    </div>
                    <div style="float: left">
                      <div style="font-weight: bold">{{$t('AddProjectDialog.phoneNumber')}}</div>
                      <div>{{$t('AddProjectDialog.workingHours')}}</div>
                    </div>
                    <div style="clear: left"></div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </section>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="8">
          <v-card-text class="px-0">
            <h1 class="text-center mt-4 mb-2">{{$t('AddProjectDialog.extrasCoordinator')}}</h1>
            <h3 class="text-center mb-4" style="font-weight: 400">{{$t('AddProjectDialog.extrasCoordinatorQuestion')}}</h3>
            <section style="width: 80%; margin: auto;" class="pt-5 pb-4">
              <v-layout row wrap>
                <v-flex xs6>
                  <v-card class="shadow pa-0 mx-3" style="cursor: pointer" @click="pricePackage.coordinator=true">
                    <div :class="pricePackage.coordinator == true ? `secondary white--text` : `white primary--text`" class="card-header" style="font-weight: bold">{{$t('yes')}}</div>
                  </v-card>
                </v-flex>
                <v-flex xs6>
                  <v-card class="shadow pa-0 mx-3" style="cursor: pointer" @click="pricePackage.coordinator=false">
                    <div :class="pricePackage.coordinator === false ? `secondary white--text` : `white primary--text`" class="card-header" style="font-weight: bold">{{$t('no')}}</div>
                  </v-card>
                </v-flex>
              </v-layout>
              <div class="text-center pt-2">
                <v-btn class="next" color="primary" :loading="saving" @click="saveProject" :disabled="pricePackage.coordinator !== true && pricePackage.coordinator !== false">{{$t('AddProjectDialog.submit')}}</v-btn>
              </div>
              <div class="text-center pt-1" style="color: #8f969a;">
                <i>{{$t('AddProjectDialog.extrasCoordinatorInfo')}}</i>
              </div>
              <div class="text-center pt-1">
                <v-btn class="back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
              </div>
            </section>
            <v-divider></v-divider>
            <section style="width: 100%;" class="pa-3">
              <div style="width: 80%; margin: auto">
                <div style="float: right; width: 225px;">
                  <h3 style="font-weight: 500">{{$t('AddProjectDialog.callUs')}}</h3>
                  <div class="pt-2">
                    <div style="float: left; padding: 12px 12px 0px 0px">
                      <v-icon color="secondary">phone</v-icon>
                    </div>
                    <div style="float: left">
                      <div style="font-weight: bold">{{$t('AddProjectDialog.phoneNumber')}}</div>
                      <div>{{$t('AddProjectDialog.workingHours')}}</div>
                    </div>
                    <div style="clear: left"></div>
                  </div>
                </div>
                <div style="clear: both"></div>
              </div>
            </section>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="9">
          <v-card-text class="text-center" :class="($vuetify.breakpoint.smAndDown ? `pa-1` : `pa-5`)" style="font-size: 18px; color: #131d2d">
            <v-img src="assets/contract.svg" width="118px" class="mx-auto mt-3 mb-4"></v-img>
            <h1 class="text-center mb-4" style="max-width: 400px; margin: auto; font-weight: 400">{{$t('AddProjectDialog.orderSent')}}</h1>
            <p style="max-width: 528px; margin: auto; font-size: 14px;">{{$t('AddProjectDialog.orderSentNote1')}}<br />{{$t('AddProjectDialog.orderSentNote2')}}</p>
            <div class="text-center pt-2">
              <v-btn class="next" color="primary" @click="close">{{$t('close')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card>
    <AddRoleDialog v-model="showNewRoleDialog" :saveRole="addRole" />
    <PhotoUploadDialog v-model="showLogoDialog" :save="addLogo" />
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.project-dialog .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 8px !important;
}
.project-dialog h1 {
  font-weight: 400;
  font-size: 32px;
}
#app .big-icon-btn {
  height: initial;
  padding: 0px;
}

#app .big-icon-btn .v-btn__content {
  display: initial;
  min-width: 120px;
}
#app .project-dialog .v-btn.next {
  margin: 28px auto 0px auto;
  padding: 14px 41px;
  height: 56px;
  font-size: 16px;
  display: block;
}
#app .project-dialog .v-btn.back {
  margin: 24px auto 0px auto;
  font-weight: 300;
  display: block;
}
#app .project-dialog .v-expansion-panel-header {
  padding: 0px;
}
#app .project-dialog .role-active .v-expansion-panel-header {
  background-color: #65bcff;
  border-radius: 3px;
}
#app .project-dialog .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
#app .project-dialog .role-header-text {
  float: left;
  line-height: 32px;
  font-size: 16px;
  margin-left: 0px;
}
#app .project-dialog .role-active .role-header-text {
  color: white;
}
#app .project-dialog .role-header-count {
  float: right;
  height: 32px;
  width: 32px;
  background-color: white;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.project-dialog .increment-button {
  border-radius: 3px !important; 
  float: right; 
  width: 32px; 
  height: 32px;
}
.project-dialog .v-btn--icon:before {
  border-radius: 3px !important; 
}
.project-dialog .card-header {
  height: 64px; 
  width: 100%; 
  line-height: 64px; 
  font-size: 16px; 
  text-align: center; 
  background-color: #65bcff; 
  color: white;
}
.project-dialog .v-list__tile {
  padding: 16px 4px;
  height: initial;
}
.project-dialog .v-list__tile__sub-title, .project-dialog .v-list__tile__title {
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    height: initial;
    line-height: initial;
}
.project-dialog .tick {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABWNxwqAAAACHRSTlMACQo+P3Xh6xc6ieIAAAAwSURBVHgBYyAVMKLxWdiYUPnsLETxGdH4TGzMaOqZ2ZlhfIQIlI8QYcVnH9QmEgAAYuAAxx3ZfqYAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 24px;
}
#app .package .transparent-checkbox,
#app .package .square-checkbox {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: #eeeff0;
  box-shadow: inset 0px 1px 5px #babcbe;
  display: flex;
  border-radius: 3px;
  float: left;
}
#app .package .square-checkbox i {
  margin: 4px !important;
}
#app .package .transparent-checkbox {
  background-color: initial;
  box-shadow: initial;
}
#app .package.checked .square-checkbox {
  background-color: #65bcff;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
#app .package.checked.disabled .square-checkbox {
  background-color: #c7cacc;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
.description-column .content {
  padding: 12px; 
  min-height: 56px; 
  position: relative;
}
.description-column .description-title {
  font-size: 16px; 
  line-height: 32px;
}
.description-column .description-subtitle {
  font-size: 16px; 
  line-height: 32px;
}
.description-column .description-hint {
  position: absolute; right: 12px; top: 50%; transform: translateY(-50%)
}
.description-column .sub-item:first-child {
  border-top: initial;
}
.description-column .sub-item {
  border-top: 1px solid rgba(0,0,0,0.07); 
  padding: 12px 0px; 
}
.description-column .sub-list {
  border-top: 1px solid rgba(0,0,0,0.07); 
  min-height: 56px; 
  padding: 0px 12px; 
}
.price-column .content {
  min-width: 150px; 
  height: 100%; 
  padding: 12px;
}
.price-column .content .price-tab {
  width: 100%; 
  height: 100%; 
  text-align: right; 
  display:table;
}
.price-column .content .price-tab .price {
  display: table-cell; 
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
}
.checked .price-column .content .price-tab .price {
  color: black;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions } from 'vuex'
import AddRoleDialog from './AddRoleDialog'
import PhotoUploadDialog from './AddLogoDialogNew'

export default {
  name: 'AddProjectDialog',
  components: {
    AddRoleDialog,
    PhotoUploadDialog
  },
  data: () => {
    return {
      step: 1,
      dates: [{date: null, menu: false, type: 0, extras: 0, coordinator: false, roles: []}],
      menu: false,
      client: null,
      agency: null,
      name: null,
      brand: null,
      director: null,
      producer: null,
      station: null,
      interpret: null,
      photographer: null,
      showNewRoleDialog: false,
      showLogoDialog: false,
      type: null,
      selectedSubType: null,
      roles: [],
      description: null,
      logo: null,
      stakeholders: [],
      roleTypes: [
        { type: 'leading', name: 'Hlavní role', roles: [] },
        { type: 'supporting', name: 'Vedlejší role', roles: [] },
        { type: 'featuredExtras', name: 'Epizodní role', roles: [] },
        { type: 'extras', name: 'Komparz', roles: [] }
      ],
      pricePackage: {
        offlineCallback: null,
        noCallback: false,
        management: null,
        extra: true,
        coordinator: null,
        profileCasting: null,
        coordinators: 1,
        rolesCount: 0,
        extraCount: 0
      },
      saving: false,
      requiredFieldRule: v => !!v || 'Vyžadované pole'
    }
  },
  watch: {
    show(val) {
      this.$refs.projectForm.reset();
      if (val == true) {
        this.step = 1;
        this.dates = [{date: null, menu: false, type: 0, extras: 0, coordinator: false}];
        this.dateFormatted = null;
        this.menu = false;
        this.type = null;
        this.selectedSubType = null;
        this.client = null;
        this.director = null;
        this.producer = null;
        this.station = null;
        this.interpret = null;
        this.photographer = null;
        this.agency = null;
        this.brand = null;
        this.name = null;
        this.roles = [];
        this.description = null;
        this.logo = null;
        this.stakeholders = [];
        this.roleTypes = [
          { type: 'leading', name: this.$t('roleType.leading'), roles: [] },
          { type: 'supporting', name: 'Vedlejší role', roles: [] },
          { type: 'featuredExtras', name: 'Epizodní role', roles: [] },
          { type: 'extras', name: 'Komparz', roles: [] }
        ];
        this.pricePackage = {
          offlineCallback: null,
          noCallback: false,
          management: null,
          profileCasting: null,
          extra: true,
          coordinator: null,
          coordinators: 1,
          rolesCount: 0,
          extraCount: 0
        };
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.$store.state.dialogs.newProject.show; },
      set: function(value) { return this.$store.state.dialogs.newProject.show = value }
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    rolesCount() {
      let count = 0;
      for (let roleType of this.roleTypes) {
        count += roleType.roles.length;
      }
      return count;
    },
    leadingRolesCount() {
      let i = this.roleTypes.findIndex(type => type.type === 'leading');
      if (i > -1)
        return this.roleTypes[i].roles.length

      return 0;
    },
    supportingRolesCount() {
      let i = this.roleTypes.findIndex(type => type.type === 'supporting');
      if (i > -1)
        return this.roleTypes[i].roles.length

      return 0;
    },
    filledDates() {
      let count = 0;
      for (let date of this.dates)
        if (date.date != null)
          count++

      return count;
    },
    subTypes() {
      if (this.type == 'ad')
        return [{value: 'comercialAd', text: this.$t('AddProjectDialog.comercialAd')}, {value: 'charityAd', text: this.$t('AddProjectDialog.charityAd')}];
      if (this.type == 'movie')
        return [{value: 'fullMovie', text: this.$t('AddProjectDialog.fullMovie')}, {value: 'studentsMovie', text: this.$t('AddProjectDialog.studentsMovie')}, {value: 'series', text: this.$t('AddProjectDialog.series')}, {value: 'clip', text: this.$t('AddProjectDialog.clip')}];
      if (this.type == 'photo')
        return [{value: 'comercialPhoto', text: this.$t('AddProjectDialog.comercialPhoto')}, {value: 'artPhoto', text: this.$t('AddProjectDialog.artPhoto')}, {value: 'charityPhoto', text: this.$t('AddProjectDialog.charityPhoto')}];

      return [];
    },
    showClient() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showBrand() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showCampaing() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showAgency() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showDirector() { return this.type == 'ad' || this.type == 'movie'; },
    showPieceName() { return this.type == 'movie' || (this.selectedSubType && this.selectedSubType.value == 'artPhoto'); },
    showProducer() { return this.type == 'movie'; },
    showStation() { return this.selectedSubType && this.selectedSubType.value == 'series'; },
    showInterpret() { return this.selectedSubType && this.selectedSubType.value == 'clip'; },
    showPhotographer() { return this.type == 'photo'; },
    totalPrice() {
      let total = 0;
      total += this.pricePackage.management ? 12000 : 3000;
      total += this.pricePackage.offlineCallback ? 9000 : 0;
      total += this.pricePackage.coordinator ? 5000 : 0;
      return total;
    }
  },
  methods: {
    ...mapActions(['addProject']),
    
    addLogo: function(logo) {
      this.logo = logo;
    },
    async saveProject() {
      let notes = [];
      if (this.description) {
        notes.push({
          type: 'text',
          note: {
            name: 'O projektu',
            text: this.description
          },
          share: ['Všechny role']
        });
      }

      let roles = [];
      for (let roleType of this.roleTypes) {
        for (let role of roleType.roles) {
          roles.push({
            roleName: role,
            roleType: roleType.type,
            budget: 0,
            picks: [],
            payments: []
          });
        }
      }

      let events = [];
      for (let date of this.dates) {
        let eventRoles = [];
        for (let role of date.roles) {
          let type = this.roleTypes[role.type];
          eventRoles.push({
            roleName: role.name,
            roleType: type.type
          });
        }
        events.push({
          eventType: 'shooting',
          eventName: null,
          eventDate: date.date,
          eventRoles: eventRoles,
          instructions: this.instructions,
          shootingType: date.type
        });
      }
      this.saving = true;
      await api.addProject({
        logo: this.logo,
        client: this.client,
        projectName: this.name,
        roles: roles,
        selectedEvent: 0,
        events: events,
        notes: notes,
        agency: this.agency,
        brand: this.brand,
        director: this.director,
        producer: this.producer,
        station: this.station,
        interpret: this.interpret,
        photographer: this.photographer,
        pricePackage: this.pricePackage,
        projectType: this.type,
        projectSubType: this.selectedSubType.value
      });
      this.saving = false;
      this.step++;
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    nextStep() {
      if (!this.$refs.projectForm.validate()) return;
      this.step++;
    },
    updateEventRoles() {
      let roles = this.getEventRoles();
      for (let i in this.dates) {
        //if (!this.dates[i].roles || this.dates[i].roles.length != roles.length)
        this.dates[i].roles = roles;
      }
    },
    getEventRoles() {
      let roles = [];
      for (let t in this.roleTypes) {
        for (let r in this.roleTypes[t].roles) {
          roles.push({ name: this.roleTypes[t].roles[r], type: t, index: r, enabled: true })
        }
      }
      return roles;
    },
    countEventRoles(roles) {
      let count = 0;
      for (let role of roles) {
        if (role.enabled)
          count++;
      }
      return count;
    },
    addRole(r) {
      this.roleTypes[r].roles.push(this.roleTypes[r].name + " " + (this.roleTypes[r].roles.length + 1));
      if (this.roleTypes[r].type === 'leading' || this.roleTypes[r].type === 'supporting')
        this.pricePackage.rolesCount++;

      else if (this.roleTypes[r].type === 'featuredExtras' || this.roleTypes[r].type === 'extras')
        this.pricePackage.extraCount++;
    },
    deleteRole(r, i) {
      this.roleTypes[r].roles.splice(i, 1);
      if (this.roleTypes[r].type === 'leading' || this.roleTypes[r].type === 'supporting')
        this.pricePackage.rolesCount--;

      else if (this.roleTypes[r].type === 'featuredExtras' || this.roleTypes[r].type === 'extras')
        this.pricePackage.extraCount--;
    },
    deleteEvent(e) {
      if (this.dates.length > 1)
        this.dates.splice(e, 1);
      else if (this.dates.length == 1)
        this.dates[0].date = null;
    },
    updateRoleName(r, i, name) {
      if (r < 0 || i < 0 || r > this.roleTypes.length || i > this.roleTypes[r].roles.length)
        return;
      this.roleTypes[r].roles[i] = name;
    },
    formatCurrency(amount) {
      const formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
      });

      return formatter.format(amount);
    },
    close() {
      this.show = false;
      window.location.reload();
    }
  }
}
</script>
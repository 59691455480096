<template>
  <v-dialog v-if="profile" v-model="show" @keydown.esc="show = false" width="75%" content-class="pa-0 overflow_visible">
    <v-card id="video-player-full">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-actions primary-title class="pa-3" style="border-bottom: 1px solid #eceded">
        <h1>
          <v-avatar :size="40" class="mr-2">
            <img :src="getImageUrl(profile, profile.mainPhoto, 40)">
          </v-avatar>
          <span>{{profile.fullname}}</span>
        </h1>
      </v-card-actions>
      <v-card-text class="pa-0">
        <v-container fluid class="pa-0">
          <v-layout row class="px-3">
            <v-flex grow pa-3 style="background-color: #f6f7f7" id="video-content">
              <template v-if="ready">
                <v-responsive v-if="audio" :aspect-ratio="16/9"><VuetifyAudio :file="audio" color="white"></VuetifyAudio></v-responsive>
                <v-img v-else-if="photo" :src="photo" :aspect-ratio="16/9" contain></v-img>
                <videoPlayer v-else class="video-player-box" ref="videoPlayer" :options='options'>
                  <v-btn absolute fab top right small class="dialog_close_alt" @click="show=false"><v-icon size="24">close</v-icon></v-btn>
                </videoPlayer>
                <v-layout row wrap class="mt-2" style="max-height: 300px; overflow-y: auto;">
                  <template v-for="(file, f) in profile.files">
                    <v-flex xs2 class="pa-1" v-if="file.videoThumbnail" :key="f">
                      <div class="add-photo-button" @click="selected = f">
                        <v-img :src="file.videoThumbnail" aspect-ratio="1" contain style="border-radius: 4px" />
                      </div>
                    </v-flex>
                    <v-flex xs2 class="pa-1" v-else-if="file.path" :key="f">
                      <div class="add-photo-button" @click="selected = f">
                        <v-img v-if="file.type === 'photo'" :src="file.path" aspect-ratio="1" contain style="border-radius: 4px" />
                        <v-responsive v-else-if="file.type === 'audio'" aspect-ratio="1" style="position: relative">
                          <v-icon size="48" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%);">mdi-play</v-icon>
                        </v-responsive>
                      </div>
                    </v-flex>
                  </template>
                </v-layout>
              </template>
            </v-flex>
            <v-flex v-if="!hideComments" shrink pa-0 hidden-sm-and-down style="border-left: 1px solid #e3e5e6">
              <v-layout column fill-height style="width: 300px;">
                <v-flex shrink pa-3 style="border-bottom: 1px solid #eceded">
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-btn v-on="on" color="secondary" class="like-button elevation-0" large :outline="!liked" @click="likeVideo">
                        <v-icon class="mr-2">thumb_up_alt</v-icon>
                        <span>{{$t('VideoPlayer.iLike')}}</span>
                        <template v-if="profile.likes && profile.likes.length">
                          <span :style="liked ? `color: rgba(255,255,255,0.6)` : `color: rgba(101,188,255,0.6)`" class="mx-2">&bull;</span> 
                          <span :style="liked ? `color: rgba(255,255,255,0.8)` : `color: rgba(101,188,255,0.8)`">{{profile.likes.length}}</span>
                        </template>
                      </v-btn>
                    </template>
                    <span v-if="profile.likes && profile.likes.length">
                      <div v-for="(like, l) in profile.likes" :key="l">{{like}}</div>
                    </span>
                    <span v-else>{{$t('VideoPlayer.notRanked')}}</span>
                  </v-tooltip>
                </v-flex>
                <v-flex grow pa-3 style="overflow-y: auto; height: 200px !important">
                  <div v-for="(comment, c) in profile.comments" :key="c" class="comment-box">
                    <div>{{comment.commentText}}</div>
                    <div><div style="float: left; font-size: 12px; color: rgb(0,0,0,0.5)">{{comment.commentName}}</div> <div style="float: right; font-size: 12px; color: rgb(0,0,0,0.5)">{{comment.commentDate}}</div><div style="clear: both;"></div></div>
                  </div>
                </v-flex>
                <v-flex shrink px-3 py-1 style="border-top: 1px solid #eceded">
                  <v-text-field :label="$t('VideoPlayer.commentary')" v-model="comment" @keydown.enter="sendComment" @click:append-outer="sendComment" append-outer-icon="send"></v-text-field>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
#video-player-full .like-button {
  margin: 0px !important; 
  width: 100%;  
  display: block;
}
.comment-box {
  border-radius: 3px;
  margin-bottom: 12px;
  padding: 12px;
  border: 1px solid #e9eaeb;
}
#video-player-full .video-js {
  margin: auto;
}
</style>

<script>
import 'video.js/dist/video-js.css'
import * as api from '@/libs/api'
import { videoPlayer } from 'vue-video-player'
import { mapGetters } from 'vuex'
import VuetifyAudio from 'vuetify-audio'

export default {
  name: 'VideoPlayer',
  components: {
    videoPlayer,
    VuetifyAudio
  },
  props: {
    profile: {
      tyoe: Object,
      default: () => null
    },
    value: {
      type: Boolean,
      default: () => false
    },
    type: {
      type: String,
      default: () => "video"
    },
    name: {
      type: String,
      default: () => null
    },
    hideComments: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => {
    return {
      ready: false,
      selected: 0,
      comment: null
    }
  },
  mounted() {
    console.log(this.profile)
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.selected = 0; this.$emit('input', value); }
    },
    options() {

      let width = window.innerWidth
      let height = window.innerHeight

      let options = null
      if (width > height)
        options = { autoplay: this.show, mute: true, height: height - 550};
      else
        options = { autoplay: this.show, mute: true, width: width - 300};

      /*const el = document.getElementById("video-player-full");
      let width = el ? (el.offsetWidth - 340) : 800;
      let options = { autoplay: this.show, width: width, mute: true};*/
      
      let source = null;
      if (this.profile.files && this.profile.files.length)
        source = this.profile.files[this.selected].videoUrl;

      if (this.type == "youtube") {
        options["techOrder"] = ["youtube", "html5"]
        options.sources = [{ type: "video/youtube", src: source }]
      }
      else if (this.type == "vimeo") {
        options["techOrder"] = ["vimeo", "html5"]
        options.sources = [{ type: "video/vimeo", src: source }]
      }
      else
        options.sources = [{ type: "video/mp4", src: source }]

      return options;      
    },
    audio() {
      if (!this.profile || !this.profile.files || !this.profile.files[this.selected].path)
        return null;

      if (this.profile.files[this.selected].type !== 'audio')
        return null;

      return this.profile.files[this.selected].path;
    },
    photo() {
      if (!this.profile || !this.profile.files || !this.profile.files[this.selected].path)
        return null;

      if (this.profile.files[this.selected].type !== 'photo')
        return null;

      return this.profile.files[this.selected].path;
    },
    username() {
      if (this.name)
        return this.name;
      let loggedUser = this.getLoggedUser();
      return (loggedUser && loggedUser.fullname) ? loggedUser.fullname : '';
    },
    liked() {
      if (!this.profile.likes || !this.profile.likes.length)
        return false;

      return this.profile.likes.findIndex(like => like === this.username) > -1;
    },
  },
  watch: {
    show(val) {
      if (!val)
        this.ready = false;
      else {
        setTimeout(function() {
          this.ready = true;
        }.bind(this), 300);
      }
    }
  },
  methods: {
    ...mapGetters(['getLoggedUser']),
    getHeight() {
      return window.innerHeight - 100
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    sendComment() {
      if (!this.comment || this.comment.length < 1)
        return;
      
      if (!this.profile.comments)
        this.profile.comments = [];

      let d = new Date();
      let date = d.getDate() + '.' + (d.getMonth()+1) + '.' + d.getFullYear() + ' ' + d.getHours() + ':' + d.getMinutes();
      api.commentVideo(this.profile.eventId, this.profile.userId, this.username, this.comment, date)
      this.profile.comments.push({
        commentText: this.comment,
        commentName: this.username,
        commentDate: date
      });

      this.comment = null;
    },
    async likeVideo() {
      if (!this.profile.likes)
        this.profile.likes = [];

      let index = this.profile.likes.findIndex(like => like === this.username);
      if (index > -1) {
        this.profile.likes.splice(index, 1);
        await api.likeVideo(this.profile.eventId, this.profile.userId, this.username, false);
      }
      else {
        this.profile.likes.push(this.username);
        await api.likeVideo(this.profile.eventId, this.profile.userId, this.username, true);          
      }
    }
  }
}
</script>
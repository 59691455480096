export default {
  ////////////////////////////////////////////
  // Obory
  selectedProfessions: {
    name: 'Profession',
    children: {
      actor: 'Actor',
      model: 'Model',
      stuntman: 'Stuntman',
      artiste: 'Artiste',
      dancer: 'Dancer',
      singer: 'Singer',
      moderator: 'Moderator',
      voiceActor: 'Voice actor',
      speaker: 'Speaker',
      sportsman: 'Sportsman',
      extra: 'Extra',
    }
  },
  ////////////////////////////////////////////
  // Zakladni info
  basicInfo: {
    name: 'Basic info',
    children: {
      motherTongue: {
        name: 'Mother tongue',
        children: {
          cs: 'Czech',
          sk: 'Slovak',
          ru: 'Russiam',
          en: 'English',
          it: 'Italian',
          uk: 'Ukrainian'
        }
      },
      foreignLanguages: {
        name: 'Foreign languages',
        children: {
          en: 'English',
          cs: 'Czech',
          de: 'German',
          sk: 'Slovak',
          ru: 'Russian',
          es: 'Spain',
          fr: 'French',
          pl: 'Polak',
          it: 'Italian',
          uk: 'Ukrainian'
        }
      },
      driverLicenses: {
        name: 'Driver licenses',
        children: {
          car: 'Car',
          motorbike: 'Bike',
          moped: 'Scooter',
          truck: 'Truck',
          bus: 'Bus',
          tractor: 'Tractor',
          plane: 'Plane'
        }
      },
      etnicity: {
        name: 'Etnicity',
        children: {
          arabian: 'Arabian',
          asian: 'Asian',
          white: 'White',
          whiteSouthern: 'White southern',
          whiteNorthern: 'White northern',
          black: 'Black',
          euroasian: 'Euroasian',
          hispanic: 'Hispanic',
          indian: 'Indiánský',
          ind: 'Indian',
          mulato: 'Mulato',
          gipsy: 'Gipsy'
        }        
      },
      handicap: {
        name: 'Handicaps',
        children: {
          no: 'No handicaps',
          eyes: 'Eyes',
          ears: 'Ears',
          movement: 'Movement',
          other: 'Other'
        }
      },
      preferredJobs: {
        name: 'Preffered jobs',
        children: {
          dabing: 'Voiceover',
          theater: 'Theater',
          movies: 'Movies',
          photos: 'Photos',
          improvisation: 'Improvisation',
          musical: 'Musicals',
          radio: 'Radio',
          ads: 'Ads',
          studentsFilm: 'Student movies',
          videoclip: 'Videoclips',
          voiceover: 'Voiceover',
          hostesing: 'Hostesing'
        }    
      }
    }
  },
  ////////////////////////////////////////////
  // Vzhled
  appearance: {
    name: 'Appearance',
    children: {
      figure: {
        name: 'Figure',
        children: {
          skinny: 'Skinny',
          athletic: 'Athletic',
          normal: 'Normal',
          plus: 'Plus',
          muscle: 'Muscular'
        }
      },
      hair: {
        name: 'Hair',
        children: {
          hairLength: {
            name: 'Length',
            children: {
              extraShort: 'Extra short',
              short: 'Short',
              midShort:	'Mid short',
              long: 'long',
              extraLong: 'Extra long',
              bald: 'Bald'
            }
          },
          hairCut: {
            name: 'Haircut',
            children: {
              classic: 'Classic',
              mikado: 'Mikado',
              bangs: 'Bangs',
              mullet: 'Mullet',
              asymetric: 'Asymetric',
              sideShaved: 'Side shaved',
              undercut: 'Undercut',
              layered: 'Layered',
              crewCut: 'Crew cut',
              mohawk: 'Mohawk',
              topKnot: 'Top knot',
              fohawk: 'Fohawk'
            }
          },
          hairColorNatural: {
            name: 'Color',
            children: {
              lightBlond: 'Light blond',
              darkBlond: 'Dark blond',
              black: 'black',
              lightBrown: 'Light brown',
              darkBrown: 'Dark brown',
              grey: 'Grey',
              red: 'Red',
              other: 'Other'
            }
          },
          hairColorDyed: {
            name: 'Dyed color',
            children: {
              red: 'Red',
              darkRed: 'Dark red',
              purple: 'Purple',
              copper: 'Copper',
              blue: 'Blue',
              orange: 'Orange',
              pink: 'Pink',
              grey: 'Grey',
              cyan: 'Cyan',
              green: 'Green',
              yellow: 'Yellow',
              bleached: 'Bleached'
            }
          },
          hairType: {
            name: 'Type',
            children: {
              dreadlocks: 'Dreadlocks',
              rastaBraids: 'Rasta braids',
              ponytails: 'Ponytails',
              wavy: 'Wavy',
              curly: 'Curly',
              straight: 'straight',
              afro: 'Afro',
              wig: 'Wig'
            }    
          },
        }
      },
      tattoo: {
        name: 'Tattoo',
        children: {
          yes: 'Yes',
          no: 'No',
          tattooPlacements: {
            name: 'Tattoo placement',
            children: {
              arms: 'Arms',
              legs: 'Legs',
              face: 'Face',
              belly: 'Belly',
              back: 'Back',
              neck: 'Neck',
              chest: 'Chest',
              head: 'Head'
            }
          },
        }
      },
      eyeColor: {
        name: 'Eye color',
        children: {
          black: 'Black',
          brown: 'Brown',
          blue: 'Blue',
          grey: 'Grey',
          green: 'Green',
          yellow: 'Yellow'
        }    
      },
      teethColor: {
        name: 'Teeth',
        children: {
          white: 'White',
          normal: 'Normal',
          pigmentSpots: 'Pigment spots',
          yellow: 'Yellow',
          teethDefect: {
            name: 'Defects',
            children: {
              fixedBraces: 'Fixed braces',
              missingTeeth: 'Missing teeth',
              gaps: 'Gaps',
              crooked: 'Crooked',
              overbite: 'Overbite',
              underbite: 'Underbite',
              bruisedTeeth: 'Bruised teeth',
              fake: 'Fake',
              implant: 'Implant'
            }
          },
        }    
      },
      beard: {
        name: 'Beard',
        children: {
          yes: 'Yes',
          no: 'No',
          beardColor: {
            name: 'Color',
            children: {
              black: 'Black',
              brown: 'Brown',
              blond: 'Blond',
              grey: 'Grey',
              red: 'Red'
            }    
          },
          beardType: {
            name: 'Type',
            children: {
              stubble: 'Stubble',
              beard: 'Beard',
              mustacheChinStrap: 'Mustache chin strap',
              ovalBeard: 'Oval',
              musketeerBeard: 'Musketeer',
              muttonChops: 'Mutton chips',
              sideburns: 'Sideburns',
              emperorBeard: 'Emperor',
              horseshoeMustache: 'Horseshoe',
              goatee: 'Goatee',
              chinstrap: 'Chinstrap',
              mustache: 'Mustache',
              shortBeard: 'Short beard',
              longBeard: 'Long beard'
            }
          },
        }
      },
      piercing: {
        name: 'Piercing',
        children: {
          yes: 'Yes',
          no: 'No',
          piercingPlacements: {
            name: 'Placement',
            children: {
              chin: 'Chin',
              tongue: 'Tongue',
              nose: 'Nose',
              lips: 'Lips',
              eyebrow: 'Eyebrows',
              ears: 'Ears',
              earTunnels: 'Ear tunnels',
              face: 'Face',
              clavicle: 'Clavicle',
              teeth: 'Teeth',
              head: 'Head',
              belly: 'Belly',
            }
          },
        }
      },
      size: {
        name: 'Size',
        children: {
          XSX: 'XXS',
          XS: 'XS',
          S: 'S',
          M: 'M',
          L: 'L',
          XL: 'XL',
          XXL: 'XXL',
          XXXL: 'XXL+'
        }
      },
    }
  },
  ////////////////////////////////////////////
  // Zkusenosti
  experience: {
    name: 'Experience',
    children: {
      roleType: {
        name: 'Preffered roles',
        children: {
          leading: 'Leading',
          supporting: 'Supporting',
          featuredExtras: 'Featured extras',
          extra: 'Extra'
        }
      },
      actorType: {
        name: 'Actor',
        children: {
          theater: 'Theater',
          movies: 'Movies',
          ads: 'Ads',
          tv: 'TV',
          musical: 'Musical',
          standUp: 'Stand-up',
          amateur: 'Amateur'
        }
      },
      modelType: {
        name: 'Modeling',
        children: {
          photo: 'Photos',
          ads: 'Ads',
          fashionShow: 'Fashion shows',
          legmodel: 'Legmodel',
          handmodel: 'Handmodel',
          facemodel: 'Facemodel'
        }
      },
      stuntmanType: {
        name: 'Stuntman',
        children: {
          car: 'Cars',
          motorbike: 'Bikes',
          martialArts: 'Martial arts',
          swordplay: 'Swordplay',
          shooting: 'Shooting',
          horse: 'Horses',
          falling: 'Falling',
          fire: 'Fire',
          parachute: 'Parachutes'
        }
      },
      artisteType: {
        name: 'Artiste',
        children: {
          acrobatics: 'Acrobatics',
          rings: 'Rings',
          fire: 'Fire',
          trampoline: 'Trampoline',
          juggling: 'Juggling',
          equilibristics: 'Equilibrics',
          magic: 'Magic',
          horse: 'Horses',
          snakes: 'Snakes',
          snakesF: 'Snakes',
          taming: 'Taming'
        }    
      },
      moderatorType: {
        name: 'Moderator',
        children: {
          tv: 'TV',
          radio: 'Radio',
          actions: 'Public actions'
        }    
      },
    }
  }
}
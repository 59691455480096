<template>
  <v-dialog v-model="show" max-width="1500" @keydown.esc="showVideoPlayer ? showVideoPlayer = false : (showPhoto != -1 ? showPhoto = -1 : show = false)" content-class="pa-0 profile-dialog">
    <v-card v-if="profile != null && loading === false" class="px-0 pt-0" color="#f6f8fa">
      <v-btn absolute fab text top right small class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-container fluid fill-height style="width: 100%" class="pa-0">
        <!-- Mobile -->
        <section v-if="$vuetify.breakpoint.mdAndDown" class="pb-3 header" :height="$vuetify.breakpoint.mdAndDown ? 550 : (getProfilePicSize() + 50)" style="width: 100%; background-color: #ebf7ff; padding: 20px;">
          <v-layout row wrap align-center>
            <div :style="`box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1); background-color: white; border-radius: 50%; padding: 7px; width:240px; height: 240px; margin: auto`">
              <v-img :src="getImageUrl(profile, profile.mainPhoto, 600)"  class="rounded-circle" />
            </div>
          </v-layout>
          <h1 class="mt-3 mb-1" style="text-align:center; font-size: 24px; font-weight: normal">{{ profile.fullname }} <span style="color: #bbc0c2">{{ profile.dob.age ? profile.dob.age : profile.age }}</span></h1>
          <div class="mb-2 text-center" style="color: #8f969a">
            <template v-if="getMainProfession()">
              <v-chip color="white lighten-3" disabled class="ml-0 mr-2 shadow-2">
                <span class="black--text">{{ $t(getMainProfession()) }}</span>
              </v-chip>
            </template>
            <template v-if="profile.address">
              {{ profile.address }}
            </template>
            <template v-if="profile.height">
              <span class="grey--text text--lighten-2">&bull;</span>
              {{ profile.height }} cm
            </template>
          </div>
          <div v-if="profile.tags" class="text-center mb-3" style="color: #8f969a">{{ profile.tags.join(', ') }}</div>
          <div style="border-top: 1px solid #dde8f0">
            <h4 class="mt-2" style="text-transform: uppercase">{{$t('video')}}</h4>
            <div class="mt-2">
              <template v-if="videoThumbnail || videos.length || showreel.length > 0">
                <v-window v-model="videoPos" style="position: relative">
                  <template v-for="(item,i) in showreel">
                    <v-window-item v-if="item" :key="`card-${i}`">
                      <v-img :src="getYoutubeThumbnail(item)" contain :aspect-ratio="16/9" class="video-thumbnail" @click="showVideo(item)">
                        <img src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                      </v-img>
                    </v-window-item>
                  </template>
                  <template v-for="(video,v) in videos">
                    <v-window-item v-if="video" :key="v">
                      <v-img :src="video.videoThumbnail" contain :aspect-ratio="16/9" class="video-thumbnail" @click="showVideo(video.videoUrl)">
                        <img src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                      </v-img>
                    </v-window-item>
                  </template>
                  <v-window-item v-if="videoThumbnail">
                    <v-img :src="videoThumbnail" contain :aspect-ratio="16/9" class="video-thumbnail" @click="showVideo(videoUrl)">
                      <img src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                    </v-img>
                  </v-window-item>
                </v-window>
                <div style="width: 100%">
                  <template v-if="(showreel.length + videos.length + (videoThumbnail?1:0)) > 1">
                    <v-btn @click="(videoPos--) < 1 ? (videoPos = (showreel.length + videos.length + (videoThumbnail?1:0)) - 1) : videoPos" class="mx-0 px-2 shadow" color="white" icon style="border-radius: 50% !important; float: left"><v-icon size="25px" color="primary">keyboard_arrow_left</v-icon></v-btn>
                    <div class="mx-2" style="float: left; height: 48px; line-height: 48px; color: #8f969a">{{videoPos+1}} z {{(showreel.length + videos.length + (videoThumbnail ? 1 : 0))}}</div>
                    <v-btn @click="(videoPos++) > (showreel.length + videos.length + (videoThumbnail?1:0)) ? videoPos = 0 : videoPos" class="mx-0 px-2 shadow" color="white" icon style="border-radius: 50% !important; float: left"><v-icon size="25px" color="primary">keyboard_arrow_right</v-icon></v-btn>
                  </template>
                  <div style="clear: both"></div>
                </div>
              </template>
            </div>
          </div>
          <div style="border-top: 1px solid rgba(0,0,0,0.06)">
            <h4 class="mt-2">{{$t('pictures')}}</h4>
            <v-layout row wrap class="mx-auto pb-3 mt-2">
              <v-flex xs3 class="pa-1" v-if="profile.mainPhoto">
                <div class="add-photo-button" @click="showPhoto=0">
                  <v-img :src="getImageUrl(profile, profile.mainPhoto, 600)" aspect-ratio="1" contain style="border-radius: 4px" />
                </div>
              </v-flex>
              <template v-for="(photo, p) in profile.photos">
                <v-flex xs3 class="pa-1" v-if="photo" :key="p">
                  <div class="add-photo-button" @click="showPhoto=(p+1)">
                    <v-img :src="getImageUrl(profile, photo, 100)" aspect-ratio="1" contain style="border-radius: 4px" />
                  </div>
                </v-flex>
              </template>
            </v-layout>
          </div>
          <v-expansion-panels class="shadow mb-1">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-title  style="font-size: 1.1em" class="pa-0">
                  {{$t('basicInfo')}}
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="px-4">
                  <v-list two-line subheader class="mt-2">
                    <template v-for="(item, i) in basicItems">
                      <v-list-item :key="i">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.value">{{ item.value }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="`d`+i" v-if="item.value && i < basicItems.length - 1"></v-divider>
                    </template>
                  </v-list>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="shadow mb-1">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-title style="font-size: 1.1em" class="pa-0">
                  {{$t('appearance')}}
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="px-4">
                  <v-list two-line subheader class="mt-2">
                    <template v-for="(item, i) in appearanceItems">
                      <v-list-item :key="i">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.value">{{ item.value }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="`d`+i" v-if="item.value && i < appearanceItems.length - 1"></v-divider>
                    </template>
                  </v-list>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels class="shadow mb-1">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card-title style="font-size: 1.1em" class="pa-0">
                  {{$t('experience')}}
                </v-card-title>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="px-4">
                  <v-list two-line subheader class="mt-2">
                    <template v-for="(item, i) in experienceItems">
                      <v-list-item :key="i">
                        <v-list-item-content>
                          <v-list-item-title>{{ item.title }}</v-list-item-title>
                          <v-list-item-subtitle v-if="item.value">{{ item.value }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider :key="`d`+i" v-if="item.value && i < experienceItems.length - 1"></v-divider>
                    </template>
                    <template v-if="profile.roles && profile.roles.length">
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Projekty</v-list-item-title>
                          <v-list-item-subtitle>
                            <div v-for="(project, i) in projects" :key="i" class="py-1">
                              <span>{{ timestampToString(project.createdAt) }} - </span>
                              <a :href="'/project/' + project.projectId">{{ project.projectName}}</a>
                              <v-chip x-small :color="getStateColor(project.role.roleState)" class="ml-1 px-1">{{ getStateText(project.role.roleState, profile.sex, project.role.winner, project.role.roleStateReason) }}</v-chip>
                              <v-chip v-if="project.eventFiles" x-small class="ml-1 px-1" @click="playEventFiles(project.eventFiles)">{{ project.eventFiles.length }} <v-icon x-small>mdi-play</v-icon></v-chip>
                            </div>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <template v-if="profile.stats">
                      <v-divider></v-divider>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>Statistika</v-list-item-title>
                          <v-list-item-subtitle>
                            <span>Počet rolí: {{ profile.stats.roles }}</span><br />
                            <span>Procento nereagování: {{ profile.stats.roleNoResponsesPercent }}%</span><br />
                            <span>Počet pozvání na casting: {{ profile.stats.events }}</span><br />
                            <span>Počet provedených castingů: {{ profile.stats.eventUploads }}</span><br />
                            <span>Počet vítězství: {{ profile.stats.winner }}</span><br />
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-list>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </section>
        <!-- Desktop -->
        <section v-else style="width: 100%">
          <div style="background-color: #ebf7ff">
            <div>
              <v-parallax :src="getParallax()" :height="600" style="background-color: #ebf7ff">
                <div class="profile-top">
                  <v-layout row fill-height center>
                    <v-flex xs4 id="pic-column" class="pa-2 text-center">
                      <div :style="`box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1); background-color: white; border-radius: 50%; padding: 10px; width:` + (getProfilePicSize() - 70) + `px; height: ` + (getProfilePicSize() - 70) + `px; margin: auto`">
                        <v-img :src="getImageUrl(profile, profile.mainPhoto, 600)" class="rounded-circle"></v-img>
                      </div>
                    </v-flex>
                    <v-flex xs8 class="pt-2">
                      <h4 class="pl-3 mt-1" style="font-size: 28px; font-weight: normal">{{ profile.fullname }} <span style="color: #8f969a">{{ profile.dob.age ? profile.dob.age : profile.age }}</span></h4>
                      <div class="pb-3 pl-3">
                        <template v-if="getMainProfession()">
                          <v-chip color="white" class="ml-0 mr-2 shadow-2">
                            <span class="black--text">{{ $t(getMainProfession()) }}</span>
                          </v-chip>
                        </template>
                        <span style="color: #8f969a">
                          <template v-if="profile.address">
                            {{ profile.address }}
                          </template>
                          <template v-if="profile.height">
                            <span class="grey--text text--lighten-2">&bull;</span>
                            {{ profile.height }} cm
                          </template>
                          <template v-if="profile.tags">
                            <span class="grey--text text--lighten-2">&bull;</span>
                            {{ profile.tags.join(', ') }}
                          </template>
                        </span>
                      </div>
                      <v-layout row style="padding: 20px 20px 0px 15px;">
                        <v-flex xs6 px-3 v-if="videoThumbnail || videos.length || showreel.length > 0 || audios">
                          <div style="border-top: 1px solid #dde8f0">
                            <v-tabs v-model="modeTab" :slider-size="audios && audios.length ? 1 : 0" background-color="transparent" color="black" height="25px" class="mt-2">
                              <v-tab style="text-transform: uppercase" class="px-0 mr-3">{{$t('video')}}</v-tab>
                              <v-tab style="text-transform: uppercase" class="px-0" v-if="audios && audios.length">Audio</v-tab>
                            </v-tabs>
                            <div class="mt-2">
                              <v-tabs-items v-model="modeTab" style="background-color: transparent">
                                <v-tab-item>
                                  <v-window v-model="videoPos" style="position: relative">
                                    <template v-for="(item,i) in showreel">
                                      <v-window-item v-if="item" :key="`card-${i}`">
                                        <v-img :src="getYoutubeThumbnail(item)" contain :aspect-ratio="16/9" class="video-thumbnail" @click="showVideo(item)">
                                          <img src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                                        </v-img>
                                      </v-window-item>
                                    </template>
                                    <template v-for="(video,v) in videos">
                                      <v-window-item v-if="video" :key="v">
                                        <v-img :src="video.videoThumbnail" contain :aspect-ratio="16/9" class="video-thumbnail" @click="showVideo(video.videoUrl)">
                                          <img src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                                        </v-img>
                                      </v-window-item>
                                    </template>
                                    <v-window-item v-if="videoThumbnail">
                                      <v-img :src="videoThumbnail" contain :aspect-ratio="16/9" class="video-thumbnail" @click="showVideo(videoUrl)">
                                        <img src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                                      </v-img>
                                    </v-window-item>
                                  </v-window>
                                  <div style="width: 100%">
                                    <template v-if="(showreel.length + videos.length + (videoThumbnail?1:0)) > 1">
                                      <v-btn @click="(videoPos--) < 1 ? (videoPos = (showreel.length + videos.length + (videoThumbnail?1:0)) - 1) : videoPos" class="mx-0 px-2 shadow" color="white" icon style="border-radius: 50% !important; float: left"><v-icon size="25px" color="primary">keyboard_arrow_left</v-icon></v-btn>
                                      <div class="mx-2" style="float: left; height: 48px; line-height: 48px; color: #8f969a">{{videoPos+1}} z {{(showreel.length + videos.length + (videoThumbnail ? 1 : 0))}}</div>
                                      <v-btn @click="(videoPos++) > (showreel.length + videos.length + (videoThumbnail?1:0)) ? videoPos = 0 : videoPos" class="mx-0 px-2 shadow" color="white" icon style="border-radius: 50% !important; float: left"><v-icon size="25px" color="primary">keyboard_arrow_right</v-icon></v-btn>
                                    </template>
                                    <div style="clear: both"></div>
                                  </div>
                                </v-tab-item>
                                <v-tab-item v-if="audios && audios.length">
                                  <v-responsive :aspect-ratio="16/9" style="position: relative">
                                    <template v-for="(audio,a) in audios">
                                      <VuetifyAudio v-show="audioPos == a" :file="audio.url" color="white" :key="a"></VuetifyAudio>
                                    </template>
                                  </v-responsive>
                                  <div style="width: 100%">
                                    <template v-if="audios.length > 1">
                                      <v-btn @click="(audioPos--) < 1 ? (audioPos = (audios.length - 1)) : audioPos" class="mx-0 px-2 shadow" color="white" icon style="border-radius: 50% !important; float: left"><v-icon size="25px" color="primary">keyboard_arrow_left</v-icon></v-btn>
                                      <div class="mx-2" style="float: left; height: 48px; line-height: 48px; color: #8f969a">{{audioPos+1}} z {{(audios.length)}}</div>
                                      <v-btn @click="(audioPos > audios.length - 2) ? audioPos = 0 : audioPos++" class="mx-0 px-2 shadow" color="white" icon style="border-radius: 50% !important; float: left"><v-icon size="25px" color="primary">keyboard_arrow_right</v-icon></v-btn>
                                    </template>
                                    <div style="clear: both"></div>
                                  </div>
                                </v-tab-item>
                              </v-tabs-items>
                            </div>
                          </div>
                        </v-flex>
                        <v-flex px-3 :class="videoThumbnail || videos.length || showreel.length > 0 ? `xs6` : `xs12`">
                          <div style="border-top: 1px solid #dde8f0">
                            <v-tabs :slider-size="0" background-color="transparent" color="black" height="25px" class="mt-2">
                              <v-tab style="text-transform: uppercase" class="px-0 mr-3">{{$t('pictures')}}</v-tab>
                            </v-tabs>
                            <v-layout row wrap class="mt-2" style="max-height: 300px; overflow-y: auto;">
                              <v-flex :class="videoThumbnail || videos.length || showreel.length > 0 ? `xs2` : `xs1`" class="pa-1" v-if="profile.mainPhoto">
                                <div class="add-photo-button" @click="showPhoto=0">
                                  <v-responsive aspect-ratio="1" style="position: relative">
                                    <v-img :src="getImageUrl(profile, profile.mainPhoto)" :lazy-src="getImageUrl(profile, profile.mainPhoto, 600)" style="border-radius: 4px" />
                                  </v-responsive>
                                </div>
                              </v-flex>
                              <template v-for="(photo, p) in profile.photos">
                                <v-flex :class="videoThumbnail || videos.length || showreel.length > 0 ? `xs2` : `xs1`" class="pa-1" v-if="photo" :key="p">
                                  <div class="add-photo-button" @click="showPhoto=(p+1)">
                                    <v-img :src="getImageUrl(profile, photo)" :lazy-src="getImageUrl(profile, photo, 300)" aspect-ratio="1" contain style="border-radius: 4px" />
                                  </div>
                                </v-flex>
                              </template>
                            </v-layout>
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </v-parallax>
            </div>
          </div>
          <div style="margin-top: -70px">
            <v-layout row wrap style="padding: 0px 20px">
              <v-flex xs12 sm4 class="px-3">
                <v-card class="my-3 px-3 pt-3 pb-4 shadow" style="height: calc(100% - 40px)">
                  <v-card-title style="font-size: 24px" class="pa-0">
                    {{$t('basicInfo')}}
                  </v-card-title>
                  <div class="px-1">
                    <v-list two-line subheader class="mt-2">
                      <template v-for="(item, i) in basicItems">
                        <v-list-item :key="i">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.value">{{ item.value }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="`d`+i" v-if="item.value && i < basicItems.length - 1"></v-divider>
                      </template>
                    </v-list>
                  </div>
                </v-card>
              </v-flex>
              <v-flex xs12 sm4 class="px-3">
                <v-card class="my-3 px-3 pt-3 pb-4 shadow" style="height: calc(100% - 40px)">
                  <v-card-title style="font-size: 24px" class="pa-0">
                    {{$t('appearance')}}
                  </v-card-title>
                  <div class="px-1">
                    <v-list two-line subheader class="mt-2">
                      <template v-for="(item, i) in appearanceItems">
                        <v-list-item :key="i">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.value">{{ item.value }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="`d`+i" v-if="item.value && i < appearanceItems.length - 1"></v-divider>
                      </template>
                    </v-list>
                  </div>
                </v-card>
              </v-flex>
              <v-flex xs12 sm4 class="px-3">
                <v-card class="my-3 px-3 pt-3 pb-4 shadow" style="height: calc(100% - 40px)">
                  <v-card-title style="font-size: 24px" class="pa-0">
                    {{$t('experience')}}
                  </v-card-title>
                  <div class="px-1">
                    <v-list two-line subheader class="mt-2">
                      <template v-for="(item, i) in experienceItems">
                        <v-list-item :key="i">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.value">{{ item.value }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="`d`+i" v-if="item.value && i < experienceItems.length - 1"></v-divider>
                      </template>
                      <template v-if="profile.roles && profile.roles.length">
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Projekty</v-list-item-title>
                            <v-list-item-subtitle>
                              <div v-for="(project, i) in projects" :key="i" class="py-1">
                                <span>{{ timestampToString(project.createdAt) }} - </span>
                                <a :href="'/project/' + project.projectId">{{ project.projectName}}</a>
                                <v-chip x-small :color="getStateColor(project.role.roleState)" class="ml-1 px-1">{{ getStateText(project.role.roleState, profile.sex, project.role.winner, project.role.roleStateReason) }}</v-chip>
                                <v-chip v-if="project.eventFiles" x-small class="ml-1 px-1" @click="playEventFiles(project.eventFiles)">{{ project.eventFiles.length }} <v-icon x-small>mdi-play</v-icon></v-chip>
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                      <template v-if="profile.stats">
                        <v-divider></v-divider>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Statistika</v-list-item-title>
                            <v-list-item-subtitle>
                              <span>Počet rolí: {{ profile.stats.roles }}</span><br />
                              <span>Procento nereagování: {{ profile.stats.roleNoResponsesPercent }}%</span><br />
                              <span>Počet pozvání na casting: {{ profile.stats.events }}</span><br />
                              <span>Počet provedených castingů: {{ profile.stats.eventUploads }}</span><br />
                              <span>Počet vítězství: {{ profile.stats.winner }}</span><br />
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-list>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </div>
        </section>
      </v-container>
      <v-alert :value="showControls" v-if="getLoggedUser()" dark color="#b3d4fc" class="bottom_alert black--text" transition="scroll-y-reverse-transition">
        <div class="button_row">
          <div class="content">
            <v-btn small :color="profile.disliked ? `secondary` : `white`" fab class="ml-1 mr-1 elevation-0" @click="dislikeProfile(profile)">
              <v-icon size="24" :color="profile.disliked ? `white` : `black`">not_interested</v-icon>
            </v-btn>
            <v-btn small :color="profile.liked ? `secondary` : `white`" fab class="ml-1 mr-1 elevation-0" @click="likeProfile(profile)">
              <v-icon size="24" :color="profile.liked ? `white` : `black`">favorite_border</v-icon>
            </v-btn>
            <v-btn small fab light class="ml-1 mr-1 elevation-0" v-if="profile.email && profile.email.length" @click="showEmailDialog()">
              <v-icon size="24" color="black">mdi-email-outline</v-icon>
            </v-btn>
            <v-menu v-if="project" top offset-y style="position:absolute; z-index: 10000;">
              <template v-slot:activator="{on}">
                <v-btn v-on="on" color="white" small fab  class="ml-1 mr-1 elevation-0">
                  <v-icon size="24" color="black">mdi-account-plus</v-icon>
                </v-btn>
              </template>
              <v-list class="pa-0">
                <v-list-item v-for="role in project.roles" :key="role.roleName" @click="addPick(profile, role)">
                  <v-list-item-title>{{role.roleName}}</v-list-item-title>
                </v-list-item>
                <v-divider v-if="project.roles.length"></v-divider>
                <v-list-item color="primary" @click="showRoleDialog(null)">
                  <v-list-item-title>{{$t('ProfileDetail.addRole')}}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn small color="white" fab class="ml-1 mr-1 elevation-0" @click="linkProfile(profile)">
              <v-icon size="24" color="black">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </div>
      </v-alert>
    </v-card>
    <Gallery v-model="showPhoto" :profile="profile"></Gallery>
    <VideoPlayerSimple v-if="profile" v-model="showVideoPlayer" :source="selectedVideo"></VideoPlayerSimple>
    <VideoPlayer v-model="eventVideoPlayer.show" :profile="eventVideoPlayer.profile" hide-comments />
  </v-dialog>
</template>

<style>
#app.mobile .profile-dialog {
  overflow-y: auto !important;
}
#app.desktop .profile-dialog {
  max-height: 95% !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  padding: 0px;
  margin: 0px;
}
.profile-dialog .v-card{
  border-radius: 0px;
  padding-bottom: 72px;
}
.profile-dialog h2 {
  font-size: 24px;
  font-weight: normal;
  color: #131d2d;
}
.profile-dialog h4 {
  color: #131d2d;
}
.profile-detail .headline {
  font-size: 3em !important;
}
.profile-detail .profile-details-tabs .v-card__text {
  height: 45vh;
  overflow-y: auto;
}

.profile-detail .v-carousel {
  height: 400px;
}

.profile-detail td {
  border-bottom: 1px solid #f6f7f7;
  padding-top: 4px;
  padding-bottom: 4px;
}
#app.mobile .v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close_alt {
  top: 8px;
  right: 8px;
}
.rounded-circle {
  border-radius: 50%;
}
.video-thumbnail {
  border-radius: 4px; 
  cursor: pointer; 
  background-color: rgba(143, 150, 154, 0.1); 
  position: relative;
}

.play-overlay {
  width: 88px; 
  height: 88px; 
  position: absolute; 
  left: 50%; 
  top: 50%; 
  transform: translate(-50%, -50%);
  opacity: 0.7;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
}

.video-thumbnail:hover .play-overlay {
  opacity: 1;
}

.profile-dialog .v-list--two-line .v-list__tile {
  height: initial !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.profile-dialog .v-list__tile__sub-title {
  white-space: pre-wrap;
}
.profile-dialog .v-alert.bottom_alert {
  position: fixed; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}

.profile-dialog .v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
  width: 100%;
}

.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
  right: 64px;
}
.pane_expanded .v-alert.bottom_alert .close_alert {
  right: 80px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
</style>

<script>
const basicSteps = [
  "fullname",
  "email",
  "password",
  "sex",
  "dob",
  "country",
  "countryForeign",
  "address",
  "phone",
  "parentPhone",
  "motherTongue",
  "foreignLanguages",
  "driver",
  "driverLicenses",
  "driverLicensesMotorbike",
  "driverLicensesPlane",
  "etnicity",
  "tags",
  "jobQuestion",
  "civilJob",
  "handicap",
  "handicapText",
  "preferredJobs"
];

const experienceSteps = [
  "selectedProfessions",
  "mainProfession",
  "roleType",
  "actorType",
  "modelType",
  "stuntmanType",
  "artisteType",
  "dancerSkill",
  "dancerStyle",
  "musicianSkill",
  "musicianInstrument",
  "musicianStyle",
  "athleteSkill",
  "athleteType",
  "singerSkill",
  "singerType",
  "moderatorType",
  "experienceProfession"
];

const appearanceSteps = [
  "figure",
  "height",
  "weight",
  "eyeColor",
  "hairLength",
  "hairCut",
  "hairType",
  "hairColorNatural",
  "hairColorDyed",
  "beard",
  "beardColor",
  "beardType",
  "tattoo",
  "tattooPlacements",
  "piercing",
  "piercingPlacements",
  "teethType",
  "teethDefect",
  "teethColor",
  "measures",
  "measuresBreast",
  "measuresWaist",
  "measuresHips",
  "measuresHead",
  "measuresNeck",
  "measuresLeg",
  "size",
  "shoe"
];

import * as states from '@/libs/states'
import * as api from '@/libs/api'
import { Storage } from "aws-amplify";
import { mapActions, mapGetters } from 'vuex'
import VuetifyAudio from 'vuetify-audio'
import Gallery from './Gallery'
import VideoPlayerSimple from './VideoPlayerSimple'
import VideoPlayer from './VideoPlayer'

export default {
  name: 'ProfileDetail',
  components: {
    Gallery,
    VideoPlayerSimple,
    VuetifyAudio,
    VideoPlayer
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    showControls: {
      type: Boolean,
      default: () => true
    },
    userId: {
      type: String,
      default: () => null
    },
    userProfile: {
      type: Object,
      default: () => null
    },
  },
  data: () => {
    return {
      loading: true,
      showPhoto: -1,
      videoPos: null,
      selectedVideo: null,
      showVideoPlayer: false,
      modeTab: 0,
      audioPos: 0,
      eventVideoPlayer: {
        show: false,
        profile: null
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) {
        this.loading = true; 
        this.videoPos = null;
        this.$emit('input', value); 
        if (value === false)
          this.$store.state.dialogs.profileDetail = { show: false, profile: null, noRefresh: false }
      }
    },
    project: function() {
      return this.getCurrentProject();
    },
    basicItems() {
      return this.getItems(basicSteps)
    },
    appearanceItems() {
      return this.getItems(appearanceSteps);
    },
    experienceItems() {
      return this.getItems(experienceSteps);
    },
    showreel() {
      let showreel = this.profile.showreel;
      if (typeof showreel === 'undefined' || showreel === null)
        return [];

      if (showreel.length === 0)
        return showreel;

      showreel = showreel.filter(url => url !== null && url !== '' && !url.includes('vimeo.'));

      return showreel;
    },
    videos() {
      let videos = this.profile.videos;
      if (typeof videos === 'undefined' || videos === null)
        return [];

      return videos;
    },
    audios() {
      let audios = this.profile.audios;
      if (typeof audios === 'undefined' || audios === null)
        return [];

      return audios;
    }
  },
  asyncComputed: {
    async profile() {
      let user = this.getLoggedUser();
      if (this.userProfile) {
        let updatedActor = await api.getUpdatedActor(this.userProfile);
        if (user) {
          updatedActor.liked = (user.liked && user.liked.indexOf(updatedActor.userId)) > -1 ? true : false;
          updatedActor.disliked = (user.disliked && user.disliked.indexOf(updatedActor.userId)) > -1 ? true : false;
        }
        console.log(updatedActor);
        this.loading = false;
        return updatedActor;
      }
      let userId = this.userId;
      if (!userId)
        return null;
      try {
        let actor = !user ? await api.getActor(userId) : await api.getActorPrivate(userId);
        let updatedActor = await api.getUpdatedActor(actor);
        if (user) {
          updatedActor.liked = (user.liked && user.liked.indexOf(updatedActor.userId)) > -1 ? true : false;
          updatedActor.disliked = (user.disliked && user.disliked.indexOf(updatedActor.userId)) > -1 ? true : false;
        }
        this.loading = false;
        return updatedActor;
      }
      catch (err) {
        console.error(err)
        return null;
      }
    },
    async videoThumbnail() {
      if (this.profile && this.profile.video === 'COMPLETED') {
        let thumb = await Storage.get("thumb-videomp4/t00001.png", { level: 'private', identityId: (this.profile.manager || this.profile.userId), bucket: 'video-uploads.aircasting', provider: 'AWSS3' });
        return thumb
      }
      else
        return null
    },
    async videoUrl() {
      if (this.profile && this.profile.video === 'COMPLETED')
        return await Storage.get("video.mp4", { level: 'private', identityId: (this.profile.manager || this.profile.userId), bucket: 'video-uploads.aircasting', provider: 'AWSS3' });
      return null
    },
    async projects() {
      if (!this.profile || !this.profile.roles || !this.profile.roles.length || !this.profile.projects)
        return [];

      let projectsObj = {};

      for (let role of this.profile.roles) {
        if (role.roleState === 'added' || !role.projectId || !(role.projectId in this.profile.projects))
          continue;

        let project = this.profile.projects[role.projectId];
        if (!project)
          continue;

        project.role = role;
        projectsObj[project.projectId] = project;
      }

      for (let event of this.profile.events) {
        if (!event.projectId || !event.files || !(event.projectId in projectsObj))
          continue;

        let project = projectsObj[event.projectId];
        if (!project)
          continue;

        if (!project.eventFiles)
          project.eventFiles = [];

        project.eventFiles.push(...event.files);
      }

      let projects = Object.values(projectsObj);

      projects.sort((a, b) => {
        if (a.createdAt > b.createdAt)
          return -1;
        if (a.createdAt < b.createdAt)
          return 1;
        return 0;
      });

      return projects;
    }
  },
  methods:{
    ...mapActions(['addPickToCurrentProject', 'showRoleDialog']),
    ...mapGetters(['getCurrentProject', 'getLoggedUser']),
    getItems(steps) {
      let items = []
      let localizeValue = (key, value) => this.$i18n.keyExists(key + '.' + value) ? this.$t(key + '.' + value) : this.$t(value)
      for (let key of steps) {
        if (key in this.profile) {
          let value = this.profile[key]
          if (value && value.length) {
            if (Array.isArray(value))
              value = value.map((item) => localizeValue(key, item)).join(', ');
            else
              value = localizeValue(key, value);
            items.push({ title: this.$t(key + '.name'), value: value })
          }
        }
      }
      return items
    },
    getProfilePicSize() {
      let elem = document.getElementById("pic-column");
      if (elem)
        return elem.offsetWidth

      return 500
    },
    getMainProfession() {
      let mainProfession = this.profile.mainProfession;
      if (mainProfession && mainProfession.length)
        return mainProfession

      let selectedProfessions = this.profile.selectedProfessions;
      if (selectedProfessions && selectedProfessions.length)
        return selectedProfessions[0]

      return null;
    },
    getIcon() {
      let prof = this.getMainProfession()
      if (!prof)
        return ""

      switch (prof) {
        case 'actor': return 'fa-theater-masks'
        case 'model': return 'whatshot'
        case 'stuntman': return 'fa-user-ninja'
        case 'artiste': return 'fa-street-view'
        case 'dancer': return 'directions_walk'
        case 'singer': return 'fa-microphone-alt'
        case 'promoter': return 'fa-user-tie'
        case 'moderator': return 'accessibility_new'
        case 'voiceActor': return 'fa-headset'
      }

      return ''
    },
    getYoutubeThumbnail(url) {
      // eslint-disable-next-line no-useless-escape
      let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
      let match = url.match(regExp);
      let id = (match&&match[7].length==11)? match[7] : false;
      if (!id)
        return ''
      
      return 'https://img.youtube.com/vi/' + id + '/0.jpg'
    },
    showVideo(url) {
      this.selectedVideo = url
      this.showVideoPlayer = true;
    },
    getParallax() {
      let prof = this.getMainProfession()
      let sex = this.profile.sex;
      if (!prof)
        return "https://app.aircasting.cz/parallax-3.png"

      switch (prof) {
        case 'actor': return 'https://app.aircasting.cz/parallax-3.png'
        case 'model': return 'https://app.aircasting.cz/parallax-model.png'
        case 'stuntman': return 'https://app.aircasting.cz/parallax-gymnastics.png'
        case 'dancer': return sex === 'woman' ? 'https://app.aircasting.cz/parallax-dancer-female.png' : 'https://app.aircasting.cz/parallax-dancer-male.png'
        case 'singer': return 'https://app.aircasting.cz/parallax-singer.png'
        case 'moderator': return 'https://app.aircasting.cz/parallax-microphone.png'
        case 'speaker': return 'https://app.aircasting.cz/parallax-microphone.png'
        case 'voiceActor': return 'https://app.aircasting.cz/parallax-voiceactor.png'
        case 'sportsman': return 'https://app.aircasting.cz/parallax-sport.png'
      }

      return 'https://app.aircasting.cz/parallax-3.png'
    },
    getImageUrl(user, filename, size) {
      if (size)
        return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
      
      return 'https://d3d0zdy5deiwj.cloudfront.net/private/' + (user.manager || user.userId) + '/' + filename;
    },
    async likeProfile(profile) {
      if (profile.disliked)
        this.dislikeProfile(profile);

      if (profile.liked)
        profile.liked = false;
      else
        profile.liked = true;

      if (!this.$store.state.user.liked)
        this.$store.state.user.liked = [];

      let index = this.$store.state.user.liked.findIndex(userId => userId === profile.userId);

      if (index > -1) {
        this.$store.state.user.liked.splice(index, 1);
        await api.likeProfile(profile.userId, true);
      }
      else {
        this.$store.state.user.liked.push(profile.userId);
        await api.likeProfile(profile.userId, false);
      }
    },
    async dislikeProfile(profile) {
      if (profile.liked)
        this.likeProfile(profile);

      if (profile.disliked)
        profile.disliked = false;
      else
        profile.disliked = true;

      if (!this.$store.state.user.disliked)
        this.$store.state.user.disliked = [];

      let index = this.$store.state.user.disliked.findIndex(userId => userId === profile.userId);

      if (index > -1) {
        this.$store.state.user.disliked.splice(index, 1);
        await api.dislikeProfile(profile.userId, true);
      }
      else {
        this.$store.state.user.disliked.push(profile.userId);
        await api.dislikeProfile(profile.userId, false);
      }
    },
    addPick(profile, role) {
      profile.roleState = 'added';
      this.addPickToCurrentProject({pick: profile, role: role})
    },
    newTab(url) {
      var win = window.open(url, '_blank');
      win.focus();
    },
    linkProfile(profile) {
      if (!profile || !profile.userId)
        return;

      this.newTab(window.location.origin + "/profile/" + profile.userId)
    },
    showEmailDialog() {
      this.$store.state.dialogs.sendEmail = { show: true, actors: [this.profile] }
    },
    getStateText(state, sex, winner, reason) {
      return this.$t(states.getStateText(state, sex, winner)) + (reason ? (' (' + this.$t('States.' + reason) + ')') : '');
    },
    getStateIcon(state, winner) {
      return states.getStateIcon(state, winner);
    },
    getStateColor(state, winner) {
      return states.getStateColor(state, winner);
    },
    timestampToString(timestamp) {
      let date = new Date(timestamp);
      return date.getDate() + '. ' + date.getMonth() + '. ' + date.getFullYear();
    },
    async playEventFiles(eventFiles) {
      if (!eventFiles || !eventFiles.length) 
        return;

      let files = [];
      for (let f in eventFiles) {
        let key = eventFiles[f].key;
        let thumb = eventFiles[f].thumbnail;
        
        if (key.length < 59 || thumb.length < 59)
          continue;

        let videoThumbnail = await Storage.get(thumb.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (this.profile.manager || this.profile.userId), download: false });
        let videoUrl = await Storage.get(key.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (this.profile.manager || this.profile.userId), download: false });
        files.push({ videoThumbnail: videoThumbnail, videoUrl: videoUrl });
      }
      let eventProfile = this.profile;
      eventProfile.files = files;
      this.eventVideoPlayer.show = true;
      this.eventVideoPlayer.profile = eventProfile;
    }
  }
}
</script>
import { Doughnut } from 'vue-chartjs'
import { EventBus } from '@/libs/eventBus.js';
 
export default {
  extends: Doughnut,
  props: {
    data: {
      type: Array,
      default: () => []
    },
    labels: {
      type: Array,
      default: () => []
    },
    colors: {
      type: Array,
      default: () => ["#FF5252", "#e0e0e0", "#FF5252", "#4CAF50"]
    },
    index: {
      type: Number,
      default: () => 0
    }
  },
  mounted () {
    this.renderChart({
      labels: this.labels,
      datasets: [
        {
          backgroundColor: this.colors,
          data: this.data,
          borderWidth: 1
        }
      ],
      index: this.index
    },
    {
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
        custom: function(tooltipModel) {
          EventBus.$emit('showTooltip', {model: tooltipModel, index: this._data.index});
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      cutoutPercentage: 75
    });
  }
}
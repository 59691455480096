<template>
<div style="height: 100%">
  <v-container fluid grid-list-md class="pt-0 pr-0 pb-0 pl-0 events" style="max-width: 100%; height: 100%;">
    <v-layout row style="height: 100%;">
      <v-flex shrink pa-0 v-if="projectDrawer" style="width: 300px;">
        <v-navigation-drawer v-model="projectDrawer" left width="300">
          <Timeline @collapse="projectDrawer = false" @rolesSelected="selectRoles"></Timeline>
        </v-navigation-drawer>
      </v-flex>
      <v-flex grow pa-0 style="width: 1px;">
        <Role v-if="rolesSelected"></Role>
        <Event v-else></Event>
      </v-flex>
    </v-layout>
    <v-btn v-if="!projectDrawer" fixed small fab bottom left :style="$vuetify.breakpoint.smAndDown ? `bottom: 67px !important`:`bottom: 13px !important`"  :color="($vuetify.breakpoint.smAndDown ? `primary`: `grey lighten-3`)" @click="projectDrawer = true"><v-icon :color="($vuetify.breakpoint.smAndDown ? `white`: `black`)">fa-list-ul</v-icon></v-btn>
  </v-container>
  <v-speed-dial v-model="fab" fixed bottom right direction="top" :style="$vuetify.breakpoint.smAndDown ? `bottom: 67px`:``">
    <template v-slot:activator>
      <v-btn v-on="on" fab color="red darken-1"><v-icon color="white" style="line-height: 56px">add</v-icon></v-btn>
    </template>
    <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" fab dark small color="orange darken-1" class="mb-2" @click="newProject">
          <v-icon style="line-height: 40px">mdi-notebook</v-icon>
        </v-btn>
      </template>
      <span>{{$t('Events.addProject')}}</span>
    </v-tooltip>
    <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" fab dark small color="green" @click="newRole">
          <v-icon style="line-height: 40px">fa-users</v-icon>
        </v-btn>
      </template>
      <span>{{$t('Events.addRole')}}</span>
    </v-tooltip>
    <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" fab dark small color="primary" @click="newEvent">
          <v-icon style="line-height: 40px">mdi-calendar-clock</v-icon>
        </v-btn>
      </template>
      <span>{{$t('Events.addEvent')}}</span>
    </v-tooltip>
  </v-speed-dial>
</div>
</template>
<style>
h1 {
  font-weight: 300;
}
.v-list--two-line .v-list__tile {
  height: 54px !important;
}
.events .v-btn--floating .v-btn__content :not(:only-child):first-child, .projects .v-btn--floating .v-btn__content :not(:only-child):last-child {
  top: -12px;
}
</style>
<script>
import { mapGetters } from 'vuex'
import Timeline from '@/panels/Events'
import Role from '@/pages/Role'
import Event from '@/pages/Event'

export default {
  name: 'Events',
  components: {
    Timeline,
    Role,
    Event
  },
  data() {
    return {
      projectDrawer: !this.$vuetify.breakpoint.smAndDown,
      speedDialTooltip: false,
      fab: false,
      rolesSelected: true
    }
  },
  watch: {
    fab(val) {
      setTimeout(() => { this.speedDialTooltip = val }, 300);
    },
  },
  methods: {
    ...mapGetters(['getCurrentProject', 'getCurrentEvent', 'getRole']),
    newEvent() {
      this.$store.state.dialogs.newEvent.show = true;
    },
    newRole() {
      this.$store.state.dialogs.roleDialog.profile = null;
      this.$store.state.dialogs.roleDialog.show = true;
    },
    newProject() {
      this.$store.state.dialogs.newProject.show = true;
    },
    selectRoles(value) {
      this.rolesSelected = value;
    }
  }
}
</script>

export default {
  s3: {
    REGION:  'eu-west-1',
    BUCKET: 'image-uploads.aircasting'
  },
  apiGateway: {
    REGION: "eu-central-1",
    URL: "https://x42fwfzmy8.execute-api.eu-central-1.amazonaws.com/prod"
  },
  cognito: {
    REGION: 'eu-central-1',
    USER_POOL_ID: 'eu-central-1_DUScsr2qg',
    APP_CLIENT_ID: '5n3mpjd23shqd2p2apq39n9m82',
    IDENTITY_POOL_ID: 'eu-central-1:f5de1d62-a0c3-4268-b7ce-3bb51d76d8f2'
  }
};
<template>
  <v-dialog persistent v-model="show" max-width="650" @keydown.esc="dialog ? dialog = false : show = false">
    <v-card color="#f6f8fa" class="edit-role-dialog">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">{{$t('EditEventDialog.headline')}}</v-card-title>
      <v-card-text>
        <v-textarea :label="$t('AddEventAttendeesEx.instructionsTitle')" :rows="7" v-model="description" required></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" class="mx-0" text @click="showVideoUploadDialog()"><v-icon size="18" class="mr-2">video_call</v-icon> {{(role && role.video) ? 'Změnit video instrukce' : 'Přidat video instrukce'}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="show = false">{{$t('cancel')}}</v-btn>
        <v-btn class="elevation-0" color="primary" @click="save">{{$t('save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>

#app .checked .square-checkbox {
  background-color: white;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditEventDialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    role: {
      type: Object,
      default: () => null
    },
    event: {
      type: Object,
      default: () => null
    },
    saveInstructions: {
      type: Function,
      default: (role) => {
        console.log(role);
      }
    }
  },
  data: () => {
    return {
      description: null,
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) {
        this.$emit('input', value); 
      }
    }
  },
  watch: {
    show: function(value) {
      if (value == false || !this.role || !this.role.instructions)
        return;
      this.description = this.role.instructions;
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    ...mapActions(['updateRole']),
    save() {
      if (!this.description)
        return;
      
      this.saveInstructions(this.description);
      this.show = false;
    },
    submit() {

    },
    showVideoUploadDialog() {
      this.$store.state.dialogs.videoUploadDialog.show = true;
      this.$store.state.dialogs.videoUploadDialog.role = this.role;
      this.$store.state.dialogs.videoUploadDialog.event = this.event;
    }
  }
}
</script>
<template>
<div style="height: calc(100% - 135px)" class="files_page" :key="renderCount">
  <v-container fluid grid-list-md  class="pa-0" style="max-width: 100%">
    <div class="pa-4" style="width: 100%">
      <template v-if="project!=null && project.roles!=null">
        <section v-for="(role, r) in roles" :key="r" class="pt-3 pb-4 mb-5 role_section shadow white" style="padding-left: 48px; padding-right: 48px;">
          <template v-if="role.attendees.length">
            <h3 class="check_header">
              <v-btn small icon class="ma-0 check_header_button" v-on:click.stop="checkArray(r, role.attendees.length)">
                <v-icon>{{getArrayCheckIcon(r, role.attendees.length)}}</v-icon>
              </v-btn>
              {{role.roleName}}
            </h3>
            <v-layout class="row wrap" style="min-width: 100%">
              <v-flex v-for="(attendee, i) in role.attendees" :key="i" xs12 md4 lg4 xl3>
                <v-card hover class="pa-0 ma-1">
                  <v-img contain v-on:click="showVideoPlayer(attendee)" :aspect-ratio="16/9" :src="attendee.files[0].videoThumbnail ? attendee.files[0].videoThumbnail : attendee.files[0].path" style="background-color: rgb(235, 247, 255)">
                    <div style="float: left">
                      <v-btn icon style="background-color: rgba(255,255,255,0.45)" small class="mt-2 ml-2" :class="!isItemChecked(r, i) && $vuetify.breakpoint.mdAndUp ? `show-on-hover` : ``" v-on:click.stop="checkItem(r, i)">
                        <v-icon color="black">{{isItemChecked(r, i) ? `mdi-check-circle` : `mdi-checkbox-blank-circle-outline`}}</v-icon>
                      </v-btn>
                    </div>
                    <div style="float: right">
                      <div class="pt-2 pr-2" v-if="attendee.videoCount">
                        <v-chip small class="videos-chip px-0" color="white" text-color="#444" style="font-weight: bold; font-size: 1.1em">{{attendee.videoCount}}<v-icon class="ml-1">videocam</v-icon></v-chip>
                      </div>
                      <div class="pt-2 pr-2" v-if="attendee.photoCount">
                        <v-chip small class="videos-chip px-0" color="white" text-color="#444" style="font-weight: bold; font-size: 1.1em">{{attendee.photoCount}}<v-icon class="ml-1">camera</v-icon></v-chip>
                      </div>
                      <div class="pt-2 pr-2" v-if="attendee.audioCount">
                        <v-chip small class="videos-chip px-0" color="white" text-color="#444" style="font-weight: bold; font-size: 1.1em">{{attendee.audioCount}}<v-icon class="ml-1">mdi-volume-high</v-icon></v-chip>
                      </div>
                    </div>
                    <div style="clear: both"></div>
                    <v-avatar v-if="attendee.files[0].type === 'photo'" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" :size="80" color="rgba(0,0,0,0.5)">
                      <v-icon :size="50" color="white">mdi-magnify</v-icon>
                    </v-avatar>
                    <v-avatar v-else style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" :size="80" color="rgba(0,0,0,0.5)">
                      <v-icon :size="50" color="white">mdi-play</v-icon>
                    </v-avatar>
                  </v-img>
                  <v-card-title primary-title class="pa-3" style="position: relative; cursor: default;">
                    <v-chip pill color="rgb(235, 247, 255)" @click="showProfileDetail(attendee)">
                      <v-avatar left>
                        <img :src="getImageUrl(attendee, attendee.mainPhoto, 40)">
                      </v-avatar>
                      {{ attendee.name }}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-badge :value="typeof attendee.comments != 'undefined' && attendee.comments.length > 0" left overlap color="rgba(12,49,158,0.7)">
                      <span slot="badge">{{attendee.comments ? attendee.comments.length : ``}}</span>
                      <v-btn @click="showVideoPlayer(attendee)" icon small class="my-0 mx-1"><v-icon size="21" color="primary">{{commented(attendee) ? `mdi-forum` : `mdi-forum-outline`}}</v-icon></v-btn>
                    </v-badge>
                    <v-tooltip bottom>
                      <template v-slot:activator="{on}">
                        <v-badge v-on="on" :value="typeof attendee.likes != 'undefined' && attendee.likes.length > 0" right overlap color="rgba(12,49,158,0.7)">
                          <span slot="badge">{{ attendee.likes ? attendee.likes.length : 0 }}</span>
                          <v-btn @click="likeVideo(attendee)" icon small class="my-0 mx-1"><v-icon color="primary">{{liked(attendee) ? `mdi-thumb-up` : `mdi-thumb-up-outline`}}</v-icon></v-btn>
                        </v-badge>
                      </template>
                      <span v-if="attendee.likes && attendee.likes.length">
                        <div v-for="(like, l) in attendee.likes" :key="l">{{like}}</div>
                      </span>
                      <span v-else>{{$t('Files.notRankedYet')}}</span>
                    </v-tooltip>
                  </v-card-title>
                </v-card>
              </v-flex>
            </v-layout>
          </template>
        </section>
      </template>
      <v-alert :value="itemsSelected" dark color="#b3d4fc" class="bottom_alert black--text" transition="scroll-y-reverse-transition">
        <v-avatar v-for="(selected, s) in selectedAvatars" :key="s" color="white" size="40" class="mr-1">
          <img v-if="selected.url" :src="selected.url">
          <template v-else>{{selected.count}}</template>
        </v-avatar>
        <div class="button_row" style="width: 100%">
          <div class="content">
            <v-btn small fab  class="ml-1 mr-1 elevation-0">
              <v-icon size="24" color="black">thumb_down_alt</v-icon>
            </v-btn>
            <v-btn small fab  class="ml-1 mr-1 elevation-0">
              <v-icon size="24" color="black">thumb_up_alt</v-icon>
            </v-btn>
            <v-btn small fab @click="shareNameDialog = true" class="ml-1 mr-1 elevation-0">
              <v-icon size="24" color="black">share</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="close_alert" style="right: 72px">
          <v-btn icon @click="checkedItems = []">
            <v-icon color="black">close</v-icon>
          </v-btn>
        </div>
      </v-alert>
    </div>
    <VideoPlayer v-model="videoPlayerDialog.show" :profile="videoPlayerDialog.profile" :source="videoPlayerDialog.source"></VideoPlayer>
  </v-container>  
  <v-dialog max-width="600" v-model="shareNameDialog" persistent>
    <v-card flat tile>
      <template v-if="shareURL">
        <v-card-text>
          <span style="font-size: 18px">Odkaz ke sdileni</span>
          <v-text-field class="mt-4" v-model="shareURL" label="URL"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="shareNameDialog = false" color="primary">OK</v-btn>
        </v-card-actions>
      </template>
      <template v-else>
        <v-card-text>
          <span style="font-size: 18px">{{$t('Files.addSharingName')}}</span>
          <v-text-field v-model="shareName" :label="$t('Files.name')"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="shareVideos()" color="primary">{{$t('Files.createUrl')}}</v-btn>
          <v-btn class="elevation-0" @click="shareNameDialog = false">{{$t('cancel')}}</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
  <v-dialog max-width="600" v-model="showCommentsDialog" @keydown.esc="showCommentsDialog = false" content-class="shared-comments-dialog">
    <v-card flat tile v-if="showCommentsFor" color="#ebf7ff">
      <v-btn absolute fab top right small text class="dialog_close" @click="showCommentsDialog=false"><v-icon size="24">close</v-icon></v-btn>
      <v-card-title class="headline">{{$t('Files.commentOnActor')}}</v-card-title>
      <v-card-text class="px-3 pb-0">
        <v-list two-line  style="background-color: #ebf7ff">
          <template v-for="(comment, c) in showCommentsFor.comments">
            <v-list-item :key="c">
              <v-list-item-content>
                <v-list-item-title><div style="float: left; font-size: 12px; color: rgb(0,0,0,0.7)">{{comment.commentName}}</div> <div style="float: right; font-size: 12px; color: rgb(0,0,0,0.7)">{{comment.commentDate}}</div><div style="clear: both;"></div></v-list-item-title>
                <v-list-item-subtitle style="color: black">{{comment.commentText}}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="`d`+c"></v-divider>
          </template>
        </v-list>
        <v-textarea autofocus solo v-model="comment" class="mt-4" :label="$t('Files.commentary')"></v-textarea>
      </v-card-text>
      <v-card-actions class="px-3 pt-0 pb-3">
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="sendComment" :disabled="comment === null || comment.length < 1" color="primary">{{$t('send')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<style>
.show-on-hover {
  visibility: hidden;
}
.v-card:hover .show-on-hover {
  visibility: visible;
}
.check_header {
  margin-left: -36px;
  font-size: 24px;
  font-weight: 400;
}
#app.desktop .role_section .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: hidden;
}
#app.desktop .role_section:hover .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: visible;
}
.v-alert.bottom_alert {
  position: fixed; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 2;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
}
.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
.files_page .v-chip .v-chip__content {
  cursor: pointer !important;
}
.files_page .v-badge__badge {
  width: 18px;
  height: 18px;
}
.shared-comments-dialog .v-list__tile {
  padding: 0px;
}
.shared-comments-dialog .v-list--two-line .v-list__tile {
  height: initial !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.shared-comments-dialog .v-list__tile__sub-title {
  white-space: pre-wrap;
}
.files_page .v-chip.videos-chip {
  height: 22px !important;
}
.files_page .v-chip.videos-chip  .v-chip__content {
  padding: 0px 6px;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions, mapGetters } from 'vuex'
import { Storage } from "aws-amplify"
import VideoPlayer from './../dialogs/VideoPlayer'

export default {
  name: 'Files',
  components: {
    VideoPlayer,
  },
  data() {
    return {
      checkedItems: [],
      projectDrawer: !this.$vuetify.breakpoint.smAndDown,
      activeRole: 'Všechny role',
      selectedEvent: 'Všechny události',
      videoPlayerDialog: {
        show: false,
        profile: null
      },
      showVideoPlayerDialog: false,
      selectedProfile: null,
      selectedVideo: null,
      shareNameDialog: false,
      shareName: null,
      shareURL: null,
      showCommentsFor: null,
      comment: null,
      renderCount: 0
    }
  },
  watch: {
    project: function() {
      //this.updateCurrentProject(val);
    }
  },
  computed: {
    itemsSelected: function() {
      return this.checkedItems.length;
    },
    selectedAvatars: function() {
      let result = [];
      let count = 0
      let max = this.$vuetify.breakpoint.lgAndUp ? 10 : (this.$vuetify.breakpoint.mdAndUp ? 5 : 2);
      for (let item of this.checkedItems)
        if (++count <= max)
          result.push({url: this.getImageUrl(this.roles[item[0]].attendees[item[1]], this.roles[item[0]].attendees[item[1]].mainPhoto, 40)});

      if (count > max)
          result.push({count: '+' + (count - max)});

      return result;
    },
    event: function() {
      return this.getCurrentEvent();
    },
    anyCompletedEvent() {
      if (!this.project)
        return false;
      for (let event of this.project.events) {
        for (let attendee of event.attendees) {
          if (attendee.eventState == 'uploaded' && attendee.files && attendee.files.length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    showCommentsDialog: {
      get() {
        return this.showCommentsFor !== null;
      },
      set(val) {
        if (val === false)
          this.showCommentsFor = null;
      }
    },
    project() {
      return this.getCurrentProject();
    },
    empty() {
      return !this.roles || !this.roles.length;
    },
    username() {
      let loggedUser = this.getLoggedUser();
      return (loggedUser && loggedUser.fullname) ? loggedUser.fullname : '';
    },
  },
  asyncComputed: {
    async roles() {
      if (!this.event)
        return [];
      let roles = [];
      let attendeeMap = new Map();
      for (let attendee of this.event.attendees) {
        if (!attendee.roleId || attendee.eventState !== 'uploaded' || !attendee.files || !attendee.files.length)
          continue;

        let actor = await this.getUpdatedActor(attendee, this.event.eventId);
        let arr = attendeeMap.get(attendee.roleId);
        if (arr && Array.isArray(arr))
          arr.push(actor);
        else
          arr = [actor];

        attendeeMap.set(attendee.roleId, arr);
      }

      for (let [roleId, attendees] of attendeeMap) {
        let role = this.getRole()(roleId);
        if (!role)
          continue;
        roles.push({ roleName: role.roleName, attendees: attendees });
      }
      console.log(roles);
      return roles;
    },
  },
  methods: {
    ...mapActions(['updateCurrentProject', 'showProfileDetail']),
    ...mapGetters(['getCurrentProject', 'getCurrentEvent', 'getRole', 'getLoggedUser']),
    formatDate(date) {
      const [year, month, day] = date.split('-')
      return `${day}. ${month}. ${year}`
    },
    updateProject: function() {
      this.updateCurrentProject(this.project);
    },
    showVideoPlayer(profile, url) {
      this.videoPlayerDialog = {
        show: true,
        profile: profile,
        source: url
      }
    },
    toggleTrophy: function(profile) {
      profile.trophy = !profile.trophy;
      this.$forceUpdate();
    },
    async getUpdatedActor(actor) {
      let ageDate = new Date(Date.now() - new Date(actor.dob));
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      actor.age = age;
      actor.location = { city: actor.address };
      actor.name = actor.fullname;
      actor.videoCount = 0;
      actor.photoCount = 0;
      actor.audioCount = 0;
      if (actor.files) {
        for (let f in actor.files) {
          let type = actor.files[f].type;
          if (!type || type === 'video') {
            let key = actor.files[f].key;
            let thumb = actor.files[f].thumbnail;
            
            if (key.length < 59 || thumb.length < 59)
              continue;

            actor.files[f].videoThumbnail = await Storage.get(thumb.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
            actor.files[f].videoUrl = await Storage.get(key.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
            actor.videoCount++;
          }
          else if (type === 'photo') {
            actor.files[f].path = await Storage.get(actor.files[f].filepath, { level: 'private', bucket: 'image-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
            actor.photoCount++;
          }
          else if (type === 'audio') {
            actor.files[f].path = await Storage.get(actor.files[f].filepath, { level: 'private', bucket: 'audio-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
            actor.audioCount++;
          }
        }
      }

      return actor;
    },
    checkArray: function(r, c) {
      if (this.checkedItems.findIndex(a => a[0] == r) > -1)
        this.checkedItems = this.checkedItems.filter(a => a[0] !== r);
      else {
        for (let i = 0; i < c; ++i)
          this.checkedItems.unshift([r, i]);
      }
    },
    getArrayCheckIcon: function(r, c) {
      let ac = 0
      for (let a of this.checkedItems) {
        if (a[0] === r)
          ac++;
      }
      if (ac == 0)
        return 'mdi-checkbox-blank-circle-outline';
      else if (ac == c)
        return 'mdi-check-circle';

      return 'mdi-radiobox-marked';
    },
    checkItem: function(r, i) {
      let index = this.checkedItems.findIndex(a => a[0] == r && a[1] == i);
      if (index > -1)
        this.checkedItems.splice(index, 1);
      else
        this.checkedItems.unshift([r, i]);
    },
    isItemChecked: function(r, i) {
      return this.checkedItems.findIndex(a => a[0] == r && a[1] == i) > -1;
    },
    async shareVideos() {
      const projectId = this.project.projectId;
      const eventId = this.event.eventId;
      let videos = [];
      for (let item of this.checkedItems)
        videos.push( { roleName: this.roles[item[0]].roleName, userId: this.roles[item[0]].attendees[item[1]].userId } );
      try {
        const shareId = await api.shareVideos(projectId, eventId, this.shareName, videos);
        this.shareURL = "https://prod.aircasting.cz/share/" + shareId;
      }
      catch (err) {
        console.log(err);
      }
    },
    copyShareURL() {

    },
    getIcon(event) {
      switch (event.eventType) {
        case 'Casting': return 'fa-users';
        case 'Call back': return 'fa-sync-alt';
        case 'Fitting': return 'fa-user-tie';
        case 'Zkouška': return 'fa-street-view';
        case 'Natáčení': return 'movie';
      }

      return 'date_range';
    },
    getColor(event) {
      if (event.eventType == 'Natáčení')
        return 'red darken-4';

      return 'primary';
    },
    liked(actor) {
      if (!actor || !actor.likes)
        return false;

      return actor.likes.findIndex(like => like === this.username) > -1;
    },
    async likeVideo(actor) {
      if (!actor)
        return;

      if (!actor.likes)
        actor.likes = [];

      let index = actor.likes.findIndex(like => like === this.username);
      if (index > -1) {
        actor.likes.splice(index, 1);
        await api.likeVideo(actor.eventId, actor.userId, this.username, false);
        this.renderCount++;
      }
      else {
        actor.likes.push(this.username);
        await api.likeVideo(actor.eventId, actor.userId, this.username, true);
        this.renderCount++;
      }
    },
    commented(actor) {
      if (!actor || !actor.comments)
        return false;

      return actor.comments.findIndex(comment => comment.commentName === this.username) > -1;
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    }
  }
}
</script>

<template>
<div style="height: 100%">
  <template v-if="loading">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  <template v-else-if="project == null">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <img src="assets/empty-project.svg" width="200" class="mx-auto" />
          <div class="mb-5 mt-5 mx-auto" style="color: #131d2d; font-size: 26px; max-width: 270px;">{{$t('Projects.emptyText')}}</div>
          <v-btn large style="height: 56px; font-size: 18px; font-weight: normal;" class="elevation-0" color="primary" @click="$store.state.dialogs.newProject.show=true">{{$t('Projects.create')}}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  <template v-else>
    <v-container fluid grid-list-md  class="pt-0 pr-0 pb-5 pl-0 projects" style="max-width: 100%">
      <section class="pl-4 pt-4 pb-4 project-header" style="width: 100%; background-color: white;">
        <div style="float:left">
          <v-avatar v-if="project.logo" size="100px" class="mr-2">
            <img :src="project.logo" />
          </v-avatar> 
        </div>
        <div style="float: left; position: relative; width: calc(100% - 150px); height: 100px; padding-left: 18px;">
          <div style="position: absolute; top: 50%; transform: translateY(-50%); width: 100%;">
            <h2>
              <span v-if="showClient" class="grey--text">{{$t('Projects.client')}} </span>
              <span v-if="showClient">{{project.client}}</span> 
              <span v-if="showClient && showAgency" class="grey--text text--lighten-2"> &bull; </span> 
              <span v-if="showAgency" class="grey--text">{{$t('Projects.agency')}} </span>
              <span v-if="showAgency">{{project.agency}}</span> 
            </h2>
            <h1>{{project.projectName}} <v-icon class="mb-3" small @click="showEditProjectDialog = true">edit</v-icon></h1>
          </div>
        </div>
        <div style="clear: both"></div>
      </section>
      <div :class="$vuetify.breakpoint.mdAndUp ? `pa-4` : `py-4`" class="projects-contents" style="width: 100%">
        <v-layout class="row wrap mt-3">
          <v-flex xs4 sm2 v-if="$vuetify.breakpoint.mdAndUp && remainingDays !== null">
            <div class="px-3 top-bar-section border" v-if="remainingDays >= 0">
              <div class="top-bar-header">{{$t('Projects.shootingCountdown')}}</div>
              <div class="top-bar-text">{{remainingDays}} {{$t('Projects.days')}}</div>
            </div>
            <div class="px-3 top-bar-section border" v-else>
              <div class="top-bar-header">{{$t('Projects.shootingDaysAgo')}}</div>
              <div class="top-bar-text">{{-1 * remainingDays}} {{remainingDays == -1 ? $t('Projects.day') : $t('Projects.dayPlural')}}</div>
            </div>
          </v-flex>
          <v-flex xs4 sm2 v-if="$vuetify.breakpoint.mdAndUp">
            <v-progress-circular class="mb-4 ml-2 d-inline-block" :size="40" :width="3" :value="replied" color="green"></v-progress-circular>
            <div style="width: calc(100% - 60px)" class="px-3 top-bar-section border">
              <div class="top-bar-header">{{$t('Projects.responseRateHeader')}}</div>
              <div class="top-bar-text">{{replied}}{{$t('Projects.responseRateText')}}</div>
            </div>
          </v-flex>
          <v-flex xs4 sm2 v-if="$vuetify.breakpoint.mdAndUp">
            <v-progress-circular class="mb-4 ml-2 d-inline-block" :size="40" :width="3" :value="accepted" color="green"></v-progress-circular>
            <div style="width: calc(100% - 60px)" class="px-3 top-bar-section border">
              <div class="top-bar-header">{{$t('Projects.acceptRateHeader')}}</div>
              <div class="top-bar-text">{{accepted}}{{$t('Projects.responseRateText')}}</div>
            </div>
          </v-flex>
          <v-flex xs12 sm6 style="position: relative">
            <v-icon v-if="notifications && notifications.length && $vuetify.breakpoint.mdAndUp" style="position: absolute; left: 14px; top: 6px;" :size="42" color="grey lighten-2">{{notifications[notificationPos].icon}}</v-icon>
            <div class="top-bar-notifications px-3 top-bar-section d-inline-block" v-if="notifications && notifications.length">
              <div class="top-bar-header">
                <span>{{$t('Projects.notifications')}}</span>
                <template v-if="notifications.length > 1">
                  <v-icon @click="notificationPos = (notificationPos == 0 ? (notifications.length-1) : (notificationPos-1))" small>chevron_left</v-icon> 
                  <span class="grey--text text--lighten-1">{{notificationPos+1}}</span>
                  <span class="grey--text text--lighten-2">/</span> 
                  <span class="grey--text text--lighten-1">{{notifications.length}}</span>
                  <v-icon @click="notificationPos = (notificationPos == notifications.length-1 ? 0 : (notificationPos+1))" small v-if="notifications.length > 1">chevron_right</v-icon>
                </template>
              </div>
              <div class="top-bar-text">
                <v-window v-model="notificationPos">
                  <v-window-item v-for="(notification, i) in notifications" :key="i">
                    <span>{{notification.text}}</span>
                    <v-btn @click="performAction(notification.action)" class="elevation-0 primary" style="font-size: 16px; font-weight: normal;" small>{{notification.button}}</v-btn>
                  </v-window-item>
                </v-window>
              </div>
            </div>
          </v-flex>
        </v-layout>
        <v-layout class="row wrap mt-3">
          <v-flex sm12 md12 lg6 v-if="roleStatusRows.length && roleStatusRows.length > 1">
            <v-card class="pa-2 ma-2" style="min-height: 479px">
              <v-card-title primary-title class="pa-2">
                <div class="headline">{{$t('Projects.occupancyRate')}}</div>
              </v-card-title>
              <v-card-text class="pa-0" style="max-height: 405px; overflow-y: auto;">
                <v-data-table :headers="roleStatusHeaders" :items="roleStatusRows" :dense="roleStatusRows.length > 7" hide-default-footer class="elevation-0">
                  <template v-slot:body="items">
                    <tbody v-if="items && items.items">
                      <tr v-for="item in items.items" :key="item.name">
                        <td :style="item.sum == true ? `font-weight: bold` : ``">{{ item.name }}</td>
                        <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.total ? item.total : 0 }}</td>
                        <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.addressed || 0}}</td>
                        <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.rejected || 0}}</td>
                        <td class="text-center" :style="item.sum == true ? `font-weight: bold` : ``">{{item.accepted || 0}}</td>
                      </tr>
                    </tbody>
                  </template>
                  <template slot="no-data">&nbsp;</template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm12 md12 lg6>
            <v-card class="px-0 pt-2 pb-0 ma-2">
              <v-card-title primary-title class="mx-2 pa-2">
                <div style="width: 100%">
                  <div style="float: left">
                    <div class="headline d-inline-block">{{$t('Projects.calendar')}}</div>
                  </div>
                  <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="$refs.calendar.next()" style="float: right" class="black--text elevation-0"><v-icon dark>keyboard_arrow_right</v-icon></v-btn>
                  <v-btn v-if="$vuetify.breakpoint.mdAndUp" @click="$refs.calendar.prev()" style="float: right" class=" black--text elevation-0"><v-icon dark>keyboard_arrow_left</v-icon></v-btn>
                  <div style="clear: both"></div>
                </div>
              </v-card-title>
              <v-card-text class="pa-0">
                <v-date-picker v-if="$vuetify.breakpoint.smAndDown" :value="closestEvent" @input="navigate('events')" full-width no-title locale="cs-cz" event-color="green lighten-1" class="elevation-0"></v-date-picker>
                <v-sheet v-else height="420">
                  <v-calendar ref="calendar" :locale="$i18n.locale() == 'en' ? 'en-ww' : 'cs-cz'" v-model="calendar.start" :type="calendar.type" :end="calendar.end" color="primary">
                    <template slot="day" slot-scope="{ date }">
                      <template v-for="(event, e) in projectEvents[date]">
                        <div :key="e">
                          <v-tooltip top v-if="event.other">
                            <template v-slot:activator="{on}">
                              <div v-on="on" style="background-color: #d0eaff; padding-left: 2px; padding-right: 18px; border-radius: 3px; position: relative">
                                <span>{{event.title}}</span>
                                <v-icon size="18" style="position: absolute; top: 2px; right: 2px;">{{event.icon}}</v-icon>
                              </div>
                            </template>
                            <span>{{ event.project }}</span>
                          </v-tooltip>
                          <div v-else style="min-height: 40px; background-color: #002e99; color: white; padding-left: 2px; padding-right: 18px; border-radius: 3px; position: relative">
                            <span>{{event.title}}</span>
                            <v-icon size="18" color="white" style="position: absolute; top: 2px; right: 2px;">{{event.icon}}</v-icon>
                            <div class="text-center" style="min-width: 16px; position: absolute; bottom: 0px; right: 2px;">{{event.count}}</div>
                          </div>
                        </div>
                      </template>
                    </template>
                  </v-calendar>
                </v-sheet>   
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm12 md12 lg6 v-if="project.roles && project.roles.length > 0">
            <v-card class="pa-2 ma-2" style="min-height: 479px">
              <v-card-title primary-title class="pa-2">
                <div class="headline">{{$t('Projects.occupancy')}}</div>
              </v-card-title>
              <v-card-text class="pa-0" style="max-height: 445px; overflow-y: auto;">
                <v-container fluid grid-list-md class="pa-2">
                  <v-layout row wrap>
                    <v-flex sm12 md12 lg3 v-for="(roleWinner, r) in roleWinners" :key="r">
                      <div class="add-winner-button" @click="showWinnerDialog(roleWinner.role)">
                        <v-img v-if="roleWinner.winner" :src="getImageUrl(roleWinner.winner, roleWinner.winner.mainPhoto, 600)" aspect-ratio="1" style="border-radius: 3px; cursor: pointer;"></v-img>
                        <v-responsive v-else aspect-ratio="1" style="position: relative">
                          <div class="add-winner-button-content">
                            <v-avatar color="white" class="elevation-2">
                              <v-icon color="primary">add</v-icon>
                            </v-avatar>
                          </div>
                        </v-responsive>
                      </div>
                      <div class="mt-2">{{roleWinner.role.roleName + (roleWinner.role.roleWinners.length > 1 ? (` ` + (r+1)) : ``)}}</div>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm12 md12 lg6 v-if="false/*budget.length && budget.length > 1*/">
            <v-card class="pa-2 ma-2" style="min-height: 400px">
              <v-card-title primary-title class="pa-2">
                <div class="headline">{{$t('Projects.budget')}}</div>
              </v-card-title>
              <v-card-text class="pa-1" style="max-height: 335px; overflow-y: auto;">
                <v-expansion-panels class="shadow mb-4 mt-4" v-if="project.roles">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div style="width: 100%;font-size: 18px; font-weight: 500; padding: 10px 0px;">
                        <div style="float: left">{{$t('Projects.salaries')}}</div>
                        <div style="float: right">{{formatCurrency(roleBudgetSum)}}</div>
                        <div style="clear: both"></div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card class="py-0">
                        <v-card-text class="py-0">
                          <v-list class="px-0 pt-2 pb-0 ma-0">
                            <v-list-item class="px-0" v-for="(role, i) in roleBudget" :key="i">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <div style="float: left">{{role.name}}</div>
                                  <div style="float: right" class="price">{{formatCurrency(role.budget)}}</div>
                                  <div style="clear: both"></div>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card-text>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <v-expansion-panels class="shadow mb-4" v-if="project.pricePackage">
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div style="width: 100%;font-size: 18px; font-weight: 500; padding: 10px 0px;">
                        <div style="float: left">{{$t('Projects.management')}}</div>
                        <div style="float: right">{{formatCurrency(managementPrice)}}</div>
                        <div style="clear: both"></div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-card class="py-0 px-3">
                        <v-list class="px-0 pt-2 pb-0 ma-0">
                          <v-list-item class="px-0" v-if="project.pricePackage.management === false">
                            <v-list-item-content>
                              <v-list-item-title>
                                <div style="float: left">{{$t('Projects.selfService')}}</div>
                                <div style="float: right" class="price">3 000 Kč</div>
                                <div style="clear: both"></div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-else>
                            <v-list-item-content>
                              <v-list-item-title>
                                <div style="float: left">{{$t('Projects.managedProject')}}</div>
                                <div style="float: right" class="price">12 000 Kč</div>
                                <div style="clear: both"></div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <template v-if="project.pricePackage.profileCasting != null">
                            <v-list-item class="px-0" v-if="project.pricePackage.profileCasting === false">
                              <v-list-item-content>
                                <v-list-item-title>
                                  <div style="float: left">Aircasting</div>
                                  <div style="float: right" class="price">15 000 Kč</div>
                                  <div style="clear: both"></div>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-else>
                              <v-list-item-content>
                                <v-list-item-title>
                                  <div style="float: left">Profile casting</div>
                                  <div style="float: right" class="price">12 000 Kč</div>
                                  <div style="clear: both"></div>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                          </template>
                          <v-list-item v-if="project.pricePackage.offlineCallback">
                            <v-list-item-content>
                              <v-list-item-title>
                                <div style="float: left">{{$t('Projects.standardCallback')}}</div>
                                <div style="float: right" class="price">9 000 Kč</div>
                                <div style="clear: both"></div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-else>
                            <v-list-item-content>
                              <v-list-item-title>
                                <div style="float: left">{{$t('Projects.onlineCallback')}}</div>
                                <div style="float: right" class="price">{{$t('Projects.free')}}</div>
                                <div style="clear: both"></div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-divider></v-divider>
                          <v-list-item v-if="project.pricePackage.coordinator">
                            <v-list-item-content>
                              <v-list-item-title>
                                <div style="float: left">{{$t('Projects.needCoordinator')}}</div>
                                <div style="float: right" class="price">5 000 Kč</div>
                                <div style="clear: both"></div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-else>
                            <v-list-item-content>
                              <v-list-item-title>
                                <div style="float: left">{{$t('Projects.noCoordinator')}}</div>
                                <div style="float: right" class="price">Zdarma</div>
                                <div style="clear: both"></div>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div style="width: 100%;font-size: 18px; font-weight: 500; padding: 10px 0px; padding: 12px 48px 0px 24px">
                  <div style="float: left; color: #8f969a; font-weight: 400">{{$t('Projects.budgetSum')}}</div>
                  <div style="float: right">{{formatCurrency(roleBudgetSum + managementPrice)}}</div>
                  <div style="clear: both"></div>
                </div>
              </v-card-text>
            </v-card>
          </v-flex>
          <ProjectDocuments></ProjectDocuments>
          <v-flex sm12 md12 lg6>
            <v-card class="pa-2 ma-2 summary" style="min-height: 400px">
              <v-card-title primary-title class="pa-2">
                <div class="headline">{{$t('Projects.about')}}</div>
              </v-card-title>
              <v-card-text class="pa-0">
                <v-list two-line subheader class="mt-2">
                  <v-list-item v-if="showCampaing">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('Projects.projectName')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.projectName}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item v-if="showPieceName">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('Projects.pieceName')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.projectName}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="showClient"></v-divider>
                  <v-list-item v-if="showClient">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('Projects.client')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.client}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="showAgency"></v-divider>
                  <v-list-item v-if="showAgency">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('Projects.agency')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.agency}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="showDirector"></v-divider>
                  <v-list-item v-if="showDirector">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('AddProjectDialog.director')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.director}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="showProducer"></v-divider>
                  <v-list-item v-if="showProducer">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('AddProjectDialog.producer')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.producer}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="showStation"></v-divider>
                  <v-list-item v-if="showStation">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('AddProjectDialog.tvStation')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.station}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="showInterpret"></v-divider>
                  <v-list-item v-if="showInterpret">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('AddProjectDialog.interpret')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.interpret}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="showPhotographer"></v-divider>
                  <v-list-item v-if="showPhotographer">
                    <v-list-item-content>
                      <v-list-item-title>{{$t('AddProjectDialog.photographer')}}</v-list-item-title>
                      <v-list-item-subtitle>{{project.photographer}}&nbsp;</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <template v-for="(note, n) in project.notes">
                    <v-list-item :key="n" v-if="note && note.note">
                      <v-list-item-content>
                        <v-list-item-title>{{ $t('Projects.about') }}</v-list-item-title>
                        <v-list-item-subtitle v-if="note.note.text">{{ note.note.text }}</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="`d`+n" v-if="note && note.note && note.note.text && n < project.notes - 1"></v-divider>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
      <AddNoteDialog v-model="showAddNoteDialog" :save="addNote" />
      <SelectWinnerDialog v-model="winnerDialog.show" :role="winnerDialog.role" :actors="winnerDialog.actors"></SelectWinnerDialog>
      <EditProjectDialog v-model="showEditProjectDialog"></EditProjectDialog>
    </v-container>
    <v-speed-dial v-model="fab" fixed bottom right direction="top" :style="$vuetify.breakpoint.smAndDown ? `bottom: 67px`:``">
      <template v-slot:activator>
        <v-btn v-on="on" fab color="red darken-1"><v-icon color="white" style="line-height: 56px">add</v-icon></v-btn>
      </template>
      <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
        <template v-slot:activator="{on}">
          <v-btn v-on="on" fab dark small color="orange darken-1" class="mb-2" @click="newProject">
            <v-icon style="line-height: 40px">mdi-notebook</v-icon>
          </v-btn>
        </template>
        <span>{{$t('Projects.addProject')}}</span>
      </v-tooltip>
      <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
        <template v-slot:activator="{on}">
          <v-btn v-on="on" fab dark small color="green" @click="newRole">
            <v-icon style="line-height: 40px">fa-users</v-icon>
          </v-btn>
        </template>
        <span>{{$t('Projects.addRole')}}</span>
      </v-tooltip>
      <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
        <template v-slot:activator="{on}">
          <v-btn v-on="on" fab dark small color="primary" @click="newEvent">
            <v-icon style="line-height: 40px">mdi-calendar-clock</v-icon>
          </v-btn>
        </template>
        <span>{{$t('Projects.addEvent')}}</span>
      </v-tooltip>
    </v-speed-dial>
  </template>
</div>
</template>

<style>
@media only screen and (min-width: 1604px) {
  .projects-contents {
    max-width: 1604px;
    margin-left: auto;
    margin-right: auto;
  }
}
.project-header {
  border-bottom: 1px solid #e9ebee;
}
.project-header h1, .project-header h2 {
  font-weight: normal;
}
.project-header h1 {
  font-size: 32px;
}
.project-header h2 {
  font-size: 14px;
}
.projects .v-btn--floating .v-btn__content :not(:only-child):first-child, .projects .v-btn--floating .v-btn__content :not(:only-child):last-child {
  top: -12px;
}
.project-menu .v-tabs__bar {
  background-color: #f6f8fa;
}

.project-menu .v-tabs__item {
  padding: 6px 32px;
}
.v-list--two-line .v-list__tile {
  height: 54px !important;
}
.table.v-datatable.v-table thead tr {
  height: 48px;
}
.projects table.v-table thead th {
  font-size: 13px;
  height: 52px;
}
.projects table.v-table tbody td {
  font-size: 15px;
  height: 52px;
}
.projects .theme--light.v-table thead tr:first-child,
.projects .theme--light.v-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0,0,0,.08);
}
.projects .summary .v-list--two-line .v-list__tile {
  height: initial !important;
  padding-top: 12px;
  padding-bottom: 12px;
}
.projects .summary .v-list__tile__title {
  color: rgba(0,0,0,.54);
  font-size: 15px;
}
.projects .summary .theme--light.v-list .v-list__tile__sub-title {
  white-space: pre-wrap;
  color: black;
  font-size: 15px;
}
.projects .top-bar-section {
  height: 50px; 
  display: inline-block;
  width: 100%;
}

.projects .top-bar-section.border {
  border-right: 1px solid rgba(0,0,0,0.1);
}

.projects .top-bar-header {
  font-size: 14px; 
  color: rgba(0,0,0,0.5);
}

.projects .top-bar-text {
  font-size: 18px;
}

#app.desktop .projects .top-bar-notifications {
  width: calc(100% - 65px); 
  position: absolute; 
  right: 0px; 
  top: 2px;
}

#app.mobile .projects .top-bar-notifications {
  min-height: 85px;
}
.add-winner-button {
  background-color: #eeeff0;
  box-shadow: inset 0 1px 2px #dee0e2;
  border-radius: 4px;
  cursor: pointer;
}
.add-winner-button-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
}
.v-list__tile .price {
  color: black;
}
#app .v-calendar-weekly__day-label {
  margin-top: 0px;
}

#app .v-calendar-weekly__day-label .v-btn {
  width: 25px;
  height: 25px;
}
</style>

<script>
import * as api from '@/libs/api'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import AddNoteDialog from '@/dialogs/AddNoteDialog'
import SelectWinnerDialog from '@/dialogs/SelectWinnerDialog'
import EditProjectDialog from '@/dialogs/EditProjectDialog'
import ProjectDocuments from '@/widgets/ProjectDocuments'

export default {
    name: 'Projects',
    components: {
      AddNoteDialog,
      SelectWinnerDialog,
      EditProjectDialog,
      ProjectDocuments
    },
    props: {
      id: {
        type: String,
        default: () => null
      },
    },
    mounted() {
      if (this.id) {
        localStorage.setItem("currentProjectId", this.id);
        this.$router.push('/project');
      }
      this.$nextTick(async () => {
        console.log(this.closestEvent);
        this.calendar.start = this.closestEvent;

        try {
          this.otherEvents = [];
          let projectEvents = this.projectEvents;
          let projects = await api.listProjects();
          for (let project of projects) {
            if (!project.simpleEvents || !project.simpleEvents.length)
              continue;
            for (let event of project.simpleEvents) {
              if (event.eventDate in projectEvents)
                continue;
              this.otherEvents.push({
                title: this.$t(event.eventType),
                icon: this.getIcon(event.eventType),
                date: event.eventDate,
                project: project.client + ': ' +project.projectName,
                other: true
              });
            }
          }
        }
        catch(err) {
          console.error(err);
        }
      });
      setInterval(() => {
        if (this.notifications.length > 1)
          this.notificationPos = (this.notificationPos == this.notifications.length-1 ? 0 : (this.notificationPos+1));
      }, 10000);
    },
    data() {
      return {
        showDetails: true,
        showRoles: true,
        showAddNoteDialog: false,
        showEditProjectDialog: false,
        speedDialTooltip: false,
        fab: false,
        notificationPos: 0,
        headers: [
          {
            text: 'Typ',
            align: 'left',
            value: 'type'
          },
          { text: 'Mzda', value: 'salary' }
        ],
        calendar: {
          type: 'month',
          start: null,
          end: '2019-01-06',
          typeOptions: [
            { text: 'Denní', value: 'day' },
            { text: 'Týdenní', value: 'week' },
            { text: 'Měsíční', value: 'month' }
          ]
        },
        otherEvents: [],
        winnerDialog: {
          show: false,
          title: null,
          actors: [],
          role: null
        }
      }
    },
    computed: {
      notifications() {
        if (!this.project)
          return [];
          
        let notifications = [];

        if (this.notAddressedActors)
          notifications.push({ icon: 'fa-users', text: this.$t('Projects.notAddressedActorsText'), button: this.$t('Role.sendOffer'), action: 'notifyActors' });

        if (!this.project.roles.length)
          notifications.push({ icon: 'fa-users', text: this.$t('Projects.noRoles'), button: this.$t('Projects.addRole'), action: 'addRole' });

        if (this.project.roles.length) {
          let anyEmpty = false;
          let anyFull = false;
          for (let role of this.project.roles) {
            if (!role.picks || !role.picks.length)
              anyEmpty = true;
            
            let accepted = 0;
            for (let pick of role.picks) {
              if (!pick.roleState)
                continue;

              if (pick.roleState == 'accepted')
                accepted++;
            }

            if ((accepted / parseFloat(role.picks.length)) > 0.5) {
              let castingAlreadyCreated = false;
              for (let event of this.project.events) {
                if (event.eventType !== 'castingOnline' && event.eventType !== 'castingOffline')
                  continue;

                if (event.eventRoles.findIndex(r => r.roleId === role.roleId) > -1) {
                  castingAlreadyCreated = true;
                  break;
                }
              }
              if (!castingAlreadyCreated)
                notifications.push({ icon: 'mdi-calendar-clock', text: 'O roli ' + role.roleName.slice(0, 12) + ' uz projevili zájem kandidáti (' + accepted + ')', button: this.$t('Projects.inviteToCasting'), action: 'addEvent' });
            }                

            if (!role.description || !role.description.length)
              notifications.push({ icon: 'fas fa-sticky-note', text: 'Role ' + role.roleName.slice(0, 12) + ' neobsahuje popis', button: this.$t('Projects.addDescription'), action: 'openRoles' });
            
            if (!role.payments || !role.payments.length)
              notifications.push({ icon: 'fas fa-money-bill-alt', text: 'Role ' + role.roleName.slice(0, 12) + ' neobsahuje honorář', button: this.$t('Projects.addSalary'), action: 'openRoles' });
          }
          if (anyEmpty)
            notifications.push({ icon: 'fas fa-search', text: this.$t('Projects.emptyRolesText'), button: this.$t('Projects.addSalary'), action: 'search' });

          if (anyFull && (!this.project.events.length))
            notifications.push({ icon: 'mdi-calendar-clock', text: this.$t('Projects.emptyEvents'), button: this.$t('Projects.addEvent'), action: 'addEvent' });
        }

        if (!this.project.notes.length)
          notifications.push({ icon: 'fas fa-sticky-note', text: this.$t('Projects.emptyProjectDescription'), button: this.$t('Projects.addDescription'), action: 'addDescription' });

        return notifications;
      },
      loading() {
        return this.$store.state.loadingProject;
      },
      project: {
        get: function() {
          let project = this.getCurrentProject();
          return project;
        },
        set: function(project) {
          this.updateCurrentProject(project);
        }
      },
      usages() {
        return this.getUsage();
      },
      notAddressedActors() {
        let project = this.getCurrentProject();
        for (let role of project.roles) {
          for (let pick of role.picks) {
            if (pick.roleState && pick.roleState == "added")
              return true;
          }
        }
        return false;
      },
      accepted() {
        let total = 0;
        let accepted = 0;
        for (let role of this.project.roles) {
          for (let pick of role.picks) {
            if (!pick.roleState)
              continue;

            if (pick.roleState == 'accepted' || pick.roleState == 'rejected')
              total++;

            if (pick.roleState == 'accepted')
              accepted++;
          }
        }

        if (total == 0)
          return 0;

        return parseInt(100 * (parseFloat(accepted))/(parseFloat(total)))
      },
      replied() {
        let total = 0;
        let replied = 0;
        for (let role of this.project.roles) {
          for (let pick of role.picks) {
            if (!pick.roleState)
              continue;
            
            if (pick.roleState != 'added')
              total++;

            if (pick.roleState != 'added' && pick.roleState != 'addressed')
              replied++;
          }
        }

        if (total == 0)
          return 0;

        return parseInt(100 * (parseFloat(replied))/(parseFloat(total)))
      },
      remainingDays() {
        let firstShooting = null;
        for (let event of this.project.events) {
          if (!event.eventType || event.eventType != 'Natáčení')
            continue;
          let timestamp = new Date(event.eventDate).getTime();
          if (firstShooting === null)
            firstShooting = timestamp;
          else if (timestamp < firstShooting) 
            firstShooting = timestamp;
        }

        if (firstShooting === null)
          return null;

        let now = new Date().getTime();

        let remaining = Math.floor(((firstShooting - now) / 1000) / 86400);

        return remaining;
      },
      budget: function() {
        let budget = [];
        let project = this.getCurrentProject();
        let sum = 0;
        for (let role of project.roles) {
          budget.push({
            name: role.roleName,
            budget: role.budget
          });
          sum += role.budget;
        }

        budget.push({
          name: this.$t('NotifyActorsDialog.total'),
          budget: sum,
          sum: true
        });
        return budget;
      },
      managementPrice() {
        if (!this.project.pricePackage)
          return 0;

        let total = 0;
        total += this.project.pricePackage.management ? 12000 : 3000;
        total += this.project.pricePackage.profileCasting === null ? 0 : (this.project.pricePackage.profileCasting ? 3000 : 15000);
        total += this.project.pricePackage.offlineCallback ? 9000 : 0;
        total += this.project.pricePackage.coordinator ? 5000 : 0;
        return total;
      },
      roleBudget: function() {
        let budget = [];
        let project = this.getCurrentProject();
        for (let role of project.roles) {
          let sum = 0;
          // find all shootings where given role attends
          let shootings = this.project.events.filter((event) => {
            if (event.eventType != 'shooting')
              return false;
            if (event.eventRoles.findIndex(r => r.roleId === role.roleId) > -1)
              return true;
            return false;
          }).length;
          if (shootings < 1)
            shootings = 1; // let's presume, that every role will attend at least one shooting

          // find out if there's already any winners for given role
          let winners = role.picks.filter(pick => pick.winner && pick.winner === true).length;
          if (winners < 1)
            winners = 1; // let's presume, that every role will have at least one winner

          if (role.roleType == 'extra')
            sum = 1800;
          else if (role.payments) {
            for (let payment of role.payments) {
              sum += payment.salary * (payment.type == 'dailySalary' ? shootings : 1);
            }
          }

          budget.push({
            name: role.roleName,
            budget: sum * winners
          });
        }
        return budget;
      },
      roleBudgetSum() {
        let sum = 0;
        for (let role of this.roleBudget) {
          sum += role.budget;
        }
        return sum;
      },
      roleStatusHeaders() {
        return [
          { text: this.$t('Projects.role'), align: 'left', sortable: false, value: 'role'},
          { text: this.$t('Projects.total'), align: 'center', sortable: false, value: 'total'},
          { text: this.$t('Projects.addressed'), align: 'center', sortable: false, value: 'addressed'},
          { text: this.$t('Projects.rejected'), align: 'center', sortable: false, value: 'rejected'},
          { text: this.$t('Projects.accepted'), align: 'center', sortable: false, value: 'accepted'},
        ]
      },
      roleStatusRows() {
        if (!this.project || !this.project.roles)
          return [];

        const incrementStatus = (row, status, count) => {
          if (!count)
            count = 1;
          if (!row[status])
            row[status] = count;
          else
            row[status] += count;
        }

        const roles = this.project.roles;
        let statusRows = []
        let totalRow = {
          name: this.$t('NotifyActorsDialog.total'),
          sum: true
        }
        for (let role of roles) {
          let statusRow = { 
            name: role.roleName 
          };
          for (let pick of role.picks) {
            if (!pick.roleState)
              continue

            incrementStatus(statusRow, pick.roleState)
            incrementStatus(totalRow, pick.roleState)
          }
          statusRow.total = role.picks.length;
          if (role.picks.length)
            incrementStatus(totalRow, 'total', role.picks.length)
          statusRows.push(statusRow);
        }

        statusRows.push(totalRow);

        return statusRows;
      },
      projectEvents() {
        let result = {};
        if (this.otherEvents.length) {
          for (let otherEvent of this.otherEvents) {
            result[otherEvent.date] = [otherEvent];
          }
        }

        if (!this.project || !this.project.events || !this.project.events.length)
          return result;

        let e = 0;
        for (let event of this.project.events) {
          if (event.eventDate)
            result[event.eventDate] = [{
              title: event.eventName || this.$t(event.eventType),
              icon: this.getIcon(event.eventType),
              count: event.attendees.length,
              details: event.eventName || event.eventType,
              date: event.eventDate,
              open: false,
              index: e++
            }];
        }
        
        return result;
      },
      closestEvent() {
        if (!this.project || !this.project.events || !this.project.events.length)
          return null;

        let now = new Date();
        for (let event of this.project.events) {
          if (!event.eventDate)
            continue;

          let date = new Date(event.eventDate);
          if (now.getTime() < date.getTime())
            return event.eventDate;
        }
        return null;
      },
      showClient() { return this.project.projectType == 'ad' || this.project.projectSubType == 'comercialPhoto' || this.project.projectSubType == 'charityPhoto'; },
      showBrand() { return this.project.projectType == 'ad' || this.project.projectSubType == 'comercialPhoto' || this.project.projectSubType == 'charityPhoto'; },
      showCampaing() { return this.project.projectType == 'ad' || this.project.projectSubType == 'comercialPhoto' || this.project.projectSubType == 'charityPhoto'; },
      showAgency() { return this.project.projectType == 'ad' || this.project.projectSubType == 'comercialPhoto' || this.project.projectSubType == 'charityPhoto'; },
      showDirector() { return this.project.projectType == 'ad' || this.project.projectType == 'movie'; },
      showPieceName() { return this.project.projectType == 'movie' || this.project.projectSubType == 'artPhoto'; },
      showProducer() { return this.project.projectType == 'movie'; },
      showStation() { return this.project.projectSubType == 'series'; },
      showInterpret() { return this.project.projectSubType == 'clip'; },
      showPhotographer() { return this.project.projectType == 'photo'; },
      winners() {
        let winners = [];
        for (let role of this.project.roles) {
          let roleWinners = [];
          for (let pick of role.picks) {
            if (!pick.winner)
              continue;
            roleWinners.push(pick);
          }
          winners.push({
            roleName: role.roleName,
            roleWinners: roleWinners,
            picks: role.picks,
            roleId: role.roleId,
            projectId: role.projectId
          });
        }
        return winners;
      },
      user() {
        return this.getLoggedUser();
      },
      roleWinners() {
        let roleWinners = [];
        for (let role of this.winners) {
          if (!role.roleWinners.length)
            roleWinners.push({ role: role, winner: null }); 

          else
            for (let winner of role.roleWinners) {
              roleWinners.push({ role: role, winner: winner });
            }
        }
        /*
        <v-flex sm12 md12 lg3 v-for="(roleWinner, r) in roleWinners" :key="r">
          <div class="add-winner-button" @click="showWinnerDialog(roleWinner.role)">
            <v-img v-if="roleWinner.winner" :src="getImageUrl(roleWinner.winner, roleWinner.winner.mainPhoto, 600)" aspect-ratio="1" style="border-radius: 3px; cursor: pointer;"></v-img>
            <v-responsive v-else aspect-ratio="1" style="position: relative">
              <div class="add-winner-button-content">
                <v-avatar color="white" class="elevation-2">
                  <v-icon color="primary">add</v-icon>
                </v-avatar>
              </div>
            </v-responsive>
          </div>
          <div class="mt-2">{{roleWinner.role.roleName + (roleWinner.role.roleWinners.length > 1 ? (` ` + (r+1)) : ``)}}</div>
        </v-flex>

        <v-flex sm12 md12 lg3 v-for="(role, r) in winners" :key="r">
          <template v-for="(winner, w) in role.roleWinners">
            <div class="add-winner-button" @click="showWinnerDialog(role)" :key="w">
              <v-img :src="getImageUrl(winner, winner.mainPhoto, 600)" aspect-ratio="1" style="border-radius: 3px; cursor: pointer;"></v-img>
            </div>
            <div class="mt-2" :key="`n`+w">{{role.roleName + (role.roleWinners.length > 1 ? (` ` + (w+1)) : ``)}}</div>
          </template>
          <template v-if="!role.roleWinners.length">
            <div class="add-winner-button" @click="showWinnerDialog(role)">
              <v-responsive aspect-ratio="1" style="position: relative">
                <div class="add-winner-button-content">
                  <v-avatar color="white" class="elevation-2">
                    <v-icon color="primary">add</v-icon>
                  </v-avatar>
                </div>
              </v-responsive>
            </div>
            <div class="mt-2">{{role.roleName}}</div>
          </template>
        </v-flex>
        */
        return roleWinners;
      }
    },
    watch: {
      fab(val) {
        setTimeout(() => { this.speedDialTooltip = val }, 300);
      },
      loading(val) {
        if (val == false && this.user && this.user.lite) {
          router.push("/search");
        }
      }
    },
    methods: {
    ...mapActions([
      'selectEventInCurrentProject',
      'updateCurrentProject', 
      'removeRoleFromCurrentProject', 
      'showProfileDetail', 
      'showRoleDialog',
      'addNoteToCurrentProject', 
      'removeNoteFromCurrentProject',
      'removePickFromRole'
    ]),
    ...mapGetters(['getCurrentProject', 'getLoggedUser']),
    removePick: function(role, pick) {
      this.removePickFromRole( { role: role, pick: pick } );
    },
    async addNote(note) {      
      this.addNoteToCurrentProject(note);

      let project = this.project;
      delete project.productionId;
      await api.updateProject(project);
    },
    deleteNote: function(note) {
      this.removeNoteFromCurrentProject(note);
    },
    formatCurrency: function(amount) {
      const formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
      });

      let result = formatter.format(amount);
      return result;
    },
    getStateText(state, sex) {
      switch (state) {
        case 'added': return sex === 'woman' ? 'Neoslovena' : 'Neosloven'
        case 'addressed': return sex === 'woman' ? 'Oslovena' : 'Osloven'
        case 'accepted': return sex === 'woman' ? 'Přijala' : 'Přijal'
        case 'rejected': return sex === 'woman' ? 'Odmítla' : 'Odmítl'
      }
      return ''
    },
    getStateIcon(state) {
      switch (state) {
        case 'added': return 'mdi-account-alert'
        case 'addressed': return 'mdi-account-clock'
        case 'accepted': return 'mdi-account-check'
        case 'rejected': return 'mdi-account-remove'
      }
      return 'mdi-account-question'
    },
    getPercentage(count, total) {
      if (!count || !total)
        return 0;

      return 100 * (parseFloat(count))/(parseFloat(total))
    },
    navigate: function (path) {
      router.push('/' + path)
    },
    newEvent() {
      this.navigate('roles');
      setTimeout(() => {
        this.$store.state.dialogs.newEvent.show = true
      }, 500);
    },
    newRole() {
      this.navigate('roles');
      setTimeout(() => {
        this.$store.state.dialogs.roleDialog.profile = null
        this.$store.state.dialogs.roleDialog.show = true
      }, 500);
    },
    newProject() {
      this.$store.state.dialogs.newProject.show = true
    },
    performAction(action) {
      switch (action) {
        case 'notifyActors': {
          this.$store.state.dialogs.notifyActors.show = true
          return;
        }
        case 'addDescription': {
          this.showAddNoteDialog = true;
          return;
        }
        case 'addRole': {
          this.newRole();
          return;
        }
        case 'addEvent': {
          this.newEvent();
          return;
        }
        case 'search': {
          this.navigate('search');
          return;
        }
        case 'openRoles': {
          this.navigate('roles');
          return;
        }
      }
    },
    getIcon(type) {
      switch (type) {
        case 'castingOnline': return 'mdi-camera-front';
        case 'callbackOnline': return 'mdi-camera-front-variant';
        case 'callbackOffline': return 'mdi-backup-restore';
        case 'fitting': return 'mdi-hanger';
        case 'shooting': return 'movie';
      }

      return 'date_range';
    },
    selectEvent(e) {
      this.selectEventInCurrentProject(e);
      router.push('/events');
    },
    showWinnerDialog(role) {
      this.winnerDialog = {
        show: true,
        actors: role.picks.filter(pick => pick.roleState != 'rejected' && pick.roleState != 'addressed' && pick.roleState != 'added'),
        role: role
      }
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    }
  }
}
</script>
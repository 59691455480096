<template>
  <v-dialog v-if="source" v-model="show" @keydown.esc="show = false" content-class="pa-0 overflow_visible elevation-0 video-player video-player-dialog">
    <div @click="dialogClicked">
      <videoPlayer class="video-player-box" ref="videoPlayer" :options='options'>
        <v-btn absolute fab top right small class="dialog_close_alt" @click="show=false"><v-icon size="24">close</v-icon></v-btn>
      </videoPlayer>
    </div>
  </v-dialog>
</template>

<style>
.video-js {
  margin: auto;
}
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close_alt {
  top: -20px;
  right: -20px;
  border-radius: 50% !important;
}
.video-player-dialog .vjs-tech {
  box-shadow: 0 1px 30px 0 rgba(19, 29, 45, 0.5) !important;
}

.video-player .vjs-tech,
.video-player .video-js {
  border-radius: 4px !important;
}
.video-player .vjs-big-play-button {
  background-color: transparent !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI4OCIgaGVpZ2h0PSI4OCIgdmlld0JveD0iMCAwIDg4IDg4Ij4gICAgPGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gICAgICAgIDxjaXJjbGUgY3g9IjQ0IiBjeT0iNDQiIHI9IjQ0IiBmaWxsPSIjMDAwIiBvcGFjaXR5PSIuNyIvPiAgICAgICAgPHBhdGggZmlsbD0iI0ZGRiIgZD0iTTM4Ljc0NCAyNy4zODhsMTkuODM4IDE0LjE3YTMgMyAwIDAgMSAwIDQuODgzbC0xOS44MzggMTQuMTdBMyAzIDAgMCAxIDM0IDU4LjE3MlYyOS44M2EzIDMgMCAwIDEgNC43NDQtMi40NDJ6Ii8+ICAgIDwvZz48L3N2Zz4=) !important;
  background-repeat: no-repeat !important;
  background-size: 72px !important;
  background-position: 50% calc(50% - 10px) !important;
  border: none !important;
  box-shadow: none !important;
  opacity: .7 !important;
}

.video-player .vjs-big-play-button:hover {
  background-color: transparent;
  opacity: 1;
}

.video-player .vjs-big-play-button .vjs-icon-placeholder {
  display: none;
}
</style>

<script>
import 'video.js/dist/video-js.css'
import { videoPlayer } from 'vue-video-player'

export default {
  name: 'VideoPlayer',
  components: {
    videoPlayer
  },
  props: {
    source: {
      type: String,
      default: () => null
    },
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => {
    return {
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    type() {
      const pattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/
      if (pattern.test(this.source))
        return 'youtube';

      const patternVimeo = /^(http(s)?:\/\/)?((w){3}.)?vimeo(\.com)?\/.+/
      if (patternVimeo.test(this.source))
        return 'vimeo'        

      return 'video';
    },
    options() {
      let width = window.innerWidth
      let height = window.innerHeight

      let options = null
      if (width > height)
        options = { autoplay: this.show, mute: true, height: height - 100};
      else
        options = { autoplay: this.show, mute: true, width: width - 50};

      if (this.type == "youtube") {
        options["techOrder"] = ["youtube", "html5"]
        options.sources = [{ type: "video/youtube", src: this.source }]
      }
      else if (this.type == "vimeo") {
        options["techOrder"] = ["vimeo", "html5"]
        options.sources = [{ type: "video/vimeo", src: this.source }]
      }
      else
        options.sources = [{ type: "video/mp4", src: this.source }]

      return options
    }
  },
  methods: {
    getHeight() {
      return window.innerHeight - 100
    },
    dialogClicked(e) {
      if (e.target && e.target.matches('.video-player.video-player-box'))
        this.show = false
    },
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="800" @keydown.esc="show = false" content-class="pa-0 role-dialog">
    <v-card color="#f6f8fa" class="pb-4">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-window v-model="step">
        <v-window-item :value="1">
          <v-card-text>
            <h1 class="text-center my-4">{{$t('AddRoleDialog.headline')}}</h1>
            <v-layout row wrap align-center class="mx-auto my-5" style="width: 90%; min-width: 300px;">
              <v-flex xs12 sm3>
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'leading') && step++" :color="(type == 'leading') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1.25">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">fa-user-tie</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('roleType.leading')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-flex>
              <v-flex xs12 sm3>
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'supporting') && step++" :color="(type == 'supporting') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1.25">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">fas fa-user-friends</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('roleType.supporting')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-flex>
              <v-flex xs12 sm3>
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'featuredExtras') && step++" :color="(type == 'featuredExtras') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1.25">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">fas fa-user-clock</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('roleType.featuredExtras')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-flex>
              <v-flex xs12 sm3>
                <v-card class="text-center pa-2 ma-2" hover @click="(type = 'extra') && step++" :color="(type == 'extra') ? `secondary` : `white`">
                  <v-responsive aspect-ratio="1.25">
                    <div class="big_button_content">
                      <v-icon size="40" color="primary" class="my-2">fa-users</v-icon>
                      <div style="font-size: 18px; color: #002e99">{{$t('roleType.extra')}}</div>
                    </div>
                  </v-responsive>
                </v-card>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="2">
          <v-card-text>
            <h1 class="text-center my-4">{{$t('AddRoleDialog.roleDetails')}}</h1>
            <v-form ref="roleForm" class="pt-2" style="width: 60%; margin: auto;">
              <v-text-field v-model="name" class="mb-4" label="Název role" :rules="[requiredFieldRule]" autofocus></v-text-field>
              <v-textarea v-model="description" label="Veřejný popis role" :rules="[requiredFieldRule]"></v-textarea>
            </v-form>
            <div class="text-center pt-2">
              <v-btn class="elevation-0 next" color="primary" @click="nextStep">{{$t('next')}}</v-btn>
              <v-btn class="elevation-0 back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
        <v-window-item :value="3">
          <v-card-text>
            <h1 class="text-center my-4">{{$t('AddRoleDialog.salaryHeadline')}}</h1>
            <section style="width: 90%; margin: auto;">
              <v-expansion-panels class="shadow mb-2" :value="dailySalaryEnabled ? [0] : []" multiple :class="dailySalaryEnabled ? `role-active` : ``">
                <v-expansion-panel hide-default-footer readonly :value="dailySalaryEnabled" style="border-radius: 4px;">
                  <v-expansion-panel-header>
                    <div class="px-3 my-3" :class="dailySalaryEnabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click.stop="dailySalaryEnabled = !dailySalaryEnabled">
                      <div class="square-checkbox d-flex"><v-icon color="#65bcff" class="ma-0">done</v-icon></div>
                      <div class="salary-header-text">{{$t('AddRoleDialog.daily')}}</div>
                      <div v-if="$vuetify.breakpoint.mdAndUp" class="salary-header-total">{{dailySalary}} {{dailySalary !== null ? `Kč` : ``}}</div>
                      <div style="clear: both"></div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card color="transparent" class="elevation-0 mt-3">
                      <v-card-text class="pt-0 role-content">
                        <v-layout row style="max-width: 150px">
                          <v-flex xs10>
                            <v-text-field v-model="dailySalary" placeholder="Mzda" :disabled="!dailySalaryEnabled" mask="########" suffix="Kč"></v-text-field>
                          </v-flex>
                          <v-flex xs2>
                            <div style="position: absolute; top: 50%; transform: translateY(-50%); font-size: 16px; font-weight: bold; padding: 0px 0px 6px 16px;">x 1 den</div>
                          </v-flex>
                        </v-layout>
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels class="shadow mb-2" :value="buyoutEnabled ? [0] : []" multiple :class="buyoutEnabled ? `role-active` : ``">
                <v-expansion-panel hide-default-footer readonly :value="buyoutEnabled" style="border-radius: 4px;">
                  <v-expansion-panel-header>
                    <div class="px-3 my-3" :class="buyoutEnabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click.stop="buyoutEnabled = !buyoutEnabled">
                      <div class="square-checkbox d-flex"><v-icon color="#65bcff" class="ma-0">done</v-icon></div>
                      <div class="salary-header-text">{{$t('AddRoleDialog.buyout')}}</div>
                      <div v-if="$vuetify.breakpoint.mdAndUp" class="salary-header-total">{{buyout}} {{buyout !== null ? `Kč` : ``}}</div>
                      <div style="clear: both"></div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card color="transparent" class="elevation-0 mt-3">
                      <v-card-text class="pa-0 role-content">
                        <v-container fluid grid-list-md class="pa-0">
                          <v-layout row wrap v-for="(buyout, i) in buyouts" :key="i">
                            <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="buyout.salary" placeholder="Mzda" :disabled="!buyoutEnabled" mask="########" suffix="Kč"></v-text-field></v-flex>
                            <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="buyout.country" label="Země" hint="např. CZ, DE" :disabled="!buyoutEnabled"></v-text-field></v-flex>
                            <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="buyout.medium" label="Typ média" hint="např. TV, online, print" :disabled="!buyoutEnabled"></v-text-field></v-flex>
                            <v-flex xs8 sm1 py-2 pr-1><v-text-field v-model="buyout.length" label="Doba" :disabled="!buyoutEnabled"></v-text-field></v-flex>
                            <v-flex xs4 sm2 py-2><v-select v-model="buyout.unit" :items="['měs', 'rok']" append-outer-icon="close" @click:append-outer="buyouts.splice(i, 1)"></v-select></v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                      <v-card-actions class="pt-2 pb-4 px-4">
                        <v-spacer></v-spacer>
                        <v-btn class="elevation-0 white--text" color="#65bcff" @click="buyouts.push({salary:null,country:null,medium:null,length:1,unit:'rok'})">{{$t('AddRoleDialog.addBuyout')}}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-expansion-panels class="shadow mb-2" :value="otherEnabled ? [0] : []" multiple :class="otherEnabled ? `role-active` : ``">
                <v-expansion-panel hide-default-footer readonly :value="otherEnabled" style="border-radius: 4px;">
                  <v-expansion-panel-header>
                    <div class="px-3 my-3" :class="otherEnabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click.stop="otherEnabled = !otherEnabled">
                      <div class="square-checkbox d-flex"><v-icon color="#65bcff" class="ma-0">done</v-icon></div>
                      <div class="salary-header-text">Ostatní</div>
                      <div v-if="$vuetify.breakpoint.mdAndUp" class="salary-header-total">{{other}} {{other !== null ? `Kč` : ``}}</div>
                      <div style="clear: both"></div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card color="transparent" class="elevation-0 mt-3">
                      <v-card-text class="pa-0 role-content">
                        <v-container fluid grid-list-md class="pa-0">
                          <v-layout row wrap v-for="(item, i) in others" :key="i">
                            <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="item.salary" placeholder="Mzda" :disabled="!otherEnabled" mask="########" suffix="Kč"></v-text-field></v-flex>
                            <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="item.name" label="Název" hint="např. Kostýmní zkouška" :disabled="!otherEnabled"></v-text-field></v-flex>
                            <v-flex xs12 sm6 py-2 pr-3><v-text-field v-model="item.description" label="Popis" hint="např. Extra poplatek za oholení hlavy" :disabled="!otherEnabled" append-outer-icon="close" @click:append-outer="others.splice(i, 1)"></v-text-field></v-flex>
                          </v-layout>
                        </v-container>
                      </v-card-text>
                      <v-card-actions class="pt-2 pb-4 px-4">
                        <v-spacer></v-spacer>
                        <v-btn class="elevation-0 white--text" color="#65bcff" @click="others.push({salary:null,name:null,description:null})">{{$t('AddRoleDialog.addOther')}}</v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-container fluid class="px-0 pb-0 pt-5 mt-5">
                <v-layout row>
                  <v-flex grow pa-0 style="border-top: 1px solid rgba(0,0,0,0.1)">
                    <div style="font-size: 22px; padding-left: 12px; padding-top: 12px;">{{$t('AddRoleDialog.budget')}}</div>
                  </v-flex>
                  <v-flex shrink pa-0 style="border-top: 1px solid black">
                    <div style="min-width: 125px; font-size: 22px; padding-top: 12px; padding-right: 12px; text-align: right;">
                      {{total}} Kč
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
            </section>
            <div class="text-center pt-2">
              <v-btn class="elevation-0 next" color="primary" @click="save">{{$t('AddRoleDialog.createRole')}}</v-btn>
              <v-btn class="elevation-0 back" color="#8f969a" text @click="step--">{{$t('prev')}}</v-btn>
            </div>
          </v-card-text>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.role-dialog .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 8px !important;
}
.role-dialog h1 {
  font-weight: 400;
  font-size: 32px;
}
#app .big-icon-btn {
  height: initial;
  padding: 0px;
}

#app .big-icon-btn .v-btn__content {
  display: initial;
  min-width: 120px;
}
#app .role-dialog .v-btn.next {
  margin: 28px auto 0px auto;
  padding: 14px 41px;
  height: 56px;
  font-size: 16px;
  display: block;
}
#app .role-dialog .v-btn.back {
  margin: 24px auto 0px auto;
  font-weight: 300;
  display: block;
}
#app .role-dialog .v-expansion-panel-header {
  padding: 0px;
}
#app .role-dialog .role-active .v-expansion-panel-header {
  background-color: #65bcff;
  border-radius: 3px;
}
#app .role-dialog .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
#app .role-dialog .salary-header-text {
  float: left;
  line-height: 32px;
  font-size: 16px;
  margin-left: 0px;
}
#app .role-dialog .role-active .salary-header-text {
  color: white;
}
#app .role-dialog .salary-header-total {
  float: right;
  color: white;
  font-size: 18px;
  width: 110px;
  text-align: right;
  border-left: 1px solid rgba(255,255,255,.3);
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.role-dialog .increment-button {
  border-radius: 3px !important; 
  float: right; 
  width: 32px; 
  height: 32px;
}
.role-dialog .v-btn--icon:before {
  border-radius: 3px !important; 
}

#app .role-dialog .square-checkbox {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: #eeeff0;
  box-shadow: inset 0px 1px 5px #babcbe;
  display: flex;
  border-radius: 3px;
  float: left;
}
#app .role-dialog .square-checkbox i {
  margin: 4px !important;
}
#app .checked .square-checkbox {
  background-color: white;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
</style>

<script>

export default {
  name: 'AddRoleDialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    saveRole: {
      type: Function,
      default: (role) => {
        console.log(role);
      }
    }
  },
  data: () => {
    return {
      step: 1,
      name: null,
      description: null,
      type: null,
      dailySalary: null,
      dailySalaryEnabled: false,
      buyoutEnabled: false,
      otherEnabled: false,
      buyouts: [ 
        {
          salary: null,
          country: null,
          medium: null,
          length: 1,
          unit: 'rok'
        }
      ],
      others: [ 
        {
          salary: null,
          name: null,
          description: null
        }
      ],
      requiredFieldRule: v => !!v || 'Vyžadované pole'
    }
  },
  watch: {
    show(val) {
      //this.$refs.roleForm.reset();
      if (val == true) {
        this.step = 1;
        this.type = null;
        this.name = null;
        this.description = null;
        this.roleTypes = [
          { type: 'leading', name: 'Hlavní role', roles: [] },
          { type: 'supporting', name: 'Vedlejší role', roles: [] },
          { type: 'featuredExtras', name: 'Epizodní role', roles: [] },
          { type: 'extras', name: 'Komparz', roles: [] }
        ];
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    buyout() {
      let result = 0;
      for (let buyout of this.buyouts) {
        if (!buyout.salary)
          continue;

        result += parseInt(buyout.salary);
      }
      return result;
    },
    other() {
      let result = 0;
      for (let item of this.others) {
        if (!item.salary)
          continue;

        result += parseInt(item.salary);
      }
      return result;
    },
    total() {
      return ((this.dailySalaryEnabled && this.dailySalary) ? parseInt(this.dailySalary) : 0) + ((this.buyoutEnabled && this.buyout) ? parseInt(this.buyout) : 0) + ((this.otherEnabled && this.other) ? parseInt(this.other) : 0);
    }
  },
  methods: {
    nextStep() {
      if (!this.$refs.roleForm.validate()) 
        return;

      if (this.step == 2 && this.type == 'extra')
        this.save();
      else
        this.step++;
    },
    save() {
      if (!this.$refs.roleForm.validate()) 
        return;
      
      let payments = [];
      if (this.dailySalaryEnabled && this.dailySalary) {
        payments.push({
          type: 'dailySalary',
          salary: parseInt(this.dailySalary)
        });
      }

      if (this.buyoutEnabled && this.buyouts) {
        for (let buyout of this.buyouts) {
          if (!buyout.salary)
            continue;

          payments.push({
            type: 'buyout',
            country: buyout.country,
            medium: buyout.medium,
            length: buyout.length,
            unit: buyout.unit,
            salary: parseInt(buyout.salary)
          });
        }
      }
      if (this.otherEnabled && this.other) {
        for (let item of this.others) {
          if (!item.salary)
            continue;

          payments.push({
            type: 'other',
            otherName: item.name,
            description: item.description,
            salary: parseInt(item.salary)
          });
        }
      }
      this.saveRole({
        roleName: this.name,  
        description: this.description,
        roleType: this.type,
        budget: 0,
        picks: [],
        payments: payments
      }, true);
      this.$store.state.dialogs.roleDialog.profile = null;
      this.show = false;
    }
  }
}
</script>
<template>
  <v-card class="elevation-0" style="height: calc(100% - 64px)" :key="renderCnt">
    <v-card-text v-if="getRoles != null || project != null" class="pl-0 pr-0 pt-0" style="height: 100%; padding-bottom: 64px !important;">
      <template v-if="!getRolesInt().length">
        <v-layout row wrap class="project" align-center style="height: calc(100% - 64px); overflow-y: auto;">
          <v-flex xs12 sm12 md12 class="text-center">
            <img src="assets/empty-roles.svg" width="200" class="mx-auto" />
            <div class="mb-5 mt-5 mx-auto" style="color: #131d2d; font-size: 18px;">{{$t('Role.noRoleYet')}}</div>
          </v-flex>
        </v-layout>
      </template>
      <template v-else>
        <v-list class="project pl-0 pt-0" style="height: 100%; overflow-y: auto; overflow-x: hidden;">
          <v-list-group sub-group no-action v-for="(role, r) in getRolesInt()" :key="r" :value="true" prepend-icon="expand_less">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{role.roleName}} <span class="grey--text text--lighten-2">&bull;</span> <span class="grey--text text--lighten-1">{{role.picks.length}}</span></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <div class="text-right" style="width: 100%">
                  <v-tooltip top v-if="project">
                    <template v-slot:activator="{on}">
                      <v-btn v-on="on" icon small v-if="role.picks.length" class="d-inline-flex" @click.stop="showActorsDialog(role.roleName, role.picks)">
                        <v-icon small color="grey lighten-1">fa-expand</v-icon>
                      </v-btn>
                    </template>
                    <span>{{$t('Role.showBigThumbnail')}}</span>
                  </v-tooltip>
                </div>
              </v-list-item-action>
            </template>
            <draggable v-model="role.picks" :options="{group: { name:'role',  pull:true, put:true }, disabled: true }" class="pb-4" style="width: 100%;" @add="updateProject">
              <v-list-item v-for="(pick, i) in role.picks" :key="i" @click="project ? showProfileDetail(pick) : ($store.state.dialogs.profileDetail = { show: true, profile: pick, noRefresh: true })" class="should-hover px-0" :style="'border-right: 6px solid' + (pick.roleState=='winner' ? ' #65bcff' : ' white')">
                <v-list-item-avatar class="py-0 my-0">
                  <v-avatar size="42px">
                    <img :src="getImageUrl(pick, pick.mainPhoto, 42)" />
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>{{ pick.name || pick.fullname }}</v-list-item-title>
                  <v-list-item-subtitle :class="getStateColor(pick.roleState) + `--text`" style="font-size: 0.7em">
                    <v-icon :color="getStateColor(pick.roleState)" size="16" class="mr-1">{{getStateIcon(pick.roleState)}}</v-icon>
                    <v-tooltip right :disabled="pick.roleState != 'share-added' || !pick.shareUser">
                      <template v-slot:activator="{on}">
                        <span v-on="on">{{getStateText(pick.roleState, pick.sex, pick.winner, pick.roleStateReason)}}</span>
                      </template>
                      <span>{{pick.shareUser + $t('Role.shareSuggest')}}</span>
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="hide-on-not-hover ma-0">
                  <div>
                    <v-tooltip top>
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" icon small v-if="pick.mainPhoto" @click.stop="findSimilarFaces(pick)" class="mr-2">
                          <v-icon small color="grey lighten-1">image_search</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('Search.faceSearch')}}</span>
                    </v-tooltip>
                    <v-tooltip top v-if="project || pick.roleState == 'share-added'">
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" icon small @click.stop="removePickInt(pick, role)">
                          <v-icon small color="grey lighten-1">close</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('Search.removeActor')}}</span>
                    </v-tooltip>
                  </div>
                </v-list-item-action>
              </v-list-item>
            </draggable>
          </v-list-group>
        </v-list>
      </template>
      <v-alert class="notify_alert" v-if="user && !user.lite && getRoles == null" :value="showNotifyAlert" color="info" dark transition="scale-transition" dismissible>
        <v-btn color="white" class="ma-0" text @click="$store.state.dialogs.notifyActors.show = true"><v-icon size="18" class="mr-2">fa-bullhorn</v-icon> Oslovit herce</v-btn>
      </v-alert>
    </v-card-text>
    <footer class="pane_footer" :style="!getRolesInt().length ? `bottom: 54px;` : ``">
      <div class="left" v-if="allowAddingRoles">
        <v-btn color="primary" text @click="showRoleDialog(null)"><v-icon size="18" class="mr-2">fa-plus-circle</v-icon> {{$t('Events.addRole')}}</v-btn>
      </div>
      <div class="right">
        <v-btn @click="$emit('collapse')" small fab color="white" class="elevation-0"><v-icon color="primary">fa-angle-left</v-icon></v-btn>
      </div>
      <div style="clear: both"></div>
    </footer>
    <ProfileMultiView v-model="actorsDialog.show" :title="actorsDialog.title" :actors="actorsDialog.actors" state="roleState"></ProfileMultiView>
  </v-card>
</template>

<style>
#app.desktop .hide-on-not-hover {
  opacity: 0;
}
#app.desktop .should-hover:hover .hide-on-not-hover {
  opacity: 1;
}
.notify_alert {
  bottom: 54px !important;
  padding: 9px 11px !important;
}
footer.pane_footer {
  border-top: 1px solid rgb(228,228,228); 
  background-color: white;
  width: 100%; 
  height: 64px; 
  position: absolute; 
  bottom: 0px;
}
#app.mobile footer.pane_footer{
  bottom: 0px;
}
#app.desktop footer.pane_footer .left {
  width: 220px;
  height: 100%;
  float: left;
  border-right: 1px solid rgb(228,228,228);
}
#app.desktop footer.pane_footer .right {
  float: right;
  height: 100%;
  width: 56px;
}
footer.pane_footer .v-btn {
  margin: 12px 0px 0px 11px;
}

.theme--light.v-list .v-list__group--active:before
{
  background: transparent;
}
.project .v-list__group__header.v-list__group__header--sub-group {
  margin-left: 12px !important;
}
.project .v-list__group__header--sub-group .v-list__group__header__prepend-icon {
  padding-left: 0px;
  min-width: 16px;
}
.project .v-list__group__items--no-action .v-list__tile {
  padding-left: 16px;
}

.v-list--two-line .v-list__tile {
  height: 54px !important;
}
</style>

<script>
import { EventBus } from '@/libs/eventBus.js';
import * as states from '@/libs/states'
import ProfileMultiView from '@/dialogs/ProfileMultiView'
import draggable from 'vuedraggable'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Project',
  components: {
    draggable,
    ProfileMultiView
  },
  props: {
    getRoles: {
      type: Function,
      default: null
    },
    removePick: {
      type: Function,
      default: null
    },
    allowAddingRoles: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      renderCnt: 0,
      roleName: "",
      showDetails: false,
      supressNotification: false,
      actorsDialog: {
        show: false,
        title: null,
        actors: [],        
      },
    }
  },
  computed: {
    showNotifyAlert: {
      get() {
        if (this.supressNotification)
          return false;

        if (this.project == null)
          return false;

        for (let role of this.project.roles) {
          for (let pick of role.picks) {
            if (pick.roleState == 'added' || pick.roleState == 'share-added')
              return true;
          }
        }

        return false;
      },
      set(val) {
        this.supressNotification = !val;
      }
    },
    project() {
      return this.getCurrentProject();
    },
    user() {
      return this.getLoggedUser();
    },
  },
  watch: {
    project(val) {
      this.updateCurrentProject(val);
    }
  },
  mounted() {
    EventBus.$on('picksUpdated', function() {
      this.showNotifyAlert = true;
      this.renderCnt++;
    }.bind(this));
  },
  methods: {
    ...mapActions(['updateCurrentProject', 'showProfileDetail', 'showRoleDialog', 'removePickFromRole']),
    ...mapGetters(['getCurrentProject', 'getLoggedUser']),
    /*removePick: function(role, pick) {
      this.removePickFromRole( { role: role, pick: pick } );
    },*/
    updateProject() {
      this.updateCurrentProject(this.project);
    },
    notifyActors() {
      let project = this.getCurrentProject();
      for (let role of project.roles) {
        for (let pick of role.picks) {
          if (pick.roleState && pick.roleState !== "added" && pick.roleState !== "share-added")
            continue;
          else
            pick.roleState = "addressed";
        }
      }
      this.updateCurrentProject(project);
    },
    getStateText(state, sex, winner, reason) {
      return this.$t(states.getStateText(state, sex, winner)) + (reason ? (' (' + this.$t('States.' + reason) + ')') : '');
    },
    getStateIcon(state) {
      if (state == 'added' && !this.user)
        return 'mdi-account';
      return states.getStateIcon(state);
    },
    getStateColor(state) {
      if (state == 'added' && !this.user)
        return 'gray';
      return states.getStateColor(state);
    },
    findSimilarFaces(actor) {
      EventBus.$emit('findSimilarFaces', actor);
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    showActorsDialog(title, actors) {
      this.actorsDialog.show = true;
      this.actorsDialog.title = title;
      this.actorsDialog.actors = actors;
    },
    getRolesInt() {
      if (this.getRoles != null)
        return this.getRoles();

      if (this.project)
        return this.project.roles;

      return [];
    },
    removePickInt(pick, role) {
      if (this.removePick != null) {
        return this.removePick(pick, role);
      }

      this.removePickFromRole( { role: role, pick: pick } );
    },
  }
}
</script>
<template>
  <v-dialog v-model="show" v-if="role" max-width="90%" @keydown.esc="onEscape" content-class="pa-0">
    <v-card color="#f6f8fa" min-height="600">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">
        <span>Pozvat účastníky role {{role.roleName.toLowerCase()}} na {{$t(event.eventType).toLowerCase()}}</span> <span class="mx-2 grey--text text--lighten-2">&bull;</span><span class="grey--text">{{checkedItems.length}}</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md class="pt-3 pl-0 pr-0">
          <v-layout row wrap style="min-width: 100%">
            <v-flex v-for="(actor, i) in actors" xs12 md3 lg2 xl2 class="pa-2" :key="i">
              <MiniProfile bigCheck @showProfile="showProfileDetail" @check="checkItem(i)" :checked="isItemChecked(i)" :profile="actor" :showMultiselect="false" :showControls="false" :showBottomControls="false" :state="{state: (state === 'eventState' && actor[state] === 'added') ? 'addressed' : actor[state], sex: actor.sex}"></MiniProfile>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-alert :value="checkedItems.length" dark color="white" class="text-center bottom_alert abs black--text ma-0">
        <v-btn @click="showConfirm = true" :loading="saving" large style="height: 56px; font-size: 18px; font-weight: normal;" class="elevation-0" color="primary">{{$t('invite')}}</v-btn>
      </v-alert>
    </v-card>
    <v-dialog persistent v-model="showConfirm" max-width="800px" content-class="pa-0">
      <v-card color="#f6f8fa">
        <v-btn absolute fab top right small text class="dialog_close" @click="showConfirm=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
        <v-card-title class="headline">
          <span>{{$t('AddEventAttendeesEx.instructionsTitle')}}</span>
        </v-card-title>
        <v-card-text>
          <div>{{$t('AddEventAttendeesEx.instructionsText')}}</div>
          <v-textarea autofocus class="mt-3" v-model="instructions" rows="10" label="Instrukce" :rules="[v => !!v || 'Vyžadované pole']"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="showConfirm = false">{{$t('cancel')}}</v-btn>
          <v-btn class="elevation-0" color="primary" @click="addAttendees" :disabled="!instructions || instructions.length < 5">{{$t('AddEventAttendeesEx.sendInstructions')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="showOutro" max-width="800px" content-class="pa-0">
      <v-card color="#f6f8fa">
        <v-btn absolute fab top right small text class="dialog_close" @click="closeOutro()"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
        <v-card-title class="headline">
          <span>{{$t('AddEventAttendeesEx.outroTitle')}}</span>
        </v-card-title>
        <v-card-text>
          <div>{{$t('AddEventAttendeesEx.outroText')}}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" color="primary" @click="closeOutro">{{$t('ok')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<style>
.v-alert.bottom_alert.abs {
  position: absolute; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 20000;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
  width: 100%;
}

.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
  right: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions, mapGetters } from 'vuex'
import MiniProfile from '@/widgets/MiniProfile'

export default {
  name: 'ProfileMultiView',
  props: {
    actors: {
      type: Array,
      default: () => []
    },
    role: {
      type: Object,
      default: () => {}
    },
    event: {
      type: Object,
      default: () => {}
    },
    state: {
      type: String,
      default: () => null
    },
    value: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    MiniProfile
  },
  data: () => {
    return {
      saving: false,
      checkedItems: [],
      showConfirm: false,
      instructions: null,
      showOutro: false
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { 
        this.$emit('input', value); 
      }
    },
    project: function() {
      return this.getCurrentProject();
    },
    itemsSelected: function() {
      return this.checkedItems.length;
    }
  },
  watch: {
    show(value) {
      this.checkedItems = [];
      if (value == true) {
        for (let i in this.actors)
          this.checkedItems.push(i);

        this.instructions = this.role.instructions;
      }
    }
  },
  methods: {
    ...mapActions(['addAttendeeToEvent', 'showProfileDetail', 'showRoleDialog', 'likeProfile', 'dislikeProfile']),
    ...mapGetters(['getCurrentProject']),
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    checkItem: function(i) {
      let index = this.checkedItems.findIndex(c => c == i);
      if (index > -1)
        this.checkedItems.splice(index, 1);
      else
        this.checkedItems.unshift(i);
    },
    isItemChecked: function(i) {
      return this.checkedItems.findIndex(c => c == i) > -1;
    },
    checkAll: function() {
      if (this.checkedItems.length > 0)
        this.checkedItems = [];
      else {
        for (let i in this.actors)
          this.checkedItems.push(i);
      }
    },
    async addAttendees() {
      this.saving = true;
      this.showConfirm = false;
      for (let i in this.actors) {
        if (!this.checkedItems.includes(i))
          continue;
        if (this.instructions != this.role.instructions) {
          let iRole = this.event.eventRoles.findIndex(role => role.roleId === this.role.roleId);
          if (iRole >= 0) {
            this.role.instructions = this.instructions;
            this.event.eventRoles[iRole].instructions = this.instructions;
            try {
              await api.updateEvent(this.event);
            }
            catch (err) {console.error(err);}
          }
        }

        let actor = this.actors[i];
        try {
          await api.addAttendeeToEvent(this.event, actor);
        }
        catch (err) {console.error(err);}
        this.addAttendeeToEvent({event: this.event, pick: actor});
      }
      this.showOutro = true;
      this.saving = false;
    },
    closeOutro() {
      this.showOutro = false;
      this.show = false;
    },
    onEscape() {
      if (this.shareNameDialog) {
        this.shareNameDialog = false;
        return;
      }

      if (this.$store.state.dialogs.profileDetail.show) {
        this.$store.state.dialogs.profileDetail.show = false;
        return;
      }

      if (this.showConfirm) {
        this.showConfirm = false;
        return;
      }

      if (this.showOutro) {
        this.showOutro = false;
        this.show = false;
        return;
      }

      this.show = false;
    }
  }
}
</script>
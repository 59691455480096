<template>
  <v-list two-line subheader>
    <template v-for="(item, index) in items">
      <v-list-item :key="item.name + item.category" @click="add(item)" @keydown.enter="add(item)">
        <v-list-item-content>
          <v-list-item-title v-html="item.listName"></v-list-item-title>
          <v-list-item-subtitle>{{item.category}}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="index"></v-divider>
    </template>
  </v-list>
</template>

<style>
</style>

<script>
import Categories from './Categories'
import { mapActions } from 'vuex'

export default {
  name: 'AutocompleteList',
  props: {
    search: String
  },
  data() {
    return {
      treeData: Categories,
      filters: [
        { name: 'Muži', db: 'man', dbParent: 'sex' },
        { name: 'Ženy', db: 'woman', dbParent: 'sex' },
        { name: 'Věk', db: '35', dbParent: 'age' },
        { name: 'Váha', db: '86', dbParent: 'weight' },
        { name: 'Výška', db: '186', dbParent: 'height' }
      ]
    }
  },
  computed: {
    items: function() {
      let list = [];
      this.fillFilter(list);
      this.fillMatchedChildren("", null, this.treeData, list);
      return list;
    }
  },
  methods: {
    ...mapActions(['addSearchTag', 'setCurrentSearch']),
    add: function(item) {
      let norm = this.normalizeString(item.name);
      switch (norm) {
        case "vek": return this.addSearchTag({name: "věk", type: "age", db: '0,90', dbParent: 'age'});
        case "vaha": return this.addSearchTag({name: "váha", type: "weight", db: '0,200', dbParent: 'weight'});
        case "vyska": return this.addSearchTag({name: "výška", type: "height", db: '0,250', dbParent: 'height'});
      }
      let db = {}
      db[item.dbParent] = item.db;
      let name = item.category.toLowerCase() + ':' + item.name.toLowerCase()
      this.addSearchTag({name: name, db: db});
      this.setCurrentSearch(null);
    },
    fillFilter: function(list) {
      for (let filter of this.filters) {
        let listName = this.markSearchedWord(filter.name);
        if (listName.length)
          list.push({ name: filter.name, listName: listName, category: "Filtr", type: "filter", db: filter.db, dbParent: filter.dbParent });
      }
    },
    fillMatchedChildren: function(category, dbParent, children, list) {
      for (let key in children) {
        let child = children[key];
        if (child.children)
          this.fillMatchedChildren(child.name, key, child.children, list);
        else {
          let listName = this.markSearchedWord(child);
          if (listName.length) {
            list.push({ 
              name: child, 
              db: key,
              dbParent: dbParent,
              listName: listName, 
              category: category, 
              type: "category" 
            });
          }
        }
      }
    },
    markSearchedWord: function(item) {
      let f = this.normalizeString(item).search(this.normalizeString(this.search));
      if (f != -1) {
        item = item.substr(0, f) + "<b>" + item.substr(f);
        item = item.substr(0, f + 3 + this.search.length) + "</b>" + item.substr(f + 3 + this.search.length);
        return item;
      }
      return "";
    },
    normalizeString: function(str) {
      return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
    }
  }
}
</script>
<template>
<div style="height: 100%">
  <template v-if="profiles.length == 0">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <v-icon size="12vh" color="primary">not_interested</v-icon>
          <div class="display-1 primary--text mb-5 mt-1" style="font-weight: 200">{{$t('DislikedProfiles.notDislikedYet')}}</div>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  <template v-else>
    <v-container fluid grid-list-md  class="pt-0 pr-3 pb-5 pl-0" style="max-width: 100%">
      <div class="pl-4 pt-4 pb-4" style="width: 100%">
        <h1 class="mb-3">
          <span>{{$t('DislikedProfiles.dislike')}}</span> 
          <span class="grey--text text--lighten-2">&bull;</span> 
          <span class="grey--text">{{profiles.length}}</span>
        </h1>
      </div>
      <SearchResults :search="false" class="pl-4 pr-4" :value="profiles" :removeProfile="removeProfile"></SearchResults>
    </v-container>
  </template>
</div>
</template>

<style>
h1 {
  font-weight: 300;
}
</style>

<script>
import * as api from '@/libs/api'
import SearchResults from './Results' // Todo: rename

export default {
  name: 'DislikedProfiles',
  components: {
    SearchResults
  },
  data() {
    return {
      
    }
  },
  asyncComputed: {
    async profiles() {
      let profiles = await api.getDislikedProfiles();
      return await Promise.all(profiles.map(async(profile) => {
        profile = await api.getUpdatedActor(profile);
        profile.categoryName = "";
        profile.roleState = "added";
        profile.liked = false;
        profile.disliked = true;

        return profile;
      }));
    }
  },
  methods: {
    async removeProfile(i) {
      if (!this.profiles || this.profiles.length < i)
        return;

      let profile = this.profiles[i];

      let index = this.$store.state.user.disliked.findIndex(userId => userId === profile.userId);
      if (index > -1) {
        this.$store.state.user.disliked.splice(index, 1);
      }

      await api.dislikeProfile(profile.userId, true);
      this.profiles.splice(i, 1);
    }
  }
  
}
</script>
import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuex from 'vuex'
import Vuetify from 'vuetify'
import VueAnalytics from 'vue-analytics'
import App from './App'
import 'vuetify/dist/vuetify.min.css'
import AsyncComputed from 'vue-async-computed'
import VuexI18n from 'vuex-i18n'
import cz from './i18n/cz'
import en from './i18n/en'
import {registerYoutubePlayer} from './libs/youtube'

const vuetifyOpts = {
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#002e99',
        secondary: '#65bcff',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
      dark: {
        primary: '#002e99',
        secondary: '#65bcff',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      }
    }
  }
};
Vue.use(Vuetify, vuetifyOpts);

Vue.use(VueAnalytics, {
  id: 'UA-128119145-1'
});

Vue.use(AsyncComputed)

const store = new Vuex.Store();
Vue.use(VuexI18n.plugin, store);
Vue.i18n.add('en', en);
Vue.i18n.add('cz', cz);

let locale = localStorage.getItem('locale');
if (locale !== 'en')
  locale = 'cz'

Vue.i18n.set(locale);

registerYoutubePlayer();

/* eslint-disable no-new */
new Vue({
  el: '#app',
  template: '<App/>',
  vuetify : new Vuetify(vuetifyOpts),
  components: { App }
})

<template>
  <v-container fluid grid-list-xl class="search-page pa-0" style="max-width: 100%" :class="(getCurrentProject() != null || getRoles != null) && projectDrawer == true && this.$vuetify.breakpoint.mdAndUp ? `pane_expanded` : `pane_collapsed` ">
    <v-layout row style="height: 100%;">
      <v-flex shrink pa-0 v-if="projectDrawer && (getCurrentProject() != null || getRoles != null)" style="width: 300px;">
        <v-navigation-drawer v-if="(getCurrentProject() != null || getRoles != null)" v-model="projectDrawer" left width="300" class="pl-5" fixed :style="getLoggedUser() || $vuetify.breakpoint.mdAndUp ? 'top: 64px' : null">
          <ActualProject @collapse="projectDrawer = false" :getRoles="getRoles" :removePick="removePick" :allowAddingRoles="allowAddingRoles"></ActualProject>
        </v-navigation-drawer>
      </v-flex>
      <v-flex grow pa-0 style="width: 1px;">
        <div :class="this.$vuetify.breakpoint.mdAndUp ? 'pa-6' : 'pa-2'" style="width: 100%; height: 100%;">
          <v-card class="pt-1 pb-1">
            <input type="file" ref="inputfile" name="image" accept=".png,.jpg,.jpeg" style="display: none" @change="setImage" />
            <Autocomplete class="searchbar mb-0 v-input--is-focused" v-model="categories" :placeholder="$t('ctaSearch')" color="primary" height="54" :menu-props="{maxHeight:'660'}" :items="items" :loading="isLoading || searching" append-icon="expand_less" :append-outer-icon="showSearchByImage? `camera_alt` : null" @click:append-outer="showSearchByImage ? searchFaces() : null" deletable-chips :search-input.sync="search" chips multiple>
              <template slot="no-data">
                <div>
                  <v-container grid-list-xl class="pa-0" :class="$vuetify.breakpoint.mdAndUp ? `fill-height` : ``" style="max-width: 100%; padding-bottom: 60px !important; align-items: normal;">
                    <v-btn v-if="$vuetify.breakpoint.smAndDown" absolute fab top right small text class="dialog_close" @click="closeSearchDropdown"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
                    <div v-if="!search || search.length == 0" class="pa-4 left_search_panel">
                      <div class="pl-5">
                        <h3>{{$t('Search.category')}}</h3>
                      </div>
                      <CategoryTree></CategoryTree>
                    </div>
                    <div v-else class="pa-0 left_search_panel">
                      <AutocompleteList :search="search"></AutocompleteList>
                    </div>
                    <div class="right_search_panel">
                      <SearchFilter></SearchFilter>
                    </div>
                  </v-container>
                  <div v-if="$vuetify.breakpoint.mdAndUp" style="width: 100%; border-top: 1px solid #f3f4f5; background-color: transparent; height: 60px; bottom: 0px; position: absolute;">
                    <center><v-btn color="primary" class="mt-2 pl-5 pr-5 elevation-0" large @click="closeSearchDropdown">{{$t('Search.closePane')}}</v-btn></center>
                  </div>
                </div>
              </template>
            </Autocomplete>
          </v-card>
          <template>
            <div class="text-center pt-2 pb-2" style="float: left">
              <v-menu offset-y>
                <template v-slot:activator="{on}">
                  <v-btn v-on="on" color="white" class="grey--text ml-0" text small>{{sort.text}} <v-icon small class="ml-1">fa-angle-down</v-icon></v-btn>
                </template>
                <v-list>
                  <v-list-item @click="sort = {text: $t('Search.mostPopular'), value: 'rating'}">
                    <v-list-item-title>{{$t('Search.mostPopular')}}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="sort = {text: $t('Search.newest'), value: 'date'}">
                    <v-list-item-title>{{$t('Search.newest')}}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <div style="clear: both;"></div>
          </template>
          <SearchResults v-model="searchResult" :searchFn="searchFn" :getRoles="getRoles" :addPick="addPick" :removePick="removePick" :allowAddingRoles="allowAddingRoles"></SearchResults>
        </div>
      </v-flex>
    </v-layout>    
    <v-btn v-if="!projectDrawer" fixed small fab bottom left :style="$vuetify.breakpoint.smAndDown ? `bottom: 26px !important`:`bottom: 13px !important`"  :color="($vuetify.breakpoint.smAndDown ? `primary`: `grey lighten-3`)" @click="projectDrawer = true"><v-icon :color="($vuetify.breakpoint.smAndDown ? `white`: `black`)">fa-list-ul</v-icon></v-btn>
    <v-speed-dial v-model="fab" v-if="showProjectManagement" fixed bottom right direction="top" :style="$vuetify.breakpoint.smAndDown ? `bottom: 67px`:``">
      <template v-slot:activator>
        <v-btn fab color="red darken-1"><v-icon color="white" style="line-height: 56px">add</v-icon></v-btn>
      </template>
      <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
        <template v-slot:activator="{on}">
          <v-btn v-on="on" fab dark small color="orange darken-1" class="mb-2" @click="newProject">
            <v-icon style="line-height: 40px">mdi-notebook</v-icon>
          </v-btn>
        </template>
        <span>{{$t('Events.addProject')}}</span>
      </v-tooltip>
      <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
        <template v-slot:activator="{on}">
          <v-btn v-on="on" fab dark small color="green" @click="newRole">
            <v-icon style="line-height: 40px">fa-users</v-icon>
          </v-btn>
        </template>
        <span>{{$t('Events.addRole')}}</span>
      </v-tooltip>
      <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
        <template v-slot:activator="{on}">
          <v-btn v-on="on" fab dark small color="primary" @click="newEvent">
            <v-icon style="line-height: 40px">mdi-calendar-clock</v-icon>
          </v-btn>
        </template>
        <span>{{$t('Events.addEvent')}}</span>
      </v-tooltip>
    </v-speed-dial>
  </v-container>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.inner-drawer {
  position: fixed;
}
.left_search_panel {
  width: calc(100% - 450px); 
  max-height: 560px; 
  overflow-y: auto;
}
.right_search_panel {
  width: 450px; 
  height: 564px;
  overflow: hidden;
}
#app.mobile .left_search_panel {
  width: 100%; 
}
#app.mobile .right_search_panel {
  width: 100%; 
}
.v-toolbar__content {
  padding: 0px 15px;
}
.search-page .v-text-field.v-input--is-focused:not(.range) .v-text-field > .v-input__control > .v-input__slot:after,
.search-page .v-text-field:not(.range) > .v-input__control > .v-input__slot:after, 
.search-page .v-text-field:not(.range) > .v-input__control > .v-input__slot:before {
  content: none;
  display: none;
}

.v-text-field:not(.range) > .v-input__control > .v-input__slot {
  margin-bottom: 0px;
}
.v-autocomplete__content .v-list {
  padding: 0px;
}
.v-menu__content.v-autocomplete__content {
  /*min-width: calc(100% - 45px) !important; // full width autocomplete dropdown no longer needed*/
  margin-top: 10px;
  border-top: 1px solid rgb(243, 244, 245);
  box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
}
.searchbar .v-input__slot {
  padding-left: 16px;
  padding-right: 16px;
}
.searchbar .v-input__append-outer {
  position: absolute;
  right: 50px;
  top: 20px;
}
.searchbar input::placeholder {
  color: #002e99 !important;
  font-size: 1.3em !important;
  font-weight: 300;
}
.searchbar.v-input--is-focused input::placeholder {
  color: #c0c4c6 !important;
}
.search-footer {
  width: 100%; 
  height: 90px; 
  border-top: 1px solid #f3f4f5; 
  background-color: transparent; 
  bottom: 0px; 
  position: absolute;
}
.v-list--two-line .v-list__tile {
  height: 54px !important;
}
#app .v-autocomplete__content.v-menu__content {
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}
</style>

<script>
import * as api from '@/libs/api'
import { EventBus } from '@/libs/eventBus.js';
import SearchFilter from '@/widgets/Filter'
import ActualProject from '@/panels/Search'
import SearchResults from './Results'
import CategoryTree from '@/widgets/CategoryTree'
import AutocompleteList from '@/widgets/AutocompleteList'
import Autocomplete from '@/widgets/Autocomplete'
import { mapActions, mapGetters } from 'vuex'
import { Storage } from "aws-amplify";

export default {
    name: 'Search',
    components: {
      SearchFilter,
      ActualProject,
      SearchResults,
      CategoryTree,
      Autocomplete,
      AutocompleteList
    },
    props: {
      getRoles: {
        type: Function,
        default: null
      },
      addPick: {
        type: Function,
        default: null
      },
      removePick: {
        type: Function,
        default: null
      },
      allowAddingRoles: {
        type: Boolean,
        default: () => true
      },
      showProjectManagement: {
        type: Boolean,
        default: () => true
      },
      showSearchByImage: {
        type: Boolean,
        default: () => true
      },
      shareId: {
        type: String,
        default: null
      },
    },
    data() {
      return {
        isLoading: false,
        items: [],
        searchResult: [],
        fabPhoto: false,
        fabView: false,
        sort: {text: this.$t('Search.mostPopular'), value: 'rating'},
        thumbs: 'Náhledy',
        photo: 'Fotografie',
        projectDrawer: !this.$vuetify.breakpoint.smAndDown,
        speedDialTooltip: false,
        fab: false,
        searching: false
      }
    },
    created () {
      window.addEventListener('scroll', this.handleScroll);
    },
    destroyed () {
      window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
      this.setSearchTags([]);
      this.last = null;
      EventBus.$on('findSimilarFaces', async (actor) => {
        this.searchResult = [];
        this.isLoading = true;
        try {
          await this.findSimilarFaces(actor.mainPhoto, actor.userId);
          this.setLastSearch(null);
        }
        catch (err) {
          console.log(err);
        }
        this.isLoading = false;
      })
    },
    computed: {
      categories: {
        get: function() {
          let tags = this.getSearchTags();
          return tags;
        },
        set: function(val) {
          this.setSearchTags(val.map((search) => {
            if (typeof search == "string") {
              return { name: search };
            }
            return search;
          }));
        }
      },
      last: {
        get: function() {
          return this.getLastSearch();
        },
        set: function(val) {
          this.setLastSearch(val);
        }
      },
      search: {
        get() {
          return this.getCurrentSearch();
        },
        set(val) {
          this.setCurrentSearch(val);
        }
      }
    },
    watch: {
      categories() {
        if (!this.searching)
          this.startSearch();
      },
      sort(val) {
        if (val.value) {
          this.setSearchSort(val.value);
          this.startSearch();
        }
      },
      fab(val) {
        setTimeout(() => { this.speedDialTooltip = val }, 300);
      },
    },    
    methods: {
      ...mapActions(['setLastSearch', 'addSearchTag', 'removeSearchTag', 'setSearchTags', 'setCurrentSearch', 'setSearchSort']),
      ...mapGetters(['getLastSearch', 'getSearchSort', 'getSearchTags', 'getCurrentSearch', 'isSearchTagSet', 'getCurrentProject', 'getLoggedUser']),
      add: function(search) {
        let norm = search.toLowerCase();
        switch (norm) {
          case "vek":
          case "věk": return this.addSearchTag({name: "věk", type: "age"});
          case "vyska":
          case "výška": return this.addSearchTag({name: "výška", type: "height"});
          case "vaha":
          case "váha": return this.addSearchTag({name: "váha", type: "weight"});
        }
        return this.addSearchTag({name: search});
      },
      remove: function(search) {
        this.removeSearchTag(search);
      },
      searchFn: async function(startKey) {
        this.searching = true;
        let sort = this.getSearchSort();
        let search = this.getSearchTags();
        for (let tag of search) {
          if (tag.faces) {
            this.searching = false;
            return;
          }
        }
        let results = await api.search(search, startKey, sort, this.shareId);

        if (!results) {
          this.searching = false;
          return {items: []};
        }

        let items = [];
        if (results.Items) {
          let user = this.getLoggedUser();
          if (user) {
            items = (user.disliked && user.disliked.length) ? results.Items.filter((val) => {
              if (user.disliked.indexOf(val.userId) > -1)
                return false;
              return true
            }) : results.Items;
          }
          else 
            items = results.Items;

          items = items.map(async(val) => {
            val = await api.getUpdatedActor(val);
            val.categoryName = this.getMainProfession(val);
            val.categoryIcon = 'fa-theater-masks';
            //val.rating = Math.floor((Math.random() * 5) + 6) / 2;
            val.roleState = "added";
            val.liked = user ? ((user.liked && user.liked.indexOf(val.userId)) > -1 ? true : false) : false;
            val.disliked = false;

            return val;
          });
        }

        let returnValue = { items: await Promise.all(items) };
        if (results.LastEvaluatedKey)
          returnValue.lastKey = results.LastEvaluatedKey;

        this.searching = false;

        return returnValue;
      },
      async startSearch() {
        let { items, lastKey } = await this.searchFn();
        console.log('result:',items);
        this.searchResult = items;
        this.last = lastKey;
      },
      getMainProfession(actor) {
        let mainProfession = actor.mainProfession;
        if (mainProfession && mainProfession.length)
          return mainProfession

        let selectedProfessions = actor.selectedProfessions;
        if (selectedProfessions && selectedProfessions.length)
          return selectedProfessions[0]

        return "";
      },
      async findSimilarFaces(filename, userId) {
        this.addSearchTag({name: "podobné obličeje", faces: true});
        let results = await api.findFaces(filename, userId);

        let items = []
        if (results && results.Items) {
          items = results.Items.map(async(val) => {
            val = await api.getUpdatedActor(val);
            val.categoryName = this.getMainProfession(val);
            val.categoryIcon = 'fa-theater-masks';
            //val.rating = Math.floor((Math.random() * 5) + 6) / 2;
            val.roleState = "added";

            return val;
          });
        }

        this.searchResult = await Promise.all(items);
      },
      async setImage(e) {
        let sourceFile = e.target.files[0];
        if (!sourceFile)
          return;

        this.addSearchTag({name: "podobné obličeje", faces: true});

        this.searchResult = [];
        this.isLoading = true;
        const filename = `${Date.now()}-${sourceFile.name}`;
        try {
          const stored = await Storage.put(filename, sourceFile, { level: 'private', bucket: 'image-uploads.aircasting', contentType: sourceFile.type, provider: 'AWSS3' });
          await this.findSimilarFaces(stored.key);
          this.isLoading = false;
        }
        catch (err) {
          this.isLoading = false;
          console.error(err);
        }
      },
      searchFaces() {
        this.$refs.inputfile.click()
      },
      handleScroll() {
        this.fab = false;
      },
      closeSearchDropdown() {
        EventBus.$emit('closeSearchDropdown');
      },
      newEvent() {
        this.$store.state.dialogs.newEvent.show = true;
      },
      newRole() {
        this.$store.state.dialogs.roleDialog.profile = null;
        this.$store.state.dialogs.roleDialog.show = true;
      },
      newProject() {
        this.$store.state.dialogs.newProject.show = true;
      },
    }
}
</script>
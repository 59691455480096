<template>
  <div class="pt-4 pr-5 pl-5 search-filter" style="height: 100%; border-left: 1px solid #f3f4f5; position: relative;">
    <h3 class="pl-2">{{$t('Filter.filter')}}</h3>
    <v-layout row wrap align-center class="pt-3 pl-2">
      <v-flex xs6 class="pa-0">
        <v-checkbox :label="$t('men')" v-model="males" color="secondary"></v-checkbox>
      </v-flex>
      <v-flex xs6 class="pa-0">
        <v-checkbox :label="$t('women')" v-model="females" color="secondary"></v-checkbox>
      </v-flex>
    </v-layout>

    <v-layout row wrap align-center class="pt-3 pl-2">
      <v-flex xs4 class="pa-0">
        <h3 class="ma-0">{{$t('Filter.age')}}</h3>
      </v-flex>
      <v-flex xs8 class="pa-0">
        <h3 v-if="age[0]<=0&&age[1]>=maxAge" style="text-align: right; font-weight: normal">{{$t('Filter.any')}}</h3>
        <h3 v-else style="text-align: right">{{age[0]}} - {{age[1]}}</h3>
      </v-flex>
      <v-flex xs12 class="pa-0">
        <v-range-slider v-model="age" :max="maxAge" :min="0" :step="1" thumb-color="#74bcfe" class="pl-2 pr-2" color="primary"></v-range-slider>
      </v-flex>
    </v-layout>

    <v-layout row wrap align-center class="pt-3 pl-2">
      <v-flex xs4 class="pa-0">
        <h3 class="ma-0">{{$t('Filter.height')}}</h3>
      </v-flex>
      <v-flex xs8 class="pa-0">
        <h3 v-if="height[0]<=0&&height[1]>=maxHeight" style="text-align: right; font-weight: normal">{{$t('Filter.any')}}</h3>
        <h3 v-else style="text-align: right">{{height[0]}} - {{height[1]}} cm</h3>
      </v-flex>
      <v-flex xs12 class="pa-0">
        <v-range-slider v-model="height" :max="maxHeight" :min="0" :step="1" thumb-color="#74bcfe" class="pl-2 pr-2" color="primary"></v-range-slider>
      </v-flex>
    </v-layout>

    <v-layout row wrap align-center class="pt-3 pl-2 pb-4">
      <v-flex xs4 class="pa-0">
        <h3 class="ma-0">{{$t('Filter.weight')}}</h3>
      </v-flex>
      <v-flex xs8 class="pa-0">
        <h3 v-if="weight[0]<=0&&weight[1]>=maxWeight" style="text-align: right; font-weight: normal">{{$t('Filter.any')}}</h3>
        <h3 v-else style="text-align: right">{{weight[0]}} - {{weight[1]}} kg</h3>
      </v-flex>
      <v-flex xs12 class="pa-0">
        <v-range-slider v-model="weight" :max="maxWeight" :min="0" :step="1" thumb-color="#74bcfe" class="pl-2 pr-2" color="primary"></v-range-slider>
      </v-flex>
    </v-layout>

    <div style="position: absolute; bottom: 0px; left: 0px; width: 100%; border-top: 1px solid #e3e4e5" v-if="admin">
      <v-expansion-panels>
        <v-expansion-panel class="elevation-0 transparent">
          <v-expansion-panel-header>Pokročilé</v-expansion-panel-header>
          <v-expansion-panel-content class="px-6 pb-3">
            <v-text-field solo flat dense hide-details v-model="fullname" placeholder="Jméno" class="my-0" @keydown.enter="searchName()">
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <div v-if="fullname && fullname.length > 2">
                    <div style="margin-right: -6px; margin-top: 0px; display: inline-flex" @click="searchName()" ><v-icon left color="primary" style="cursor: pointer">mdi-magnify</v-icon></div>
                  </div>
                </v-fade-transition>
              </template>
            </v-text-field>
              <div class="text-center">
                <v-checkbox v-model="notEvaluated" small hide-details label="Nehodnoceni" color="secondary" class="d-inline-block mr-2"></v-checkbox>
                <v-checkbox v-model="approved" small hide-details label="Schvaleni" color="secondary" class="d-inline-block mr-2"></v-checkbox>
                <v-checkbox v-model="declined" small hide-details label="Zamitnuti" color="secondary" class="d-inline-block"></v-checkbox>
              </div>            
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<style>
.search-filter {
  background-color: #fbfbfc;
}
/*.search-filter .v-slider__thumb {
  width: 32px;
  height: 32px;
}*/
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'SearchFilter',
    data() {
        return {
          males: true,
          females: true,
          age: this.getSearchFilter().age,
          height: this.getSearchFilter().height,
          weight: this.getSearchFilter().weight,
          maxAge: 90,
          maxWeight: 200,
          maxHeight: 250,
          maxSalary: 250,
          fullname: null,
          notEvaluated: false,
          approved: false,
          declined: false,
        }
    },
    computed: {
      searchAge() {
       return this.getSearchFilter().age 
      },
      searchHeight() {
       return this.getSearchFilter().height
      },
      searchWeight() {
       return this.getSearchFilter().weight
      },
      admin() {
        return this.$store.state.user && this.$store.state.user.productionId && this.$store.state.user.productionId === 'ac076a32-454c-45c0-ba72-5d2c7b09f539';
      }
    },
    watch: {
      searchAge(val) {
        this.age = val;
      },
      searchHeight(val) {
        this.height = val;
      },
      searchWeight(val) {
        this.weight = val;
      },
      age(val) {
        setTimeout(() => {
          if (this.age === val) {
            this.setSearchFilter({age: val, weight: this.weight, height: this.height});
            if (val[0] <= 0 && val[1] >= this.maxAge)
              this.removeSearchTag("věk")
            
            else {
              const now = new Date();
              let month = "" + (now.getMonth() + 1);
              if (month.length < 2)
                month = "0" + month;
              const dob = ((now.getFullYear() - this.age[1]) + '-' + month + '-1,') + ((now.getFullYear() - this.age[0]) + '-' + month + '-31')
              this.addSearchTag({name: "věk", type: "age", db: { dob: dob }});
            }
          }
        }, 1000);
      },
      height(val) {
        setTimeout(() => {
          if (this.height === val) {
            this.setSearchFilter({age: this.age, weight: this.weight, height: val});
            if (val[0] <= 0 && val[1] >= this.maxHeight)
              this.removeSearchTag("výška");

            else
              this.addSearchTag({name: "výška", type: "height", db: { heightN: this.height[0] + ',' + this.height[1] }});
          }
        }, 1000);
      },
      weight(val) {
        setTimeout(() => {
          if (this.weight === val) {
            this.setSearchFilter({age: this.age, weight: val, height: this.height});
            if (val[0] <= 0 && val[1] >= this.maxWeight)
              this.removeSearchTag("váha");

            else {
              this.addSearchTag({name: "váha", type: "weight", db: { weightN: this.weight[0] + ',' + this.weight[1] }});
            }
          }
        }, 1000);
      },
      males: function(val) {
        if (val === true) {
          if (this.females === true)
            this.removeSearchTag("ženy");
          else
            this.addSearchTag({name: "muži", db: { sex: 'man' }});
        }
        else if (val === false) {
          if (this.females === true)
            this.addSearchTag({name: "ženy", db: { sex: 'woman' }});
          else
            this.removeSearchTag("muži");
        }
      },
      females: function(val) {
        if (val === true) {
          if (this.males === true)
            this.removeSearchTag("muži");
          else
            this.addSearchTag({name: "ženy", db: { sex: 'woman' }});
        }
        else if (val === false) {
          if (this.males === true)
            this.addSearchTag({name: "muži", db: { sex: 'man' }});
          else
            this.removeSearchTag("ženy");
        }
      },
      notEvaluated: function(val) {
        if (val === true) {
          this.addSearchTag({name: "nehodnocení", db: { approveState: false }});
        }
        else if (val === false) {
          this.removeSearchTag("nehodnocení");
        }
      },
      approved: function(val) {
        if (val === true) {
          this.addSearchTag({name: "schválení", db: { approveState: 'approved' }});
        }
        else if (val === false) {
          this.removeSearchTag("schválení");
        }
      },
      declined: function(val) {
        if (val === true) {
          this.addSearchTag({name: "neschválení", db: { approveState: 'declined' }});
        }
        else if (val === false) {
          this.removeSearchTag("neschválení");
        }
      },
    },    
    methods: {
      ...mapActions(['addSearchTag', 'setSearchTags', 'removeSearchTag', 'setSearchFilter']),
      ...mapGetters(['getSearchFilter']),
      toggleSearchFilter: function(name, type) {
        this.addSearchTag({ name: name, type: type});
      },
      searchName() {
        this.setSearchTags([{name: "jméno:" + this.fullname, db: { fullname: this.fullname }}]);
        this.fullname = null;
      }
    }
}
</script>
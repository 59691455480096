export default {
  AddEventAttendees: {
    headline: 'Add attendees',
    invited: 'Invited attendees'
  },
  AddEventAttendeesEx: {
    instructionsTitle: 'Role instructions',
    instructionsText: 'Give some background about the role for actors. Instructions should be concise but detailed enough so actors know what is expected of them.',
    sendInstructions: 'Send instructions',
    outroTitle: 'The actors have your instructions!',
    outroText: 'Once the actors have confirmed that they will participate in the online casting, just wait for their uploads.'
  },
  AddEventDialog: {
    headline: 'Choose a type of the event',
    roleSelect: 'Choose the roles',
    name: 'Name of the event',
    date: 'Date'
  },
  AddEventRolesDialog: {
    headline: 'Add roles to the event'
  },
  AddLogoDialog: {
    headline: 'Add a logo'
  },
  AddProjectDialog: {
    step1Headline: 'Choose a type of the project',
    otherProjectType: 'Are you after a different type of project?',
    getInTouch: 'Get in touch',
    dailyShift: 'Day',
    shiftedShift: 'Shifted',
    nightShift: 'Night',
    selectedRoles: 'Selected roles ',
    addDate: ' Add a date',
    assistanceLevel: 'What level of assistance do you require?',
    selfService: 'None, I’ll run this myself',
    selfServiceTick1: 'You’ll choose the right actors from our database yourself',
    selfServiceTick2: 'You’ll communicate with actors regarding the dates, deadlines, instructions and such',
    selfServiceTick3: 'Don’t worry, we’ll still take care of paperwork',
    assisted: 'Partial Aircasing assistance',
    assistedTick1: 'You’ll choose the right actors from our database (mostly) yourself',
    assistedTick2: 'We’re here for you to coordinate the communication, reminders, keep deadlines... ',
    assistedTick3: 'We’ll take care of paperwork',
    fullService: 'Full Aircasting assistance',
    fullServiceTick1: 'We’ll search for the right actors based on your criteria',
    fullServiceTick2: 'We’ll handle the communication, keep actors up to date',
    fullServiceTick3: 'Paperwork, coordination... we’ll run the whole show',
    callUs: 'Not sure about something? Give us a call',
    workingHours: 'Working days, 9:00-18:00',
    phoneNumber: '+420 797 634 782',
    callbackType: 'What type of callback do you prefer?',
    callbackLive: 'Standard live callback',
    callbackLiveTick1: 'You’ll meet with candidates in person',
    callbackLiveTick2: 'We’ll arrange the place and operations',
    callbackLiveTick3: 'You can easily share callback ouputs & materials with your colleagues and client online',
    callbackOnline: 'Online callback',
    callbackOnlineTick1: 'Everythings happens online, no meetings in person',
    callbackOnlineTick2: 'Actors provide self-shoot videos through our app',
    callbackOnlineTick3: 'All of this is available to share with your colleagues and clients online',
    extrasCoordinator: 'Extras coordinator',
    extrasCoordinatorQuestion: 'Do you need us to arrange extras coordinator for the shooting itself?',
    extrasCoordinatorInfo: 'By submitting this you\'ll let us know you want to kick off this project - it is not considered a binding order. We\'ll get in touch soon to agree on the next steps.',
    orderSent: 'Thanks, you have successfully submited the project details to us!',
    orderSentNote1: 'Feel free to start browsing the database and searching for the right candidates. Please, do not contact them just yet or send them any notifications. Wait till we agree on the final project budget.',
    orderSentNote2: 'We’ll get in touch soon!',
    noCallback: 'No callback',
    noCallbackTick1: 'You’ll choose from profiles',
    noCallbackTick2: 'Everything happens online',
    noCallbackTick3: 'You can easily share materials with your team online',
    client: 'Client',
    brand: 'Brand',
    campaignName: 'Campaign name',
    agency: 'Agency',
    director: 'Director',
    projectName: 'Project name',
    producer: 'Producer',
    tvStation: 'TV station',
    interpret: 'Interpreter',
    photographer: 'Photographer',
    briefProjectDescription: 'Brief project description',
    briefProjectDescriptionHint: 'All candidates will see this information',
    photoShootingDays: 'Photo shooting days',
    shootingDays: 'Shooting day',
    photoShootingDate: 'Photo shooting date',
    shootingDate: 'Shooting date',
    submit: 'Submit project',
    comercialAd: 'Commercial advertisement',
    charityAd: 'Charity ads',
    fullMovie: 'Movie',
    studentsMovie: 'Student movie',
    series: 'Series',
    clip: 'Videoclip',
    comercialPhoto: 'Commercial photographs',
    artPhoto: 'Art photographs',
    charityPhoto: 'Charity-cause photographs',
  },
  AddRoleDialog: {
    headline: 'Choose a role type',
    salaryHeadline: 'Salary components',
    daily: 'Daily salary',
    buyout: 'Buyout',
    addBuyout: 'Add buyout',
    addOther: 'Add item',
    budget: 'Total salary',
    createRole: 'Create a role',
    roleDetails: 'Role details',
    roleName: 'Role name',
    roleDescription: 'Public role description',
    perOneDay: 'x 1 day',
    czk: 'Kč',
    country: 'Country',
    countryExample: 'e.g. CZ, DE',
    mediaType: 'Media type',
    mediaTypeExample: 'e.g. TV, online, print',
    period: 'Period',
    other: 'Extra salary items',
    hintName: 'e.g. Dress rehearsal',
    hintDescription: 'e.g. Extra compensation for shaving the head '
  },
  EditProjectDialog: {
    editProject: 'Edit project'
  },
  EditRoleDialog: {
    headline: 'Edit role',
    genericHeadline: 'Basic information',
    noSalaryError: 'Salary is not specified - it is required',
    salary: 'Salary'
  },
  NotifyActorsDialog: {
    headline: 'Notify actors',
    infoShownToActor: 'Actors will see this information',
    infoNotShownToActor: 'Actos will see only info relevant to their role. They will not see the other candidates nor information relevant to other roles.',
    roleDescription: 'Role desctiption',
    total: 'Total',
    selectedActors: 'Selected actors',
    showBigThumbnail: 'Show large thumbnails',
    addNote: 'Add note'
  },
  ProfileDetail: {
    addRole: 'Add role'
  },
  ProfileMultiView: {
    sharingLink: 'Sharing link',
    sharingName: 'Add name for this sharing link',
    name: 'Name',
    createLink: 'Create link'
  },
  SelectWinnerDialog: {
    selectWinner: 'Select a winner',
    noCandidatesYet: 'No actors have accepted this role yet.',
    text1: 'You’re just a click away from confirming this actor as the winner. Once you do so, we’ll get in touch with him regarding signing the contract. Fingers crossed!',
    text2: 'Submitting this order is a binding act - you are acknowleding that you are ordering the service based on the signed contract with Aircasting (New Things On the Net, s.r.o.).',
    selectWinnerCta: 'Select a winner',
    selectWinners: 'Confirm selection'
  },
  ShareVideosDialog: {
    pickVideosToRate: 'Select videos which you’d like others to evaluate and comment on.',
    videosShared: 'Shared videos',
    shareWith: 'Share with',
    share: 'Share'
  },
  Chat: {
    empty: "No one has written anything in the shared communication yet, be the first one to break the ice <span style=\"font-size: 16px\">🙂</span>"
  },
  ShareProfilesDialog: {
    title: "Share actors",
    subtitle: "Share the selected actors using a shareable link.",
    allowSearch: "Allow search for other actors",
    createLink: "Create a shareable link"
  },
  SharedRoles: {
    filter: "Filter",
    selectActors: "Select actors",
    selectActorsFilter: "Select filtered actors",
    share: "Share actors",
    removeSelection: "Cancel selection",
    nameTitle: "Please type in your name"
  },
  ShareRolesDialog: {
    winner: "Winner",
    accepted: "Accepted",
    rejected: "Rejected",
    addressed: "Addressed",
    added: "Wasn't contacted yet",
  },
  States: {
    addedF: "Wasn't contacted yet",
    added: "Wasn't contacted yet",
    addressedF: "Addressed",
    addressed: "Addressed",
    acceptedF: "Accepted",
    accepted: "Accepted",
    rejectedF: "Rejected",
    rejected: "Rejected",
    winner: "Winner",
    money: "Salary",
    time: "Time",
    skill: "Skills",
    brand: "Brand",
    other: "Other",
  },
  VideoPlayer: {
    iLike: 'Like',
    notRanked: 'Not ranked yet',
    commentary: 'Commentary'
  },
  SendMessageDialog: {
    header: 'Send message',
    chooseActors: 'Choose actors whom you\'d like to address',
    message: 'Write your message to actors here',
    answers: 'Compose predefined answers that actors can choose from',
    more: 'Add more options',
    byPressing: 'by pressing the ',
    enter: 'Enter key ↵',
    send: 'Send',
  },
  DislikedProfiles: {
    notDislikedYet: 'You haven’t marked someone as disliked artitst yet.',
    dislike: 'Dislike'
  },
  Event: {
    addRoles: 'Add role',
    shareVideos: 'Share videos',
    roles: 'Role',
    videos: 'Videos',
    cast: 'Cast',
    instructions: 'Instructions',
    missingInstructions: 'No instructions submitted',
    videoUploaded: ' Video uploaded',
    noInterestYet: 'No one has shown interest in the role yet',
    addDescription: 'Add desctiption',
    showBigThumbnail: 'Show large thumbnails',
    addEventAttendees: ' Add attendees ',
    mustAddDescription: 'Description is required',
    urlToShare: 'Sharing link'
  },
  Files: {
    notRankedYet: 'Not ranked',
    addSharingName: 'Add shared item name',
    name: 'Name',
    createUrl: 'Create link',
    commentOnActor: 'Comment on candidate',
    commentary: 'Commentary'
  },
  Messages: {
    emptyHeader: 'No questions have been submitted yet',
    emptyButton: 'Send question',
    questions: 'Questions'
  },
  Forgot: {
    passForgotten: 'Forgotten passwords',
    enterEmail: 'Please enter your e-mail',
    passReset: 'Reset password',
    resetting: 'Resetting...'
  },
  Layout: {
    projectSummary: 'Project overiew',
    roles: 'Roles',
    search: 'Search',
    menu: 'Menu',
    events: 'Events',
    communication: 'Communication'
  },
  LikedProfiles: {
   notLikedYet: 'You haven’t marked any actor as Liked yet.',
   liked: 'Liked'
  },
  Results: {
    showNextProfiles: 'Show more profiles'
   },
  Role: {
    noRoleYet: 'No role has been added yet',
    noRoleDescriptionYet: 'Role description is required',
    addSalary: 'Add salary',
    showBigThumbnail: 'Show large thumbnails',
    actorsWithNoOffer: 'There are potential role candidates whom you haven’t approached yet.',
    sendOffer: 'Send request',
    deleteRoleQuestion: 'Are you sure you want to delete this role?',
    deleteEventAlert: 'Candidates who have previously accepted the request for this role will be notified about the deleted event. This step can’t be reverted. Are you sure you want to continue?',
    reallyDeleteActorPart1: 'Remove ',
    reallyDeleteActorPart2: ' from the role '
   }, 
   Search: {
    category: 'Category',
    closePane: 'Close panel',
    mostPopular: 'Most popular',
    newest: 'Most recent',
    small: 'Small',
    normal: 'Normal',
    large: 'Large',
    list: 'List',
    head: 'Head',
    profile: 'Profile',
    body: 'Body',
    hands: 'Hands',
    legs: 'Feet',
    faceSearch: 'Search similar looking faces',
    removeActor: 'Remove from a role',
    notifyActor: ' Notify actors'
   },
   SharedEvent: {
    yourName: 'Enter your name to be able to rank videos',
    name: 'Name'
   },
   Signin: {
    password: 'Password',
    passForgotten: 'Forgotten passwords',
    ctaSignIn: 'Sign in'
   },
   Projects: {
    emptyText: 'Start by creating a project',
    create: 'Create project',
    client: 'Client',
    agency: 'Agency',
    shootingCountdown: 'Shooting begins in',
    days: 'days',
    shootingDaysAgo: 'Shooting happend before',
    day: 'day',
    dayPlural: 'days',
    responseRateHeader: 'Actors response rate',
    responseRateText: '%',
    acceptRateHeader: 'Roles acceptance rate',
    notifications: 'Hint',
    occupancyRate: 'Roles status',
    calendar: 'Calendar',
    occupancy: 'Roles acceptance details',
    budget: 'Budget',
    salaries: 'Salaries',
    management: 'Project edits',
    selfService: 'I’ll run the casting myself',
    managedProject: 'I need Aircasting team assistance',
    standardCallback: 'I need standard Callback in person',
    onlineCallback: 'Online callback is enough',
    free: 'Free',
    needCoordinator: 'I require extras coordinator',
    noCoordinator: 'No extras coordinator needed',
    budgetSum: 'Total',
    projectName: 'Project name',
    pieceName: 'Project name',
    about: 'About this project',
    addProject: 'Add project',
    addRole: 'Add role',
    addEvent: 'Add event',
    inviteToCasting: 'Invite to casting',
    addDescription: 'Add description',
    addSalary: 'Add salary',
    emptyRolesText: 'Candidates are missing for the role',
    emptyRolesButton: 'Cast',
    emptyEvents: 'There are no events defined for this project',
    emptyProjectDescription: 'Missing project description',
    notAddressedActorsText: "Some actors haven't been requested yet",
    noRoles: 'There are no roles defined for this project',
    role: 'Roles',
    total: 'Addressed',
    addressed: 'Unanswered',
    rejected: 'Declined',
    accepted: 'Accepted'
   },
   Account: {
    likedProfiles: 'Liked actors',
    hiddenProfiles: 'Disliked actors',
    readMe: 'Please, read',
    tipsAndTricks: 'Tips & Hints',
    termsOfService: 'General terms & agreements',
    agreement: 'Contract',
    projectCalculator: 'Project calculator',
    inviteUser: 'Invite user',
    signOut: ' Sign out'
   },
   Events: {
    rolesSummary: 'All roles',
    addEvent: 'Add event',
    noEventYet: 'No event created yet',
    eventMustExists: 'At least one role is required before event can be created',
    createEvent: 'Create event',
    addInstructions: 'Add instructions',
    addProject: 'Add project',
    addRole: 'Add role',
    addQuestion: 'Submit question',
    deleteEventAlert: 'Actors that have already confirmed the attendence will be informed about this event deletion. This step can’t be reverted (though you can always create a new event). Do you want to continue?',
   },
   ProjectsList: {
    projects: 'Projects',
    addProject: ' Add project'
   },
   Filter: {
    filter: 'Filter',
    age: 'Age',
    any: 'any',
    height: 'Height',
    weight: 'Weight'
   },
   ProjectDocuments: {
    documents: 'Documents'
   },
  createEvent: 'Crete event',
  invite: 'Invite',
  create: 'Create',
  footer: {
    link1: 'General Conditions on Aircasting Service',
    link2: 'Information on personal data processing',
    contactUs: 'Contact us',
    copyright: '2021 New things on the net, s.r.o.',
  },
  profile: {
    addOtherVideo: 'Add a different video',
    addVideo: 'Add video',
    jobOffersTitle: 'Work opportunities',
    jobOffersText: 'Look alive! This is where you’ll see all your work opportunities. You’ll be fabulous, dahling. ',
  },
  men: 'Men',
  women: 'Women',
  ads: 'Commercial ad',
  movies: 'Movie',
  photoshoot: 'Shooting',
  role: 'Role',
  select: 'Select',
  close: 'Close',
  closeOk: 'OK',
  itemName: 'Name',
  description: 'Description',
  czkCurrency: 'Kč',
  dailySalary: 'Daily salary',
  video: 'Video',
  pictures: 'Photo',
  send: 'Send',
  edit: 'Edit',
  delete: 'Delete',
  ctaSearch: 'Search',
  addDescription: 'Add description',
  buyout: 'Buyout',
  castingOnline: 'Online casting',
  callbackOnline: 'Online callback',
  callbackOffline: 'Callback in person',
  fitting: 'Costume fitting',
  shooting: 'Shooting',
  age: '{age}',
  complete: 'Complete',
  cookieConsent: 'By using this site, you agree that we may use cookies to continue offering our service and gage the number of visitors we may have.',
  cookieMoreInfo: 'More information',
  cookieAgree: 'I agree',
  offerProjectHeader: "You've been invited to a casting!",
  offerEventHeader: "You've been invited to {type}",
  date: 'Date:',
  accept: 'Accept',
  decline: 'Reject',
  cancel: 'Cancel',
  letMeThink: 'Let me think about that',
  signInHeader: 'Log in',
  signInButton: 'Log in',
  signInErrorNotAuthorized: "Oops. You've entered an invalid email or password",
  signInErrorNotRegistered: "Hmmm. There's no account assigned to this email just yet",
  signInErrorLimit: 'You have exceeded the number of allowed login attempts',
  signInError: 'Oops! Something’s gone wrong. Please sign in again.',
  signInEmail: 'Enter your email',
  signInValidEmail: 'Enter a valid email',
  signInPassword: 'Enter your password',
  pickVideo: 'Choose video',
  pickYoutube: 'Link to YouTube',
  uploadVideo: 'Upload video',
  navBack: 'Back',
  describeSelf: 'Describe your self',
  editProfile: 'Complete your profile',
  showProfile: 'Show profile',
  weakProfileTitle: 'Your profile is not complete',
  weakProfileText: 'The more complete your profile, the higher the chance you’ll land a gig.',
  noVideoTitle: 'You haven’t uploaded a video',
  noVideoText: 'It’s audition time! Make the right impression with a quick, personal video.',
  noPhotoTitle: 'You haven’t uploaded enough photos',
  noPhotoText: 'The more photos you add, the more competitive your profile will be.',
  iWillAttend: 'I will attend',
  iWontAttend: 'Reject',
  readMore: 'Read more',
  signout: 'Log out',
  basicInfo: 'Basic information',
  appearance: 'Appearance',
  experience: 'Experience',
  salary: 'Salary:',
  next: 'Next',
  prev: 'Previous',
  skip: 'Skip',
  save: 'Save',
  yes: 'Yes',
  no: 'No',
  actor: 'Actor',
  model: 'Model',
  stuntman: 'Stuntman',
  artiste: 'Artiste',
  dancer: 'Dancer',
  singer: 'Singer',
  moderator: 'Moderator',
  voiceActor: 'Dubber',
  speaker: 'Speaker / VO Artist',
  sportsman: 'Athlete',
  noProfession: 'I’m not in the industry. Just trying my luck',
  extra: 'Extra',
  actorF: 'Actor',
  modelF: 'Model',
  stuntmanF: 'Stuntman',
  artisteF: 'Artiste',
  dancerF: 'Dancer',
  singerF: 'Singer',
  moderatorF: 'Moderator',
  voiceActorF: 'Dubber',
  speakerF: 'Speaker / VO Artist',
  sportsmanF: 'Athlete',
  noProfessionF: 'I’m not in the industry. Just trying my luck',
  extraF: 'Extra',
  pickPhoto: 'Choose photo',
  usePhoto: 'Use photo',
  pro: 'Professional',
  hobby: 'Hobby',
  underSixteen: 'Ah, young star! We don’t support talent that’s 16 or younger just yet. Come back in a few weeks.',
  contactFormHeader: 'How can we help you?',
  notificationReassurance: 'Look alive! This is where you’ll see all your work opportunities. You’ll be fabulous, dahling. ',
  videoUploadHeader: 'Your masterpiece is being uploaded',
  videoUploadSubheader: 'This might take a few minutes',
  fullname: {
    name: 'Name',
    header: 'Ready to make a killer profile? First, please tell us your full name.',
    label: 'Your full name',
  },
  email: {
    name: 'E-mail',
    header: 'And what’s your email, {declinedName}?',
    subheader: 'We won’t spam you, it’s for work-related communication.',
    label: 'Your email',
  },
  password: {
    name: 'Heslo',
    header: 'How would you like to log into Aircasting?',
    subheader: 'This is how you’ll log in from now on.',
    headerVerify: 'Please enter your verification code',
    subheaderVerify: 'We sent it by email in order to verify that it’s really you who’s setting up an account.',
    register: 'Register',
    ownPassword: 'Set up own password',
    signedInAs: 'Hmmm. You’re already signed in as {user}',
    verificationCode: 'Code',
    resend: 'Send again',
    error: 'Oops! Something’s gone wrong. Please sign in again.',
    errorAlreadyRegistered: 'Copycat! An account with this email already exists.',
    errorGeneric: 'Oops! Something’s gone wrong. ({code})',
    errorVerify: 'Wrong verification code',
  },
  sex: {
    name: 'Gender',
    header: 'What’s your gender?',
    man: 'Male',
    woman: 'Female',
  },
  dob: {
    name: 'Date of birth',
    header: 'When were you born, you talented thing?',
    headerF: 'When were you born, you talented thing?',
    rule1: 'Enter your date of birth in this form: DD.MM.YYYY',
    rule2: 'Ah, young star! We don’t support talent that’s 16 or younger just yet. Come back in a few weeks.',
  },
  country: {
    name: 'Location',
    header: 'Do you live in the Czech Republic (Czechia)?',
  },
  countryForeign: {
    name: 'Location',
    header: 'Where outside of the Czech Republic are you based?',
    label: 'Add a country',
  },
  address: {
    name: 'City',
    header: 'Pick the city you’re closest to.',
    label: 'Choose one city',
  },
  phone: {
    name: 'Phone number',
    header: 'To tell you about potential opportunities, we’ll need your phone number.',
    subheader: 'Don’t worry, it’s solely for work-related communication, like if you get a part.',
    label: 'Your phone number',
  },
  selectedProfessions: {
    name: 'Expertise',
    header: 'Choose your area of expertise, please!',
    headerF: 'Choose your area of expertise, please!',
  },
  mainProfession: {
    name: 'Core skill',
    header: 'And which of these are you best at, {declinedName}?',
  },
  roleType: {
    name: 'Preferred roles',
    header: 'Which roles are you after?',
    subheader: 'Choose more than one if you like.',
    leading: 'Leading',
    supporting: 'Supporting',
    featuredExtras: 'Featured extra',
    extra: 'Extra',
    extras: 'Extra',
  },
  tags: {
    name: 'Tags',
    header: 'Personalize your profile using helpful tags that agency folk can use to get to know you. Yes, you. Don’t be shy.',
    subheader: 'For example: running, Star Wars, Michael Jackson, vegan',
    label: 'Hit enter between tags',
  },
  driver: {
    name: 'Driver’s license',
    header: 'Do you have a driver’s license?',
  },
  driverLicenses: {
    name: 'Licenses',
    header: 'What can you drive?',
    subheader: 'Or fly...we’re not judging.',
    car: 'Car',
    motorbike: 'Motor bike',
    moped: 'Moped',
    truck: 'Truck',
    bus: 'Bus',
    tractor: 'Tractor',
    plane: 'Airplane',
  },
  driverLicensesMotorbike: {
    name: 'Motor bike license',
    header: 'Cool. Which type of bike are you licensed to drive?',
    A1: 'Upto 125cm3 (A1)',
    A2: 'Upto 35kW (A2)',
    A: 'Upto 35hW (A)',
  },
  driverLicensesPlane: {
    name: 'Pilot’s license',
    header: 'Which type of pilot’s license do you have?',
    subheader: 'We believe you can flyyy...',
    PPL: 'PPL',
    CPL: 'CPL',
    ATPL: 'ATPL',
  },
  etnicity: {
    name: 'Ethnicity',
    header: 'Which ethnic group fits you best?',
    headerF: 'Which ethnic group fits you best?',
    subheader: 'This selection doesn’t represent the world’s ethnic palette, but rather highlights the most common requirements from agencies.',
    arabian: 'Arabic',
    asian: 'Asian',
    white: 'White',
    whiteSouthern: 'White – Southern European',
    whiteNorthern: 'White – Northern European',
    black: 'African / African American',
    euroasian: 'Euroasian',
    hispanic: 'Hispanic',
    indian: 'Native American',
    ind: 'Indian',
    mulato: 'Mulatto',
    gipsy: 'Gipsy',
  },
  figure: {
    name: 'Figure',
    header: 'How would you describe your figure?',
    skinny: 'Skinny',
    athletic: 'Athletic',
    normal: 'Normal',
    plus: 'Plus',
    muscle: 'Muscular',
  },
  height: {
    name: 'Height',
    header: 'Great! We’re starting to get a good picture. So how tall are you?',
    label: 'Height',
  },
  hairLength: {
    name: 'Hair length',
    header: 'How long is your hair?',
    extraShort: 'Very short',
    short: 'Short',
    midShort: 'Shoulder length',
    long: 'Long',
    extraLong: 'Extra long',
    bald: 'I’m bald',
  },
  hairCut: {
    name: 'Hair style',
    header: 'What kind of hairstyle are you rocking?',
    classic: 'The way nature intended',
    mikado: 'Bob',
    bangs: 'Fringe',
    mullet: 'Mullet',
    asymetric: 'Asymmetric',
    sideShaved: 'Sidecut',
    undercut: 'Undercut',
    layered: 'Layered',
    crewCut: 'Crew cut',
    mohawk: 'Mohawk',
    fringe: 'Fringe',
    topKnot: 'Top knot',
    fohawk: 'Fake mohawk',
  },
  hairColorNatural: {
    name: 'Hair color',
    header: "What's the color of your hair?",
    lightBlond: 'Light blonde',
    darkBlond: 'Dark blonde',
    black: 'Black',
    lightBrown: 'Light brown',
    darkBrown: 'Dark brown',
    grey: 'Gray',
    red: 'Ginger',
    other: 'Other',
  },
  hairColorDyed: {
    name: 'Hair color',
    header: "What's the color of your hair?",
    red: 'Red',
    darkRed: 'Dark red',
    purple: 'Purple',
    copper: 'Copper',
    blue: 'Blue',
    orange: 'Orange',
    pink: 'Pink',
    grey: 'Gray',
    cyan: 'Turquoise',
    green: 'Green',
    yellow: 'Yellow',
    bleached: 'Bleached',
  },
  hairType: {
    header: 'What type of hair do you have?',
    name: 'Hair type',
    dreadlocks: 'Dreadlocks',
    rastaBraids: 'Rasta braids',
    ponytails: 'Ponytails',
    wavy: 'Wavy',
    curly: 'Curly',
    straight: 'Straight',
    afro: 'Afro',
    wig: 'Wih',
  },
  tattoo: {
    name: 'Tattoo',
    header: 'And now: do you have any tattoos in visible places?',
  },
  tattooPlacements: {
    name: 'Tattoo placement',
    header: 'Where’s your ink?',
    arms: 'Hands or arms',
    legs: 'Legs or feet',
    face: 'Face',
    belly: 'Stomach',
    back: 'Back',
    neck: 'Neck',
    chest: 'Chest',
    head: 'Head',
  },
  tattooArmsPhoto: {
    name: 'Tattoos: hands or arms',
    header: 'Add a photo of the tattoo on your hand or arms.',
  },
  tattooLegsPhoto: {
    name: 'Tattoos: legs or feet',
    header: 'Add a photo of the tattoo on your legs or feet.',
  },
  tattooBackPhoto: {
    name: 'Tattoos: back',
    header: 'Add a photo of the tattoo on your back.',
  },
  tattooNeckPhoto: {
    name: 'Tattoos: neck',
    header: 'Add a photo of the tattoo on your neck.',
  },
  tattooChestPhoto: {
    name: 'Tattoos: chest',
    header: 'Add a photo of the tattoo on your chest.',
  },
  tattooFacePhoto: {
    name: 'Tattoos: face',
    header: 'Add a photo of the tattoo on your face.',
  },
  tattooHeadPhoto: {
    name: 'Tattoos: head',
    header: 'Add a photo of the tattoo on your head.',
  },
  mainPhoto: {
    name: 'Profile photo',
    header: 'You’re almost set, {declinedName}! Now just add a profile photo.',
    singleHeader: 'Now just add a profile photo.',
    subheader: 'Please make sure it’s in colour, front-facing, and against a simple background.',
  },
  frontPhoto: {
    header: 'Upload a photo of your face pretty please.',
  },
  rightPhoto: {
    header: 'Show us your best side. Upload a photo of your right profile.',
  },
  leftPhoto: {
    header: 'Show us your best side. Upload a photo of your left profile.',
  },
  figurePhoto: {
    header: 'Now your figure. Please upload a full-length photo.',
  },
  handsPhoto: {
    header: 'Hands up! A photo of your hands, please.',
  },
  selfiePhoto: {
    header: 'Selfie time. Upload your best one.',
  },
  weight: {
    name: 'Weight',
    header: 'How much do you weigh?',
    label: 'Your weight',
  },
  eyeColor: {
    name: 'Eye colour',
    header: 'Tell us your eye colour.',
    black: 'Black',
    brown: 'Brown',
    blue: 'Blue',
    grey: 'Gray',
    green: 'Green',
    yellow: 'Yellow',
  },
  teethType: {
    name: 'Dental state',
    header: 'Braces, a couple of cavities, or a missing wisdom tooth don’t count as a flaw.',
    subheader: 'A couple of cavities or a missing wisdom tooth don’t count as a flaw.',
    normal: 'Normal',
    defect: 'Flawed',
  },
  teethDefect: {
    name: 'Dental flaws',
    header: 'So your teeth aren’t perfect; no one is. Which imperfections do you have?',
    fixedBraces: 'Braces',
    missingTeeth: 'Missing teeth',
    gaps: 'Gappy teeth',
    crooked: 'Crooked teeth',
    overbite: 'Overbite',
    underbite: 'Underbite',
    bruisedTeeth: 'Decaying teeth',
    fake: 'False teeth',
    implant: 'Colour prosthesis/Grill',
  },
  teethColor: {
    name: 'Teeth color',
    header: 'Choose the colour of your smile.',
    white: 'Bright white',
    normal: 'Normal',
    pigmentSpots: 'Dotted (fluorosis)',
    yellow: 'Yellow',
  },
  beard: {
    name: 'Facial hair',
    header: 'Do you have any facial hair?',
  },
  beardColor: {
    name: 'Facial hair colour',
    header: 'What colour is your facial hair?',
    black: 'Black',
    brown: 'Brown',
    blond: 'Blond',
    grey: 'Gray',
    red: 'Ginger',
  },
  beardType: {
    name: 'Facial hair style',
    header: 'How do you style your facial hair?',
    stubble: 'Stubble',
    beard: 'Full beard',
    mustacheChinStrap: 'Mustache and chin strap',
    ovalBeard: 'Oval beard',
    musketeerBeard: 'Musketeer beard',
    muttonChops: 'Mutton chops',
    muttonChopsMoustache: 'Mutton chops mustache',
    sideburns: 'Side burns',
    emperorBeard: 'Emperor beard',
    horseshoeMustache: 'Horseshoe mustache',
    goatee: 'Goatee',
    chinpath: 'Bradka',
    chinstrap: 'Chin strap',
    mustache: 'Mustache',
    klingon: 'Full beard, separate mustache',
    shortBeard: 'Short full beard',
    longBeard: 'Long full beard',
  },
  piercing: {
    name: 'Piercings',
    header: 'Do you have any piercings or body modifications?',
    subheader: '(You don’t need to tell us about your private parts.)',
  },
  piercingPlacements: {
    name: 'Piercings',
    header: 'Are any of your piercings or body modifications in these places?',
    chin: 'Chin',
    tongue: 'Tongue',
    nose: 'Nose',
    lips: 'Lips',
    eyebrow: 'Eyebrows',
    ears: 'Ears',
    earTunnels: 'Ear tunnel',
    face: 'Cheeks',
    clavicle: 'Collar bones',
    teeth: 'Teeth',
    head: 'Forehead/Head',
    belly: 'Belly button',
    other: 'Other',
  },
  measures: {
    name: 'Measurements',
    header: 'Give us your measurements, please.',
    subheader: 'Got a meter at hand? You’ll need to measure yourself for this part.',
    yes: 'Yes, ready',
    later: 'Not now',
  },
  measuresBreast: {
    name: 'Chest',
    header: 'Please measure your chest. Go all the way around.',
  },
  measuresWaist: {
    name: 'Waist',
    header: 'Measure your waist. Breath in!',
  },
  measuresHips: {
    name: 'Waist',
    header: 'The hips don’t lie. Measure them, please.',
  },
  measuresHead: {
    name: 'Head',
    header: 'Now your head.',
    subheader: "Measure from around your forehead, please. Imagine you're wearing a hat.",
  },
  measuresNeck: {
    name: 'Neck',
    header: 'Let’s not forget your neck measurements.',
    subheader: 'Don’t worry, measurements are almost done.',
  },
  measuresLeg: {
    name: 'Leg length',
    header: 'Just your leg measurements now.',
    label: 'Go from hip to ankle.',
  },
  shoe: {
    name: 'Shoe size',
    header: 'Which shoe size are you?',
    subheader: '(European sizing.)',
  },
  size: {
    name: 'Clothes size',
    header: "What's your clothes size?",
    XSX: 'XXS',
    XS: 'XS',
    S: 'S',
    M: 'M',
    L: 'L',
    XL: 'XL',
    XXL: 'XXL',
    XXXL: 'Bigger than XXL',
  },
  actorType: {
    name: 'Acting experience',
    header: 'Which type of acting do you have experience in?',
    theater: 'Theatre',
    movies: 'Movies',
    ads: 'Advertising',
    tv: 'TV',
    musical: 'Musical',
    standUp: 'Stand-up',
    amateur: 'Amateur',
  },
  modelType: {
    name: 'Modelling experience',
    header: 'What kind of modelling work have you done before?',
    photo: 'Photo shoots',
    ads: 'Commercials',
    fashionShow: 'Cat walk',
    legmodel: 'Leg model',
    handmodel: 'Hand model',
    facemodel: 'Face model',
  },
  modelPhoto: {
    name: 'Photo: legs',
    header: 'Please add a photo of your legs.',
    subHeader: 'From the front please, and waist down.',
  },
  stuntmanType: {
    name: 'Stunt work',
    header: 'What kind of stunt work have you done before?',
    car: 'Car',
    motorbike: 'Mortorbikes and Go Karts',
    martialArts: 'Combat sports',
    swordplay: 'Sword fighting',
    shooting: 'Shooting',
    horse: 'Horse riding',
    falling: 'Falls',
    fire: 'Live fires',
    parachute: 'Flying and parachuting',
  },
  artisteType: {
    name: 'Artiste skills',
    header: 'Which of these disciplines do you have experience with?',
    acrobatics: 'Acrobatics',
    rings: 'Gymnastics',
    fire: 'Fire poi',
    trampoline: 'Trampolining',
    juggling: 'Jungling',
    equilibristics: 'Equilibristic',
    magic: 'Magic',
    horse: 'Dressage',
    snakes: 'Contortionist',
    snakesF: 'Contortionist',
    taming: 'Animal trainer',
  },
  dancerSkill: {
    name: 'Dance experience',
    header: 'Your dancing skills are...',
  },
  dancerStyle: {
    name: 'Dance styles',
    header: 'We wanna dance with somebody…state your dance style!',
  },
  musicianSkill: {
    name: 'Music expertise',
    header: 'You appreciate music as a…',
  },
  musicianInstrument: {
    name: 'Musical instruments',
    header: 'Which instruments can you play?',
  },
  musicianStyle: {
    name: 'Musical instruments',
    header: 'Which genres are your thing?',
  },
  athleteSkill: {
    name: 'Sport experience',
    header: 'You said you’re an athlete. Do you exercise like a...',
  },
  athleteType: {
    name: 'Sport',
    header: 'What kind of sport do you do?',
  },
  singerSkill: {
    name: 'Singing',
    header: 'So you sing! In the shower, or like a pro?',
  },
  singerType: {
    name: 'Singing styles',
    header: 'What kind of singing do you enjoy most?',
  },
  moderatorType: {
    name: 'Moderator type',
    header: 'Your moderating work is mainly…',
    tv: 'TV',
    radio: 'Radio',
    actions: 'Events (private and public)',
  },
  experienceProfession: {
    name: 'Experience summary',
    header: 'So you told us you’re',
    subheader: 'Give us a little more detail about your skills, experiences, and more! We want to know who you are.',
    subheaderF: 'Give us a little more detail about your skills, experiences, and more! We want to know who you are.',
    hint: 'Max. 1000 characters',
    actor: 'an actor',
    model: 'a model',
    stuntman: 'a stuntman',
    artiste: 'an artiste',
    dancer: 'a dancer',
    singer: 'a singer',
    moderator: 'a moderator',
    voiceActor: 'a dubber',
    speaker: 'a speaker',
    sportsman: 'a sportsman',
    extra: 'an extra',
  },
  motherTongue: {
    name: 'Mother tongue',
    header: 'What’s your mother tongue?',
  },
  foreignLanguages: {
    name: 'Language skills',
    header: 'Which languages do you speak, {declinedName}?',
    subheader: 'Only include those you’re able to have a conversation in.',
  },
  handicap: {
    name: 'Health-related limitations',
    header: 'Do you have some health-related limitations?',
    no: 'None',
    eyes: 'Sight',
    ears: 'Hearing',
    movement: 'Mobility',
    other: 'Other',
  },
  handicapText: {
    name: 'Health-related limitations',
    header: 'If you’d like to add details about your health-related limitations, please do so here.',
  },
  jobQuestion: {
    name: 'Day job',
    headerJob: 'Do you have a day job? Apart from the skills you already listed ({job}) are you employed in any other way?',
    header: 'Do you have a day job? Apart from trying your luck are you employed in any other way?',
    actor: 'Actor',
    model: 'Model',
    moderator: 'Moderator',
    stuntman: 'Stuntman',
    artiste: 'Artiste',
    dancer: 'Dancer',
    singer: 'Singer',
    voiceActor: 'Dubber',
    speaker: 'Speaker / VO Artist',
    sportsman: 'Athlete',
    extra: 'Extra',
  },
  civilJob: {
    name: 'Employment',
    header: 'What’s your day job?',
    hint: 'Max. 50 characters',
  },
  preferredJobs: {
    name: 'Preferred opportunities',
    header: 'What type of opportunities are you interested in?',
    dabing: 'Dubbing',
    theater: 'Theatre',
    movies: 'Film',
    photos: 'Photography',
    improvisation: 'Improvisation',
    musical: 'Musicals',
    radio: 'Radio',
    ads: 'Advertising',
    studentsFilm: 'Student films',
    videoclip: 'Video clips',
    voiceover: 'Voice overs',
    hostesing: 'Hostessing',
  },
  welcome: {
    header: 'Welcome to Aircasting!',
    subheader: '<p>The main part of the questionnaire is done. All you need to do now is finetune some details and add your photos, videos, and effectively, your audition.</p> <p>We hope you enjoy the online casting process and wish you many “broken legs”!</p>',
  },
  photos: {
    header: 'Pick your photo',
  },
  parentEmail: {
    name: 'E-mail of one of your parents',
    header: 'What’s the email of your parent?',
    subheader: 'It’s for work-related communication.',
    label: 'E-mail',
  },
  parentPhone: {
    name: 'Phone number of one of your parents',
    header: 'Type in a phone number of one of your parents',
    subheader: 'Don’t worry, it’s solely for work-related communication, like if you get a part.',
    label: 'Your phone number'
  },
}
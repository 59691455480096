<template>
<div style="height: 100%">
  <v-container v-if="event" fluid grid-list-md class="pa-0 events" style="max-width: 100%; height: 100%;">
    <v-tooltip :value="roleTooltip.show" top absolute :position-x="roleTooltip.x" :position-y="roleTooltip.y">
      <span>{{roleTooltip.text}}</span>
    </v-tooltip>
    <v-fade-transition>
      <div v-if="showEvents" class="pa-0" style="width: 100%; height: 100%">
        <section class="px-4 pt-4" :class="videos > 0 ? `pb-0` : `pb-2`" style="background-color: white; border-bottom: 1px solid #e9ebee; width: 100%;">
          <div style="width: 100%">
            <div class="mb-3 ml-3" style="font-size: 28px; font-weight: 300; float: left;">
              <span>{{event.eventName ? event.eventName : $t(event.eventType)}}</span> 
              <span class="grey--text text--lighten-2">&bull;</span> 
              <span class="grey--text">{{formatDate(event.eventDate)}}</span>
              <v-tooltip right v-if="event.eventType == 'shooting'">
                <template v-slot:activator="{on}">
                  <v-icon size="34" v-on="on">
                    <template v-if="event.shootingType==2">mdi-weather-night</template>
                    <template v-else-if="event.shootingType==1">mdi-theme-light-dark</template>
                    <template v-else>mdi-weather-sunny</template>
                  </v-icon>
                </template>
                <span>{{event.shootingType == 2 ? 'noční' : (event.shootingType == 1 ? 'posunuté' : 'denní')}}</span>
              </v-tooltip>
            </div>
            <div style="float: right">
              <v-btn v-if="project.roles.length > attendees.length" class="elevation-0 mr-2" @click="addRolesDialog = {show: true, event: event}" color="secondary"><v-icon small class="mr-2">fas fa-users</v-icon>{{$t('Event.addRoles')}}</v-btn>
              <v-btn v-if="videos > 0" class="elevation-0" color="secondary" @click="shareVideos"><v-icon small class="mr-2">share</v-icon>{{$t('Event.shareVideos')}}</v-btn>
            </div>
            <div style="clear: both"></div>
          </div>
          <v-tabs v-model="tab" color="black" class="py-0 pl-2" v-if="videos > 0">
            <v-tabs-slider color="#6dc2ff"></v-tabs-slider>
            <v-tab class="px-2">{{$t('Event.roles')}}</v-tab>
            <v-tab>
              <v-badge color="primary">
                <template slot="badge">
                  <span>{{videos}}</span>
                </template>
                <span class="px-2">{{$t('Event.videos')}}</span>
              </v-badge>
            </v-tab>
          </v-tabs>
        </section>
        <Files v-if="tab == 1"></Files>
        <div v-else-if="event.eventType=='shooting'" class="px-4 py-2">
          <Winners :winners="attendees" :flexClass="'sm12 md6 lg3 xl2'"></Winners>
        </div>
        <v-layout v-else class="row wrap px-4">
          <v-flex xs12 sm12 md4 xl3 v-for="(role, r) in attendees" :key="r">
            <v-card class="pa-2 ma-2">
              <v-card-title primary-title class="pa-2">
                <div>
                  <div class="d-inline-block pt-2 mr-2 doughnut" :style="role.attendees.length ? `width: 32px; height: 32px;` : ``">
                    <DoughnutChart v-if="role.attendees.length" :data="getRoleStats(role)" :index="r" :labels="['Neodpovědělo', 'Odmítlo', 'Přijalo', 'Nahrálo video']" :colors='["#e0e0e0", "#FF5252", "#4CAF50", "#4CAF50"]'></DoughnutChart>
                  </div>
                  <div class="headline d-inline-block">
                    {{role.roleName}}
                  </div>
                </div>
                <v-spacer></v-spacer>
                <v-menu bottom left light offset-y>
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on" small icon class="elevation-0 my-0">
                      <v-icon size="24">more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item @click="role.role ? showEditRoleDialog(event, role.role) : null">
                      <v-list-item-title>{{$t('edit')}}</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="confirmDelete = {role: role, show: true}">
                      <v-list-item-title>{{$t('delete')}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>
              <v-card-text class="pa-0">
                <v-tabs v-model="role.tab">
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab>{{$t('Event.cast')}}</v-tab>
                  <v-tab>
                    <span>{{$t('Event.instructions')}}</span>
                    <v-tooltip top v-if="!role.role || !role.role.instructions || !role.role.instructions.length">
                      <template v-slot:activator="{on}">
                        <v-icon v-on="on" class="ml-1" small color="error">fas fa-exclamation-circle</v-icon>
                      </template>
                      <span>{{$t('Event.missingInstructions')}}</span>
                    </v-tooltip>
                    <v-tooltip top v-else-if="role.role && role.role.video">
                      <template v-slot:activator="{on}">
                        <v-icon v-on="on" class="ml-2" size="12">fa-solid fa-video</v-icon>
                      </template>
                      <span>Obsahuje video instrukce</span>
                    </v-tooltip>
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="role.tab" style="border-top: 1px solid rgb(228,228,228)">
                  <v-tab-item style="height: 400px; overflow-y: auto;">
                    <v-list v-if="role.attendees.length">
                      <template v-for="(attendee, i) in role.attendees">
                        <div :key="i">
                          <v-list-item @click="showProfileDetail(attendee)">
                            <v-list-item-avatar>
                              <v-avatar size="36px">
                                <img :src="getImageUrl(attendee, attendee.mainPhoto, 40)" />
                              </v-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ attendee.name }}</v-list-item-title>
                              <v-list-item-subtitle v-if="attendee.eventState == `uploaded`" style="font-size: 0.7em;" class="success--text"><v-icon color="success" size="16">mdi-account-check</v-icon>{{$t('Event.videoUploaded')}}</v-list-item-subtitle>
                              <v-list-item-subtitle v-else style="font-size: 0.7em;"><v-icon size="16">{{getStateIcon(attendee.eventState)}}</v-icon> {{getStateText(attendee.eventState, attendee.sex)}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon small ripple @click.stop="removeAttendee(attendee)">
                                <v-icon small color="grey lighten-1">close</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                          <v-divider inset v-if="i < role.attendees.length - 1"></v-divider>
                        </div>
                      </template>
                    </v-list>
                    <v-container v-else-if="role.available.length > 0" fill-height>
                      <v-layout row wrap align-center>
                        <v-flex xs12 sm12 md12 class="text-center">
                          <v-btn large color="primary" v-if="role.available.length" @click="showAttendeesDialog(role.role, role.available)" class="ma-0 pl-1 pr-2 elevation-0"><v-icon small class="ml-0 mr-1">add</v-icon> Přidat účastníky ({{role.available.length}})</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <v-container v-else-if="role.available.length == 0" fill-height>
                      <v-layout row wrap align-center>
                        <v-flex xs12 sm12 md12 class="text-center">
                          <img src="assets/empty-roles.svg" width="150" class="mx-auto" />
                          <div class="mb-5 mt-4 mx-auto" style="color: #131d2d; font-size: 18px">{{$t('Event.noInterestYet')}}</div>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-tab-item>
                  <v-tab-item style="height: 400px; overflow-y: auto;">
                    <div v-if="role.role && role.role.instructions && role.role.instructions.length" class="pa-3">
                      <template v-if="role && role.role && role.role.video && role.role.video.thumb">
                        <v-img @click="showVideoPlayer(role.role.video.url)" contain :aspect-ratio="16/9" :src="role.role.video.thumb" class="mb-3" style="background-color: rgb(235, 247, 255); cursor: pointer">
                          <img width="60" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" src="https://aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                        </v-img>
                      </template>
                      <div>{{role.role.instructions}}</div>
                    </div>
                    <v-container v-else fill-height>
                      <v-layout row wrap align-center>
                        <v-flex xs12 sm12 md12 class="text-center">
                          <v-btn class="elevation-0" large color="primary" @click="role.role ? showEditRoleDialog(event, role.role) : null"><v-icon left>add</v-icon>{{$t('Event.addDescription')}}</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
              <v-card-actions>
                <v-tooltip right v-if="role.attendees.length">
                  <template v-slot:activator="{on}">
                    <v-btn v-on="on" small icon class="elevation-0 my-0" @click="showActorsDialog(role.roleName, role.attendees)">
                      <v-icon small>fa-expand</v-icon>
                    </v-btn>
                  </template>
                  <span>{{$t('Event.showBigThumbnail')}}</span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <v-btn @click="showUploadEventVideoDialog(role.attendees, role.role)" text class="ma-0 pl-1 pr-2 elevation-0" color="primary"><v-icon small class="ml-0 mr-1">add</v-icon> Nahrát video</v-btn>
                <v-btn v-if="role.available.length" @click="showAttendeesDialog(role.role, role.available)" text class="ma-0 pl-1 pr-2 elevation-0" color="primary"><v-icon small class="ml-0 mr-1">add</v-icon>{{$t('Event.addEventAttendees')}} ({{role.available.length}})</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </div>
    </v-fade-transition>
  </v-container>  
  <ProfileMultiView v-model="actorsDialog.show" :title="(event ? ($t(event.eventType) + ' - ') : '') + actorsDialog.title" :actors="actorsDialog.actors" state="eventState"></ProfileMultiView>
  <AddEventAttendeesDialogEx v-model="attendeesDialog.show" :role="attendeesDialog.role" :event="event" :actors="attendeesDialog.actors"></AddEventAttendeesDialogEx>
  <AddEventRolesDialog v-model="addRolesDialog.show" :event="addRolesDialog.event"></AddEventRolesDialog>
  <AddNoteDialog v-model="addInstructionsDialog.show" :title="$t('Event.mustAddDescription')" :save="saveRoleInstructions" />
  <EditEventDialog v-model="editEventDialog.show" :event="editEventDialog.event" :role="editEventDialog.role" :saveInstructions="updateRoleInstructions"></EditEventDialog>
  <VideoPlayerSimple v-model="videoPlayer.show" :source="videoPlayer.url"></VideoPlayerSimple>
  <EventVideoUploadDialog v-model="uploadEventVideoDialog.show" :event="uploadEventVideoDialog.event" :role="uploadEventVideoDialog.role" :actors="uploadEventVideoDialog.actors"></EventVideoUploadDialog>
  <v-dialog max-width="600" v-model="shareVideosDialog.show" persistent>
    <v-card flat tile>
      <v-card-text>
        <span style="font-size: 18px">{{$t('Event.urlToShare')}}</span>
        <v-text-field class="mt-4" v-model="shareVideosDialog.url" label="URL">
          <v-icon slot="append" @click="copyURL">{{shareVideosDialog.copying ? `check` : `far fa-clipboard`}}</v-icon>
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="shareVideosDialog.show = false" color="primary">{{$t('closeOk')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-if="confirmDelete.role" max-width="500" v-model="confirmDelete.show">
      <v-card flat tile class="pt-3">
        <v-card-text>
          <div style="font-size: 18px">{{$t('Role.deleteRoleQuestion')}} {{ confirmDelete.role.roleName.toLowerCase() }}?</div>
          <div style="font-size: 14px; margin-top: 18px">{{$t('Role.deleteEventAlert')}}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="confirmDelete.show = false" color="success">{{$t('no')}}</v-btn>
          <v-btn class="elevation-0" @click="(confirmDelete.show = false) || removeRoleFromCurrentProject(confirmDelete.role)" color="error">{{$t('yes')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</div>
</template>
<style>
h1 {
  font-weight: 300;
}
.v-list--two-line .v-list__tile {
  height: 54px !important;
}
.events .v-btn--floating .v-btn__content :not(:only-child):first-child, .projects .v-btn--floating .v-btn__content :not(:only-child):last-child {
  top: -12px;
}
</style>
<script>
import * as api from '@/libs/api'
import { Storage } from "aws-amplify"
import { mapActions, mapGetters } from 'vuex'
import { EventBus } from '@/libs/eventBus.js'
import AddEventAttendeesDialogEx from '@/dialogs/AddEventAttendeesEx'
import EditEventDialog from '@/dialogs/EditEventDialog'
import EventVideoUploadDialog from '@/dialogs/EventVideoUpload'
import ProfileMultiView from '@/dialogs/ProfileMultiView'
import AddEventRolesDialog from '@/dialogs/AddEventRolesDialog'
import AddNoteDialog from '@/dialogs/AddNoteDialog'
import VideoPlayerSimple from '@/dialogs/VideoPlayerSimple'
import DoughnutChart from '@/widgets/DoughnutChart'
import Winners from '@/widgets/Winners'
import Files from '@/pages/Files'

export default {
  name: 'Event',
  components: {
    AddEventAttendeesDialogEx,
    AddEventRolesDialog,
    ProfileMultiView,
    DoughnutChart,
    Files,
    AddNoteDialog,
    Winners,
    EditEventDialog,
    VideoPlayerSimple,
    EventVideoUploadDialog
  },
  mounted() {
    if (this.videos > 0)
      this.tab = 1;
    EventBus.$on('showTooltip', (tooltip) => {
      if (tooltip.model.opacity === 0) {
        this.roleTooltip.show = false;
      }
      else {
        if (tooltip.model.body && tooltip.model.body.length && tooltip.model.body[0].lines && tooltip.model.body[0].lines.length)
          this.roleTooltip.text = tooltip.model.body[0].lines[0];

        let elms = document.querySelectorAll(".doughnut");
        let elm = null;
        if (elms.length >= tooltip.index)
          elm = elms[tooltip.index];


        if (elm) {
          let position = elm.getBoundingClientRect();
          this.roleTooltip.x = position.left + window.pageXOffset + tooltip.model.caretX;
          this.roleTooltip.y = position.top + window.pageYOffset + tooltip.model.caretY;
          this.roleTooltip.show = true;
        }
      }
    });
  },
  data() {
    return {
      actorsDialog: {
        show: false,
        title: null,
        actors: []
      },
      attendeesDialog: {
        show: false,
        title: null,
        role: null,
        actors: []
      },
      shareVideosDialog: {
        show: false,
        url: null,
        copying: false
      },
      roleTooltip: {
        show: false,
        text: null,
        x: 0,
        y: 0
      },
      showEvents: true,
      attendees: [],
      tab: 0,
      addRolesDialog: {
        show: false,
        event: null
      },
      addInstructionsDialog: {
        show: false,
        roleId: null
      },
      confirmDelete: {
        show: false,
        role: null
      },
      editEventDialog: {
        show: false,
        role: null
      },
      videoPlayer: {
        show: false,
        url: null
      },
      uploadEventVideoDialog: {
        show: false,
        event: null,
        role: null,
        actors: null
      },
    }
  },
  computed: {
    empty: function() {
      if (!this.project || !("events" in this.project) || !Array.isArray(this.project.events) || this.project.events.length === 0)
        return true;

      for (let event of this.project.events) {
        if (event.attendees > 0)
          return false;
      }

      return false;//true;
    },
    roles: function() {
      let project = this.getCurrentProject();
      let items = [];
      for (let role of project.roles) {
        items.push(role.roleName);
      }
      return items;
    },
    project: {
      get: function() {
        return this.getCurrentProject();
      },
      set: function(project) {
        this.updateCurrentProject(project);
      }
    },
    currentEventId() {
      this.updateAttendees();
      return this.$store.state.selectedEvent;
    },
    event: function() {
      return this.getCurrentEvent();
    },
    videos() {
      let videos = 0;
      for (let attendee of this.event.attendees) {
        if (attendee.eventState == 'uploaded')
          videos++;
      }
      return videos;
    }
  },
  watch: {
    event: async function() {
      if (this.event.eventType == 'shooting')
        this.tab = 0;
      /*this.showEvents = false;
      setTimeout(() => { this.showEvents = true }, 250);*/
    },
    currentEventId() {
      
    }
  },
  methods: {
    ...mapActions(['showProfileDetail', 'addInstructionToCurrentEvent', 'removeInstructionFromCurrentEvent', 'removeAttendeeFromEvent', 'updateEvent']),
    ...mapGetters(['getCurrentProject', 'getCurrentEvent', 'getRole']),
    formatDate(date) {
      const [year, month, day] = date.split('-')
      return `${day}. ${month}. ${year}`
    },
    isDone() {
      let now = new Date();
      let date = new Date(this.event.eventDate);
      return (now.getTime() > date.getTime());
    },
    getIcon(event) {
      if (!event)
        return 'date_range';
      switch (event.eventType) {
        case 'castingOnline': return 'mdi-camera-front';
        case 'callbackOnline': return 'mdi-camera-front-variant';
        case 'callbackOffline': return 'mdi-backup-restore';
        case 'fitting': return 'mdi-hanger';
        case 'shooting': return 'movie';
      }

      return 'date_range';
    },
    getColor(event) {
      if (this.isDone(event)) 
        return 'success';

      if (event.eventType == 'Natáčení')
        return 'red darken-4';

      return 'primary';
    },
    // todo: update this crap with asyncComputed
    async updateAttendees() {
      if (!this.event)
        return [];
      if (!this.event.eventRoles) 
        return [];
      this.attendees = [];
      let roleMap = new Map();
      for (let it of this.event.eventRoles) {
        let role = this.getRole()(it.roleId);
        if (!role)
          continue;

        role.instructions = it.instructions;
        role.video = null;
        if (it.video && it.video.thumbnail) {
          console.log('HUHUHU', it, this.event)
          try {
            role.video = {
              thumb: await Storage.get(it.video.thumbnail.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: it.video.userId, expires: 86400 }),
              url: await Storage.get(it.video.key.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: it.video.userId, expires: 86400 }),
            }
          }
          catch (err) {
            console.log(err);
          }
        }

        let picks = role.picks.filter((pick) => {
          // filter out actors that didn't accept the role offer
          if (pick.roleState !== 'accepted')
            return false;
          // filter out actors that are already part of the event
          return this.event.attendees.findIndex(attendee => attendee.userId === pick.userId) < 0;
        });
        let roleWinners = [];
        for (let pick of picks) {
          if (!pick.winner)
            continue;
          roleWinners.push(pick);
        }
        let roleData = {
          role: role,
          available: picks,
          roleWinners: roleWinners,
          attendees: [],
          picks: picks
        };
        roleMap.set(role.roleId, roleData);
      }
      for (let attendee of this.event.attendees) {
        if (!attendee.roleId)
          continue;

        if (!roleMap.has(attendee.roleId))
          continue;

        let role = roleMap.get(attendee.roleId);
        role.attendees.push(await this.getUpdatedActor(attendee));
        roleMap.set(attendee.roleId, role);
      }

      for (let [, role] of roleMap) {
        this.attendees.push({ role: role.role, roleName: role.role.roleName, attendees: role.attendees, picks: role.picks, available: role.available, roleWinners: role.roleWinners });
      }
    },
    async getUpdatedActor(actor) {
      let ageDate = new Date(Date.now() - new Date(actor.dob));
      let age = Math.abs(ageDate.getUTCFullYear() - 1970);
      actor.age = age;
      actor.location = { city: actor.address };
      actor.name = actor.fullname;
      return actor;
    },
    removeAttendee(pick) {
      api.deleteAttendeeFromEvent(this.event, pick);
      this.removeAttendeeFromEvent({event: this.event, pick: pick});
    },
    getStateText(state, sex) {
      switch (state) {
        case 'added': return sex === 'woman' ? 'Oslovena' : 'Osloven'
        case 'accepted': return sex === 'woman' ? 'Přijala' : 'Přijal'
        case 'rejected': return sex === 'woman' ? 'Odmítla' : 'Odmítl'
      }
      return ''
    },
    getStateIcon(state) {
      switch (state) {
        case 'added': return 'mdi-account-clock'
        case 'accepted': return 'mdi-account-check'
        case 'rejected': return 'mdi-account-remove'
      }
      return 'mdi-account-question'
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    showActorsDialog(title, actors) {
      this.actorsDialog.show = true;
      this.actorsDialog.title = title;
      this.actorsDialog.actors = actors;
    },
    showAttendeesDialog(role, actors) {
      this.attendeesDialog.show = true;
      this.attendeesDialog.role = role;
      this.attendeesDialog.actors = actors;
    },
    getRoleStats(role) {
      let added = 0;
      let accepted = 0;
      let rejected = 0;
      let uploaded = 0;
      for (let pick of role.attendees) {
        switch (pick.eventState) {
          case 'added': added++; break;
          case 'accepted': accepted++; break;
          case 'rejected': rejected++; break;
          case 'uploaded': uploaded++; break;
        }
      }
      return [added, rejected, accepted, uploaded];
    },
    async shareVideos() {
      this.tab = 1;
      try {
        let shareId = await api.shareEvent(this.project.projectId, this.event.eventId);
        this.shareVideosDialog = {
          show: true,
          url: "https://prod.aircasting.cz/event/" + shareId,
          copying: false
        };
      }
      catch (err) {
        console.error(err);
      }
    },
    async saveRoleInstructions(note) {
      try {
        let event = this.event;
        let r = event.eventRoles.findIndex(role => role.roleId === this.addInstructionsDialog.roleId);
        if (r < 0)
          return;

        event.eventRoles[r].instructions = note.note.text;
        this.updateEvent(event);
      }
      catch (err) {console.error(err);}
    },
    copyURL() {
      this.shareVideosDialog.copying = true;
      navigator.clipboard.writeText(this.shareVideosDialog.url);
      setInterval(()=>{this.shareVideosDialog.copying = false}, 3000);
    },
    showEditRoleDialog(event, role) {
      this.editEventDialog.show = true;
      this.editEventDialog.role = role;
      this.editEventDialog.event = event;
    },
    async updateRoleInstructions(instructions) {
      try {
        let event = this.event;
        let r = event.eventRoles.findIndex(role => role.roleId === this.editEventDialog.role.roleId);
        if (r < 0)
          return;

        event.eventRoles[r].instructions = instructions;
        this.updateEvent(event);
      }
      catch (err) {console.error(err);}
    },
    showVideoPlayer(url) {
      this.videoPlayer = {
        show: true,
        url: url
      }
    },
    showUploadEventVideoDialog(actors, role) {
      this.uploadEventVideoDialog = {
        show: true,
        actors: actors,
        role: role,
        event: this.event
      }
    },    
  }
}
</script>

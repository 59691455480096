<template>
  <v-container fluid grid-list-md class="pa-2">
    <v-layout row wrap>
      <v-flex :class="flexClass" v-for="(role, r) in winners" :key="r">
        <template v-for="(winner, w) in role.roleWinners">
          <div class="add-winner-button" @click="showWinnerDialog(role)" :key="w">
            <v-img :src="getImageUrl(winner, winner.mainPhoto, 600)" aspect-ratio="1" style="border-radius: 3px; cursor: pointer;"></v-img>
          </div>
          <div class="mt-2" :key="`n`+w">{{role.roleName + (role.roleWinners.length > 1 ? (` ` + (w+1)) : ``)}}</div>
        </template>
        <template v-if="!role.roleWinners.length">
          <div class="add-winner-button" @click="showWinnerDialog(role)">
            <v-responsive aspect-ratio="1" style="position: relative">
              <div class="add-winner-button-content">
                <v-avatar color="white" class="elevation-2">
                  <v-icon color="primary">add</v-icon>
                </v-avatar>
              </div>
            </v-responsive>
          </div>
          <div class="mt-2">{{role.roleName}}</div>
        </template>
      </v-flex>
    </v-layout>
    <SelectWinnerDialog v-model="winnerDialog.show" :role="winnerDialog.role" :actors="winnerDialog.actors"></SelectWinnerDialog>
  </v-container>
</template>

<style>
.add-winner-button {
  background-color: #eeeff0;
  box-shadow: inset 0 1px 2px #dee0e2;
  border-radius: 4px;
  cursor: pointer;
}
.add-winner-button-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  white-space: nowrap;
}
</style>

<script>
import SelectWinnerDialog from '@/dialogs/SelectWinnerDialog'

export default {
  name: 'Winners',
  components: {
    SelectWinnerDialog
  },
  props: {
    winners: {
      type: Array,
      default: () => []
    },
    flexClass: {
      type: String,
      default: () => "sm12 md12 lg4"
    }
  },
  data() {
    return {
      winnerDialog: {
        show: false,
        title: null,
        actors: [],
        role: null
      }
    }
  },
  methods: {
    showWinnerDialog(role) {
      this.winnerDialog = {
        show: true,
        actors: role.picks.filter(pick => pick.roleState != 'rejected' && pick.roleState != 'addressed' && pick.roleState != 'added'),
        role: role
      }
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    }
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="800" @keydown.esc="showLogoDialog ? showLogoDialog = false : show" content-class="pa-0 project-dialog">
    <v-card color="#f6f8fa" class="pb-4">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-text class="pa-5">
        <h1 class="text-center my-4">{{$t('EditProjectDialog.editProject')}}</h1>
        <v-form ref="projectForm" class="pt-2" style="width: 60%; margin: auto;">
          <v-layout v-if="showClient"  row wrap class="mb-4">
            <v-flex xs10>
              <v-text-field v-model="client" :label="$t('AddProjectDialog.client')" :rules="[requiredFieldRule]" autofocus></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-avatar @click="showLogoDialog = true" size="48px" color="white" style="cursor: pointer; float: right;" class="elevation-2">
                <v-icon v-if="!logo" color="primary">add_a_photo</v-icon>
                <img v-else :src="logo" />
              </v-avatar>
              <div style="clear: both"></div>
            </v-flex>
          </v-layout>
          <v-text-field v-if="showBrand" v-model="brand" class="mb-4" :label="$t('AddProjectDialog.brand')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showCampaing" v-model="name" class="mb-4" :label="$t('AddProjectDialog.campaignName')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showAgency" v-model="agency" class="mb-4" :label="$t('AddProjectDialog.agency')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showDirector" v-model="director" class="mb-4" :label="$t('AddProjectDialog.director')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showPieceName" v-model="name" class="mb-4" :label="$t('AddProjectDialog.projectName')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showProducer" v-model="producer" class="mb-4" :label="$t('AddProjectDialog.producer')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showStation" v-model="station" class="mb-4" :label="$t('AddProjectDialog.tvStation')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showInterpret" v-model="interpret" class="mb-4" :label="$t('AddProjectDialog.interpret')" :rules="[requiredFieldRule]"></v-text-field>
          <v-text-field v-if="showPhotographer" v-model="photographer" class="mb-4" :label="$t('AddProjectDialog.photographer')" :rules="[requiredFieldRule]"></v-text-field>
          <v-textarea v-model="description" class="mb-4" :label="$t('AddProjectDialog.briefProjectDescription')" :rules="[requiredFieldRule]" :hint="$t('AddProjectDialog.briefProjectDescriptionHint')" persistent-hint></v-textarea>
        </v-form>
        <div class="text-center pt-2">
          <v-btn class="elevation-0 next" color="primary" @click="saveProject">{{$t('save')}}</v-btn>
        </div>
      </v-card-text>
    </v-card>
    <AddLogoDialog v-model="showLogoDialog" :save="addLogo" />
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.project-dialog .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 8px !important;
}
.project-dialog h1 {
  font-weight: 400;
  font-size: 32px;
}
#app .big-icon-btn {
  height: initial;
  padding: 0px;
}

#app .big-icon-btn .v-btn__content {
  display: initial;
  min-width: 120px;
}
#app .project-dialog .v-btn.next {
  margin: 28px auto 0px auto;
  padding: 14px 41px;
  height: 56px;
  font-size: 16px;
  display: block;
}
#app .project-dialog .v-btn.back {
  margin: 24px auto 0px auto;
  font-weight: 300;
  display: block;
}
#app .project-dialog .v-expansion-panel-header {
  padding: 0px;
}
#app .project-dialog .role-active .v-expansion-panel-header {
  background-color: #65bcff;
  border-radius: 3px;
}
#app .project-dialog .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
#app .project-dialog .role-header-text {
  float: left;
  line-height: 32px;
  font-size: 16px;
  margin-left: 0px;
}
#app .project-dialog .role-active .role-header-text {
  color: white;
}
#app .project-dialog .role-header-count {
  float: right;
  height: 32px;
  width: 32px;
  background-color: white;
  border-radius: 3px;
  text-align: center;
  font-size: 16px;
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.project-dialog .increment-button {
  border-radius: 3px !important; 
  float: right; 
  width: 32px; 
  height: 32px;
}
.project-dialog .v-btn--icon:before {
  border-radius: 3px !important; 
}
.project-dialog .card-header {
  height: 64px; 
  width: 100%; 
  line-height: 64px; 
  font-size: 16px; 
  text-align: center; 
  background-color: #65bcff; 
  color: white;
}
.project-dialog .v-list__tile {
  padding: 16px 4px;
  height: initial;
}
.project-dialog .v-list__tile__sub-title, .project-dialog .v-list__tile__title {
    white-space: initial;
    overflow: initial;
    text-overflow: initial;
    height: initial;
    line-height: initial;
}
.project-dialog .tick {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGFBMVEUAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABWNxwqAAAACHRSTlMACQo+P3Xh6xc6ieIAAAAwSURBVHgBYyAVMKLxWdiYUPnsLETxGdH4TGzMaOqZ2ZlhfIQIlI8QYcVnH9QmEgAAYuAAxx3ZfqYAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: center left;
  padding-left: 24px;
}
#app .package .transparent-checkbox,
#app .package .square-checkbox {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: #eeeff0;
  box-shadow: inset 0px 1px 5px #babcbe;
  display: flex;
  border-radius: 3px;
  float: left;
}
#app .package .square-checkbox i {
  margin: 4px !important;
}
#app .package .transparent-checkbox {
  background-color: initial;
  box-shadow: initial;
}
#app .package.checked .square-checkbox {
  background-color: #65bcff;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
#app .package.checked.disabled .square-checkbox {
  background-color: #c7cacc;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
.description-column .content {
  padding: 12px; 
  min-height: 56px; 
  position: relative;
}
.description-column .description-title {
  font-size: 16px; 
  line-height: 32px;
}
.description-column .description-subtitle {
  font-size: 16px; 
  line-height: 32px;
}
.description-column .description-hint {
  position: absolute; right: 12px; top: 50%; transform: translateY(-50%)
}
.description-column .sub-item:first-child {
  border-top: initial;
}
.description-column .sub-item {
  border-top: 1px solid rgba(0,0,0,0.07); 
  padding: 12px 0px; 
}
.description-column .sub-list {
  border-top: 1px solid rgba(0,0,0,0.07); 
  min-height: 56px; 
  padding: 0px 12px; 
}
.price-column .content {
  min-width: 150px; 
  height: 100%; 
  padding: 12px;
}
.price-column .content .price-tab {
  width: 100%; 
  height: 100%; 
  text-align: right; 
  display:table;
}
.price-column .content .price-tab .price {
  display: table-cell; 
  vertical-align: middle;
  font-size: 16px;
  font-weight: 500;
}
.checked .price-column .content .price-tab .price {
  color: black;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions, mapGetters } from 'vuex'
import AddLogoDialog from './AddLogoDialog'

export default {
  name: 'EditProjectDialog',
  components: {
    AddLogoDialog
  },
  props: {
    value: {
      type: Boolean,
      default: () => false
    }
  },
  data: () => {
    return {
      client: null,
      agency: null,
      name: null,
      brand: null,
      director: null,
      producer: null,
      station: null,
      interpret: null,
      photographer: null,
      showLogoDialog: false,
      type: null,
      selectedSubType: null,
      description: null,
      logo: null,
      requiredFieldRule: v => !!v || 'Vyžadované pole'
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    },
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
    showClient() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showBrand() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showCampaing() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showAgency() { return this.type == 'ad' || (this.selectedSubType && (this.selectedSubType.value == 'comercialPhoto' || this.selectedSubType.value == 'charityPhoto')); },
    showDirector() { return this.type == 'ad' || this.type == 'movie'; },
    showPieceName() { return this.type == 'movie' || (this.selectedSubType && this.selectedSubType.value == 'artPhoto'); },
    showProducer() { return this.type == 'movie'; },
    showStation() { return this.selectedSubType && this.selectedSubType.value == 'series'; },
    showInterpret() { return this.selectedSubType && this.selectedSubType.value == 'clip'; },
    showPhotographer() { return this.type == 'photo'; }
  },
  watch: {
    show(val) {
      this.$refs.projectForm.reset();
      if (val == true) {
        let project = this.getCurrentProject();
        this.logo = project.logo;
        this.client = project.client;
        this.name = project.projectName;
        this.agency = project.agency;
        this.brand = project.brand;
        this.director = project.director;
        this.producer = project.producer;
        this.station = project.station;
        this.interpret = project.interpret;
        this.photographer = project.photographer;
        this.type = project.projectType;
        this.selectedSubType = project.projectSubType ? {value: project.projectSubType} : null;
        if (project.notes && project.notes.length)
          this.description = project.notes[0].note.text;
      }
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    ...mapActions(['updateCurrentProject']),
    addLogo(logo) {
      this.logo = logo;
    },
    async saveProject() {
      let notes = [];
      if (this.description) {
        notes.push({
          type: 'text',
          note: {
            name: 'O projektu',
            text: this.description
          },
          share: ['Všechny role']
        });
      }

      let project = this.getCurrentProject();
      project.logo = this.logo;
      project.client = this.client;
      project.projectName = this.name;
      project.agency = this.agency;
      project.brand = this.brand;
      project.director = this.director;
      project.producer = this.producer;
      project.station = this.station;
      project.interpret = this.interpret;
      project.photographer = this.photographer;
      project.projectType = this.type;
      project.projectSubType  = this.selectedSubType ? this.selectedSubType.value : null;
      this.updateCurrentProject(project);
      await api.updateProject({
        projectId: project.projectId,
        logo: this.logo,
        client: this.client,
        projectName: this.name,
        notes: notes,
        agency: this.agency,
        brand: this.brand,
        director: this.director,
        producer: this.producer,
        station: this.station,
        interpret: this.interpret,
        photographer: this.photographer,
        projectType: this.type,
        projectSubType: this.selectedSubType.value
      });

      this.show = false;
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    formatCurrency(amount) {
      const formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
      });

      return formatter.format(amount);
    }
  }
}
</script>
<template>
  <div class="fill-height">
    <v-app-bar app color="white" fixed clipped-left class="elevation-0 main-toolbar">
      <v-toolbar-title><a href="https://aircasting.cz/productions" target="_blank"><img src="https://prod.aircasting.cz/logo.svg" style="margin: 8px 24px 0px 0px;" height="32" /></a></v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <div v-if="loading" style="height: 100%">
      <v-container fill-height>
        <v-layout row wrap align-center>
          <v-flex xs12 sm12 md12 class="text-center">
            <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
    <v-container v-else fluid grid-list-md  class="py-3 px-5 shared" style="max-width: 100%">
      <h1 class="mb-5">Administrace projektů</h1>
      <div>
        <v-data-table
          :headers="[
            { text: 'Produkce', value: 'productionName', width: 200 },
            { text: 'Název', value: 'projectName', width: 350},
            { text: 'Klient', value: 'client', width: 150 },
            { text: 'Director', value: 'director', width: 150 },
            { text: 'Agentura', value: 'agency', width: 150},
            { text: 'Vytvořeno', value: 'createdAt', width: 150},
            { text: 'Stav', value: 'projectState', width: 200 },
            { text: 'Pocet roli', value: 'roleCount' },
            { text: 'Pocet udalosti', value: 'eventCount' },
            { text: 'Budget', value: 'budget' },
            { text: '', value: 'issues' },
            { text: 'Akce', value: 'actions', sortable: false, align: 'end' }
          ]"
          :items="projects"
          :loading="loading"
          :loading-text="loading ? 'Načítám data...' : ''"
          :no-data-text="loading ? '' : 'Žádné projekty'"
          :footer-props="{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': 'Počet položek na stránku',
            'show-current-page': true,
            'show-first-last-page': true
          }"
          :search="search"
          :options="options"
          :xserver-items-length="totalProjects"
          class="elevation-1"
        >
        <template v-slot:top>
          <v-text-field v-model="search" label="Vyhledat" class="mx-4"></v-text-field>
        </template>
          <template v-slot:item.productionName="{ item }">
            <span>{{ item.productionName }}</span><span v-if="item.productionEmail"> ({{ item.productionEmail }})</span>
          </template>
          <template v-slot:item.projectName="{ item }">
            <a :href="'https://public.aircasting.cz/project/'+item.projectId" target="_blank">{{ item.projectName }}</a>
          </template>
          <template v-slot:item.projectState="{ item }">
            <v-select solo flat v-model="item.projectState" @change="updateProjectState(item, $event)" :items="getProjectStateItems(item.projectState)" menu-props="auto" hide-details single-line>
              <template v-slot:selection="{ item }">
                <v-chip small :color="(item === 'in-review') ? 'error' : ((item === 'approved') ? 'success' : (item === 'canceled' ? 'orange' : null))">{{ item }}</v-chip>
              </template>
            </v-select>
          </template>
          <template v-slot:item.eventCount="{ item }">
            <v-chip v-if="item.eventCount == 'in-review'" @click="projectEvents = {show: true, project: item}" small color="error">{{ item.eventCount }}</v-chip>
            <span v-else :class="item.eventInReview ? 'error--text font-weight-bold ml-1' : ''">{{ item.eventCount }}</span>
          </template>
          <template v-slot:item.createdAt="{ item }">
            <span>{{ timestampeToDate(item.createdAt) }}</span>
          </template>
          <template v-slot:item.budget="{ item }">
            <span>{{ formatCurrency(item.budget) }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn x-small icon @click="projectEvents = {show: true, project: item}" :color="item.eventInReview ? 'error' : 'primary'" v-if="item.simpleEvents && item.simpleEvents.length > 0"><v-icon>mdi-calendar-range</v-icon></v-btn>
            <v-btn x-small icon color="primary" @click="roleDialog = {show: true, project: item}"><v-icon>mdi-account-group</v-icon></v-btn>
            <v-btn x-small icon color="primary" @click="projectDetail = {show: true, project: item}"><v-icon>mdi-code-braces</v-icon></v-btn>
            <v-btn v-if="allowDelete" x-small icon color="error" @click="deleteDialog = {show: true, project: item}"><v-icon>mdi-delete</v-icon></v-btn>
          </template>
        </v-data-table>
      </div>
      <v-dialog v-model="projectDetail.show" max-width="800px" color="white">
        <v-card v-if="projectDetail && projectDetail.project">
          <v-card-title>
            <span class="headline">Detail projektu {{ projectDetail.project.projectName }}</span>
          </v-card-title>
          <v-card-text style="max-width: 800px; max-height: 80vh; overflow: auto">
            <pre>{{ JSON.stringify(projectDetail.project, undefined, 2) }}</pre>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="projectDetail.show = false">OK</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="projectEvents.show" max-width="800px">
        <v-card v-if="projectEvents && projectEvents.project">
          <v-card-title>
            <span class="headline">Udalosti projektu {{ projectEvents.project.projectName }}</span>
          </v-card-title>
          <v-card-text>
            <v-data-table :headers="[{ text: 'Event Type', value: 'eventType' }, { text: 'Event Date', value: 'eventDate' }, { text: 'Event State', value: 'eventState' }]" :items="projectEvents.project.simpleEvents" class="elevation-1">
              <template v-slot:item.eventType="{ item }">
                <a :href="'https://public.aircasting.cz/project/'+projectEvents.project.projectId+'/event/'+item.eventId" target="_blank">{{ item.eventType }}</a>
              </template>
              <template v-slot:item.eventDate="{ item }">
                <span>{{ new Date(item.eventDate).toLocaleDateString() }}</span>
              </template>
              <template v-slot:item.eventState="{ item }">
                <v-select solo flat v-model="item.eventState" @change="updateEventState(projectEvents.project, item, $event)" :items="['in-review', 'approved', 'declined']" menu-props="auto" hide-details single-line>
                  <template v-slot:selection="{ item }">
                    <v-chip small :color="(item === 'in-review') ? 'error' : ((item === 'approved') ? 'success' : (item === 'canceled' ? 'yellow' : null))">{{ item }}</v-chip>
                  </template>
                </v-select>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="projectEvents.show = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="roleDialog.show" max-width="800px">
        <v-card v-if="roleDialog && roleDialog.project">
          <v-card-title class="headline">Roles in {{ roleDialog.project.projectName }}</v-card-title>
          <v-card-text>
            <v-data-table :headers="[{ text: 'Role Name', value: 'roleName' }, { text: 'Description', value: 'description' }, { text: 'Payments', value: 'payments' }, { text: ' ', value: 'actions' }]"
                          :items="roleDialog.project.roles"
                          class="elevation-1">
              <template v-slot:item.roleName="{ item }">
                <span>{{ item.roleName }}</span>
              </template>
              <template v-slot:item.description="{ item }">
                <span v-if="item.description && item.description.length">{{ item.description.substring(0, 120) }}</span>
              </template>
              <template v-slot:item.payments="{ item }">
                <span>{{ formatCurrency(getBudget(item)) }}</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <span><v-btn elevation="0" small color="primary" @click="editRole(item, roleDialog.project)"><v-icon left>mdi-pencil</v-icon> Upravit</v-btn></span>
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="roleDialog.show = false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog.show" max-width="500px">
        <v-card v-if="deleteDialog && deleteDialog.project">
          <v-card-title class="headline">Smazat projekt {{ deleteDialog.project.projectName }}?</v-card-title>
          <v-card-text class="text-h6 font-weight-light">
            Opravdu chcete smazat tento projekt? Jedna se o nenavratnou operaci, pri ktere budou smazany vsechny role, udalosti a dotazy projektu!
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="deleteDialog.show = false">Storno</v-btn>
            <v-btn color="red darken-1" text @click="deleteProject(deleteDialog.project)">Smazat</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <EditRoleDialog v-model="editRoleDialog.show" :role="editRoleDialog.role"></EditRoleDialog>
    </v-container>
  </div>
  </template>
  
  <style>
  h1 {
    font-weight: 300;
  }
  </style>
  
  <script>
  import { API } from "aws-amplify";
  import EditRoleDialog from '@/dialogs/EditRoleDialog'
  import { mapActions } from 'vuex'
  
  export default {
    name: 'Productions',
    components: {
      EditRoleDialog,
    },
    props: {
      id: {
        type: String,
        default: () => null
      }
    },
    data() {
      return {
        loading: true,
        projects: [],
        options: {
          page: 1,
          itemsPerPage: 20,
          sortBy: ['createdAt'],
          sortDesc: [true]
        },
        projectDetail: {
          show: false,
          project: null
        },
        projectEvents: {
          show: false,
          project: null
        },
        roleDialog: {
          show: false,
          project: null
        },
        deleteDialog: {
          show: false,
          project: null
        },
        editRoleDialog: {
          show: false,
          role: null
        },
        search: null,
        allowDelete: false
      }
    },
    computed: {
      totalProjects() {
        return this.projects.length;
      },
    },
    async mounted() {
      this.loading = true;
      let query = this.$route.query;
      if (query && query.allowDelete) {
        this.allowDelete = true;
      }
      try {
        this.projects = await API.get("productionExt", "/projects");
        let totalBudget = 0; 
        this.projects = this.projects.map(p => {
          p.eventInReview = p.simpleEvents && p.simpleEvents.find(e => e.eventState === 'in-review');
          p.roleCount = p.roles ? p.roles.length : 0;
          p.eventCount = p.eventInReview ? 'in-review' : (p.simpleEvents ? p.simpleEvents.length : 0);
          p.projectState = p.projectState || 'done';
          p.budget = 0;
          p.issues = [];
          if (p.roles && p.roles.length) {
            for (let role of p.roles) {
              if ((!role.roleName || !role.roleName.length) && !p.issues.includes('roleName'))
                p.issues.push('roleName');

              if ((!role.description || !role.description.length) && !p.issues.includes('description'))
                p.issues.push('description');

              if (!role.payments || !role.payments.length) {
                if (!p.issues.includes('budget'))
                  p.issues.push('budget');
                continue;
              }

              p.budget += this.getBudget(role, p);
              if (p.projectState !== 'canceled' && p.projectState !== 'declined')
                totalBudget += p.budget;
            }
          }
          
          return p;
        });

        console.log('Total budget: ' + totalBudget);
      }
      catch (err) {
        console.log(err);
      }

      this.loading = false;
    },
    methods: {
      ...mapActions(['selectProject']),
      timestampeToDate(timestamp) {
        const date = new Date(timestamp);
        return date.toLocaleDateString('cs-CZ', { year: 'numeric', month: '2-digit', day: '2-digit' });
      },
      async deleteProject(project) {
        this.deleteDialog.show = false;
        this.loading = true;

        try {
          await API.del("productionExt", "/projects/" + project.projectId);
          this.projects = this.projects.filter(p => p.projectId !== project.projectId);
        }
        catch (err) {
          console.log(err);
        }

        this.loading = false;
      },
      async updateProjectState(project, state) {
        if (!project)
          return;

        this.loading = true;

        try {
          await API.put("productionExt", "/projects/" + project.projectId + "/state", { body: { state: state, productionId: project.productionId } });
          project.projectState = state;
        }
        catch (err) {
          console.log(err);
        }

        this.loading = false;
      },
      async updateEventState(project, event, state) {
        this.loading = true;

        try {
          await API.put("productionExt", "/projects/" + project.projectId + "/events/" + event.eventId + "/state", { body: { state: state } });
          event.eventState = state;
          project.eventInReview = project.simpleEvents && project.simpleEvents.find(e => e.eventState === 'in-review');
          project.eventCount = project.eventInReview ? 'in-review' : (project.simpleEvents ? project.simpleEvents.length : 0);
        }
        catch (err) {
          console.log(err);
        }

        this.loading = false;
      },
      getProjectStateItems(state) {
        let items = ['in-review', 'approved', 'declined', 'canceled']
        if (items.includes(state))
          return items;

        items.push(state);
        return items;
      },
      shootings(project) {
        if (!project || !project.simpleEvents || !project.simpleEvents.length)
          return 0;

        let shootings = 0;
        for (let event of project.simpleEvents) {
          if (event.eventType != 'shooting')
            continue;
          
          /*if (event.eventRoles.findIndex(r => r.roleId === role.roleId) < 0)
            continue;*/

          shootings++;                
        }

        return shootings;
      },
      getBudget(role, project) {
        if (!role || !role.payments || !role.payments.length)
          return 0;
          
        let budget = 0;

        for (let payment of role.payments)
          budget += (payment.salary * (payment.type == 'dailySalary' ? Math.max(1,this.shootings(project)) : 1));

        return budget;
      },
      formatCurrency(amount) {
        const formatter = new Intl.NumberFormat('cs-CZ', {
          style: 'currency',
          currency: 'CZK',
          minimumFractionDigits: 0
        });

        let result = formatter.format(amount);
        return result;
      },
      editRole(role, project) {
        this.$store.state.project = project;

        this.editRoleDialog.role = role;
        this.editRoleDialog.show = true;
      }
    }
  }
  </script>
<template>
  <v-dialog v-model="show" v-if="role" max-width="90%" @keydown.esc="$store.state.dialogs.profileDetail.show ? $store.state.dialogs.profileDetail.show = false : show = false" content-class="pa-0">
    <v-card color="#f6f8fa" min-height="600">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">
        <span>{{$t('SelectWinnerDialog.selectWinner')}} {{role.roleName.toLowerCase()}}</span>
      </v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md class="pt-3 pl-0 pr-0">
          <v-layout row wrap style="min-width: 100%">
            <div v-if="!actors.length" class="text-center" style="width: 100%; height: 450px; line-height: 450px;">{{$t('SelectWinnerDialog.noCandidatesYet')}}</div>
            <v-flex v-else v-for="(actor, i) in actors" xs12 md3 lg2 xl2 class="pa-2" :key="i">
              <MiniProfile bigCheck @showProfile="showProfileDetail" @check="!actor.winner ? checkItem(i) : actor.winner" :checked="actor.winner || isItemChecked(i)" :profile="actor" :showMultiselect="false" :showControls="false" :showBottomControls="false" :state="{state: actor.winner ? 'winner' : actor.roleState, sex: actor.sex}"></MiniProfile>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-alert :value="checkedItems.length" dark color="white" class="text-center bottom_alert abs black--text ma-0">
        <v-btn @click="showConfirm = true" :loading="saving" large style="height: 56px; font-size: 18px; font-weight: normal;" class="elevation-0" color="primary">{{$t('SelectWinnerDialog.selectWinnerCta')}}</v-btn>
      </v-alert>
    </v-card>
    <v-dialog persistent v-model="showConfirm" max-width="800px" content-class="pa-0">
      <v-card color="#f6f8fa" class="pa-3">
        <v-btn absolute fab top right small text class="dialog_close" @click="showConfirm=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
        <v-card-text>
          <v-img src="assets/contract.svg" width="176px" class="mx-auto mt-3 mb-4"></v-img>
          <p class="mt-3"><b>{{$t('SelectWinnerDialog.text1')}}</b></p>
          <p><i>{{$t('SelectWinnerDialog.text2')}}</i></p>
          <v-btn class="elevation-0 mx-auto mt-5 d-block" color="primary" @click="selectWinners">{{$t('SelectWinnerDialog.selectWinners')}}</v-btn>
          <v-btn class="elevation-0 mx-auto d-block" @click="showConfirm = false">{{$t('cancel')}}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<style>
.v-alert.bottom_alert.abs {
  position: absolute; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 20000;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
  width: 100%;
}

.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
  right: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions, mapGetters } from 'vuex'
import MiniProfile from '@/widgets/MiniProfile'

export default {
  name: 'ProfileMultiView',
  props: {
    actors: {
      type: Array,
      default: () => []
    },
    role: {
      type: Object,
      default: () => {}
    },
    state: {
      type: String,
      default: () => null
    },
    value: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    MiniProfile
  },
  data: () => {
    return {
      saving: false,
      checkedItems: [],
      showConfirm: false
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { 
        this.$emit('input', value); 
      }
    },
    project: function() {
      return this.getCurrentProject();
    },
    itemsSelected: function() {
      return this.checkedItems.length;
    }
  },
  watch: {
    show() {
      this.checkedItems = [];
    }
  },
  methods: {
    ...mapActions(['showProfileDetail', 'showRoleDialog']),
    ...mapGetters(['getCurrentProject']),
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    checkItem: function(i) {
      let index = this.checkedItems.findIndex(c => c == i);
      if (index > -1)
        this.checkedItems.splice(index, 1);
      else
        this.checkedItems.unshift(i);
    },
    isItemChecked: function(i) {
      return this.checkedItems.findIndex(c => c == i) > -1;
    },
    checkAll: function() {
      if (this.checkedItems.length > 0)
        this.checkedItems = [];
      else {
        for (let i in this.actors)
          this.checkedItems.push(i);
      }
    },
    async selectWinners() {
      this.saving = true;
      this.showConfirm = false;
      for (let i in this.actors) {
        if (!this.checkedItems.includes(parseInt(i)))
          continue;

        let actor = this.actors[i];
        actor.winner = true;
        await api.setWinner(this.role, actor.userId, true);
        //window.location.reload();
      }
      this.saving = false;
      this.show = false;
    },
  }
}
</script>
<template>
  <div class="elevation-0 pa-0" id="chatConatiner" style="height: 100%">
    <Chat :id="id" :userName="userName" :getMessages="getMessages" :sendMessage="sendMessage" :shown="shown"></Chat>
  </div>
</template>

<style>
</style>

<script>
import Chat from '@/widgets/Chat'

export default {
  name: 'SharedChat',
  components: {
    Chat
  },
  props: {
    id: {
      type: String,
      default: () => null
    },
    userName: {
      type: String,
      default: () => null
    },
    getMessages: {
      type: Function,
      default: () => []
    },
    sendMessage: {
      type: Function,
      default: () => []
    },
    shown: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
    }
  }
}
</script>
<template>
<div style="height: 100%">
  <template v-if="!project || !questions || !questions.length">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <img src="assets/empty-roles.svg" width="200" class="mx-auto" />
          <div class="mb-5 mt-5 mx-auto" style="color: #131d2d; font-size: 26px; max-width: 270px;">{{$t('Messages.emptyHeader')}}</div>
          <v-btn large style="height: 56px; font-size: 18px; font-weight: normal;" class="elevation-0" color="primary" @click="$store.state.dialogs.sendMessage = { show: true }">{{$t('Messages.emptyButton')}}</v-btn>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  <v-container v-else fluid grid-list-md class="pt-0 pr-0 pb-0 pl-0 events" style="max-width: 100%; height: 100%;">
    <v-layout row style="height: 100%;">
      <v-flex shrink pa-0 v-if="projectDrawer" style="width: 300px;">
        <v-navigation-drawer v-model="projectDrawer" left class="inner-drawer pt-1" width="296px" style="margin-top: 64px;">
          <v-list two-line>
            <v-subheader>{{$t('Messages.questions')}}</v-subheader>
            <template v-for="(question, i) in questions">
              <div :key="i">
                <v-divider></v-divider>
                <v-list-item @click="selectedMessage = i" color="red">
                  <v-list-item-action>
                    <v-badge color="primary" right overlap>
                      <template v-slot:badge>
                        <span style="font-size: 10px">{{question.answers.length}}</span>
                      </template>
                      <v-avatar size="38">
                        <v-icon class="secondary white--text">{{question.answers && question.answers.length ? 'far fa-comments' : 'far fa-comment'}}</v-icon>
                      </v-avatar>
                    </v-badge>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{timestampToString(question.createdAt)}}</v-list-item-title>
                    <v-list-item-subtitle >{{question.message}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>
            </template>
          </v-list>
        </v-navigation-drawer>
      </v-flex>
      <v-flex grow pa-0 style="width: 1px;" v-if="selectedQuestion">
        <section class="px-4 pt-4 pb-2" style="background-color: white; border-bottom: 1px solid #e9ebee; width: 100%;">
          <h1 class="mb-3 ml-3" style="font-size: 28px;">
            <span>{{timestampToString(selectedQuestion.createdAt)}}</span>
          </h1>
          <div class="pl-3 pb-2">
            <div style="font-size: 16px;">{{selectedQuestion.message}}</div>
            <div class="mt-3 text-right" style="font-size: 14px;">
              <v-chip class="mr-2" label v-for="(answer, a) in selectedQuestion.availableAnswers" :key="a">{{answer}}</v-chip>
            </div>
          </div>
        </section>
        <v-layout class="row wrap px-4">
          <v-flex sm12 md6 lg4 xl3 v-for="(role, r) in roles" :key="r">
            <v-card class="pa-2 ma-2">
              <v-card-title primary-title class="pa-2">
                <div class="headline">
                  <div v-if="role.answered.length" class="d-inline-block pt-2 doughnut" style="width: 32px; height: 32px;">
                    <DoughnutChart :data="getRoleStats(role)" :index="r" :labels="selectedQuestion.availableAnswers" :colors="['#9e9e9e', '#3f51b5', '#009688', '#4CAF50', '#ffeb3b', '#cddc39', '#9c27b0', '#F44336']"></DoughnutChart>
                  </div>
                  <div class="d-inline-block ml-2">{{role.roleName}}</div>
                </div>
              </v-card-title>
              <v-card-text class="pa-0" style="height: 400px; overflow-y: auto;">
                <v-list two-line v-if="role.answered.length">
                  <template v-for="(pick, i) in role.answered">
                    <div :key="i">
                      <v-list-item @click="showProfileDetail(pick)">
                        <v-list-item-avatar>
                          <v-avatar size="36px">
                            <img :src="getImageUrl(pick, pick.mainPhoto, 36)" />
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{ pick.name }}</v-list-item-title>
                          <v-list-item-subtitle style="font-size: 1em"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action><v-chip label small class="white--text" :color="getAnswerColor(pick.answer)">{{pick.answer || 'Neodpověděl' }}</v-chip></v-list-item-action>
                      </v-list-item>
                      <v-divider inset v-if="i < role.answered.length - 1"></v-divider>
                    </div>
                  </template>
                </v-list>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-btn v-if="!projectDrawer" fixed small fab bottom left :style="$vuetify.breakpoint.smAndDown ? `bottom: 67px !important`:`bottom: 13px !important`"  :color="($vuetify.breakpoint.smAndDown ? `primary`: `grey lighten-3`)" @click="projectDrawer = true"><v-icon :color="($vuetify.breakpoint.smAndDown ? `white`: `black`)">fa-list-ul</v-icon></v-btn>
  </v-container>
  <v-speed-dial v-model="fab" fixed bottom right direction="top" :style="$vuetify.breakpoint.smAndDown ? `bottom: 67px`:``">
    <template v-slot:activator>
      <v-btn v-on="on" fab :small="$vuetify.breakpoint.smAndDown" color="red darken-1"><v-icon color="white">add</v-icon></v-btn>
    </template>
    <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" fab dark small color="orange darken-1" class="mb-2" @click="newProject">
          <v-icon>mdi-notebook</v-icon>
        </v-btn>
      </template>
      <span>{{$t('Events.addProject')}}</span>
    </v-tooltip>
    <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" fab dark small color="green" @click="newRole">
          <v-icon>fa-users</v-icon>
        </v-btn>
      </template>
      <span>{{$t('Events.addRole')}}</span>
    </v-tooltip>
    <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" fab dark small color="primary" @click="newEvent">
          <v-icon>mdi-calendar-clock</v-icon>
        </v-btn>
      </template>
      <span>{{$t('Events.addEvent')}}</span>
    </v-tooltip>
    <v-tooltip left :value="speedDialTooltip" :close-delay="10000000">
      <template v-slot:activator="{on}">
        <v-btn v-on="on" fab dark small color="red darken-1" class="mb-2" @click="$store.state.dialogs.sendMessage = { show: true }">
          <v-icon style="line-height: 40px">fa-comment</v-icon>
        </v-btn>
      </template>
      <span>{{$t('Events.addQuestion')}}</span>
    </v-tooltip>
  </v-speed-dial>
</div>
</template>
<style>
h1 {
  font-weight: 300;
}
.v-list--two-line .v-list__tile {
  height: 54px !important;
}
.events .v-btn--floating .v-btn__content :not(:only-child):first-child, .projects .v-btn--floating .v-btn__content :not(:only-child):last-child {
  top: -12px;
}
</style>
<script>
import { mapActions, mapGetters } from 'vuex'
import DoughnutChart from '@/widgets/DoughnutChart'

export default {
  name: 'Messages',
  components: {
    DoughnutChart
  },
  data() {
    return {
      projectDrawer: !this.$vuetify.breakpoint.smAndDown,
      speedDialTooltip: false,
      fab: false,
      rolesSelected: true,
      selectedMessage: 0
    }
  },
  computed: {
    project() {
      return this.getCurrentProject();        
    },
    questions() {
      let questions = this.project ? this.project.questions : [];
      return questions ? questions : [];
    },
    selectedQuestion() {
      if (!this.questions || this.questions.length < this.selectedMessage || this.selectedMessage < 0)
        return null;
      
      return this.questions[this.selectedMessage];
    },
    roles() {
      if (!this.project || !this.project.roles || !this.selectedQuestion)
        return [];

      let roles = [];

      let project = this.project;
      for (let role of project.roles) {
        let picks = [];
        for (let pick of role.picks) {
          let answer = this.selectedQuestion.answers.find(answer => answer.userId === pick.userId);
          if (!answer)
            continue;

          pick.answer = answer.answer;
          picks.push(pick);
        }
        if (!picks.length)
          continue;
        
        role.answered = picks;
        roles.push(role);
      }

      return roles;
    }
  },
  watch: {
    fab(val) {
      setTimeout(() => { this.speedDialTooltip = val }, 300);
    },
  },
  methods: {
    ...mapActions(['showProfileDetail']),
    ...mapGetters(['getCurrentProject', 'getCurrentEvent', 'getRole']),
    newEvent() {
      this.$store.state.dialogs.newEvent.show = true;
    },
    newRole() {
      this.$store.state.dialogs.roleDialog.profile = null;
      this.$store.state.dialogs.roleDialog.show = true;
    },
    newProject() {
      this.$store.state.dialogs.newProject.show = true;
    },
    selectRoles(value) {
      this.rolesSelected = value;
    },
    timestampToString(timestamp) {
      let date = new Date(timestamp);
      return date.getDate() + '. ' + date.getMonth() + '. ' + date.getFullYear() + ' ' + date.getHours() + ':' + date.getMinutes();
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    getAnswerColor(answer) {
      let availableAnswers = this.selectedQuestion.availableAnswers;
      let i = availableAnswers.findIndex(a => answer && (a === answer));

      switch (i) {
        case -1: return 'grey';
        case 0: return 'indigo';
        case 1: return 'teal';
        case 2: return 'green';
        case 3: return 'yellow';
        case 4: return 'lime';
        case 5: return 'purple';
        case 6: return 'pink';
        default: return 'red'
      }
    },
    getRoleStats(role) {
      let result = [];
      let availableAnswers = ['Neodpověděli', ...this.selectedQuestion.availableAnswers];
      // eslint-disable-next-line no-unused-vars
      for (let i in availableAnswers) {
        result.push(0);
      }
      for (let pick of role.answered) {
        let i = availableAnswers.findIndex(answer => pick.answer && (answer === pick.answer));
        if (i < 0)
          i = 0;

        if (i >= 0 && i < availableAnswers.length)
          result[i]++;
      }
      return result;
    },
  }
}
</script>

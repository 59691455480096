export default {
  ////////////////////////////////////////////
  // Obory
  selectedProfessions: {
    name: 'Obor',
    children: {
      actor: 'Herec',
      model: 'Model',
      stuntman: 'Kaskadér',
      artiste: 'Artista',
      dancer: 'Tanečník',
      singer: 'Zpěvák',
      moderator: 'Moderátor',
      voiceActor: 'Dabér',
      speaker: 'Speaker',
      sportsman: 'Sportovec',
      extra: 'Komparzista',
    }
  },
  ////////////////////////////////////////////
  // Zakladni info
  basicInfo: {
    name: 'Základní informace',
    children: {
      motherTongue: {
        name: 'Mateřský jazyk',
        children: {
          cs: 'čeština',
          sk: 'slovenština',
          ru: 'ruština',
          en: 'angličtina',
          it: 'italština',
          uk: 'ukrajinština'
        }
      },
      foreignLanguages: {
        name: 'Jazykové dovednosti',
        children: {
          en: 'angličtina',
          cs: 'čeština',
          de: 'němčina',
          sk: 'slovenština',
          ru: 'ruština',
          es: 'španělština',
          fr: 'francouzština',
          pl: 'polština',
          it: 'italština',
          uk: 'ukrajinština'
        }
      },
      driverLicenses: {
        name: 'Řídí',
        children: {
          car: 'Auto',
          motorbike: 'Motorka',
          moped: 'Moped',
          truck: 'Kamion',
          bus: 'Autobus',
          tractor: 'Traktor',
          plane: 'Letadlo'
        }
      },
      etnicity: {
        name: 'Etnikum',
        children: {
          arabian: 'Arabský',
          asian: 'Asijský',
          white: 'Bělošský',
          whiteSouthern: 'Bělošský jižanský',
          whiteNorthern: 'Bělošský severský',
          black: 'Černošský',
          euroasian: 'Euroasijský',
          hispanic: 'Hispánský',
          indian: 'Indiánský',
          ind: 'Indický',
          mulato: 'Mulato',
          gipsy: 'Romský'
        }        
      },
      handicap: {
        name: 'Zdravotní omezení',
        children: {
          no: 'Žádné',
          eyes: 'Zrakové',
          ears: 'Sluchové',
          movement: 'Pohybové',
          other: 'Jiné'
        }
      },
      preferredJobs: {
        name: 'Preferované příležitosti',
        children: {
          dabing: 'Dabing',
          theater: 'Divadlo',
          movies: 'Film',
          photos: 'Focení',
          improvisation: 'Improvizace',
          musical: 'Muzikál',
          radio: 'Radio',
          ads: 'Reklama',
          studentsFilm: 'Studentský film',
          videoclip: 'Videoklip',
          voiceover: 'Voiceover',
          hostesing: 'Hostesing'
        }    
      }
    }
  },
  ////////////////////////////////////////////
  // Vzhled
  appearance: {
    name: 'Vzhled',
    children: {
      figure: {
        name: 'Postava',
        children: {
          skinny: 'Štíhlá',
          athletic: 'Atletická',
          normal: 'Normál',
          plus: 'Plus',
          muscle: 'Svalnatá'
        }
      },
      hair: {
        name: 'Vlasy',
        children: {
          hairLength: {
            name: 'Délka vlasů',
            children: {
              extraShort: 'Extrémně krátké',
              short: 'Krátké',
              midShort:	'Středně dlouhé',
              long: 'Dlouhé',
              extraLong: 'Extra dlouhé',
              bald: 'Pleš'
            }
          },
          hairCut: {
            name: 'Střih',
            children: {
              classic: 'Klasický',
              mikado: 'Mikádo',
              bangs: 'Ofina',
              mullet: 'Mullet',
              asymetric: 'Asymetrický',
              sideShaved: 'S vyholenými stranami',
              undercut: 'Podholený',
              layered: 'Sestříhaný',
              crewCut: 'Na ježka',
              mohawk: 'Číro',
              topKnot: 'Pánský drdol',
              fohawk: 'falešné číro'
            }
          },
          hairColorNatural: {
            name: 'Barva vlasů',
            children: {
              lightBlond: 'Blond světlá',
              darkBlond: 'Blond tmavá',
              black: 'Černá',
              lightBrown: 'Hnědá světlá',
              darkBrown: 'Hnědá tmavá',
              grey: 'Šedivá',
              red: 'Zrzavá',
              other: 'Jiná'
            }
          },
          hairColorDyed: {
            name: 'Barvení',
            children: {
              red: 'Červená',
              darkRed: 'Tmavě červená',
              purple: 'Fialová',
              copper: 'Měděná',
              blue: 'Modrá',
              orange: 'Oranžová',
              pink: 'Růžová',
              grey: 'Šedá',
              cyan: 'Tyrkysová',
              green: 'Zelená',
              yellow: 'Žlutá',
              bleached: 'Odbarvené vlasy'
            }
          },
          hairType: {
            name: 'Typ vlasů',
            children: {
              dreadlocks: 'Dredy',
              rastaBraids: 'Copánky',
              ponytails: 'Copánky',
              wavy: 'Vlnité',
              curly: 'Kudrnaté',
              straight: 'Rovné',
              afro: 'Afro',
              wig: 'Paruka'
            }    
          },
        }
      },
      tattoo: {
        name: 'Tetování',
        children: {
          yes: 'Ano',
          no: 'Ne',
          tattooPlacements: {
            name: 'Umístění tetování',
            children: {
              arms: 'Ruce',
              legs: 'Nohy',
              face: 'Obličej',
              belly: 'Břicho',
              back: 'Záda',
              neck: 'Krk',
              chest: 'Hruď',
              head: 'Hlava'
            }
          },
        }
      },
      eyeColor: {
        name: 'Barva očí',
        children: {
          black: 'Černá',
          brown: 'Hnědá',
          blue: 'Modrá',
          grey: 'Šedá',
          green: 'Zelená',
          yellow: 'Žlutá'
        }    
      },
      teethColor: {
        name: 'Zuby',
        children: {
          white: 'Zářivě bílé',
          normal: 'Normální',
          pigmentSpots: 'S pigmentovými skvrnami',
          yellow: 'Žluté',
          teethDefect: {
            name: 'Vady chrupu',
            children: {
              fixedBraces: 'Fixní rovnátka',
              missingTeeth: 'Chybějíci zuby',
              gaps: 'Chrup s mezerami',
              crooked: 'Křivé zuby',
              overbite: 'Předkus',
              underbite: 'Podkus',
              bruisedTeeth: 'Zkažené zuby',
              fake: 'Umělé zuby',
              implant: 'Barevný implantát'
            }
          },
        }    
      },
      beard: {
        name: 'Vousy',
        children: {
          yes: 'Ano',
          no: 'Ne',
          beardColor: {
            name: 'Barva vousů',
            children: {
              black: 'Černé',
              brown: 'Hnědé',
              blond: 'Blond',
              grey: 'Šedivé',
              red: 'Zrzavé'
            }    
          },
          beardType: {
            name: 'Úprava vousů',
            children: {
              stubble: 'Strniště',
              beard: 'Brada',
              mustacheChinStrap: 'Knír a bradka',
              ovalBeard: 'Ovál',
              musketeerBeard: 'Muška',
              muttonChops: 'Kotlety',
              sideburns: 'Licousy',
              emperorBeard: 'Císař',
              horseshoeMustache: 'Podkova',
              goatee: 'Bradka',
              chinstrap: 'Proužek na bradě',
              mustache: 'Knír',
              shortBeard: 'Kratší plnovous',
              longBeard: 'Dlouhý plnovous'
            }
          },
        }
      },
      piercing: {
        name: 'Piercing',
        children: {
          yes: 'Ano',
          no: 'Ne',
          piercingPlacements: {
            name: 'Umístění piercingu',
            children: {
              chin: 'Brada',
              tongue: 'Jazyk',
              nose: 'Nos',
              lips: 'Rty',
              eyebrow: 'Obočí',
              ears: 'Uši',
              earTunnels: 'Tunely v uších',
              face: 'Tváře',
              clavicle: 'Klíční kosti',
              teeth: 'Zuby',
              head: 'Čelo / Hlava',
              belly: 'Pupík',
            }
          },
        }
      },
      size: {
        name: 'Konfekční velikost',
        children: {
          XSX: 'XXS',
          XS: 'XS',
          S: 'S',
          M: 'M',
          L: 'L',
          XL: 'XL',
          XXL: 'XXL',
          XXXL: 'Větší než XXL'
        }
      },
    }
  },
  ////////////////////////////////////////////
  // Zkusenosti
  experience: {
    name: 'Zkušenosti',
    children: {
      roleType: {
        name: 'Preferované role',
        children: {
          leading: 'Hlavní',
          supporting: 'Vedlejší',
          featuredExtras: 'Epizodní',
          extra: 'Komparz'
        }
      },
      actorType: {
        name: 'Herectví',
        children: {
          theater: 'Divadlo',
          movies: 'Film',
          ads: 'Reklama',
          tv: 'Televize',
          musical: 'Muzikál',
          standUp: 'Stand-up',
          amateur: 'Amatér'
        }
      },
      modelType: {
        name: 'Modeling',
        children: {
          photo: 'Focení',
          ads: 'Reklama',
          fashionShow: 'Přehlídková mola',
          legmodel: 'Nohy (legmodel)',
          handmodel: 'Ruce (handmodel)',
          facemodel: 'Tvář (facemodel)'
        }
      },
      stuntmanType: {
        name: 'Kaskadérství',
        children: {
          car: 'Automobilové',
          motorbike: 'Motorky a motokáry',
          martialArts: 'Bojové sporty',
          swordplay: 'Šerm',
          shooting: 'Střelba',
          horse: 'Jízda na koni',
          falling: 'Pády z výšky',
          fire: 'Živý oheň',
          parachute: 'Parašutista'
        }
      },
      artisteType: {
        name: 'Artistické disciplíny',
        children: {
          acrobatics: 'Akrobacie',
          rings: 'Kruhy',
          fire: 'Ohňová show',
          trampoline: 'Skoky na trampolíně',
          juggling: 'Žonglování',
          equilibristics: 'Ekvilibristika',
          magic: 'Kouzelnictví',
          horse: 'Krasojízda na koni',
          snakes: 'Hadí muž',
          snakesF: 'Hadí žena',
          taming: 'Krocení zvířat'
        }    
      },
      /*dancerSkill: {
        name: 'Tanec',
        children: {}
      },
      dancerStyle: {
        name: 'Taneční styly',
        children: {}
      },
      musicianSkill: {
        name: 'Hudba',
        children: {}
      },
      musicianInstrument: {
        name: 'Hudební nástroje',
        children: {}
      },
      musicianStyle: {
        name: 'Hudební žánry',
        children: {}
      },
      athleteSkill: {
        name: 'Sport',
        children: {}
      },
      athleteType: {
        name: 'Sportovní disciplíny',
        children: {}
      },
      singerSkill: {
        name: 'Zpěv',
        children: {}
      },
      singerType: {
        name: 'Pěvecké styly',
        children: {}
      },*/
      moderatorType: {
        name: 'Moderování',
        children: {
          tv: 'TV',
          radio: 'Rádio',
          actions: 'Akce (veřejné, firemní)'
        }    
      },
    }
  }
}
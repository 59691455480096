import VAutocomplete from 'vuetify/es5/components/VAutocomplete/VAutocomplete'
import VMenu from 'vuetify/es5/components/VMenu/VMenu'
import VChip from 'vuetify/es5/components/VChip/VChip'
import ChipRange from './ChipRange'
import { EventBus } from '@/libs/eventBus.js'

export default {
    name: 'v-autocomplete-ex',
    extends: VAutocomplete,
    methods: {
        genChipSelection: function genChipSelection(item, index) {
            /*var _this6 = this;

            var isDisabled = this.isDisabled || this.getDisabled(item);
            var isInteractive = !isDisabled && this.isInteractive;
            return this.$createElement(VChip.default, {
                staticClass: 'v-chip--select',
                attrs: {
                tabindex: -1
                },
                props: {
                close: this.deletableChips && isInteractive,
                disabled: isDisabled,
                inputValue: index === this.selectedIndex,
                small: this.smallChips
                },
                on: {
                  click: function click(e) {
                      if (!isInteractive) return;
                      e.stopPropagation();
                      _this6.selectedIndex = index;
                  },
                  'click:close': function clickClose() {
                      return _this6.onChipInput(item);
                  }
                },
                key: JSON.stringify(this.getValue(item))
            }, this.getText(item));*/

            var _this4 = this;

            var isDisabled = this.isDisabled || this.getDisabled(item);//this.disabled || this.readonly || this.getDisabled(item);
            var isInteractive = !isDisabled && this.isInteractive;
            var focus = function focus(e, cb) {
                if (isDisabled) return;
                e.stopPropagation();
                _this4.onFocus();
                cb && cb();
            };
            return this.$createElement(item.type ? ChipRange : VChip, {
                staticClass: 'v-chip--select-multi',
                props: {
                    close: this.deletableChips && !isDisabled,
                    dark: this.dark,
                    disabled: isDisabled,
                    selected: index === this.selectedIndex,
                    small: this.smallChips,
                    label: true,
                    color: 'secondary',
                    textColor: 'white',
                    type: item.type ? item.type : null,
                    caption: item.type ? item.name : null
                },
                on: {
                    click: function click(e) {
                      if (!isInteractive) return;
                      e.stopPropagation();
                      _this4.selectedIndex = index;
                    },
                    'click:close': function clickClose() {
                        return _this4.onChipInput(item);
                    },
                    focus: focus,
                    input: function input() {
                        return _this4.onChipInput(item.name);
                    }
                },
                key: this.getValue(item.name)
            }, this.getText(item.name));
        },
        setSelectedItems: function setSelectedItems() {
            this.selectedItems = this.value;
        },
        findExistingIndex: function findExistingIndex(item) {
            var _this3 = this;

            var itemValue = this.getValue(item);
            return (this.internalValue || []).findIndex(function (i) {
                var value = _this3.getValue(i);
                return  value && (value.name === itemValue.name);
            });
        },
        selectItem: function selectItem(item) {
            var _this8 = this;

            if (!this.multiple) {
                this.internalValue = this.returnObject ? item : this.getValue(item);
                this.isMenuActive = false;
            } else {
                var internalValue = (this.internalValue || []).slice();
                let isString =  (typeof item === "string");
                var i = this.findExistingIndex(isString ? {name: item} : item);
                i !== -1 ? internalValue.splice(i, 1) : internalValue.push(item);
                this.internalValue = internalValue.map(function (i) {
                    return _this8.returnObject ? i : _this8.getValue(i);
                });
                
                this.$nextTick(function () {
                    _this8.$refs.menu && _this8.$refs.menu.updateDimensions();
                });
            }
        },
        genMenu: function genMenu() {
            var _this6 = this;

            var props = this.$_menuProps;
            props.activator = this.$refs['input-slot'];
            var inheritedProps = Object.keys(VMenu.options.props);
            var deprecatedProps = Object.keys(this.$attrs).reduce(function (acc, attr) {
                if (inheritedProps.includes((0, _helpers.camelize)(attr))) acc.push(attr);
                return acc;
            }, []);
            var _iteratorNormalCompletion = true;
            var _didIteratorError = false;
            var _iteratorError = undefined;

            try {
                for (var _iterator = deprecatedProps[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
                    var prop = _step.value;

                    props[(0, _helpers.camelize)(prop)] = this.$attrs[prop];
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally {
                try {
                    if (!_iteratorNormalCompletion && _iterator.return) {
                        _iterator.return();
                    }
                } finally {
                    if (_didIteratorError) {
                        // eslint-disable-next-line no-unsafe-finally
                        throw _iteratorError;
                    }
                }
            }
            if (this.attach === '' || this.attach === true || this.attach === 'attach') {
                props.attach = this.$el;
            } else {
                props.attach = this.attach;
            }

            EventBus.$on('closeSearchDropdown', () => {
              _this6.isMenuActive = false;
              _this6.isFocused = false;
            });

            props.allowOverflow = true;
            return this.$createElement(VMenu, {
                props: props,
                on: {
                    input: function input(val) {
                        _this6.isMenuActive = val;
                        _this6.isFocused = val;
                    }
                },
                ref: 'menu'
            }, [this.genList()]);
        },
    }
};
<template>
  <v-dialog persistent v-model="show" max-width="650" @keydown.esc="show = false" content-class="elevation-0 pa-0">
    <v-card color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">{{title}}</v-card-title>
      <v-card-text>
        <div class="avatar">
          <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
            <h3>Dropněte sem</h3>
          </div>
          <div class="avatar-upload" v-show="!edit">
            <div class="text-center pa-4" style="width: 400px; border: 4px dashed silver; margin: 15px auto 30px auto;">
              <label for="avatar">
                <img v-if="files.length" :src="files[0].url"  class="rounded-circle" />
                <v-icon v-else size="200">add_a_photo</v-icon>
              </label><br />
              <v-btn type="submit" class="mt-3 elevation-0">
                <file-upload extensions="gif,jpg,jpeg,png,webp" accept="image/png,image/gif,image/jpeg,image/webp" name="avatar" v-model="files" @input-filter="inputFilter" @input-file="inputFile" ref="upload">Vyberte fotku</file-upload>
              </v-btn>
            </div>
          </div>

          <div class="avatar-edit" v-show="files.length && edit">
            <div class="avatar-edit-image" v-if="files.length">
              <img ref="editImage" :src="files[0].url" />
            </div>
            <div class="text-center p-4 mt-3">
              <button type="button" class="v-btn elevation-0" @click.prevent="$refs.upload.clear">Storno</button>
              <button type="submit" class="v-btn primary elevation-0" @click.prevent="editSave">Použít</button>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions v-if="!edit">
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="show = false">Storno</v-btn>
        <v-btn class="elevation-0" color="primary" @click="saveInternal">Uložit</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>

.avatar .avatar-upload .rounded-circle {
  width: 200px;
  height: 200px;
}
.avatar .text-center .btn {
  margin: 0 .5rem
}
.avatar .avatar-edit-image {
  max-width: 100%
}
.avatar .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: .6;
  text-align: center;
  background: #000;
}
.avatar .drop-active h3 {
  margin: -.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}

.rounded-circle {
  border-radius: 50%;
}
</style>

<script>
import Cropper from 'cropperjs'
import FileUpload from 'vue-upload-component'

export default {
  name: 'AddLogoDialog',
  components: {
    FileUpload
  },
  props: {
    title: {
      type: String,
      default: () => "Nahrát logo"
    },
    value: {
      type: Boolean,
      default: () => false
    },
    save: {
      type: Function,
      default: (image) => {
        console.log(image);
      }
    }
  },
  data: () => {
    return {
      files: [],
      edit: false,
      cropper: false,
      blob: null
    }
  },
  watch: {
    edit(value) {
      if (value) {
        this.$nextTick(function () {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            aspectRatio: 1 / 1,
            viewMode: 2,
          })
          this.cropper = cropper
        })
      } else {
        if (this.cropper) {
          this.cropper.destroy()
          this.cropper = false
        }
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { this.$emit('input', value); }
    }
  },
  methods: {
    saveInternal: function() {
      this.save(this.blob);
      this.show = false;
    },
    editSave() {
      this.edit = false;
      let oldFile = this.files[0]
      this.blob = this.cropper.getCroppedCanvas({width: 200, height: 200}).toDataURL(oldFile.type)
      let binStr = atob(this.blob.split(',')[1])
      let arr = new Uint8Array(binStr.length)
      for (let i = 0; i < binStr.length; i++) {
        arr[i] = binStr.charCodeAt(i)
      }
      let file = new File([arr], oldFile.name, { type: oldFile.type })
      this.$refs.upload.update(oldFile.id, {
        file,
        type: file.type,
        size: file.size,
        active: true,
      })
    },
    alert(message) {
      alert(message)
    },
    inputFile(newFile, oldFile) {
      if (newFile && !oldFile) {
        this.edit = true;
      }
      if (!newFile && oldFile) {
        this.edit = false;
      }
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(gif|jpg|jpeg|png|webp)$/i.test(newFile.name)) {
          this.alert('Vyberte prosím fotografii')
          return prevent()
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.url = ''
        let URL = window.URL || window.webkitURL
        if (URL && URL.createObjectURL) {
          newFile.url = URL.createObjectURL(newFile.file)
        }
      }
    }    
  }
}
</script>
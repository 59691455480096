<template>
<div class="fill-height">
  <v-app-bar app color="white" fixed clipped-left class="elevation-0 main-toolbar">
    <v-toolbar-title><a href="https://aircasting.cz/productions" target="_blank"><img src="https://prod.aircasting.cz/logo.svg" style="margin: 8px 24px 0px 0px;" height="32" /></a></v-toolbar-title>
    <v-toolbar-items v-if="!loading && allowSearch">
      <v-btn class="mx-0" text :to="{path: '/roles/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{shareName}}</span><v-icon v-else>mdi-apps</v-icon></v-btn>
      <v-btn class="mx-0" text :to="{path: '/search/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{$t('Layout.search')}}</span><v-icon v-else>search</v-icon></v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
  </v-app-bar>
  <div v-if="loading" style="height: 100%">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  <v-container v-else-if="sharedProfiles" fluid grid-list-md  class="pt-3 px-5 pb-5 shared" style="max-width: 100%">
    <h1 class="mb-5">{{sharedProfiles.shareName}}</h1>
    <v-layout row wrap style="min-width: 100%">
      <v-flex v-for="(profile, i) in sharedProfiles.profiles" xs12 md3 lg2 xl2 class="pa-2" :key="i">
        <MiniProfile @showProfile="showProfileDetail" :profile="profile" :showControls="false" :showMultiselect="false" :showBottomControls="false"></MiniProfile>
      </v-flex>
    </v-layout>
    <ProfileDetail v-model="showProfileDialog" :userProfile="selectedProfile" :showControls="false" />
  </v-container>
</div>
</template>

<style>
h1 {
  font-weight: 300;
}
.show-on-hover {
  visibility: hidden;
}
.v-card:hover .show-on-hover {
  visibility: visible;
}
.check_header {
  margin-left: -36px;
}
#app.desktop .role_section .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: hidden;
}
#app.desktop .role_section:hover .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: visible;
}
.v-alert.bottom_alert {
  position: fixed; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 2;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
}
.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
.files_page .v-chip .v-chip__content {
  cursor: pointer !important;
}
.shared .v-badge__badge {
  width: 18px;
  height: 18px;
}
.shared-comments-dialog .v-list__tile {
  padding: 0px;
}
.shared-comments-dialog .v-list--two-line .v-list__tile {
  height: initial !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.shared-comments-dialog .v-list__tile__sub-title {
  white-space: pre-wrap;
}
</style>

<script>
import * as api from '@/libs/api'
import MiniProfile from '@/widgets/MiniProfile'
import ProfileDetail from '@/dialogs/ProfileDetail'

export default {
  name: 'SharedVideos',
  components: {
    MiniProfile,
    ProfileDetail
  },
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      loading: true,
      sharedProfiles: null,
      showProfileDialog: false,
      selectedProfile: null
    }
  },
  async mounted() {
    if (!this.sharedProfiles)
      this.sharedProfiles = await api.getSharedProfiles(this.id);

    this.loading = false;
  },
  methods: {
    showProfileDetail(profile) {
      this.selectedProfile = null;
      this.$nextTick(() => {
        this.selectedProfile = profile;
        this.showProfileDialog = true;
      });
    }
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="700" @keydown.esc="show = false" content-class="elevation-0 pa-0 role-dialog">
    <v-card color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline pb-1">Sdílet herce</v-card-title>
      <v-card-text class="pt-1">
        <p>Sdílejte herce ve vybraných rolích, kteří odpovídají danému stavu.</p>
        <section v-if="!shareId" style="width: 95%; margin: auto;">
          <v-text-field class="my-5 pt-5" v-model="name" :label="$t('ProfileMultiView.sharingName')"></v-text-field>
          <v-expansion-panels :value="enabledRoles" multiple class="mb-2" >
            <v-expansion-panel v-for="(role, i) in roles" :key="i"  hide-default-footer readonly :class="role.enabled ? `role-active` : ``" style="border-radius: 4px; margin-top: 8px; ">
                <v-expansion-panel-header>
                  <div class="px-3 my-3" :class="role.enabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click="role.enabled = !role.enabled">
                      <div class="square-checkbox d-flex"><v-icon color="#65bcff" class="ma-0">done</v-icon></div>
                      <div class="salary-header-text">{{role.roleName}}</div>
                      <div style="clear: both"></div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-combobox v-model="role.filter" :items="items" chips disable-lookup multiple hide-details class="elevation-0 mx-1 pt-0">
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip v-bind="attrs" v-if="isAllowedState(item)" :input-value="selected" close :color="stateToColor(item)" @click="select" @click:close="remove(item, role)">
                        <span>{{ $t('ShareRolesDialog.'+item) }}</span>
                      </v-chip>
                    </template>
                    <template v-slot:item="{ index, item }">
                      <v-chip small :color="stateToColor(item)">{{ $t('ShareRolesDialog.'+item) }}</v-chip>
                    </template>
                  </v-combobox>
                </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-checkbox color="secondary" :disabled="enabledRolesWithFilter.length < 1" v-model="allowSearch" label="Umožnit vyhledávat další herce"></v-checkbox>
        </section>
        <section v-else>
          <v-text-field class="my-5 pt-5" :value="shareURL" label="URL" id="shareRoleURL">
            <v-icon slot="append" @click="copyURL">{{copying ? `check` : `far fa-clipboard`}}</v-icon>
          </v-text-field>
        </section>
        <div class="text-center pt-3">
          <i>Odkaz bude vždy zobrazovat nejaktuálnější seznam herců odpovídající zadaným kritériím.</i>
          <v-btn v-if="!shareId" class="elevation-0 next mb-0" color="primary" @click="save" :loading="loading" :disabled="enabledRolesWithFilter.length < 1">Vytvořit odkaz ke sdílení</v-btn>
          <v-btn v-if="!shareId" class="elevation-0 back mt-1" color="#8f969a" text @click="show=false">{{$t('cancel')}}</v-btn>
          <v-btn v-if="shareId" class="elevation-0 next mb-0" color="primary" @click="show=false">OK</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<style>
.v-btn--right.v-btn--top.v-btn--absolute.v-size--small.dialog_close {
  top: 8px;
  right: 8px;
  border-radius: 50% !important;
}
.role-dialog .v-text-field.v-text-field--enclosed .v-text-field__details {
  padding-top: 8px !important;
}
.role-dialog h1 {
  font-weight: 400;
  font-size: 32px;
}
#app .big-icon-btn {
  height: initial;
  padding: 0px;
}

#app .big-icon-btn .v-btn__content {
  display: initial;
  min-width: 120px;
}
#app .role-dialog .v-btn.next {
  margin: 28px auto 0px auto;
  padding: 14px 41px;
  height: 56px;
  font-size: 16px;
  display: block;
}
#app .role-dialog .v-btn.back {
  margin: 24px auto 0px auto;
  font-weight: 300;
  display: block;
}
#app .role-dialog .v-expansion-panel-header {
  padding: 0px;
}
#app .role-dialog .role-active .v-expansion-panel-header {
  background-color: #65bcff;
  border-radius: 3px;
}
#app .role-dialog .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
#app .role-dialog .salary-header-text {
  float: left;
  line-height: 32px;
  font-size: 16px;
  margin-left: 0px;
}
#app .role-dialog .role-active .salary-header-text {
  color: white;
}
#app .role-dialog .salary-header-total {
  float: right;
  color: white;
  font-size: 18px;
  width: 110px;
  text-align: right;
  border-left: 1px solid rgba(255,255,255,.3);
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.role-dialog .increment-button {
  border-radius: 3px !important; 
  float: right; 
  width: 32px; 
  height: 32px;
}
.role-dialog .v-btn--icon:before {
  border-radius: 3px !important; 
}

#app .role-dialog .square-checkbox {
  width: 32px;
  height: 32px;
  margin-right: 12px;
  background-color: #eeeff0;
  box-shadow: inset 0px 1px 5px #babcbe;
  display: flex;
  border-radius: 3px;
  float: left;
}
#app .role-dialog .square-checkbox i {
  margin: 4px !important;
}
#app .checked .square-checkbox {
  background-color: white;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
.role-dialog .v-expansion-panel-header__icon i {
  color: transparent !important;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapGetters } from 'vuex'

export default {
  name: 'ShareRolesDialog',

  data: () => {
    return {
      name: null,
      allowSearch: true,
      loading: false,
      copying: false,
      shareId: null,
      roles: [],
      items: ['winner', 'accepted', 'added', 'addressed', 'rejected'],
    }
  },
  watch: {
    show(val) {
      if (val == true) {
        this.update();
        this.loading = false;
        this.shareId = null;
        this.allowSearch = true;
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.$store.state.dialogs.shareRoles.show; },
      set: function(value) { return this.$store.state.dialogs.shareRoles.show = value }
    },
    enabledRoles() {
      let result = [];
      for (let r in this.roles)
        if (this.roles[r].enabled)
          result.push(parseInt(r));
      return result;
    },
    enabledRolesWithFilter() {
      let result = [];
      for (let r in this.roles)
        if (this.roles[r].enabled && this.roles[r].filter.length)
          result.push(parseInt(r));
      return result;
    },
    shareURL() {
      return window.location.origin + "/roles/" + this.shareId;
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject', 'getLoggedUser']),
    update() {
      let project = this.getCurrentProject();
      this.name = (project.client ? (project.client + ' - ') :  '') + project.projectName;
      let result = [];
      // Find roles that are not part of the event yet
      let roles = project.roles;
      for (let role of roles) {
        result.push({
          enabled: false,
          roleType: role.roleType,
          roleName: role.roleName,
          roleId: role.roleId,
          filter: ['winner', 'accepted']
        });
      }

      this.roles = result;
    },
    remove(item, role) {
      if (!role || !role.filter)
        return;

      role.filter.splice(role.filter.indexOf(item), 1)
      role.filter = [...role.filter]
    },
    stateToColor(state) {
      switch (state) {
        case 'added':
        case 'addressed':
          return 'gray lighten-2';
        case 'accepted':
        case 'winner':
          return 'success';
        case 'rejected':
          return 'error';
      }
      return 'gray lighten-1';
    },
    isAllowedState(state) {
      switch (state) {
        case 'added':
        case 'addressed':
        case 'accepted':
        case 'winner':
        case 'rejected':
          return true;
      }

      return false;
    },
    fallbackCopyTextToClipboard() {
      let el = document.getElementById('shareRoleURL');
      el.focus();
      el.select();
      document.execCommand('copy');
    },
    copyURL() {
      this.copying = true;
      if (navigator.clipboard)
        navigator.clipboard.writeText(this.shareURL);
      else
        this.fallbackCopyTextToClipboard();
        
      setInterval(()=>{this.copying = false}, 3000);
    },
    async save() {
      this.loading = true;
      let project = this.getCurrentProject();
      let roles = [];
      for (let role of this.roles) {
        if (!role.enabled || !role.filter || !role.filter.length)
          continue

        roles.push({
          roleId: role.roleId,
          filter: role.filter
        });
      }
      let userName = '';
      let user = this.getLoggedUser();
      if (user)
        userName = user.fullname;
      this.shareId = await api.createSharedRoles(project.projectId, roles, this.name, this.allowSearch, null, userName);
      this.loading = false;
    }
  }
}
</script>
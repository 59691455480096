<template>
<div style="height: 100%">
  <v-menu v-model="menu" :close-on-content-click="false" :nudge-bottom="64" :nudge-width="200">
    <template v-slot:activator="{ on, attrs }">
      <v-btn fab color="indigo" style="position: absolute; top: 24px; right: 24px;" dark v-bind="attrs" v-on="on"><v-icon>fas fa-cog</v-icon></v-btn>
    </template>
    <v-card class="px-5 py-2">
      <v-select v-model="selectedAudioInput" :items="audioInputs" item-text="label" item-value="deviceId" prepend-icon="fas fa-microphone-alt" return-object single-line></v-select>
      <v-select v-model="selectedAudioOutput" :items="audioOutputs" item-text="label" item-value="deviceId" prepend-icon="fas fa-headphones" return-object single-line></v-select>
      <v-select v-model="selectedVideoInput" :items="videoInputs" item-text="label" item-value="deviceId" prepend-icon="fas fa-video" return-object single-line></v-select>
    </v-card>
  </v-menu>
  <v-container fill-height style="max-width: 100%; width: 100%">
    <v-layout row wrap align-center>
      <v-flex xs12 sm12 md12 class="text-center">
        {{meetingJoinUrl}}
        {{tileCount}}
        <div class="d-block">
          <audio id="audio-element-id"></audio>
          <v-container fluid grid-list-md class="pa-2">
            <v-layout row wrap>
              <v-flex :class="videoColumnClass">
                <video width="95%" height="95%" id="video-element-id-1"></video>
              </v-flex>
              <template v-if="tileCount > 1">
                <v-flex :class="videoColumnClass" v-for="tile in tileCount" :key="tile">
                  <video width="95%" height="95%" style="border: 1px solid black" :id="'video-element-id-' + (tile+1)"></video>
                </v-flex>
              </template>
            </v-layout>
          </v-container>
        </div>
        <div class="d-block text-center">
          <v-btn @click="startSession">Start</v-btn>
          <v-btn @click="stopSession">Stop</v-btn>
        </div>
      </v-flex>
    </v-layout>
  </v-container>
</div>
</template>

<style>
</style>

<script>

import {
  ConsoleLogger,
  DefaultDeviceController,
  DefaultMeetingSession,
  LogLevel,
  MeetingSessionConfiguration
} from 'amazon-chime-sdk-js';

import * as api from '@/libs/api'

export default {
  name: 'VideoCall',
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      menu: false,
      meetingJoinUrl: null,
      meetingSession: null,
      selectedAudioInput: null,
      audioInputs: [],
      selectedAudioOutput: null,
      audioOutputs: [],
      selectedVideoInput: null,
      videoInputs: [],
      tileCount: 1
    }
  },
  computed: {
    videoColumnClass() {
      if (this.tileCount <= 1)
        return 'xs12'
      else if (this.tileCount <= 4)
        return 'xs6'
      else if (this.tileCount <= 9)
        return 'xs4'
      else if (this.tileCount <= 16)
        return 'xs3'

      return 'xs2';
    }
  },
  watch: {
    async selectedAudioInput(deviceId) {
      if (!deviceId)
        return;

      try {
        await this.meetingSession.audioVideo.chooseAudioInputDevice(deviceId);
      }
      catch (err) {
        console.log(err);
      }
    },
    async selectedAudioOutput(deviceId) {
      if (!deviceId)
        return;

      try {
        await this.meetingSession.audioVideo.chooseAudioOutputDevice(deviceId);
      }
      catch (err) {
        console.log(err);
      }
    },
    async selectedVideoInput(deviceId) {
      if (!deviceId)
        return;

      try {
        await this.meetingSession.audioVideo.chooseVideoInputDevice(deviceId);
      }
      catch (err) {
        console.log(err);
      }
    },
  },
  methods: {
    startSession() {
      this.meetingSession.audioVideo.startLocalVideoTile();
      this.meetingSession.audioVideo.start();
    },
    stopSession() {
      this.meetingSession.audioVideo.stopLocalVideoTile();
      this.meetingSession.audioVideo.removeLocalVideoTile();
    }
  },
  async mounted() {
    const logger = new ConsoleLogger('Logger', LogLevel.INFO);
    const deviceController = new DefaultDeviceController(logger);

    try {
      let response = this.id ? await api.joinMeeting(this.id) : await api.createMeeting();
      if (response && response.meeting && response.meeting.Meeting)
        this.meetingJoinUrl = window.location.origin + '/call/' + response.meeting.Meeting.MeetingId;

      // You need responses from server-side Chime API. See below for details.
      const meetingResponse = response.meeting;
      const attendeeResponse = response.attendee;
      const configuration = new MeetingSessionConfiguration(meetingResponse, attendeeResponse);

      // In the usage examples below, you will use this meetingSession object.
      this.meetingSession = new DefaultMeetingSession(configuration, logger, deviceController);

      const audioInputDevices = await this.meetingSession.audioVideo.listAudioInputDevices();
      const audioOutputDevices = await this.meetingSession.audioVideo.listAudioOutputDevices();
      const videoInputDevices = await this.meetingSession.audioVideo.listVideoInputDevices();
      
      this.audioInputs = audioInputDevices;
      this.audioOutputs = audioOutputDevices;
      this.videoInputs = videoInputDevices;

      if (audioInputDevices && audioInputDevices.length)
        this.selectedAudioInput = audioInputDevices[0].deviceId;
      if (audioOutputDevices && audioOutputDevices.length)
        this.selectedAudioOutput = audioOutputDevices[0].deviceId;
      if (videoInputDevices && videoInputDevices.length)
        this.selectedVideoInput = videoInputDevices[0].deviceId;
      const audioElement = document.getElementById('audio-element-id');
      this.meetingSession.audioVideo.bindAudioElement(audioElement);

      const observer = {
        audioVideoDidStart: () => {
          console.log('Started');
        },
        audioVideoDidStop: sessionStatus => {
          // See the "Stopping a session" section for details.
          console.log('Stopped with a session status code: ', sessionStatus.statusCode());
        },
        audioVideoDidStartConnecting: reconnecting => {
          if (reconnecting) {
            // e.g. the WiFi connection is dropped.
            console.log('Attempting to reconnect');
          }
        },
        // videoTileDidUpdate is called whenever a new tile is created or tileState changes.
        videoTileDidUpdate: tileState => {
          // Ignore a tile without attendee ID and other attendee's tile.
          console.log('tile updated', tileState)
          if (!tileState.boundAttendeeId) {
            return;
          }
          let remoteTiles = this.meetingSession.audioVideo.getAllRemoteVideoTiles();
          let tile = tileState.localTile ? 1 : ((remoteTiles.length || 1) + 1);
          let videoElement = document.getElementById('video-element-id' + '-' + tile);
          this.meetingSession.audioVideo.bindVideoElement(tileState.tileId, videoElement);
        },
        videoTileWasRemoved: tileId => {
          console.log('Tile ' + tileId + ' was removed');
        },
        remoteVideoSourcesDidChange: videoSources => {
          this.tileCount = videoSources.length + 1;
        }
      };

      this.meetingSession.audioVideo.addObserver(observer);
      this.startSession();

      let remoteTiles = this.meetingSession.audioVideo.getAllRemoteVideoTiles();
      if (remoteTiles && remoteTiles.length > 0) {
        this.tileCount = remoteTiles.length + 1;
        for (let i of remoteTiles) {
          let videoElement = document.getElementById('video-element-id' + '-' + i + 2);
          this.meetingSession.audioVideo.bindVideoElement(remoteTiles[i].tileId, videoElement);
        }
      }
    }
    catch (err) {
      console.log(err);
    }
  }
}
</script>

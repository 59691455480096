<template>
  <v-container fill-height class="signin pa-0">
    <v-layout row wrap align-center>
      <div class="mx-auto">
        <v-card class="shadow" style="min-width: 370px;" @keydown.enter="resetPassword ? newPassword() : login()">
          <v-card-title primary-title>
            <h4 class="headline ">{{resetPassword ? `Nastavte nové heslo` : `Přihlášení`}}</h4>
          </v-card-title>
          <v-card-text>
            <v-alert outline type="error" dismissible class="mb-4 mt-0" v-model="showerr">{{ errmsg }}</v-alert>
            <v-form v-model="valid" ref="form">
            <v-text-field v-if="!resetPassword" v-model="email" prepend-icon="person" label="E-mail" :rules="[emailrules.required, emailrules.email]" required autofocus clearable></v-text-field>
            <v-text-field v-model="password" prepend-icon="lock" 
                    :append-icon="hidePassword ? 'visibility' : 'visibility_off'" @click:append="() => (hidePassword = !hidePassword)" 
                    :type="hidePassword ? 'password' : 'text'" :label="$t('Signin.password')" :rules="passRules" required></v-text-field>
            </v-form>
            <div>
            <v-btn style="float: left;" v-if="false && !resetPassword" class="mt-4 mb-3 elevation-0" text small :to="{path: '/forgot'}">{{$t('Signin.passForgotten')}}</v-btn>
            <v-btn style="float: right;" :loading="loading" @click.native="resetPassword ? newPassword() : login()" class="mt-3 mb-3 white--text elevation-0" color="primary">
              {{$t('Signin.ctaSignIn')}}
            </v-btn>
            <div style="clear: both;"></div>
            </div>          
          </v-card-text>
        </v-card>
      </div>
    </v-layout>
  </v-container>
</template>

<style>
</style>

<script>
import router from './../router'
import { Auth } from "aws-amplify";

export default {
    name: 'Login',
    data() {
        return {       
            hidePassword: true,
            email: '',
            emailrules: {
              required: (value) => !!value || 'Zadejte e-mail',
              email: (value) => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Zadejte validni e-mail'
              }
            },
            password: '',
            passRules: [
              (v) => !!v || 'Zadejte heslo',
              (v) => !v || v.length >= 8 || 'Zadejte heslo'
            ],
            showerr: false,
            errmsg: '',
            valid: false,
            loading: false,
            resetPassword: null
        }
    },
    mounted: function() {
      
    },
    methods: {
      login: async function() {
        this.loading = true;
        try {
          let user = await Auth.signIn(this.email, this.password);
          this.handleLoginSuccess(user);
        } catch (e) {
          this.handleLoginError(e);
        }
      },
      newPassword: function() {
        this.loading = true;
        this.resetPassword.completeNewPasswordChallenge(this.password, this.resetPassword.userAttributes, {
          onSuccess: this.handleLoginSuccess,
          onFailure: this.handleLoginError
        });
        router.push('/');
      },
      handleLoginSuccess: function(result) {
        console.log("handle login success", result)
        if (result.challengeName && result.challengeName == "NEW_PASSWORD_REQUIRED") {
          console.log("reset password");
          this.resetPassword = result;
          this.errmsg = "Je nutné nastavit nové heslo";
          this.password = null;
          this.showerr = true;  
          this.loading = false;
        }
        else {
          Auth.currentAuthenticatedUser().then(() => {
            this.loading = false;
            router.push('/');
            window.setTimeout(() => {
              window.location.reload();
            }, 600);
          }).catch((err) => {
            this.errmsg = "Nastala chyba, prihlaste se znovu";
            this.showerr = true;  
            this.loading = false;
            console.error(err);
          });
        }
      },
      handleLoginError: function(err) {
        console.log("handle login error", err)
        if (err.code == 'UnknownError') {
          setTimeout(() => {
            this.loading = false;
            router.push('/');
          }, 2000);
          return;
        }

        if (err.code === "NotAuthorizedException" || err.code === "UserNotFoundException") 
          this.errmsg = 'Chybný e-mail nebo heslo'
        else if (err.code === "UserNotConfirmedException")
          this.errmsg = 'Nepotvrzený uživatel. Klikněte na odkaz v e-mailu'
        else if (err.code === "LimitExceededException") 
          this.errmsg = 'Prekročili jste počet povolených pokusů'
        else
          this.errmsg = 'Nastala chyba, prihlaste se znovu'

        this.showerr = true;
        this.loading = false;
      }
    }
}
</script>

<style scoped>
  .content {
    background-color: blue !important;
  }
</style>
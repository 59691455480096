<template>
  <v-dialog persistent v-model="show" max-width="650" @keydown.esc="dialog ? dialog = false : show = false">
    <v-card color="#f6f8fa" class="edit-role-dialog">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">{{$t('EditRoleDialog.headline')}}</v-card-title>
      <v-card-text>
        <v-form ref="roleForm" @submit.prevent="submit">
          <v-expansion-panels class="expansion-panel-spaced" :value="0">
            <v-expansion-panel class="elevation-0">
              <v-expansion-panel-header>
                <div style="font-size: 16px; font-weight: bold;">{{$t('EditRoleDialog.genericHeadline')}}</div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card class="elevation-0">
                  <v-card-text class="mx-auto pt-4" style="width: 95%">
                    <v-layout row wrap align-center class="mt-0 mb-4 pt-0 mx-auto">
                      <v-flex xs12 sm3>
                        <v-card class="text-center pa-2 ma-2" hover @click="type = 'leading'" :color="(type == 'leading') ? `secondary` : `white`">
                          <v-responsive aspect-ratio="1.1">
                            <div class="big_button_content" style="font-size: 16px">
                              <v-icon size="30" :color="(type == 'leading') ? `white` : `primary`" class="my-2">fa-user-tie</v-icon>
                              <div :style="(type == 'leading') ? `color: white` : `color: #002e99`">{{$t('roleType.leading')}}</div>
                            </div>
                          </v-responsive>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 sm3>
                        <v-card class="text-center pa-2 ma-2" hover @click="type = 'supporting'" :color="(type == 'supporting') ? `secondary` : `white`">
                          <v-responsive aspect-ratio="1.1">
                            <div class="big_button_content" style="font-size: 16px">
                              <v-icon size="30" :color="(type == 'supporting') ? `white` : `primary`" class="my-2">fas fa-user-friends</v-icon>
                              <div :style="(type == 'supporting') ? `color: white` : `color: #002e99`">{{$t('roleType.supporting')}}</div>
                            </div>
                          </v-responsive>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 sm3>
                        <v-card class="text-center pa-2 ma-2" hover @click="type = 'featuredExtras'" :color="(type == 'featuredExtras') ? `secondary` : `white`">
                          <v-responsive aspect-ratio="1.1">
                            <div class="big_button_content" style="font-size: 16px">
                              <v-icon size="30" :color="(type == 'featuredExtras') ? `white` : `primary`" class="my-2">fas fa-user-clock</v-icon>
                              <div :style="(type == 'featuredExtras') ? `color: white` : `color: #002e99`">{{$t('roleType.featuredExtras')}}</div>
                            </div>
                          </v-responsive>
                        </v-card>
                      </v-flex>
                      <v-flex xs12 sm3>
                        <v-card class="text-center pa-2 ma-2" hover @click="type = 'extra'" :color="(type == 'extra') ? `secondary` : `white`">
                          <v-responsive aspect-ratio="1.1">
                            <div class="big_button_content" style="font-size: 16px">
                              <v-icon size="30" :color="(type == 'extra') ? `white` : `primary`" class="my-2">fa-users</v-icon>
                              <div :style="(type == 'extra') ? `color: white` : `color: #002e99`">{{$t('roleType.extra')}}</div>
                            </div>
                          </v-responsive>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <v-text-field v-model="name" autofocus :label="$t('AddRoleDialog.roleName')" required :rules="[v => !!v || 'Vyžadované pole']"></v-text-field>
                    <v-textarea :label="$t('AddRoleDialog.roleDescription')" :rows="7" v-model="description" required :rules="[v => !!v || 'Vyžadované pole']"></v-textarea>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel class="elevation-0" v-if="type != 'extra'">
              <v-expansion-panel-header>
                <div style="font-size: 16px;">
                  <div style="float: left; font-weight: bold;">{{$t('salary')}}</div>
                  <div style="float: right; font-weight: bold;">
                    <span v-if="total">{{total}} {{$t('czkCurrency')}}</span>
                    <v-tooltip top v-else>
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" class="ml-2 mr-2" style="margin-bottom: 2px;" small color="white">fas fa-exclamation-circle</v-icon>
                      </template>
                      <span>{{$t('EditRoleDialog.noSalaryError')}}</span>
                    </v-tooltip>
                  </div>
                  <div style="clear: both;"></div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card class="elevation-0">
                  <v-card-text class="role-budget">
                    <v-expansion-panels class="shadow mb-2" :value="dailySalaryEnabled ? [0] : []" multiple>
                      <v-expansion-panel hide-actions readonly :value="dailySalaryEnabled" style="border-radius: 4px;" :class="dailySalaryEnabled ? `role-active` : ``">
                        <v-expansion-panel-header>
                          <div class="px-3" :class="dailySalaryEnabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click.stop="dailySalaryEnabled = !dailySalaryEnabled">
                            <div class="square-checkbox d-flex"><v-icon color="black" class="ma-0">done</v-icon></div>
                            <div class="salary-header-text">{{$t('AddRoleDialog.daily')}}</div>
                            <div v-if="$vuetify.breakpoint.mdAndUp" class="salary-header-total">{{dailySalary}} {{dailySalary !== null ? `Kč` : ``}}</div>
                            <div style="clear: both"></div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card color="transparent" class="elevation-0">
                            <v-card-text class="pt-0 role-content">
                              <v-layout row style="max-width: 150px">
                                <v-flex xs10>
                                  <v-text-field v-model="dailySalary" :placeholder="$t('salary')" :disabled="!dailySalaryEnabled" mask="########" :suffix="$t('czkCurrency')"></v-text-field>
                                </v-flex>
                                <v-flex xs2>
                                  <div style="position: absolute; top: 50%; transform: translateY(-50%); font-size: 16px; font-weight: bold; padding: 0px 0px 16px 24px;">{{$t('AddRoleDialog.perOneDay')}}</div>
                                </v-flex>
                              </v-layout>
                            </v-card-text>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels class="shadow mb-2" multiple :value="buyoutEnabled ? [0] : []" :class="buyoutEnabled ? `role-active` : ``">
                      <v-expansion-panel hide-actions readonly :value="buyoutEnabled" style="border-radius: 4px;">
                        <v-expansion-panel-header>
                          <div class="px-3" :class="buyoutEnabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click.stop="buyoutEnabled = !buyoutEnabled">
                            <div class="square-checkbox d-flex"><v-icon color="black" class="ma-0">done</v-icon></div>
                            <div class="salary-header-text">{{$t('AddRoleDialog.buyout')}}</div>
                            <div v-if="$vuetify.breakpoint.mdAndUp" class="salary-header-total">{{buyout}} {{buyout !== null ? `Kč` : ``}}</div>
                            <div style="clear: both"></div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card color="transparent" class="elevation-0 mt-2">
                            <v-card-text class="py-0 role-content">
                              <v-container fluid grid-list-md class="pa-0">
                                <v-layout row wrap v-for="(buyout, i) in buyouts" :key="i">
                                  <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="buyout.salary" :placeholder="$t('salary')" :disabled="!buyoutEnabled" mask="########" :suffix="$t('czkCurrency')"></v-text-field></v-flex>
                                  <v-flex xs12 sm2 py-2 pr-3><v-text-field v-model="buyout.country" :label="$t('AddRoleDialog.country')" :hint="$t('AddRoleDialog.countryExample')" :disabled="!buyoutEnabled"></v-text-field></v-flex>
                                  <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="buyout.medium" :label="$t('AddRoleDialog.mediaType')" :hint="$t('AddRoleDialog.mediaTypeExample')" :disabled="!buyoutEnabled"></v-text-field></v-flex>
                                  <v-flex xs8 sm1 py-2 pr-1><v-text-field v-model="buyout.length" :label="$t('AddRoleDialog.period')" :disabled="!buyoutEnabled"></v-text-field></v-flex>
                                  <v-flex xs4 sm3 py-2><v-select v-model="buyout.unit" :items="['měs', 'rok']" append-outer-icon="close" @click:append-outer="buyouts.splice(i, 1)"></v-select></v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions class="pt-2 pb-4 pl-4">
                              <v-spacer></v-spacer>
                              <v-btn class="elevation-0 white--text" text color="primary" @click="buyouts.push({salary:null,country:null,medium:null,length:1,unit:'rok'})">{{$t('AddRoleDialog.addBuyout')}}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <v-expansion-panels class="shadow mb-2" multiple :value="otherEnabled ? [0] : []" :class="otherEnabled ? `role-active` : ``">
                      <v-expansion-panel hide-actions readonly :value="otherEnabled" style="border-radius: 4px;">
                        <v-expansion-panel-header>
                          <div class="px-3" :class="otherEnabled ? `checked` : ``" style="line-height: 32px; height: 32px" @click.stop="otherEnabled = !otherEnabled">
                            <div class="square-checkbox d-flex"><v-icon color="black" class="ma-0">done</v-icon></div>
                            <div class="salary-header-text">{{$t('AddRoleDialog.other')}}</div>
                            <div v-if="$vuetify.breakpoint.mdAndUp" class="salary-header-total">{{other}} {{other !== null ? `Kč` : ``}}</div>
                            <div style="clear: both"></div>
                          </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-card color="transparent" class="elevation-0 mt-2">
                            <v-card-text class="py-0 role-content">
                              <v-container fluid grid-list-md class="pa-0">
                                <v-layout row wrap v-for="(item, i) in others" :key="i">
                                  <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="item.salary" :placeholder="$t('EditRoleDialog.salary')" :disabled="!otherEnabled" mask="########" :suffix="$t('czkCurrency')"></v-text-field></v-flex>
                                  <v-flex xs12 sm3 py-2 pr-3><v-text-field v-model="item.name" :label="$t('itemName')" :hint="$t('AddRoleDialog.hintName')" :disabled="!otherEnabled"></v-text-field></v-flex>
                                  <v-flex xs12 sm6 py-2 pr-0><v-text-field v-model="item.description" :label="$t('description')" :hint="$t('AddRoleDialog.hintDescription')" :disabled="!otherEnabled" append-outer-icon="close" @click:append-outer="others.splice(i, 1)"></v-text-field></v-flex>
                                </v-layout>
                              </v-container>
                            </v-card-text>
                            <v-card-actions class="pt-2 pb-4 pl-4">
                              <v-spacer></v-spacer>
                              <v-btn class="elevation-0 white--text" text color="primary" @click="others.push({salary:null,name:null,description:null})">{{$t('AddRoleDialog.addOther')}}</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="show = false">{{$t('cancel')}}</v-btn>
        <v-btn class="elevation-0" color="primary" @click="save">{{$t('save')}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style>
.role-tab-item {
  background-color: #f6f8fa; 
  border-top: 1px solid rgb(228,228,228); 
  min-height: 451px;
}
#app .role-budget .v-expansion-panel-header {
  padding: 0px;
}
#app .role-budget .role-active .v-expansion-panel-header {
  background-color: #d0eaff;
  border-radius: 3px;
}
#app .role-budget .v-expansion-panel--active .v-expansion-panel-header {
  border-radius: 3px 3px 0px 0px;
}
#app .role-budget .salary-header-text {
  float: left;
  line-height: 26px;
  font-size: 14px;
  margin-left: 0px;
}
#app .role-budget .role-active .salary-header-text {
  color: black;
}
#app .role-budget .salary-header-total {
  float: right;
  color: black;
  font-size: 16px;
  width: 110px;
  text-align: right;
}
.role-content .v-text-field.v-text-field--solo .v-input__control {
  min-height: 38px;
}
.role-budget .v-btn--icon:before {
  border-radius: 3px !important; 
}

#app .role-budget .square-checkbox {
  width: 26px;
  height: 26px;
  margin-right: 12px;
  background-color: white;
  box-shadow: inset 0px 1px 5px #babcbe;
  display: flex;
  border-radius: 3px;
  float: left;
}
#app .role-budget .square-checkbox i {
  margin: 1px !important;
}
#app .checked .square-checkbox {
  background-color: white;
  box-shadow: initial;
  background-position: center center;
  background-repeat: no-repeat;
}
.square-checkbox i {
  opacity: 0;
}

.checked .square-checkbox i {
  opacity: 1;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EditRoleDialog',
  props: {
    value: {
      type: Boolean,
      default: () => false
    },
    role: {
      type: Object,
      default: () => null
    },
    saveRole: {
      type: Function,
      default: (role) => {
        console.log(role);
      }
    }
  },
  data: () => {
    return {
      name: null,
      description: null,
      type: null,
      dailySalary: null,
      dailySalaryEnabled: false,
      buyoutEnabled: false,
      otherEnabled: false,
      buyouts: [ 
        {
          salary: null,
          country: null,
          medium: null,
          length: 1,
          unit: 'rok'
        }
      ],
      others: [ 
        {
          salary: null,
          name: null,
          description: null
        }
      ],
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) {
        this.$emit('input', value); 
      }
    },
    buyout() {
      let result = 0;
      for (let buyout of this.buyouts) {
        if (!buyout.salary)
          continue;

        result += parseInt(buyout.salary);
      }
      return result;
    },
    other() {
      let result = 0;
      for (let item of this.others) {
        if (!item.salary)
          continue;

        result += parseInt(item.salary);
      }
      return result;
    },
    total() {
      return ((this.dailySalaryEnabled && this.dailySalary) ? parseInt(this.dailySalary) : 0) + ((this.buyoutEnabled && this.buyout) ? parseInt(this.buyout) : 0) + ((this.otherEnabled && this.other) ? parseInt(this.other) : 0);
    },
    budgetPanel() {
      return [0];
    }
  },
  watch: {
    show: function(value) {
      if (value == false || !this.role)
        return;

      this.name = this.role.roleName;
      this.description = this.role.description;
      this.type = this.role.roleType;
      this.buyouts = [];
      this.others = [];
      this.dailySalary = null;
      for (let payment of this.role.payments) {
        if (payment.type == 'dailySalary') {
          this.dailySalaryEnabled = true;
          this.dailySalary = parseInt(payment.salary);
        }
        else if (payment.type == 'buyout') {
          this.buyoutEnabled = true;
          this.buyouts.push({
            country: payment.country,
            medium: payment.medium,
            length: payment.length,
            unit: payment.unit,
            salary: parseInt(payment.salary)
          });
        }
        else if (payment.type == 'other') {
          this.otherEnabled = true;
          this.others.push({
            name: payment.otherName,
            description: payment.description,
            salary: parseInt(payment.salary)
          });
        }
      }

      if (!this.buyouts.length)
        this.buyouts.push({salary: null, country: null, medium: null, length: 1, unit: 'rok'});
      if (!this.others.length)
        this.others.push({salary: null, name: null, description: null});
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    ...mapActions(['addRoleToCurrentProject', 'removeRoleFromCurrentProject', 'updateRole']),
    save() {
      /*if (!this.$refs.roleForm.validate()) 
        return;*/
      let payments = [];
      if (this.dailySalaryEnabled && this.dailySalary) {
        payments.push({
          type: 'dailySalary',
          salary: parseInt(this.dailySalary)
        });
      }

      if (this.buyoutEnabled && this.buyouts) {
        for (let buyout of this.buyouts) {
          if (!buyout.salary)
            continue;

          payments.push({
            type: 'buyout',
            country: buyout.country,
            medium: buyout.medium,
            length: buyout.length,
            unit: buyout.unit,
            salary: parseInt(buyout.salary)
          });
        }
      }
      if (this.otherEnabled && this.other) {
        for (let item of this.others) {
          if (!item.salary)
            continue;

          payments.push({
            type: 'other',
            otherName: item.name,
            description: item.description,
            salary: parseInt(item.salary)
          });
        }
      }
      let role = this.role;
      role.payments = payments;
      role.roleName = this.name;
      role.description = this.description;
      role.roleType = this.type;
      
      let project = this.getCurrentProject();
      if (project) {
        this.updateRole(role);
      }

      this.show = false;
    },
    submit() {

    }
  }
}
</script>
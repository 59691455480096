<template>
  <v-dialog v-model="show" max-width="700px" @keydown.esc="show = false" content-class="pa-0">
    <v-card flat tile>
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24">close</v-icon></v-btn>
      <v-card-title class="headline">Odeslat e-mail</v-card-title>
      <v-card-text class="pt-6">
        <v-combobox :value="actors" :items="actors" readonly multiple chips outlined hide-controls hide-actions label="Adresáti">
          <template v-slot:selection="data">
            <v-chip v-if="showAllEmails || data.index < 5" :key="data.item.userId" v-bind="data.attrs" small class="ml-0">{{ data.item.email }}</v-chip>
            <v-chip v-if="!showAllEmails && actors.length > 6 && data.index === 5" v-bind="data.attrs" color="secondary" small class="ml-0" @click="showAllEmails = true">+{{ actors.length - 5 }} dalších ...</v-chip>
            <v-chip v-if="showAllEmails && (data.index === actors.length-1)" v-bind="data.attrs" color="secondary" small class="ml-0" @click="showAllEmails = false">Zobrazit méně ...</v-chip>
          </template>
        </v-combobox>
        <v-text-field v-model="email" readonly outlined label="E-mail, na který vám můžou herci odpovídat"></v-text-field>
        <v-text-field v-model="subject" outlined label="Předmět zprávy"></v-text-field>
        <v-textarea autofocus v-model="content" outlined hide-details rows="10" label="Zpráva, kterou chcete odeslat vybraným hercům"></v-textarea>
        <div class="d-flex" v-if="(content && content.length) || (templates && templates.length)">
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{on}">
              <v-btn v-on="on" class="mx-0" text small>Šablony <v-icon small class="ml-1">fa-angle-down</v-icon></v-btn>
            </template>
            <v-list dense class="pa-0">
              <v-list-item class="px-2" v-for="(template, t) in templates" :key="t" @click="setContentWithTemplate(template)">
                <v-list-item-title>{{template.name}}</v-list-item-title>
                <v-list-item-action class="pl-3 pb-1"><v-icon small color="black" @click="showConfirmDeleteTemplateDialog(template)">mdi-delete</v-icon></v-list-item-action>
              </v-list-item>
              <template v-if="content && content.length">
                <v-divider></v-divider>
                <v-list-item @click="showSaveTemplateDialog()">
                  <v-list-item-title class="primary--text">+ Vytvořit šablonu z této zprávy</v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-menu>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" color="primary" :disabled="!email || !content || content.length < 3" @click="sendMessage">Odeslat</v-btn>
        <v-btn class="elevation-0" @click="show = false">Storno</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="saveTemplateDialog.show" max-width="500px" @keydown.esc="saveTemplateDialog.show = false" content-class="pa-0">
      <v-card flat tile>
        <v-card-title class="headline">Uložit šablonu</v-card-title>
        <v-card-text class="pt-6">
          <v-text-field v-model="saveTemplateDialog.name" outlined label="Název šablony"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" color="primary" :disabled="!saveTemplateDialog.name || saveTemplateDialog.name.length < 3" @click="saveTamplate(saveTemplateDialog.name)">Uložit</v-btn>
          <v-btn class="elevation-0" @click="saveTemplateDialog.show = false">Storno</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDeleteTemplateDialog.show" max-width="500px" @keydown.esc="confirmDeleteTemplateDialog.show = false" content-class="pa-0">
      <v-card flat tile>
        <v-card-title class="headline">Smazat šablonu</v-card-title>
        <v-card-text class="pt-6">
          <div>Opravdu chcete smazat šablonu<strong v-if="confirmDeleteTemplateDialog.template"> {{confirmDeleteTemplateDialog.template.name}}</strong>?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" color="primary" @click="deleteTemplate(confirmDeleteTemplateDialog.template)">Smazat</v-btn>
          <v-btn class="elevation-0" @click="confirmDeleteTemplateDialog.show = false">Storno</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<style>
.v-alert.bottom_alert.abs {
  position: absolute; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 20000;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SendEmail',
  
  data: () => {
    return {
      content: null,
      subject: null,
      showAllEmails: false,
      templates: [],
      saveTemplateDialog: {
        show: false,
        name: null
      },
      confirmDeleteTemplateDialog: {
        show: false,
        template: null
      }
    }
  },
  computed: {
    show: {
      get() { return this.$store.state.dialogs.sendEmail.show; },
      set(value) { this.content = null; this.subject = null; this.showAllEmails = false; this.$store.state.dialogs.sendEmail.show = value }
    },
    actors() {
      return this.$store.state.dialogs.sendEmail.actors;
    },
    user() {
      return this.getLoggedUser();
    },
    email() {
      return (this.user && this.user.email ? (this.user.fullname + ' <' + this.user.email + '>') : 'Aircasting <noreply@aircasting.cz>');
    }
  },
  mounted() {
    this.templates = this.getTemplates();
  },
  methods: {
    ...mapActions(['showInfo']),
    ...mapGetters(['getLoggedUser']),
    async sendMessage() {
      let profiles = [];
      for (let actor of this.actors) {
        if (!actor.email || !actor.userId) 
          continue;

        profiles.push({
          userId: actor.userId,
          email: actor.email,
        });
      }

      if (profiles && profiles.length && this.content) {
        try {
          await api.sendMessage(profiles, this.content, this.email, this.subject);
          this.show = false;
          this.showInfo({title: 'Zpráva byla úspěšně odeslána.'});
        } catch (e) {
          this.showInfo({title: 'Vyskytla se neočekávaná chyba.'});
        }
      }
    },
    showSaveTemplateDialog() {
      this.saveTemplateDialog.show = true;
      this.saveTemplateDialog.name = null;
    },
    saveTamplate(name) {
      if (this.content && this.content.length > 3) {
        this.templates = this.getTemplates();
        this.templates.push({
          subject: this.subject,
          name: name || 'Šablona ' + (this.templates.length + 1),
          content: this.content,
          id: new Date().getTime()
        });
        localStorage.setItem('emailTemplates', JSON.stringify(this.templates));
        this.showInfo({title: 'Šablona byla úspěšně uložena.'});
        this.saveTemplateDialog.show = false;
        this.saveTemplateDialog.name = null;
      }
    },
    showConfirmDeleteTemplateDialog(template) {
      this.confirmDeleteTemplateDialog.show = true;
      this.confirmDeleteTemplateDialog.template = template;
    },
    getTemplates() {
      let templates = [];
      let storage = localStorage.getItem('emailTemplates');
      if (!storage || !storage.length)
        return templates;

      try {
        templates = JSON.parse(storage);
      } catch (e) {
        console.log(e);
      }

      return templates;
    },
    deleteTemplate(template) {
      this.templates = this.getTemplates();

      
      this.templates = this.templates.filter(t => t.id !== template.id);
      localStorage.setItem('emailTemplates', this.templates);
      this.showInfo({title: 'Šablona byla úspěšně smazána.'});
      this.confirmDeleteTemplateDialog.show = false;
      this.confirmDeleteTemplateDialog.template = null;
    },
    setContentWithTemplate(template) {
      if (this.content && this.content.length)
        this.showInfo({title: 'Už máte vyplněnou zprávu. Pokud chcete použít šablonu, smažte prosím obsah zprávy.'});
      else {
        this.content = template.content;
        if (template.subject && template.subject.length)
          this.subject = template.subject;
      }
    }
  }
}
</script>
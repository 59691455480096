export default {
  AddEventAttendees: {
    headline: 'Přidat účastníky',
    invited: 'Pozvaní účastníci'
  },
  AddEventAttendeesEx: {
    instructionsTitle: 'Instrukce pro roli',
    instructionsText: 'Podle těchto instrukcí se budou herci řídit při natáčení. Instrukce by proto měly být dostatečně srozumitelné.',
    sendInstructions: 'Odeslat instrukce',
    outroTitle: 'Herci mají vaše instrukce!',
    outroText: 'Jakmile herci potvrdí, že se online castingu zúčastní, stačí vyčkat na jejich nahrávky.'
  },
  AddEventDialog: {
    headline: 'Vyberte typ události',
    roleSelect: 'Výběr rolí',
    name: 'Název události',
    date: 'Datum'
  },
  AddEventRolesDialog: {
    headline: 'Přidat role do události'
  },
  AddLogoDialog: {
    headline: 'Vyberte logo'
  },
  AddProjectDialog: {
    step1Headline: 'Vyberte typ projektu',
    otherProjectType: 'Máte jiný typ projektu? ',
    getInTouch: 'Ozvěte se nám',
    dailyShift: 'Denní',
    shiftedShift: 'Posunuté',
    nightShift: 'Noční',
    selectedRoles: 'Vybrané role ',
    addDate: ' Přidat další termín',
    assistanceLevel: 'Jak chcete řešit váš projekt?',
    selfService: 'Vše si zařídím sám',
    selfServiceTick1: 'Sami si vyhledáte herce z naší databáze',
    selfServiceTick2: 'Ohlídáte si termíny a komunikaci s herci',
    selfServiceTick3: 'My se postaráme o smlouvy',
    assisted: 'Chci asistenci Aircastingu',
    assistedTick1: 'Herce z naší databáze si vyhledáte převážně sami',
    assistedTick2: 'Ke všemu máte k dispozici organizační tým Aircastingu',
    assistedTick3: 'My se postaráme o smlouvy',
    fullService: 'Chci, aby se o vše postaral Aircasting',
    fullServiceTick1: 'Předvybereme vám herce',
    fullServiceTick2: 'Obstaráme vám veškerou komunikaci',
    fullServiceTick3: 'Postaráme se o casting od A do Z',
    callUs: 'Nevíte si rady? Zavolejte nám',
    workingHours: 'V pracovní dny, 9:00-18:00',
    phoneNumber: '+420 797 634 782',
    callbackType: 'Jaký typ callbacku preferujete?',
    callbackLive: 'Callback naživo',
    callbackLiveTick1: 'Osobní setkání s herci',
    callbackLiveTick2: 'Zařídíme prostory a celý callback',
    callbackLiveTick3: 'Materiály můžete snadno sdílet se svým týmem',
    callbackOnline: 'Online callback',
    callbackOnlineTick1: 'Celý proces probíhá online',
    callbackOnlineTick2: 'Herci posílají selfshoot videa skrze aplikaci',
    callbackOnlineTick3: 'Materiály můžete snadno sdílet se svým týmem',
    noCallback: 'Bez callbacku',
    noCallbackTick1: 'Výběr z profilů',
    noCallbackTick2: 'Celý proces probíhá online',
    noCallbackTick3: 'Materiály můžete snadno sdílet se svým týmem',
    extrasCoordinator: 'Koordinátor komparzistů',
    extrasCoordinatorQuestion: 'Potřebujete od nás zařídit koordinaci komparzu na natáčení?',
    extrasCoordinatorInfo: 'Tímto nám dáváte vědet, že máte zájem uskutečnit projekt - nejedná se o závaznou objednávku. Brzy se vám ozveme, domluvíme si další postup a rozpočet.',
    orderSent: 'Děkujeme, objednávku jste úspěšně odeslali!',
    orderSentNote1: 'V mezičase si můžete prohlížet vhodné herce z naší databáze. Prosíme, abyste však herce nekontaktovali do té doby, než si schválíme kalkulaci.',
    orderSentNote2: 'Brzy se Vám ozveme!',
    client: 'Klient',
    brand: 'Značka',
    campaignName: 'Název kampaně',
    agency: 'Reklamní agentura',
    director: 'Režisér',
    projectName: 'Název díla',
    producer: 'Producent',
    tvStation: 'TV stanice',
    interpret: 'Interpret',
    photographer: 'Fotograf',
    briefProjectDescription: 'Stručný popis projektu',
    briefProjectDescriptionHint: 'Tyto informace uvidí i oslovení herci',
    photoShootingDays: 'Termíny focení',
    shootingDays: 'Natáčecí dny',
    photoShootingDate: 'Datum focení',
    shootingDate: 'Datum natáčení',
    submit: 'Odeslat objednávku',
    comercialAd: 'Komerční reklama',
    charityAd: 'Charitativní reklama',
    fullMovie: 'Celovečerní film',
    studentsMovie: 'Studentský film',
    series: 'Seriál',
    clip: 'Videoklip',
    comercialPhoto: 'Komerční focení',
    artPhoto: 'Umělecké focení',
    charityPhoto: 'Charitativní focení'
  },
  AddRoleDialog: {
    headline: 'Vyberte typ role',
    salaryHeadline: 'Položky mzdy',
    daily: 'Denní nasazení',
    buyout: 'Výkup licence',
    addBuyout: 'Přidat užití',
    addOther: 'Přidat další',
    budget: 'Celková mzda',
    createRole: 'Vytvořit roli',
    roleDetails: 'Detaily role',
    roleName: 'Název role',
    roleDescription: 'Veřejný popis role',
    perOneDay: 'x 1 den',
    czk: 'Kč',
    country: 'Země',
    countryExample: 'např. CZ, DE',
    mediaType: 'Typ média',
    mediaTypeExample: 'např. TV, online, print',
    period: 'Doba',
    other: 'Ostatní',
    hintName: 'např. Kostýmní zkouška',
    hintDescription: 'např. Extra poplatek za oholení hlavy'
  },
  EditProjectDialog: {
    editProject: 'Upravit projekt'
  },
  EditRoleDialog: {
    headline: 'Upravit roli',
    genericHeadline: 'Obecné informace',
    noSalaryError: 'Není vyplněná mzda',
    salary: 'Mzda'
  },
  EditEventDialog: {
    headline: 'Upravit popis události'
  },
  NotifyActorsDialog: {
    headline: 'Oslovit herce',
    infoShownToActor: 'Informace, které herci uvidí',
    infoNotShownToActor: 'Herci uvidí pouze informace o roli, do které byli vybráni a nikdy neuvidí seznam ostatních herců.',
    roleDescription: 'Popis role',
    total: 'Celkem',
    selectedActors: 'Vybraní herci',
    showBigThumbnail: 'Zobrazit velké náhledy',
    addNote: 'Přidat poznámku'
  },
  ProfileDetail: {
    addRole: 'Přidat roli'
  },
  ProfileMultiView: {
    sharingLink: 'Odkaz ke sdilení',
    sharingName: 'Zadejte název sdílení',
    name: 'Název',
    createLink: 'Vytvořit odkaz'
  },
  SelectWinnerDialog: {
    selectWinner: 'Vyberte vítěze role',
    noCandidatesYet: 'Pro tuto roli zatím nemáte vybrané herce, kteří potvrdili účast.',
    text1: 'Jen jeden klik Vás dělí od potvrzení herce! Jakmile tak učiníte, kontaktujeme ho ohledně podpisu smlouvy. Držíme palce!',
    text2: 'Odesláním výše uvedené objednávky je závazné a potvrzujete jím, že mezi Vámi a provozovatelem dochází k objednání služeb v souladu s uzavřenou Rámcovou smlouvou o poskytování služeb.',
    selectWinnerCta: 'Vybrat vítěze',
    selectWinners: 'Objednat herce'
  },
  ShareVideosDialog: {
    pickVideosToRate: 'Vyberte videa, která chcete nechat ohodnotit seznamem zainteresovaných osob',
    videosShared: 'Sdílená videa',
    shareWith: 'Sdílet s',
    share: 'Sdílet'
  },
  Chat: {
    empty: "Ve sdílené komunikaci zatím nikdo nic nenapsal,<br />buďte první <span style=\"font-size: 16px\">🙂</span>"
  },
  ShareProfilesDialog: {
    title: "Sdílet herce",
    subtitle: "Sdílejte vybrané herce pomocí odkazu.",
    allowSearch: "Umožnit vyhledávat další herce",
    createLink: "Vytvořit odkaz ke sdílení"
  },
  SharedRoles: {
    filter: "Filtrovat",
    selectActors: "Vybrat herce",
    selectActorsFilter: "Vybrat herce podle filtru",
    share: "Sdílet výběr herců",
    removeSelection: "Zrušit výběr",
    nameTitle: "Uveďte, prosím, své jméno"
  },
  ShareRolesDialog: {
    winner: "Vítěz",
    accepted: "Přijali",
    rejected: "Odmítli",
    addressed: "Neodpověděli",
    added: "Neosloveni",
  },
  States: {
    addedF: "Neoslovena",
    added: "Neosloven",
    addressedF: "Oslovena",
    addressed: "Osloven",
    acceptedF: "Přijala",
    accepted: "Přijal",
    rejectedF: "Odmítla",
    rejected: "Odmítl",
    winner: "Vítěz",
    money: "Mzda",
    time: "Čas",
    skill: "Schopnosti",
    brand: "Značka",
    other: "Ostatní",
  },
  VideoPlayer: {
    iLike: 'Líbí se mi',
    notRanked: 'Zatím nehodnoceno',
    commentary: 'Komentář'
  },
  SendMessageDialog: {
    header: 'Odeslat dotaz',
    chooseActors: 'Vyberte herce, které chcete oslovit',
    message: 'Napište dotaz, který chcete odeslat vybraným hercům',
    answers: 'Zadejte odpovědi, které mohou herci zvolit',
    more: 'Přidejte možnost',
    byPressing: 'stiskuntím klávesy ',
    enter: 'enter ↵',
    send: 'Odeslat'
  },
  DislikedProfiles: {
    notDislikedYet: 'Zatím jste nikoho nevyřadili',
    dislike: 'Vyřazení'
  },
  Event: {
    addRoles: 'Přidat role',
    shareVideos: 'Sdílet videa',
    roles: 'Role',
    videos: 'Videa',
    cast: 'Obsazení',
    instructions: 'Instrukce',
    missingInstructions: 'Nejsou vyplněny instrukce',
    videoUploaded: ' Video nahráno',
    noInterestYet: 'Zatím nikdo nepotvrdil zájem o roli',
    addDescription: 'Vyplnit popis',
    showBigThumbnail: 'Zobrazit velké náhledy',
    addEventAttendees: ' Přidat účastníky ',
    mustAddDescription: 'Přidat popis',
    urlToShare: 'Odkaz ke sdílení'
  },
  Files: {
    notRankedYet: 'Nehodnoceno',
    addSharingName: 'Zadejte nazev sdileni',
    name: 'Název',
    createUrl: 'Vytvorit odkaz',
    commentOnActor: 'Komentáře k herci',
    commentary: 'Komentář'
  },
  Messages: {
    emptyHeader: 'V tomto projektu jste zatím nepoložili dotaz',
    emptyButton: 'Poslat dotaz',
    questions: 'Dotazy'
  },
  Forgot: {
    passForgotten: 'Zapomenuté heslo',
    enterEmail: 'Zadejte, prosím, Váš e-mail',
    passReset: 'Obnovit heslo',
    resetting: 'Obnovuji...'
  },
  Layout: {
    projectSummary: 'Přehled projektu',
    roles: 'Role',
    search: 'Vyhledávání',
    menu: 'Menu',
    events: 'Události',
    communication: 'Komunikace s herci'
  },
  LikedProfiles: {
   notLikedYet: 'Zatím jste si nikoho neoblíbili',
   liked: 'Oblíbení'
  },
  Results: {
    showNextProfiles: 'Zozbrazit další profily'
   },
  Role: {
    noRoleYet: 'Zatím nebyla přidána žádná role',
    noRoleDescriptionYet: 'Není vyplněný popis role',
    addSalary: 'Zadat mzdu',
    showBigThumbnail: 'Zobrazit velké náhledy',
    actorsWithNoOffer: 'Máte přidány herce, kterým jste neodeslali poptávku!',
    sendOffer: 'Odeslat poptávku',
    deleteRoleQuestion: 'Opravdu chcete smazat roli',
    deleteEventAlert: 'Účastníci, kteří potvrdili svou účast budou notifikování o smazání události a akce už nepůjde vrátit. Chcete opravdu pokračovat?',
    reallyDeleteActorPart1: 'Opravdu chcete odstranit ',
    reallyDeleteActorPart2: ' z role ',
    share: 'Sdílet',
    shareSuggest: ' navrhuje, aby byl herec osloven'
   }, 
   Search: {
    category: 'Kategorie',
    closePane: 'Zavřít panel',
    mostPopular: 'Nejpopulárnější',
    newest: 'Nejnovější',
    small: 'Malé',
    normal: 'Normální',
    large: 'Velké',
    list: 'Seznam',
    head: 'Hlava',
    profile: 'Profil',
    body: 'Postava',
    hands: 'Ruce',
    legs: 'Nohy',
    faceSearch: 'Vyhledat podobné tváře',
    removeActor: 'Odstranit z role',
    notifyActor: ' Oslovit herce'
   },
   SharedEvent: {
    yourName: 'Uveďte své jméno pro hodnocení videí',
    name: 'Jméno'
   },
   Signin: {
    password: 'Heslo',
    passForgotten: 'Zapomenuté heslo',
    ctaSignIn: 'Přihlásit se'
   },
   Projects: {
    emptyText: 'Začněte vytvořením projektu',
    create: 'Vytvořit projekt',
    client: 'Klient',
    agency: 'Agentura',
    shootingCountdown: 'Do natáčení zbýva',
    days: 'dní',
    shootingDaysAgo: 'Natáčení proběhlo před',
    day: 'dnem',
    dayPlural: 'dny',
    responseRateHeader: 'Zatím se vyjádřilo',
    responseRateText: '% herců',
    acceptRateHeader: 'Role přijalo',
    notifications: 'Upozornění',
    occupancyRate: 'Obsazenost',
    calendar: 'Kalendář',
    occupancy: 'Obsazenost',
    budget: 'Rozpočet',
    salaries: 'Honoráře',
    management: 'Správa projektu',
    selfService: 'Projekt si budu spravovat sám',
    managedProject: 'Projekt potřebuji spravovat',
    standardCallback: 'Potřebuji klasický callback',
    onlineCallback: 'Stačí mi online callback',
    free: 'Zdarma',
    needCoordinator: 'Chci na place koordinátora komparzistů',
    noCoordinator: 'Koordinátora komparzistů nepotřebuji',
    budgetSum: 'Celková cena',
    projectName: 'Název projektu',
    pieceName: 'Název díla',
    about: 'O projektu',
    addProject: 'Přidat projekt',
    addRole: 'Přidat roli',
    addEvent: 'Přidat událost',
    inviteToCasting: 'Pozvat na casting',
    addDescription: 'Přidat popis',
    addSalary: 'Přidat honorář',
    emptyRolesText: 'Máte neobsazené role',
    emptyRolesButton: 'Obsadit',
    emptyEvents: 'Projekt neobsahuje události',
    emptyProjectDescription: 'Projekt neobsahuje popis',
    notAddressedActorsText: 'Máte přidány herce, kterým jste neodeslali poptávku',
    noRoles: 'Projekt neobsahuje role',
    role: 'Role',
    total: 'Osloveno',
    addressed: 'Neodpovědělo',
    rejected: 'Odmítlo',
    accepted: 'Přijalo'
   },
   Account: {
    likedProfiles: 'Oblíbené profily',
    hiddenProfiles: 'Skryté profily',
    readMe: 'Přečtěte si, prosím',
    tipsAndTricks: 'Tipy a triky',
    termsOfService: 'Podmínky poskytování služeb',
    agreement: 'Rámcová smlouva',
    projectCalculator: 'Kalkulace projektu',
    inviteUser: 'Pozvat uživatele',
    signOut: ' Odhlásit se'
   },
   Events: {
    rolesSummary: 'Souhrn rolí',
    addEvent: 'Přidat událost',
    noEventYet: 'Zatím nebyla vytvořena žádná událost',
    eventMustExists: 'Abyste mohli přidat událost, musí existovat role',
    createEvent: 'Vytvořit událost',
    addInstructions: 'Přidat instrukci',
    addProject: 'Přidat projekt',
    addRole: 'Přidat roli',
    addQuestion: 'Odeslat dotaz',
    deleteEventAlert: 'Herci, kteří potvrdili svou účast budou notifikování o smazání události a akce už nepůjde vrátit. Chcete opravdu pokračovat?'
   },
   ProjectsList: {
    projects: 'Projekty',
    addProject: ' Přidat projekt'
   },
   Filter: {
    filter: 'Filtr',
    age: 'Věk',
    any: 'nerozhoduje',
    height: 'Výška',
    weight: 'Váha'
   },
   ProjectDocuments: {
    documents: 'Dokumenty'
   },
  createEvent: 'Vytvořit událost',
  invite: 'Pozvat',
  create: 'Vytvořit',
  footer: {
    link1: 'Podmínky poskytování služby Aircasting',
    link2: 'Informace o zpracování osobních údajů',
    contactUs: 'Kontaktujte nás',
    copyright: '2019 New things on the net, s.r.o.'
  },
  profile: {
    addOtherVideo: 'Přidat jiné video',
    addVideo: 'Přidat video',
    jobOffersTitle: 'Pracovní nabídky',
    jobOffersText: 'Připravte se! Zde uvidíte informace o vašich pracovních nabídkách. Hodně štěstí!'
  },
  men: 'Muži',
  women: 'Ženy',
  ads: 'Reklama',
  movies: 'Film',
  photoshoot: 'Focení',
  role: 'Role',
  select: 'Vybrat',
  close: 'Zavřít',
  closeOk: 'OK',
  itemName: 'Název',
  description: 'Popis',
  czkCurrency: 'Kč',
  dailySalary: 'Denní mzda',
  video: 'Video',
  pictures: 'Fotogragie',
  send: 'Odeslat',
  edit: 'Upravit',
  delete: 'Smazat',
  ctaSearch: 'Vyhledat',
  addDescription: 'Vyplnit popis',
  buyout: 'Výkup licence',
  castingOnline: 'Online casting',
  callbackOnline: 'Online callback',
  callbackOffline: 'Offline callback',
  fitting: 'Kostýmní zkouška',
  shooting: 'Natáčení',
  age: '{age} let',
  complete: 'Doplnit',
  cookieConsent: 'Používáním tohoto webu souhlasíte, že k poskytování služeb a analýze návštěvnosti používáme soubory cookie.',
  cookieMoreInfo: 'Více informací',
  cookieAgree: 'Souhlasím',
  offerProjectHeader: 'Máte novou pracovní nabídku!',
  offerEventHeader: 'Nová událost - {type}',
  date: 'Datum:',
  accept: 'Přijmout',
  decline: 'Odmítnou',
  cancel: 'Storno',
  letMeThink: 'Musím si to rozmyslet',
  signInHeader: 'Přihlášení',
  signInButton: 'Příhlásit se',
  signInErrorNotAuthorized: 'Chybný e-mail nebo heslo',
  signInErrorNotRegistered: 'Tento účet zatím nebyl zaregistrován',
  signInErrorLimit: 'Prekročili jste počet povolených pokusů',
  signInError: 'Nastala chyba, přihlašte se znovu',
  signInEmail: 'Zadejte e-mail',
  signInValidEmail: 'Zadejte platný e-mail',
  signInPassword: 'Zadejte heslo',
  pickVideo: 'Vybrat video',
  pickYoutube: 'Vložit odkaz na Youtube',
  uploadVideo: 'Nahrát video',
  uploadingVideo: 'Vaše video právě nahráváme',
  uploadingMessage: 'Může to zabrat i několik minut. Vyčkejte, prosím.',
  navBack: 'Zpět',
  describeSelf: 'Napište něco o sobě...',
  editProfile: 'Doplnit profil',
  showProfile: 'Zobrazit můj profil',
  weakProfileTitle: 'Vašemu profilu schází důležité informace',
  weakProfileText: 'Produkcím se přednostně zobrazují kompletní profily, a tak si toho vašeho nemusí všimnout. Odpovězte na zbylé otázky a jděte šteští naproti.',
  noVideoTitle: 'Vašemu profilu schází video.',
  noVideoText: 'Produkcím se přednostně zobrazují kompletní profily, a tak si toho vašeho nemusí všimnout. Doplňte video a jděte šteští naproti.',
  noPhotoTitle: 'Vašemu profilu schází fotografie',
  noPhotoText: 'Produkcím se přednostně zobrazují kompletní profily, a tak si toho vašeho nemusí všimnout. Doplňte vaše fotografie a jděte šteští naproti.',
  iWillAttend: 'Účastním se',
  iWontAttend: 'Odmítnuto',
  readMore: 'Číst dál',
  signout: 'Odhlásit se',
  basicInfo: 'Základní informace',
  appearance: 'Vzhled',
  experience: 'Zkušenosti',
  salary: 'Mzda',
  next: 'Pokračovat',
  prev: 'Předchozí',
  skip: 'Přeskočit',
  save: 'Uložit',
  yes: 'Ano',
  no: 'Ne',
  actor: 'Herec',
  model: 'Model',
  stuntman: 'Kaskadér',
  artiste: 'Artista',
  dancer: 'Tanečník',
  singer: 'Zpěvák',
  moderator: 'Moderátor',
  voiceActor: 'Dabér',
  speaker: 'Speaker',
  sportsman: 'Sportovec',
  noProfession: 'Nejsem z oboru, zkusím štěstí!',
  extra: 'Komparzista',
  actorF: 'Herečka',
  modelF: 'Modelka',
  stuntmanF: 'Kaskadérka',
  artisteF: 'Artistka',
  dancerF: 'Tanečnice',
  singerF: 'Zpěvačka',
  moderatorF: 'Moderátorka',
  voiceActorF: 'Dabérka',
  speakerF: 'Speaker',
  sportsmanF: 'Sportovkyně',
  noProfessionF: 'Nejsem z oboru, zkusím štěstí!',
  extraF: 'Komparzistka',
  pickPhoto: 'Vybrat fotku',
  usePhoto: 'Použít',
  pro: 'Profesionál',
  hobby: 'Hobby',
  underSixteen: 'Profily pro mladší 16 let zatím nelze vytvořit. K dispozici budou, co nevidět. Sledujte naše sociální sítě!',
  contactFormHeader: 'Jak vám můžeme pomoci?',
  notificationReassurance: 'Připravte se! Zde uvidíte informace o vašich pracovních nabídkách. Hodně štěstí!',
  videoUploadHeader: 'Vaše video právě nahráváme',
  videoUploadSubheader: 'To může zabrat i pár minut. Vydržte, prosím.',
  fullname: {
    name: 'Jméno',
    header: 'Nejdříve nám sdělte vaše celé jméno.',
    label: 'Vaše celé jméno'
  },
  email: {
    name: 'E-mail',
    header: 'A jaký je váš e-mail, {declinedName}?',
    subheader: 'Bude sloužit pro přihlášení do Aircastingu a komunikaci s námi. Nebojte, spamu nefandíme.',
    label: 'Váš e-mail'
  },
  password: {
    name: 'Heslo',
    subheader: 'Tímto způsobem se budete přihlašovat vždy. ',
    header: 'Jak se chcete přihlašovat?',
    headerVerify: 'Zadejte ověřovací kód',
    subheaderVerify: 'Poslali jsme vám ho na e-mail. Slouží k ověření, abychom měli jistotu, že registraci provádíte vy osobně. ',
    register: 'Zaregistrovat',
    ownPassword: 'Přes vlastní heslo',
    signedInAs: 'Přihlášen jako {user}',
    verificationCode: 'Ověřovací kód',
    error: 'Nastala chyba, přihlašte se, prosím, znovu',
    errorAlreadyRegistered: 'Účet s tímto e-mailem už existuje',
    errorGeneric: 'Nastala neočekávaná chyba ({code})',
    errorVerify: 'Ověřovací kód je chybný'
  },
  sex: {
    name: 'Pohlaví',
    header: 'Vaše pohlaví?',
    man: 'Muž',
    woman: 'Žena'
  },
  dob: {
    name: 'Datum narození',
    header: 'Kdy jste se narodil?',
    headerF: 'Kdy jste se narodila?',
    rule1: 'Zadejte datum ve formátu DD. MM. RRRR',
    rule2: 'Profily pro mladší 16 let zatím nelze vytvořit. K dispozici budou, co nevidět.'
  },
  country: {
    name: 'Pobyt',
    header: 'Bydlíte v České republice?'
  },
  countryForeign: {
    name: 'Pobyt',
    header: 'Kde v zahraničí bydlíte?',
    label: 'Vyberte zemi'
  },
  address: {
    name: 'Pobyt',
    header: 'V dosahu kterého krajského města se nejčastěji zdržujete?',
    label: 'Vyberte město'
  },
  phone: {
    name: 'Telefon',
    header: 'Dejte nám, prosím, telefonní číslo.',
    subheader: 'Nebojte se, nebude veřejné. Použijeme ho třeba, až získáte roli.',
    label: 'Váš telefon'
  },
  selectedProfessions: {
    name: 'Specializace',
    header: 'Přišel jste k nám, protože jste...',
    headerF: 'Přišla jste k nám, protože jste...'
  },
  mainProfession: {
    name: 'Hlavní specializace',
    header: 'V čem z toho se cítíte být nejlepší, {declinedName}?'
  },
  roleType: {
    name: 'Preferované role',
    header: 'O jaké role máte zájem?',
    subheader: '(Můžete zvolit více možností)',
    leading: 'Hlavní',
    supporting: 'Vedlejší',
    featuredExtras: 'Epizodní',
    extra: 'Komparz',
    extras: 'Komparz'
  },
  tags: {
    name: 'Zájmy',
    header: 'Personalizujte svůj profil, {declinedName}, a přibližte nám vaši osobnost pomocí tagů.',
    subheader: 'Například: běh, Star Wars, gurmán, Karel Gott, vegan',
    label: 'Tagy oddělte enterem'
  },
  driver: {
    name: 'Řidičský průkaz',
    header: 'Máte řidičský průkaz?'
  },
  driverLicenses: {
    name: 'Umím řídit',
    header: 'A co umíte řídit?',
    subheader: 'Nebo pilotovat...',
    car: 'Auto',
    motorbike: 'Motorka',
    moped: 'Moped',
    truck: 'Kamion',
    bus: 'Autobus',
    tractor: 'Traktor',
    plane: 'Letadlo'
  },
  driverLicensesMotorbike: {
    name: 'Řidičský průkaz na motorku',
    header: 'Jaké oprávnění na motorku máte?',
    A1: 'Motorka do 125cm3 (A1)',
    A2: 'Motorka do 35kW (A2)',
    A: 'Motorka nad 35kW (A)'
  },
  driverLicensesPlane: {
    name: 'Pilotní licence',
    header: 'Jakou pilotní licenci máte?',
    subheader: 'I believe I can flyyy...',
    PPL: 'PPL',
    CPL: 'CPL',
    ATPL: 'ATPL'
  },
  etnicity: {
    name: 'Etnický vzhled',
    header: 'K jakému etnickému typu byste se převážně přiřadil?',
    headerF: 'K jakému etnickému typu byste se převážně přiřadila?',
    subheader: 'Tento výběr přirozeně nepředstavuje celou paletu světových etnik, ale zohledňuje nejčastejší klasifikační požadavky ze stran agentur. Vyberte, prosím, to nejvíce příbuzné vám.',
    arabian: 'Arabský',
    asian: 'Asijský',
    white: 'Bělošský',
    whiteSouthern: 'Bělošský jižanský',
    whiteNorthern: 'Bělošský severský',
    black: 'Černošský / Afroamerický',
    euroasian: 'Euroasijský',
    hispanic: 'Hispánský',
    indian: 'Indiánský',
    ind: 'Indický',
    mulato: 'Mulato',
    gipsy: 'Romský'
  },
  figure: {
    name: 'Postava',
    header: 'Uveďte typ vaší postavy.',
    skinny: 'Štíhlá',
    athletic: 'Atletická',
    normal: 'Normál',
    plus: 'Plus',
    muscle: 'Svalnatá'
  },
  height: {
    name: 'Výška',
    header: 'Kolik měříte?',
    label: 'Výška'
  },
  hairLength: {
    name: 'Délka vlasů',
    header: 'Vyberte délku vašich vlasů.',
    extraShort: 'Extrémně krátké',
    short: 'Krátké',
    midShort:	'Středně dlouhé',
    long: 'Dlouhé',
    extraLong: 'Extra dlouhé',
    bald: 'Pleš'
  },
  hairCut: {
    name: 'Vlasy',
    header: 'Jaký nosíte střih?',
    classic: 'Klasický',
    mikado: 'Mikádo',
    bangs: 'Ofina',
    mullet: 'Mullet',
    asymetric: 'Asymetrický',
    sideShaved: 'S vyholenými stranami',
    undercut: 'Podholený',
    layered: 'Sestříhaný',
    crewCut: 'Na ježka',
    mohawk: 'Číro',
    fringe: 'Ofina',
    topKnot: 'Pánský drdol',
    fohawk: 'falešné číro'
  },
  hairColorNatural: {
    name: 'Barva vlasů',
    header: 'Jakou barvu mají vaše vlasy?',
    lightBlond: 'Blond světlá',
    darkBlond: 'Blond tmavá',
    black: 'Černá',
    lightBrown: 'Hnědá světlá',
    darkBrown: 'Hnědá tmavá',
    grey: 'Šedivá',
    red: 'Zrzavá',
    other: 'Jiná'
  },
  hairColorDyed: {
    name: 'Barva vlasů',
    header: 'Jakou barvu mají vaše vlasy?',
    red: 'Červená',
    darkRed: 'Tmavě červená',
    purple: 'Fialová',
    copper: 'Měděná',
    blue: 'Modrá',
    orange: 'Oranžová',
    pink: 'Růžová',
    grey: 'Šedá',
    cyan: 'Tyrkysová',
    green: 'Zelená',
    yellow: 'Žlutá',
    bleached: 'Odbarvené vlasy'
  },
  hairType: {
    header: 'Jaký máte typ vlasů?',
    name: 'Typ vlasů',
    dreadlocks: 'Dredy',
    rastaBraids: 'Copánky',
    ponytails: 'Copánky',
    wavy: 'Vlnité',
    curly: 'Kudrnaté',
    straight: 'Rovné',
    afro: 'Afro',
    wig: 'Paruka'
  },
  tattoo: {
    name: 'Tetování',
    header: 'Máte nějaká tetování?'
  },
  tattooPlacements: {
    name: 'Umístění tetování',
    header: 'Kde máte tetování?',
    arms: 'Ruce',
    legs: 'Nohy',
    face: 'Obličej',
    belly: 'Břicho',
    back: 'Záda',
    neck: 'Krk',
    chest: 'Hruď',
    head: 'Hlava'
  },
  tattooArmsPhoto: {
    name: 'Fotografie tetování rukou',
    header: 'Nahrajte fotku tetování na vašich rukou.'
  },
  tattooLegsPhoto: {
    name: 'Fotografie tetování nohou',
    header: 'Nahrajte fotku tetování na vašich nohách.'
  },
  tattooBackPhoto: {
    name: 'Fotografie tetování zad',
    header: 'Nahrajte fotku tetování na vašich zádech.'
  },
  tattooNeckPhoto: {
    name: 'Fotografie tetování krku',
    header: 'Nahrajte fotku tetování na vašem krku.'
  },
  tattooChestPhoto: {
    name: 'Fotografie tetování hrudi',
    header: 'Nahrajte fotku tetování na vaší hrudi.'
  },
  tattooFacePhoto: {
    name: 'Fotografie tetování obličeje',
    header: 'Nahrajte fotku tetování na vašem obličeji.'
  },
  tattooHeadPhoto: {
    name: 'Fotografie tetování hlavy',
    header: 'Nahrajte fotku tetování na vaší hlavě.'
  },
  mainPhoto: {
    name: 'Hlavní fotografie',
    header: 'Teď, {declinedName}, nahrajte vaši profilovou fotku.',
    singleHeader: 'Nahrajte vaši profilovou fotku',
    subheader: 'Portrétní fotografie vyfocena od ramen nahoru. Musí být barevná a ideálně na jednolitém pozadí.'
  },
  frontPhoto: {
    header: 'Nahrajte fotku vašeho obličeje.'
  },
  rightPhoto: {
    header: 'Nahrajte fotku profilu zprava.'
  },
  leftPhoto: {
    header: 'Nahrajte fotku profilu zleva.'
  },
  figurePhoto: {
    header: 'Nahrajte fotku vaší postavy.',
    subheader: 'Fotografie celé vaší postavy - zepředu, včetně hlavy a chodidel'
  },
  handsPhoto: {
    header: 'Nahrajte fotku vašich rukou.',
    subheader: 'Fotografie obou vašich rukou - dlaněmi dolů. Ruce by měly být vedle sebe. Prsty a nehty musí být zřetelně vidět.'
  },
  selfiePhoto: {
    header: 'Nahrajte vaše selfie.',
    subheader: 'Jedná se o vaši běžnou selfie fotografii'
  },
  weight: {
    name: 'Váha',
    header: 'Kolik vážíte?',
    label: 'Váha'
  },
  eyeColor: {
    name: 'Barva očí',
    header: 'Uveďte barvu vašich očí.',
    black: 'Černá',
    brown: 'Hnědá',
    blue: 'Modrá',
    grey: 'Šedá',
    green: 'Zelená',
    yellow: 'Žlutá'
  },
  teethType: {
    name: 'Chrup',
    header: 'V jakém stavu je váš chrup?',
    subheader: 'Rovnátka, plomba z dětství, nebo vytržená osmička se jako vada nepočítají.',
    normal: 'Normální',
    defect: 'S viditelnou vadou'
  },
  teethDefect: {
    name: 'Vady chrupu',
    header: 'Uveďte, prosím, jakou vadu chrupu máte.',
    fixedBraces: 'Fixní rovnátka',
    missingTeeth: 'Chybějíci zuby',
    gaps: 'Chrup s mezerami',
    crooked: 'Křivé zuby',
    overbite: 'Předkus',
    underbite: 'Podkus',
    bruisedTeeth: 'Zkažené zuby',
    fake: 'Umělé zuby',
    implant: 'Barevný implantát'
  },
  teethColor: {
    name: 'Barva zubů',
    header: 'Vyberte barvu vašich zubů.',
    white: 'Zářivě bílá',
    normal: 'Normální',
    pigmentSpots: 'S pigmentovými skvrnami',
    yellow: 'Žlutá'
  },
  teethBraces: {
    name: 'Rovnátka',
    header: 'Nosíte fixní rovnátka?'
  },
  beard: {
    name: 'Vousy',
    header: 'Máte vousy?'
  },
  beardColor: {
    name: 'Barva vousů',
    header: 'Jakou barvu mají vaše vousy?',
    black: 'Černé',
    brown: 'Hnědé',
    blond: 'Blond',
    grey: 'Šedivé',
    red: 'Zrzavé'
  },
  beardType: {
    name: 'Úprava vousů',
    header: 'Jak si upravujete vousy?',
    stubble: 'Strniště',
    beard: 'Brada',
    mustacheChinStrap: 'Knír a bradka',
    ovalBeard: 'Ovál',
    musketeerBeard: 'Muška',
    muttonChops: 'Kotlety',
    sideburns: 'Licousy',
    emperorBeard: 'Císař',
    horseshoeMustache: 'Podkova',
    goatee: 'Bradka',
    chinstrap: 'Proužek na bradě',
    mustache: 'Knír',
    klingon: 'Plná bradka s odděleným knírem',
    shortBeard: 'Kratší plnovous',
    longBeard: 'Dlouhý plnovous'
  },
  piercing: {
    name: 'Piercing / Tělesné modifikace',
    header: 'Máte piercing nebo nějaké tělesné modifikace?',
    subheader: 'Intimní a jiné skryté partie neuvádějte.'
  },
  piercingPlacements: {
    name: 'Piercing a jiné',
    header: 'Kde máte piercing nebo nějaké tělesné modifikace?',
    chin: 'Brada',
    tongue: 'Jazyk',
    nose: 'Nos',
    lips: 'Rty',
    eyebrow: 'Obočí',
    ears: 'Uši',
    earTunnels: 'Tunely v uších',
    face: 'Tváře',
    clavicle: 'Klíční kosti',
    teeth: 'Zuby',
    head: 'Čelo / Hlava',
    belly: 'Pupík',
    other: 'Jiná část těla'
  },
  measures: {
    name: 'Míry',
    header: 'Dáte nám svoje míry?',
    subheader: 'Ujistěte se, že máte u sebe metr, potřebujeme je přesně.',
    yes: 'Ano, teď',
    later: 'Později'
  },
  measuresBreast: {
    name: 'Obvod hrudníku',
    header: 'Změřte si obvod hrudníku.'
  },
  measuresWaist: {
    name: 'Obvod pasu',
    header: 'Teď změřte obvod vašeho pasu.'
  },
  measuresHips: {
    name: 'Obvod boků',
    header: 'A ješťe obvod vašich boků.'
  },
  measuresHead: {
    name: 'Obvod hlavy',
    header: 'Jaký je obvod vaší hlavy?',
    subheader: 'Třeba kvůli klobouku...'
  },
  measuresNeck: {
    name: 'Obvod krku',
    header: 'Ještě poprosíme o obvod vašeho krku.',
    subheader: 'Nebojte, s mírami už skoro končíme...'
  },
  measuresLeg: {
    name: 'Délka nohavic',
    header: 'Zbývá uvést délku nohavice.',
    label: 'Od kyčle ke kotníku'
  },
  shoe: {
    name: 'Velikost bot',
    header: 'Jakou velikost bot nosíte?',
    subheader: 'Podle evropského číslováni.'
  },
  size: {
    name: 'Konfekční velikost',
    header: 'Jakou nosíte konfekční velikost?',
    XSX: 'XXS',
    XS: 'XS',
    S: 'S',
    M: 'M',
    L: 'L',
    XL: 'XL',
    XXL: 'XXL',
    XXXL: 'Větší než XXL'
  },
  actorType: {
    name: 'Herectví',
    header: 'Kde jako herec převážně působíte?',
    theater: 'Divadlo',
    movies: 'Film',
    ads: 'Reklama',
    tv: 'Televize',
    musical: 'Muzikál',
    standUp: 'Stand-up',
    amateur: 'Amatér'
  },
  modelType: {
    name: 'Modeling',
    header: 'Se kterým typem modelingu máte zkušenosti?',
    photo: 'Focení',
    ads: 'Reklama',
    fashionShow: 'Přehlídková mola',
    legmodel: 'Nohy (legmodel)',
    handmodel: 'Ruce (handmodel)',
    facemodel: 'Tvář (facemodel)'
  },
  modelPhoto: {
    name: 'Fotografie nohou',
    header: 'Nahrajte fotku vašich nohou.',
    subHeader: 'Zepředu, od pasu dolů.'
  },
  stuntmanType: {
    name: 'Kaskadérské disciplíny',
    header: 'Se kterým typem kaskadérství máte zkušenosti?',
    car: 'Automobilové',
    motorbike: 'Motorky a motokáry',
    martialArts: 'Bojové sporty',
    swordplay: 'Šerm',
    shooting: 'Střelba',
    horse: 'Jízda na koni',
    falling: 'Pády z výšky',
    fire: 'Živý oheň',
    parachute: 'Letecké a parašutistické výkony'
  },
  artisteType: {
    name: 'Artistické disciplíny',
    header: 'Se kterým typem artistických disciplín máte zkušenosti?',
    acrobatics: 'Akrobacie',
    rings: 'Kruhy',
    fire: 'Ohňová show',
    trampoline: 'Skoky na trampolíně',
    juggling: 'Žonglování',
    equilibristics: 'Ekvilibristika',
    magic: 'Kouzelnictví',
    horse: 'Krasojízda na koni',
    snakes: 'Hadí muž',
    snakesF: 'Hadí žena',
    taming: 'Krocení zvířat'
  },
  dancerSkill: {
    name: 'Tanec',
    header: 'Věnujete se tanci jako...'
  },
  dancerStyle: {
    name: 'Taneční styly',
    header: 'Kterým tanečním stylům se věnujete?'
  },
  musicianSkill: {
    name: 'Hudba',
    header: 'Věnujete se hudbě jako...'
  },
  musicianInstrument: {
    name: 'Hudební nástroje',
    header: 'Na které hudební nástroje hrajete?'
  },
  musicianStyle: {
    name: 'Hudební žánry',
    header: 'Kterým hudebním žánrům se věnujete?'
  },
  athleteSkill: {
    name: 'Sport',
    header: 'Věnujete se sportu jako...'
  },
  athleteType: {
    name: 'Sportovní disciplíny',
    header: 'Kterým sportovním disciplínám se věnujete?'
  },
  singerSkill: {
    name: 'Zpěv',
    header: 'Věnujete se zpěvu jako...'
  },
  singerType: {
    name: 'Pěvecké styly',
    header: 'Kterým pěveckým stylům se věnujete?'
  },
  moderatorType: {
    name: 'Moderování',
    header: 'Kterému typu moderace se věnujete?',
    tv: 'TV',
    radio: 'Rádio',
    actions: 'Akce (veřejné, firemní)'
  },
  experienceProfession: {
    name: 'Zkušenosti',
    header: 'Popište nám krátce vaše {job} zkušenosti.',
    subheader: 'Uveďte, na co jste nejvíce pyšný.',
    subheaderF: 'Uveďte, na co jste nejvíce pyšná.',
    hint: 'Max.1000 znaků.',
    actor: 'herecké',
    model: 'modelingové',
    stuntman: 'kaskadérské',
    artiste: 'artistické',
    dancer: 'taneční',
    singer: 'pěvecké',
    moderator: 'moderátorské',
    voiceActor: 'dabérské',
    speaker: 'speakerské',
    sportsman: 'sportovní',
    extra: 'komparzní'
  },
  motherTongue: {
    name: 'Mateřský jazyk',
    header: 'Jaký je váš mateřský jazyk?'
  },
  foreignLanguages: {
    name: 'Jazykové dovednosti',
    header: 'Jakými cizími jazyky mluvíte, {declinedName}?',
    subheader: 'Uveďte pouze ty, které ovládáte na pokročilé úrovni.'
  },
  handicap: {
    name: 'Zdravotní omezení',
    header: 'Máte nějaké zdravotní omezení?',
    no: 'Žádné',
    eyes: 'Zrakové',
    ears: 'Sluchové',
    movement: 'Pohybové',
    other: 'Jiné'
  },
  handicapText: {
    name: 'Zdravotní omezení - specifikace',
    header: 'Chcete-li, specifikovat vaše zdravotní omezení, vyplňte je, prosím.'
  },
  jobQuestion: {
    name: 'Civilní zaměstnání',
    headerJob: 'Máte kromě {job} nějaké další civilní zaměstnání?',
    header: 'Máte nějaké zaměstnání?',
    actor: 'herectví',
    model: 'modelingu',
    moderator: 'moderování',
    stuntman: 'kaskadérství',
    artiste: 'artiství',
    dancer: 'tančení',
    singer: 'zpívání',
    voiceActor: 'dabování',
    speaker: 'práce s hlasem',
    sportsman: 'sportování',
    extra: 'komparzů'
  },
  civilJob: {
    name: 'Civilní zaměstnání',
    header: 'Jaké je vaše civilní zaměstnání?',
    hint: 'Max. 50 znaků'
  },
  preferredJobs: {
    name: 'Preferované příležitosti',
    header: 'O jaký typ nabídek máte zájem?',
    dabing: 'Dabing',
    theater: 'Divadlo',
    movies: 'Film',
    photos: 'Focení',
    improvisation: 'Improvizace',
    musical: 'Muzikál',
    radio: 'Radio',
    ads: 'Reklama',
    studentsFilm: 'Studentský film',
    videoclip: 'Videoklip',
    voiceover: 'Voiceover',
    hostesing: 'Hostesing'
  },
  welcome: {
    header: 'Vítejte v Aircastingu!',
    subheader: '<p>Věříme, že se vám tu s námi bude líbit. Zbývá už jen dotáhnout váš profil k dokonalosti, doplňte ho o další informace, fotografie a videa.</p> <p>Přejeme spousty nově nabytých zkušeností a zajímavých rolí!</p>'
  },
  photos: {
    header: 'Vyberte svou fotografii'
  },
  parentEmail: {
    name: 'E-mail zákonného zástupce',
    header: 'Zadejte e-mail zákonného zástupce.',
    subheader: 'Tento e-mail bude sloužit pro komunikaci s námi. Nebojte, spamu nefandíme.',
    label: 'E-mail'
  },
  parentPhone: {
    name: 'Telefon zákonného zástupce',
    header: 'Dejte nám, prosím, telefonní číslo zákonného zástupce.',
    subheader: 'Nebojte se, nebude veřejné.',
    label: 'Telefonní číslo'
  },
}
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{key:_vm.renderCnt,staticClass:"elevation-0",staticStyle:{"height":"calc(100% - 64px)"}},[(_vm.getRoles != null || _vm.project != null)?_c('v-card-text',{staticClass:"pl-0 pr-0 pt-0",staticStyle:{"height":"100%","padding-bottom":"64px !important"}},[(!_vm.getRolesInt().length)?[_c('v-layout',{staticClass:"project",staticStyle:{"height":"calc(100% - 64px)","overflow-y":"auto"},attrs:{"row":"","wrap":"","align-center":""}},[_c('v-flex',{staticClass:"text-center",attrs:{"xs12":"","sm12":"","md12":""}},[_c('img',{staticClass:"mx-auto",attrs:{"src":"assets/empty-roles.svg","width":"200"}}),_c('div',{staticClass:"mb-5 mt-5 mx-auto",staticStyle:{"color":"#131d2d","font-size":"18px"}},[_vm._v(_vm._s(_vm.$t('Role.noRoleYet')))])])],1)]:[_c('v-list',{staticClass:"project pl-0 pt-0",staticStyle:{"height":"100%","overflow-y":"auto","overflow-x":"hidden"}},_vm._l((_vm.getRolesInt()),function(role,r){return _c('v-list-group',{key:r,attrs:{"sub-group":"","no-action":"","value":true,"prepend-icon":"expand_less"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(role.roleName)+" "),_c('span',{staticClass:"grey--text text--lighten-2"},[_vm._v("•")]),_vm._v(" "),_c('span',{staticClass:"grey--text text--lighten-1"},[_vm._v(_vm._s(role.picks.length))])])],1),_c('v-list-item-action',[_c('div',{staticClass:"text-right",staticStyle:{"width":"100%"}},[(_vm.project)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(role.picks.length)?_c('v-btn',_vm._g({staticClass:"d-inline-flex",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.showActorsDialog(role.roleName, role.picks)}}},on),[_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("fa-expand")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Role.showBigThumbnail')))])]):_vm._e()],1)])]},proxy:true}],null,true)},[_c('draggable',{staticClass:"pb-4",staticStyle:{"width":"100%"},attrs:{"options":{group: { name:'role',  pull:true, put:true }, disabled: true }},on:{"add":_vm.updateProject},model:{value:(role.picks),callback:function ($$v) {_vm.$set(role, "picks", $$v)},expression:"role.picks"}},_vm._l((role.picks),function(pick,i){return _c('v-list-item',{key:i,staticClass:"should-hover px-0",style:('border-right: 6px solid' + (pick.roleState=='winner' ? ' #65bcff' : ' white')),on:{"click":function($event){_vm.project ? _vm.showProfileDetail(pick) : (_vm.$store.state.dialogs.profileDetail = { show: true, profile: pick, noRefresh: true })}}},[_c('v-list-item-avatar',{staticClass:"py-0 my-0"},[_c('v-avatar',{attrs:{"size":"42px"}},[_c('img',{attrs:{"src":_vm.getImageUrl(pick, pick.mainPhoto, 42)}})])],1),_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',[_vm._v(_vm._s(pick.name || pick.fullname))]),_c('v-list-item-subtitle',{class:_vm.getStateColor(pick.roleState) + "--text",staticStyle:{"font-size":"0.7em"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.getStateColor(pick.roleState),"size":"16"}},[_vm._v(_vm._s(_vm.getStateIcon(pick.roleState)))]),_c('v-tooltip',{attrs:{"right":"","disabled":pick.roleState != 'share-added' || !pick.shareUser},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(_vm.getStateText(pick.roleState, pick.sex, pick.winner, pick.roleStateReason)))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(pick.shareUser + _vm.$t('Role.shareSuggest')))])])],1)],1),_c('v-list-item-action',{staticClass:"hide-on-not-hover ma-0"},[_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(pick.mainPhoto)?_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.findSimilarFaces(pick)}}},on),[_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("image_search")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Search.faceSearch')))])]),(_vm.project || pick.roleState == 'share-added')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.removePickInt(pick, role)}}},on),[_c('v-icon',{attrs:{"small":"","color":"grey lighten-1"}},[_vm._v("close")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Search.removeActor')))])]):_vm._e()],1)])],1)}),1)],1)}),1)],(_vm.user && !_vm.user.lite && _vm.getRoles == null)?_c('v-alert',{staticClass:"notify_alert",attrs:{"value":_vm.showNotifyAlert,"color":"info","dark":"","transition":"scale-transition","dismissible":""}},[_c('v-btn',{staticClass:"ma-0",attrs:{"color":"white","text":""},on:{"click":function($event){_vm.$store.state.dialogs.notifyActors.show = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v("fa-bullhorn")]),_vm._v(" Oslovit herce")],1)],1):_vm._e()],2):_vm._e(),_c('footer',{staticClass:"pane_footer",style:(!_vm.getRolesInt().length ? "bottom: 54px;" : "")},[(_vm.allowAddingRoles)?_c('div',{staticClass:"left"},[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.showRoleDialog(null)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"size":"18"}},[_vm._v("fa-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('Events.addRole')))],1)],1):_vm._e(),_c('div',{staticClass:"right"},[_c('v-btn',{staticClass:"elevation-0",attrs:{"small":"","fab":"","color":"white"},on:{"click":function($event){return _vm.$emit('collapse')}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("fa-angle-left")])],1)],1),_c('div',{staticStyle:{"clear":"both"}})]),_c('ProfileMultiView',{attrs:{"title":_vm.actorsDialog.title,"actors":_vm.actorsDialog.actors,"state":"roleState"},model:{value:(_vm.actorsDialog.show),callback:function ($$v) {_vm.$set(_vm.actorsDialog, "show", $$v)},expression:"actorsDialog.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
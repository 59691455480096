<template>
<div class="fill-height">
  <v-app-bar app color="white" fixed clipped-left class="elevation-0 main-toolbar">
    <v-toolbar-title><a href="https://aircasting.cz/productions" target="_blank"><img src="https://prod.aircasting.cz/logo.svg" style="margin: 8px 24px 0px 0px;" height="32" /></a></v-toolbar-title>
    <v-toolbar-items v-if="!loading && allowSearch">
      <v-btn class="mx-0" text :to="{path: '/roles/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{shareName}}</span><v-icon v-else>mdi-apps</v-icon></v-btn>
      <v-btn class="mx-0" text :to="{path: '/search/' + this.id}"><span v-if="$vuetify.breakpoint.mdAndUp">{{$t('Layout.search')}}</span><v-icon v-else>search</v-icon></v-btn>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items v-if="!showNameDialog && !loading">
      <v-menu v-model="langMenu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-chip v-if="$vuetify.breakpoint.mdAndUp" v-bind="attrs" v-on="on" class="mt-4 mr-2" color="secondary" text-color="white">
            <v-avatar left large><v-icon>account_circle</v-icon></v-avatar>
            <span>{{name}}</span>
            <v-icon small right style="border-left: 1px solid white">mdi-dots-vertical</v-icon>
          </v-chip>
          <v-btn v-else v-bind="attrs" v-on="on" icon><v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-card>
          <v-list dense>
            <v-list-item :class="$i18n.locale() == 'cz' ? 'v-item--active v-list-item--active px-2' : 'px-2'" @click="setLocale('cz')">
              <v-list-item-icon class="mr-3"><img width="24" src="https://prod.aircasting.cz/assets/cz.svg" /></v-list-item-icon>
              <v-list-item-content><v-list-item-title>Čeština</v-list-item-title></v-list-item-content>
            </v-list-item>
            <v-list-item :class="$i18n.locale() == 'en' ? 'v-item--active v-list-item--active px-2' : 'px-2'" @click="setLocale('en')">
              <v-list-item-icon class="mr-3"><img width="24" src="https://prod.aircasting.cz/assets/us.svg" /></v-list-item-icon>
              <v-list-item-content><v-list-item-title>English</v-list-item-title></v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
  <div v-if="loading" style="height: 100%">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  <v-container v-else-if="sharedEvent" fluid grid-list-md  class="py-3 px-5 shared" style="max-width: 100%" :key="render">
    <h1 class="mb-5">{{sharedEvent.eventName}}</h1>
    <div style="width: 100%">
      <section v-for="(role, r) in roles" :key="r" class="px-3 pt-3 pb-4 mb-5 role_section shadow white">
        <template v-if="role.attendees.length">
          <h3 style="font-size: 22px; font-weight: normal">{{role.roleName}}</h3>
          <v-layout class="row wrap" style="min-width: 100%">
            <v-flex v-for="(attendee, i) in role.attendees" :key="i" xs12 md4 lg4 xl3>
              <v-card hover class="pa-0 ma-1">
                <v-img v-on:click="showVideoPlayer(attendee)" :transition="null" :aspect-ratio="1" :src="attendee.files[0].videoThumbnail ? attendee.files[0].videoThumbnail : attendee.files[0].path" style="background-color: rgb(235, 247, 255)">
                  <img width="60" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
                </v-img>
                <v-card-title primary-title class="pa-3" style="position: relative; cursor: default;">
                  <v-chip color="rgb(235, 247, 255)" @click="showProfileDetail(attendee)" class="pl-1">
                    <v-avatar class="mr-2" size="32">
                      <img :src="getImageUrl(attendee, attendee.mainPhoto, 32)" />
                    </v-avatar>
                    {{ attendee.name }}
                  </v-chip>
                  <v-spacer></v-spacer>
                  <v-badge :value="typeof attendee.comments != 'undefined' && attendee.comments.length > 0" left overlap color="rgba(12,49,158,0.7)">
                    <span slot="badge">{{attendee.comments ? attendee.comments.length : ``}}</span>
                    <v-btn @click="showVideoPlayer(attendee)" icon small class="my-0 mx-1"><v-icon size="21" color="primary">{{commented(attendee) ? `mdi-forum` : `mdi-forum-outline`}}</v-icon></v-btn>
                  </v-badge>
                  <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                      <v-badge v-on="on" :value="typeof attendee.likes != 'undefined' && attendee.likes.length > 0" right overlap color="rgba(12,49,158,0.7)">
                        <span slot="badge">{{ attendee.likes ? attendee.likes.length : 0 }}</span>
                        <v-btn @click="likeVideo(attendee)" icon small class="my-0 mx-1"><v-icon color="primary">{{liked(attendee) ? `mdi-thumb-up` : `mdi-thumb-up-outline`}}</v-icon></v-btn>
                      </v-badge>
                    </template>
                    <span v-if="attendee.likes && attendee.likes.length">
                      <div v-for="(like, l) in attendee.likes" :key="l">{{like}}</div>
                    </span>
                    <span v-else>{{$t('Files.notRankedYet')}}</span>
                  </v-tooltip>
                </v-card-title>
              </v-card>
            </v-flex>
          </v-layout>
        </template>
      </section>
    </div>
    <VideoPlayer v-model="videoPlayerDialog.show" :profile="videoPlayerDialog.profile" :source="videoPlayerDialog.source" :name="name"></VideoPlayer>
    <ProfileDetail v-model="profileDialog.show" :userProfile="profileDialog.profile" :showControls="false" />
    <v-dialog max-width="400" v-model="showNameDialog" persistent>
      <v-card flat tile color="#ebf7ff" class="pt-5">
        <v-card-text>
          <span style="font-size: 18px">{{$t('SharedEvent.yourName')}}</span>
          <v-text-field class="mt-4" v-model="name" solo :label="$t('SharedEvent.name')"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="saveName" :disabled="name === null || name.length < 3" color="primary">{{$t('closeOk')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</div>
</template>

<style>
h1 {
  font-weight: 300;
}
.show-on-hover {
  visibility: hidden;
}
.v-card:hover .show-on-hover {
  visibility: visible;
}
.check_header {
  margin-left: -36px;
}
#app.desktop .role_section .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: hidden;
}
#app.desktop .role_section:hover .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: visible;
}
.v-alert.bottom_alert {
  position: fixed; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 2;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
}
.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
.files_page .v-chip .v-chip__content {
  cursor: pointer !important;
}
.shared .v-badge__badge {
  width: 18px;
  height: 18px;
}
.shared-comments-dialog .v-list__tile {
  padding: 0px;
}
.shared-comments-dialog .v-list--two-line .v-list__tile {
  height: initial !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.shared-comments-dialog .v-list__tile__sub-title {
  white-space: pre-wrap;
}
</style>

<script>
import * as api from '@/libs/api'
import { Storage } from "aws-amplify";
import VideoPlayer from './../dialogs/VideoPlayer'
import ProfileDetail from '@/dialogs/ProfileDetail'

export default {
  name: 'SharedEvent',
  components: {
    VideoPlayer,
    ProfileDetail
  },
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      name: null,
      render: 0,
      roles: [],
      loading: true,
      showNameDialog: false,
      sharedEvent: null,
      videoPlayerDialog: {
        show: false,
        profile: null
      },
      profileDialog: {
        show: false,
        profile: null
      }
    }
  },
  async mounted() {
    if (!this.sharedEvent)
      this.sharedEvent = await api.getSharedEvent(this.id);

    let roles = [];
    let attendeeMap = new Map();
    for (let attendee of this.sharedEvent.profiles) {
      if (!attendee.roleName || !attendee.files || !attendee.files.length)
        continue;

      let actor = await this.getUpdatedActor(attendee);
      if (!actor.likes)
          actor.likes = [];
      let arr = attendeeMap.get(attendee.roleName);
      if (arr && Array.isArray(arr))
        arr.push(actor);
      else
        arr = [actor];

      attendeeMap.set(attendee.roleName, arr);
    }

    for (let [roleName, attendees] of attendeeMap) {
      let sortedAttendees = this.sortAttendeesByLikes(attendees)
      roles.push({ roleName: roleName, attendees: sortedAttendees });
    }
    
    this.name = localStorage.getItem("shareName");
    this.roles = roles;
    this.showNameDialog = true;
    this.loading = false;
  },
  methods: {
    showVideoPlayer(profile) {
      this.videoPlayerDialog = {
        show: true,
        profile: profile
      }
    },
    saveName() {
      localStorage.setItem("shareName", this.name);
      this.showNameDialog = false;
    },
    async getUpdatedActor(actor) {
      actor.name = actor.fullname;
      if (actor.files) {
        for (let f in actor.files) {
          let type = actor.files[f].type;
          if (!type || type === 'video') {
            let key = actor.files[f].key;
            let thumb = actor.files[f].thumbnail;
            
            if (key.length < 59 || thumb.length < 59)
              continue;
            actor.files[f].videoThumbnail = await Storage.get(thumb.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
            actor.files[f].videoUrl = await Storage.get(key.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
          }
          else if (type === 'photo') {
            actor.files[f].path = await Storage.get(actor.files[f].filepath, { level: 'private', bucket: 'image-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
          }
          else if (type === 'audio') {
            actor.files[f].path = await Storage.get(actor.files[f].filepath, { level: 'private', bucket: 'audio-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
          }
        }
      }

      return actor;
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    liked(actor) {
      if (!actor || !actor.likes)
        return false;

      return actor.likes.findIndex(like => like === this.name) > -1;
    },
    async likeVideo(actor) {
      if (!actor)
        return;

      if (!actor.likes)
        actor.likes = [];

      let index = actor.likes.findIndex(like => like === this.name);
      if (index > -1) {
        actor.likes.splice(index, 1);
        await api.likeVideo(actor.eventId, actor.userId, this.name, false);
      }
      else {
        actor.likes.push(this.name);
        await api.likeVideo(actor.eventId, actor.userId, this.name, true);          
      }
      this.render++;
    },
    commented(actor) {
      if (!actor || !actor.comments)
        return false;

      return actor.comments.findIndex(comment => comment.commentName === this.name) > -1;
    },
    showProfileDetail(profile) {
      this.profileDialog.profile = null;
      this.$nextTick(() => {
        this.profileDialog.profile = profile;
        this.profileDialog.show = true;
      });
    },
    // Sort attendees by likes
    sortAttendeesByLikes(attendees) {
      if (!attendees || !attendees.length)
        return attendees;

      return attendees.sort((a, b) => {
        if (!a.likes || !b.likes)
          return 0;

        return b.likes.length - a.likes.length;
      });
    }
  }
}
</script>
<template>
  <div style="height: 100%">
    <template v-if="loading">
      <v-container fill-height>
        <v-layout row wrap align-center>
          <v-flex xs12 sm12 md12 class="text-center">
            <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-if="empty">
      <v-container fill-height>
        <v-layout row wrap align-center>
          <v-flex xs12 sm12 md12 class="text-center">
            <img src="assets/empty-roles.svg" width="200" class="mx-auto" />
            <div class="mb-5 mt-5 mx-auto" style="color: #131d2d; font-size: 26px; max-width: 270px;">{{$t('Role.noRoleYet')}}</div>
            <v-btn large style="height: 56px; font-size: 18px; font-weight: normal;" class="elevation-0" color="primary" @click="showRoleDialog(null)">{{$t('AddRoleDialog.createRole')}}</v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </template>
    <template v-else>
      <v-container fluid grid-list-md class="pa-0 events" style="max-width: 100%">
        <v-tooltip :value="roleTooltip.show" top absolute :position-x="roleTooltip.x" :position-y="roleTooltip.y">
          <span>{{roleTooltip.text}}</span>
        </v-tooltip>
        <v-fade-transition>
          <div class="pb-4">
            <section class="px-4 pt-4 pb-2" style="background-color: white; border-bottom: 1px solid #e9ebee; width: 100%;">
              <div style="float: left">
                <h1 class="mb-3 ml-3" style="font-size: 28px;">
                  <span>{{$t('Event.roles')}}</span>
                  <template v-if="!empty">
                    <span class="grey--text text--lighten-2"> &bull; </span>
                    <span class="grey--text">{{project.roles.length}}</span>
                  </template>
                </h1>
              </div>
              <div style="float: right">
                <v-btn class="elevation-0" color="secondary" @click="$store.state.dialogs.shareRoles.show = true"><v-icon small class="mr-2">share</v-icon>{{$t('Role.share')}}</v-btn>
              </div>
              <div style="clear: both"></div>
            </section>
            <draggable v-model="project.roles" class="layout row wrap px-4" :options="{group: { name:'project_role' }, disabled: $vuetify.breakpoint.smAndDown }">
              <v-flex sm12 md6 lg4 xl3 v-for="(role, r) in project.roles" :key="r">
                <v-card class="pa-2 ma-2">
                  <v-card-title primary-title class="pa-2">
                    <div class="headline">
                      <div v-if="role.picks.length" class="d-inline-block pt-2 mr-2 doughnut" style="width: 32px; height: 32px;">
                        <DoughnutChart :data="getRoleStats(role)" :index="r" :labels="['Neosloveno', 'Neodpovědělo', 'Odmítlo', 'Přijalo']"></DoughnutChart>
                      </div>
                      <div class="d-inline-block">{{role.roleName}}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <v-menu bottom left light offset-y>
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" small icon class="elevation-0 my-0">
                          <v-icon size="24">more_vert</v-icon>
                        </v-btn>
                      </template>
                      <v-list class="pa-0">
                        <v-list-item @click="showEditRoleDialog(role)">
                          <v-list-item-title>{{$t('edit')}}</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmDelete = {role: role, show: true}">
                          <v-list-item-title>{{$t('delete')}}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-card-title>
                  <v-card-text class="pa-0">
                    <v-tabs v-model="role.tab">
                      <v-tabs-slider></v-tabs-slider>
                      <v-tab>{{$t('Event.cast')}}</v-tab>
                      <v-tab>
                        <span>{{$t('description')}}</span>
                        <v-tooltip top v-if="!role.description || !role.description.length">
                          <template v-slot:activator="{on}">
                            <v-icon v-on="on" class="ml-1" small color="error">fas fa-exclamation-circle</v-icon>
                          </template>
                          <span>{{$t('Role.noRoleDescriptionYet')}}</span>
                        </v-tooltip>
                      </v-tab>
                      <v-tab v-if="role.roleType != 'extra'">
                        <span>{{$t('salary')}}</span>
                        <v-tooltip top v-if="!role.payments || !role.payments.length">
                          <template v-slot:activator="{on}">
                            <v-icon v-on="on" class="ml-1" small color="error">fas fa-exclamation-circle</v-icon>
                          </template>
                          <span>{{$t('EditRoleDialog.noSalaryError')}}</span>
                        </v-tooltip>
                        <template v-else>
                          <span class="grey--text text--lighten-2 ml-1 mr-1">&bull;</span> <span class="grey--text">{{formatCurrency(getBudget(role))}}</span>
                        </template>
                      </v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="role.tab" style="border-top: 1px solid rgb(228,228,228)">
                      <v-tab-item style="height: 400px; overflow-y: auto; overflow-x: hidden;">
                        <v-list v-if="role.picks.length">
                          <template v-for="(pick, i) in role.picks">
                            <div :key="i">
                              <v-list-item @click="showProfileDetail(pick)" class="pr-0">
                                <v-list-item-avatar size="36px">
                                  <v-avatar size="36px">
                                    <img :src="getImageUrl(pick, pick.mainPhoto, 36)" />
                                  </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                  <v-list-item-title>{{ pick.name }}</v-list-item-title>
                                  <v-list-item-subtitle style="font-size: .9em" :class="getStateColor(pick.roleState, pick.winner) + `--text`">
                                    <v-icon size="16" :color="getStateColor(pick.roleState, pick.winner)">{{getStateIcon(pick.roleState, pick.winner)}}</v-icon>
                                    <v-tooltip right :disabled="pick.roleState != 'share-added' || !pick.shareUser">
                                      <template v-slot:activator="{on}">
                                        <span v-on="on">{{getStateText(pick.roleState, pick.sex, pick.winner, pick.roleStateReason)}}</span>
                                      </template>
                                      <span>{{pick.shareUser + $t('Role.shareSuggest')}}</span>
                                    </v-tooltip>
                                  </v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                  <v-btn icon small ripple @click.stop="confirmPickDelete = {show: true, pick: pick, role: role}">
                                    <v-icon small color="grey lighten-1">close</v-icon>
                                  </v-btn>
                                </v-list-item-action>
                              </v-list-item>
                              <v-divider inset v-if="i < role.picks.length - 1"></v-divider>
                            </div>
                          </template>
                        </v-list>
                        <v-container v-else fill-height>
                          <v-layout row wrap align-center>
                            <v-flex xs12 sm12 md12 class="text-center">
                              <v-btn class="elevation-0" large color="primary" to="/search"><v-icon left>search</v-icon>{{$t('ctaSearch')}}</v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-tab-item>
                      <v-tab-item style="height: 400px; overflow-y: auto;">
                        <div class="pa-3" v-if="role.description">{{role.description}}</div>
                        <v-container v-else fill-height>
                          <v-layout row wrap align-center>
                            <v-flex xs12 sm12 md12 class="text-center">
                              <v-btn class="elevation-0" large color="primary" @click="showEditRoleDialog(role)"><v-icon left>add</v-icon>{{$t('addDescription')}}</v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-tab-item>
                      <v-tab-item style="height: 400px; overflow-y: auto;">
                        <v-data-table v-if="role.payments && role.payments.length" :headers="headers" :items="role.payments" hide-default-footer class="elevation-0 mt-0">
                          <template v-slot:body="items">
                            <tbody v-if="items && items.items">
                              <tr v-for="item in items.items" :key="item.name">
                                <td width="75%" class="pr-0">{{ getSalaryText(item) }}</td>
                                <td class="text-xs-right">{{formatCurrency(item.salary)}}{{(item.type == 'dailySalary' && shootings(role)) > 0 ? (' x ' + shootings(role)) : ''}}</td>
                              </tr>
                            </tbody>
                          </template>
                          <template slot="no-data">
                            &nbsp;
                          </template>
                        </v-data-table>
                        <v-container v-else fill-height>
                          <v-layout row wrap align-center>
                            <v-flex xs12 sm12 md12 class="text-center">
                              <v-btn class="elevation-0" large color="primary" @click="showEditRoleDialog(role)"><v-icon left>add</v-icon>{{$t('Role.addSalary')}}</v-btn>
                            </v-flex>
                          </v-layout>
                        </v-container>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card-text>
                  <v-card-actions>
                    <v-tooltip right>
                      <template v-slot:activator="{on}">
                        <v-btn v-on="on" small icon class="elevation-0 my-0" @click="showActorsDialog(role.roleName, role.picks)">
                          <v-icon small>fa-expand</v-icon>
                        </v-btn>
                      </template>
                      <span>{{$t('Role.showBigThumbnail')}}</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <v-btn text class="mr-0 pl-1 pr-2" to="/search" color="primary"><v-icon small class="ml-0 mr-1">search</v-icon> {{$t('ctaSearch')}}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </draggable>
          </div>
        </v-fade-transition>
        <v-snackbar v-model="snackbar" bottom left multi-line auto-height :timeout="0" color="info">
          <div class="pl-5">
            <span>{{$t('Role.actorsWithNoOffer')}}</span>
            <v-btn light @click="$store.state.dialogs.notifyActors.show = true" class="elevation-0 black--text">{{$t('Role.sendOffer')}}</v-btn>
            <v-btn light icon ml-0 @click="snackbar=false"><v-icon light>cancel</v-icon></v-btn>
          </div>
        </v-snackbar>
      </v-container>
    </template>
    <v-dialog v-if="confirmDelete.role" max-width="500" v-model="confirmDelete.show">
      <v-card flat tile>
        <v-card-text>
          <div style="font-size: 18px">{{$t('Role.deleteRoleQuestion')}} {{ confirmDelete.role.roleName.toLowerCase() }}?</div>
          <div style="font-size: 14px; margin-top: 18px">{{$t('Role.deleteEventAlert')}}</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="confirmDelete.show = false" color="success">{{$t('no')}}</v-btn>
          <v-btn class="elevation-0" @click="(confirmDelete.show = false) || removeRoleFromCurrentProject(confirmDelete.role)" color="error">{{$t('yes')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-if="confirmPickDelete.role && confirmPickDelete.pick" max-width="500" v-model="confirmPickDelete.show">
      <v-card flat tile>
        <v-card-text>
          <div style="font-size: 18px">{{$t('Role.reallyDeleteActorPart1')}}{{ confirmPickDelete.pick.fullname }}{{$t('Role.reallyDeleteActorPart2')}}{{ confirmPickDelete.role.roleName.toLowerCase() }}?</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" @click="confirmPickDelete.show = false" color="success">{{$t('no')}}</v-btn>
          <v-btn class="elevation-0" @click="(confirmPickDelete.show = false) || removePick(confirmPickDelete.role, confirmPickDelete.pick)" color="error">{{$t('yes')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ProfileMultiView v-model="actorsDialog.show" :title="actorsDialog.title" :actors="actorsDialog.actors" state="roleState"></ProfileMultiView>
    <EditRoleDialog v-model="editRoleDialog.show" :role="editRoleDialog.role"></EditRoleDialog>
  </div>
</template>
<style>
h1 {
  font-weight: 300;
}
.v-list--two-line .v-list__tile {
  height: 54px !important;
}
.events .v-btn--floating .v-btn__content :not(:only-child):first-child,
.projects .v-btn--floating .v-btn__content :not(:only-child):last-child {
  top: -12px;
}
.v-snack__content {
  padding-left: 6px !important;
  padding-right: 6px !important;
}
</style>
<script>
import * as states from '@/libs/states'
import { EventBus } from '@/libs/eventBus.js';
import { mapActions, mapGetters } from 'vuex'
import draggable from 'vuedraggable'
import EditRoleDialog from '@/dialogs/EditRoleDialog'
import ProfileMultiView from '@/dialogs/ProfileMultiView'
import DoughnutChart from '@/widgets/DoughnutChart'

export default {
    name: 'Roles',
    components: {
      draggable,
      ProfileMultiView,
      EditRoleDialog,
      DoughnutChart
    },
    mounted() {
      if (this.project && this.project.roles) {
        for (let i in this.project.roles) {
          if (!this.project.roles[i].picks)
            continue;
          this.project.roles[i].picks = this.sortByState(this.project.roles[i].picks);
        }
      }

      EventBus.$on('showTooltip', (tooltip) => {
        if (tooltip.model.opacity === 0) {
          this.roleTooltip.show = false;
        }
        else {
          if (tooltip.model.body && tooltip.model.body.length && tooltip.model.body[0].lines && tooltip.model.body[0].lines.length)
            this.roleTooltip.text = tooltip.model.body[0].lines[0];

          let elms = document.querySelectorAll(".doughnut");
          let elm = null;
          if (elms.length >= tooltip.index)
            elm = elms[tooltip.index];


          if (elm) {
            let position = elm.getBoundingClientRect();
            this.roleTooltip.x = position.left + window.pageXOffset + tooltip.model.caretX;
            this.roleTooltip.y = position.top + window.pageYOffset + tooltip.model.caretY;
            this.roleTooltip.show = true;
          }
        }
      });
    },
    data() {
      return {
        supressNotification: false,
        actorsDialog: {
          show: false,
          title: null,
          actors: []
        },
        editRoleDialog: {
          show: false,
          role: null
        },
        confirmDelete: {
          show: false,
          role: null
        },
        confirmPickDelete: {
          show: false,
          role: null,
          pick: null
        },
        roleTooltip: {
          show: false,
          text: null,
          x: 0,
          y: 0
        },
        fab: true,
        headers: [
          {
            text: 'Typ',
            align: 'left',
            value: 'type'
          },
          { text: 'Mzda', value: 'salary' }
        ],
      }
    },
    computed: {
      loading() {
        return this.$store.state.loadingProject;
      },
      project: {
        get: function() {
          let project = this.getCurrentProject();
          return project;
        },
        set: function(project) {
          this.updateCurrentProject(project);
        }
      },
      empty() {
        return !this.project || !this.project.roles || this.project.roles.length === 0;
      },
      budget: function() {
        let budget = [];
        let project = this.getCurrentProject();
        let sum = 0;
        for (let role of project.roles) {
          budget.push({
            name: role.roleName,
            budget: role.budget
          });
          sum += role.budget;
        }

        budget.push({
          name: 'Celkem',
          budget: sum,
          sum: true
        });
        return budget;
      },
      snackbar: {
        get: function() {
          if (this.supressNotification)
            return false;

          let project = this.getCurrentProject();
          for (let role of project.roles) {
            for (let pick of role.picks) {
              if (pick.roleState && (pick.roleState == "added" || pick.roleState == "share-added"))
                return true;
            }
          }
          return false;
        },
        set: function(val) {
          this.supressNotification = !val;
        }
      },
    },
    methods: {
    ...mapActions([
      'removeRoleFromCurrentProject',
      'updateCurrentProject',  
      'showProfileDetail', 
      'showRoleDialog',
      'removePickFromRole'
    ]),
    ...mapGetters(['getCurrentProject']),
    removePick: function(role, pick) {
      this.removePickFromRole( { role: role, pick: pick } );
    },
    addNote: function(note) {
      this.addNoteToCurrentProject(note);
    },
    deleteNote: function(note) {
      this.removeNoteFromCurrentProject(note);
    },
    formatCurrency: function(amount) {
      const formatter = new Intl.NumberFormat('cs-CZ', {
        style: 'currency',
        currency: 'CZK',
        minimumFractionDigits: 0
      });

      let result = formatter.format(amount);
      return result;
    },
    getStateText(state, sex, winner, reason) {
      return this.$t(states.getStateText(state, sex, winner)) + (reason ? (' (' + this.$t('States.' + reason) + ')') : '');
    },
    getStateIcon(state, winner) {
      return states.getStateIcon(state, winner);
    },
    getStateColor(state, winner) {
      return states.getStateColor(state, winner);
    },
    showActorsDialog(title, actors) {
      this.actorsDialog.show = true;
      this.actorsDialog.title = title;
      this.actorsDialog.actors = actors;
    },
    showEditRoleDialog(role) {
      this.editRoleDialog.show = true;
      this.editRoleDialog.role = role;
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    getSalaryText(salary) {
      switch (salary.type) {
        case 'dailySalary': return this.$t(salary.type);
        case 'buyout': return this.$t(salary.type) + ' v ' + (salary.country || '') + (salary.country && salary.country.length ? ' - ' : '') + (salary.medium || '') + ((salary.length && salary.unit) ? ' na ' + salary.length + ' ' + salary.unit + '.' : '');
        case 'other': return (salary.name || '') + (salary.name && salary.name.length ? ' ' : '') + salary.description;

      }
    },
    getRoleStats(role) {
      let added = 0;
      let addressed = 0;
      let accepted = 0;
      let rejected = 0;
      for (let pick of role.picks) {
        switch (pick.roleState) {
          case 'added': added++; break;
          case 'addressed': addressed++; break;
          case 'accepted': accepted++; break;
          case 'rejected': rejected++; break;
        }
      }
      return [added, addressed, rejected, accepted];
    },
    shootings(role) {
      let project = this.getCurrentProject();
      let shootings = 0;
      for (let event of project.events) {
        if (event.eventType != 'shooting')
          continue;
        
        if (event.eventRoles.findIndex(r => r.roleId === role.roleId) < 0)
          continue;

        shootings++;                
      }

      return shootings;
    },
    getBudget(role) {
      if (!role || !role.payments || !role.payments.length)
        return 0;
        
      let budget = 0;

      for (let payment of role.payments)
        budget += (payment.salary * (payment.type == 'dailySalary' ? Math.max(1,this.shootings(role)) : 1));

      return budget;
    },
    sortByState(picks) {
      let result = picks;
      result.sort((a, b) => states.stateToValue(b.roleState, b.winner === true) - states.stateToValue(a.roleState, a.winner === true));
      return result;
    }
  }
}
</script>

export function getStateText(state, sex, winner) {
  if (winner)
    return 'Vítěz';

  switch (state) {
    case 'share-added':
    case 'added': return sex === 'woman' ? 'States.addedF' : 'States.added'
    case 'addressed': return sex === 'woman' ? 'States.addressedF' : 'States.addressed'
    case 'accepted': return sex === 'woman' ? 'States.acceptedF' : 'States.accepted'
    case 'rejected': return sex === 'woman' ? 'States.rejectedF' : 'States.rejected'
    case 'winner': return 'States.winner'
  }
  return ''
}
export function getStateIcon(state, winner) {
  if (winner)
    return 'mdi-medal';

  switch (state) {
    case 'added': return 'mdi-account-alert'
    case 'addressed': return 'mdi-account-clock'
    case 'accepted': return 'mdi-account-check'
    case 'rejected': return 'mdi-account-remove'
    case 'winner': return 'mdi-medal'
    case 'share-added': return 'mdi-account-clock'
  }
  return 'mdi-account-question'
}
export function getStateColor(state, winner) {
  if (winner)
    return 'success';

  switch (state) {
    case 'added': return 'error'
    case 'addressed': return 'gray'
    case 'accepted': return 'success'
    case 'rejected': return 'error'
    case 'declined': return 'error'
    case 'winner': return 'success'
    case 'share-added': return 'secondary'
  }
  return 'gray'
}

export function stateToValue(state, winner) {
  if (winner)
    return 7;
  switch (state) {
    case 'winner': return 7;
    case 'accepted': return 6;
    case 'rejected': return 5;
    case 'declined': return 4;
    case 'addressed': return 3;
    case 'added': return 2;
    case 'share-added': return 1
  }
  return 0; 
}
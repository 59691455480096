<template>
  <v-card class="elevation-0 project_selector" style="height: 100%" :key="renderCnt">
    <v-card-text class="pl-0 pr-0 pt-0" style="height: 100%; padding-bottom: 64px !important;">
      <v-container fill-height v-if="loading">
        <v-layout row wrap align-center>
          <v-flex xs12 sm12 md12 class="text-center">
            <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
          </v-flex>
        </v-layout>
      </v-container>
      <v-list v-else two-line class="project pl-0 pt-0" style="height: 100%; overflow-y: auto;">
        <v-list-item style="background-color: #f6f8fa; padding-top: 5px; padding-bottom: 4px; border-bottom: 1px solid rgb(228,228,228)">
          <v-list-item-title class="pl-2 pr-2 pb-3" style="font-weight: bold">{{$t('ProjectsList.projects')}}</v-list-item-title>
          <v-list-item-action>
            <v-select v-if="mode=='sort'" @click:append-outer="mode='search'" v-model="sort" :items="[{text:'Název',value:'name'}, {text:'Klient',value:'client'}, {text:'Datum',value:'date'}]" item-text="text" item-value="value" append-outer-icon="mdi-magnify" style="width: 250px; font-size: 0.9em;"></v-select>
            <v-text-field v-else v-model="search" clearable @click:append-outer="mode='sort'" append-outer-icon="mdi-sort-descending" style="width: 250px; font-size: 0.9em;"></v-text-field>
          </v-list-item-action>
        </v-list-item>
        <template v-for="(item, i) in projects">
          <v-list-item v-if="filter(item)" :key="i" @click="select(item.projectId)" :style="getCurrentProjectId() == item.projectId ? `background-color: #d0eaff` : ``">
            <v-list-item-avatar class="my-0">
              <v-avatar v-if="item.logo" size="42px"><img :src="item.logo" /></v-avatar>
              <div v-else class="project-icon">{{getFirstLine(item)[0]}}</div>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{getFirstLine(item)}}</v-list-item-title>
              <v-list-item-subtitle>{{item.projectName}}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-action-text class="text-right" :class="item.status === 'completed' ? '' : 'error--text font-weight-bold'" style="width: 70px">
                {{getDate(item.lastDate || item.createdAt)}}
              </v-list-item-action-text>
            </v-list-item-action>              
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
    <footer class="pane_footer">
      <v-btn color="primary" text @click="$store.state.dialogs.newProject.show=true"><v-icon size="18" class="mr-2">fa-plus-circle</v-icon> {{$t('ProjectsList.addProject')}}</v-btn>
    </footer>
  </v-card>
</template>

<style>
.project_selector footer.pane_footer {
  border-top: 1px solid rgb(228,228,228); 
  width: 100%; 
  height: 64px; 
  position: absolute; 
  bottom: 0px;
}
.project_selector footer.pane_footer .v-btn {
  margin: 12px 0px 0px 11px;
}
.project .v-list__group__header--sub-group .v-list__group__header__prepend-icon {
  padding-left: 0px;
  min-width: 16px;
}
.project .v-list__group__items--no-action .v-list__tile {
  padding-left: 24px;
}
.project-icon {
  border-radius: 50%;
  background-color: #002e99;
  color: white;
  font-weight: 400 !important;
  font-size: 1.4em;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  display: inline-block;
}
</style>

<script>
import * as api from '@/libs/api'
import router from '../router'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ProjectsList',
  data() {
    return {
      sort: 'date',
      renderCnt: 0,
      projects: [],
      loading: false,
      mode: 'sort',
      search: null
    }
  },
  async mounted() {
    this.loading = true;
    this.projects = await this.getProjects();
    this.loading = false;
  },
  computed: {
    project: {
      get: function() {
        return this.getCurrentProject();
      },
      set: function(project) {
        this.updateCurrentProject(project);
      }
    }
  },
  watch: {
    async sort() {
      this.loading = true;
      this.projects = await this.getProjects();
      this.loading = false;
      this.renderCnt++;
    }
  },
  methods: {
    ...mapActions(['updateCurrentProject', 'selectProject']),
    ...mapGetters(['getAllProjects', 'getCurrentProject', 'getCurrentProjectId']),
    getFirstLine(project) {
      if (project.projectSubType == 'artPhoto')
        return project.photographer;
      else if (project.projectType == 'ad' || project.projectType == 'photo')
        return project.client;
      else if (project.producer)
        return project.producer;
      
      return ' ';
    },
    select(projectId) {
      this.selectProject(projectId);
      router.push('/project');
      this.$emit('input', false);
    },
    async getProjects() {
      try {        
        let projects = await api.listProjects();
        if (this.sort == 'name') {
          projects.sort(function(a, b) {
            let x = a.projectName ? a.projectName.toUpperCase() : '';
            let y = b.projectName ? b.projectName.toUpperCase() : '';
            return x == y ? 0 : x > y ? 1 : -1;
          });
        }
        else if (this.sort == 'client') {
          projects.sort(function(a, b) {
            let x = this.getFirstLine(a).toUpperCase();
            let y = this.getFirstLine(b).toUpperCase();
            return x == y ? 0 : x > y ? 1 : -1;
          }.bind(this));
        }
        else {
          projects.sort((a, b) => (a.lastDate || a.createdAt)  == (b.lastDate || b.createdAt) ? 0 : (a.lastDate || a.createdAt) < (b.lastDate || b.createdAt) ? 1 : -1);
        }
        return projects;
      }
      catch(err) {
        console.log(err);
      }

      return [];
    },
    getDate(timestamp) {
      let date = new Date(timestamp);
      return date.toLocaleDateString('cs-CZ');
    },
    filter(item) {
      if (this.mode != 'search' || !this.search || !this.search.length)
        return true;

      let text = this.getFirstLine(item) + item.projectName;
      let search = this.search.toLowerCase();
      if (!text || !text.length)
        return false;

      if (text.toLowerCase().indexOf(search) > -1)
        return true;
      
      return false;
    }
  }
}
</script>
<template>
<v-flex sm12 md12 lg6 v-if="documents && documents.length > 0">
    <v-card class="pa-2 ma-2" style="min-height: 479px">
      <v-card-title primary-title class="pa-2">
        <div class="headline">{{$t('ProjectDocuments.documents')}}</div>
      </v-card-title>
      <v-card-text class="pa-0" style="max-height: 445px; overflow-y: auto;">
        <v-list two-line>
          <v-list-item v-for="(doc, d) in documents" :key="d" avatar @click="openDocument(doc.url)">
            <v-list-item-avatar>
              <v-icon class="blue white--text">fas fa-file-pdf</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{doc.filename}}</v-list-item-title>
              <v-list-item-subtitle>{{formatDate(doc.lastModified)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-flex>
</template>

<style>
.one-line-ellipsis {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapGetters } from 'vuex'

export default {
  name: 'ProjectDocuments',
  data() {
    return {
    }
  },
  computed: {
    project() {
      return this.getCurrentProject();
    },
  },
  asyncComputed: {
    async documents() {
      if (!this.project)
        return [];
      return await api.listDocuments(this.project.projectId);
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    formatDate(date) {
      let d = new Date(date);
      return d.toLocaleDateString('cs-CZ') + ' ' + d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
    },
    openDocument(url) {
      let win = window.open(url, '_blank');
      win.focus();
    }
  }
}
</script>
<template>
<div>
  <a href="https://aircasting.cz/productions"><img src="https://prod.aircasting.cz/logo.svg" style="margin: 15px 0px 0px 24px;" height="32" /></a>
  <div v-if="loading" style="height: 100%">
    <v-container fill-height>
      <v-layout row wrap align-center>
        <v-flex xs12 sm12 md12 class="text-center">
          <v-progress-circular :size="150" :width="7" indeterminate color="primary"></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
  <v-container v-else-if="sharedVideos" fluid grid-list-md  class="py-3 px-5 shared" style="max-width: 100%">
    <h1 class="mb-5">{{sharedVideos.shareName}}</h1>
    <section v-for="(role, r) in roles" :key="r" class="mb-5 role_section">
      <h3>{{role.roleName}}</h3>
      <v-layout class="row wrap" style="min-width: 100%">
        <v-flex xs12 md4 lg4 xl3 v-for="(video, v) in role.videos" :key="v">
          <v-card hover class="pa-0 ma-1">
            <v-img v-on:click="showVideoPlayer(video.profile)" :aspect-ratio="1" :src="video.thumbnailUrl" style="background-color: rgb(235, 247, 255)">
              <img width="60" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);" src="https://app.aircasting.cz/assets/profile/play.svg" class="play-overlay" />
            </v-img>
            <v-card-title primary-title class="pa-3" style="position: relative; cursor: default;">
              <v-chip color="rgb(235, 247, 255)" @click="showProfileDetail(video.profile)">
                <v-avatar v-if="video.profile && video.profile.mainPhoto">
                  <img :src="getImageUrl(video, video.profile.mainPhoto, 40)">
                </v-avatar>
                {{ video.profile ? video.profile.fullname : '' }}
              </v-chip>
              <v-spacer></v-spacer>
            </v-card-title>
          </v-card>
        </v-flex>
      </v-layout>
    </section>
    <ProfileDetail v-model="profileDialog.show" :userProfile="profileDialog.profile" :showControls="false" />
    <VideoPlayer v-model="videoPlayerDialog.show" :profile="videoPlayerDialog.profile" :source="videoPlayerDialog.source" :name="name"></VideoPlayer>
    <VideoPlayerSimple v-model="showVideoPlayerDialog" :source="selectedVideo"></VideoPlayerSimple>
    <v-dialog max-width="400" v-model="showNameDialog" persistent>
      <v-card flat tile color="#ebf7ff">
          <v-card-text>
            <span style="font-size: 18px">{{$t('SharedEvent.yourName')}}</span>
            <v-text-field class="mt-4" v-model="name" solo :label="$t('SharedEvent.name')"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="elevation-0" @click="saveName" :disabled="name === null || name.length < 3" color="primary">{{$t('closeOk')}}</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</div>
</template>

<style>
h1 {
  font-weight: 300;
}
.show-on-hover {
  visibility: hidden;
}
.v-card:hover .show-on-hover {
  visibility: visible;
}
.check_header {
  margin-left: -36px;
}
#app.desktop .role_section .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: hidden;
}
#app.desktop .role_section:hover .check_header_button .mdi-checkbox-blank-circle-outline{
  visibility: visible;
}
.v-alert.bottom_alert {
  position: fixed; 
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 2;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
}
.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
.files_page .v-chip .v-chip__content {
  cursor: pointer !important;
}
.shared .v-badge__badge {
  width: 18px;
  height: 18px;
}
.shared-comments-dialog .v-list__tile {
  padding: 0px;
}
.shared-comments-dialog .v-list--two-line .v-list__tile {
  height: initial !important;
  padding-top: 8px;
  padding-bottom: 8px;
}
.shared-comments-dialog .v-list__tile__sub-title {
  white-space: pre-wrap;
}
</style>

<script>
import * as api from '@/libs/api'
import { Storage } from "aws-amplify"
import VideoPlayerSimple from './../dialogs/VideoPlayerSimple'
import VideoPlayer from './../dialogs/VideoPlayer'
import ProfileDetail from '@/dialogs/ProfileDetail'

export default {
  name: 'SharedVideos',
  components: {
    VideoPlayer,
    VideoPlayerSimple,
    ProfileDetail
  },
  props: {
    id: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      name: null,
      roles: [],
      loading: true,
      showNameDialog: false,
      sharedVideos: null,
      showVideoPlayerDialog: false,
      selectedVideo: null,
      profileDialog: {
        show: false,
        profile: null
      },
      videoPlayerDialog: {
        show: false,
        profile: null
      },
    }
  },
  async mounted() {
    if (!this.sharedVideos)
      this.sharedVideos = await api.getSharedVideos(this.id);
    
    let roles = [];
    let videoMap = new Map();
    for (let video of this.sharedVideos.videos) {
      let actor = video.profile;
      actor.files = video.files;
      let updatedActor = await this.getUpdatedActor(actor);

      let key = video.files ? video.files[0].key : '';
      let thumb = video.files ? video.files[0].thumbnail : '';
      
      if (key.length < 59 || thumb.length < 59)
        continue;

      let resolvedVideo = {
        thumbnailUrl: await Storage.get(thumb.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (video.manager || video.userId), download: false }),
        videoUrl: await Storage.get(key.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (video.manager || video.userId), download: false }),
        userId: video.userId,
        profile: updatedActor
      };

      let arr = videoMap.get(video.roleName);
      if (arr && Array.isArray(arr))
        arr.push(resolvedVideo);
      else
        arr = [resolvedVideo];

      videoMap.set(video.roleName, arr);
    }

    for (let [roleName, videos] of videoMap) {
      roles.push({ roleName: roleName, videos: videos });
    }
    this.name = localStorage.getItem("shareName");
    this.roles = roles;
    this.showNameDialog = true;
    this.loading = false;
  },
  methods: {
    showVideoPlayer(profile) {
      this.videoPlayerDialog = {
        show: true,
        profile: profile
      }
    },
    showVideoPlayerSimple(url) {
      this.selectedVideo = url;
      this.showVideoPlayerDialog = true;
    },
    saveName() {
      localStorage.setItem("shareName", this.name);
      this.showNameDialog = false;
    },
    showProfileDetail(profile) {
      this.profileDialog.profile = null;
      this.$nextTick(() => {
        this.profileDialog.profile = profile;
        this.profileDialog.show = true;
      });
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    async getUpdatedActor(actor) {
      actor.name = actor.fullname;
      if (actor.files) {
        for (let f in actor.files) {
          let key = actor.files[f].key;
          let thumb = actor.files[f].thumbnail;
          
          if (key.length < 59 || thumb.length < 59)
            continue;
          actor.files[f].videoThumbnail = await Storage.get(thumb.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
          actor.files[f].videoUrl = await Storage.get(key.slice(58), { level: 'private', bucket: 'video-uploads.aircasting', provider: 'AWSS3', identityId: (actor.manager || actor.userId), download: false });
        }
      }

      return actor;
    },
  }
}
</script>
<template>
  <v-dialog persistent v-model="show" max-width="700" @keydown.esc="showVideoPlayerDialog ? showVideoPlayerDialog = false : show = false" content-class="elevation-0 pa-0">
    <v-card color="#f6f8fa">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline pb-1">Sdílet videa</v-card-title>
      <v-card-text class="pt-1">
        <p>{{$t('ShareVideosDialog.pickVideosToRate')}}</p>
        <v-layout class="row wrap">
          <v-flex xs12 sm12 md6 class="pl-2 pr-2">
            <h4 class="mb-3">{{$t('ShareVideosDialog.videosShared')}}</h4>
            <v-treeview
              v-model="tree"
              :items="items"
              open-on-click
              :open-all="items.length>0"
              selectable
              expand-icon="mdi-chevron-down"
              on-icon="mdi-checkbox-marked"
              off-icon="mdi-checkbox-blank-outline"
              indeterminate-icon="mdi-checkbox-intermediate"
            >
              <template slot="prepend" slot-scope="{ item, leaf }">
                <v-avatar v-if="leaf" class="ml-2" size="28"><img :src="item.avatar"></v-avatar>
              </template>
              <template slot="append" slot-scope="{ item, leaf }">
                <v-icon small v-if="leaf && item.profile.trophy" color="yellow darken-3">mdi-trophy</v-icon>
                <v-btn v-if="leaf" icon small ripple @click="showVideoPlayer(item.profile)">
                  <v-icon small>play_arrow</v-icon>
                </v-btn>
              </template>
            </v-treeview>
          </v-flex>
          <v-flex xs12 sm12 md6 class="pl-2 pr-2">
            <h4>{{$t('ShareVideosDialog.shareWith')}}</h4>
            <DynamicContainer v-model="stakeholders"></DynamicContainer>
          </v-flex>
        </v-layout>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="elevation-0" @click="show = false">{{$t('cancel')}}</v-btn>
        <v-btn class="elevation-0" color="primary" @click="show = false">{{$t('ShareVideosDialog.share')}}</v-btn>
      </v-card-actions>
    </v-card>
    <VideoPlayer v-model="showVideoPlayerDialog" :profile="selectedProfile" />
  </v-dialog>
</template>

<style scoped>
</style>

<script>
import { mapGetters } from 'vuex'
import DynamicContainer from './../widgets/DynamicContainer'
import VideoPlayer from './VideoPlayer'

export default {
  name: 'ShareVideosDialog',
  components: {
    DynamicContainer,
    VideoPlayer
  },
  data: () => {
    return {
      tree: [],
      items: [],
      stakeholders: [],
      showVideoPlayerDialog: false,
      selectedProfile: null
    }
  },
  watch: {
    show (val) {
      if (val === true) {
        let project = this.getCurrentProject();
        if (project == null)
          return;
        let items = [];
        let id = 1;
        for (let role of project.roles) {
          let children = [];
          for (let pick of role.picks) {
            children.push({
              id: id++,
              name: pick.name,
              avatar: this.getImageUrl(pick, pick.mainPhoto, 28),
              profile: pick
            });
          }
          items.push({
            id: id++,
            name: role.roleName, 
            children: children
          });
        }
        for (let i = 1; i < id + 1; ++i) {
          this.tree.push(i);
        }
        this.items = items;
        this.stakeholders = project.stakeholders;
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.$store.state.dialogs.shareVideos.show; },
      set: function(value) { return this.$store.state.dialogs.shareVideos.show = value }
    }
  },
  methods: {
    ...mapGetters(['getCurrentProject']),
    showVideoPlayer: function(profile) {
      this.selectedProfile = profile;
      this.showVideoPlayerDialog = true;
    },
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    }
  }
}
</script>
<template>
  <v-container fill-height class="signin pa-0">
    <div style="width: 100%; position: absolute; top: 0px; left: 0px;">
      <v-alert v-model="message.show" :color="message.color" dark border="top" :icon="message.icon" dismissible transition="scale-transition">
        {{message.text}}
      </v-alert>
    </div>
    <v-layout row wrap align-center>
      <div class="mx-auto">
        <v-card class="shadow" style="width: 650px;">
          <v-card-title primary-title>
            <h4 class="headline ">Registrace</h4>
          </v-card-title>
          <v-card-text>
            <v-stepper v-model="step" vertical class="elevation-0">
              <v-stepper-step :complete="step > 1" step="1">Obecné informace</v-stepper-step>
              <v-stepper-content step="1">
                <v-card class="elevation-0">
                  <v-form v-model="validBasicInfo" ref="form">
                    <v-text-field v-model="name" prepend-icon="person" label="Vaše jméno" :rules="requiredRules" required></v-text-field>
                    <v-text-field v-model="company" prepend-icon="business" label="Název společnosti" :rules="requiredRules" required></v-text-field>
                    <v-text-field v-model="phone" prepend-icon="phone" label="Telefon" :rules="requiredRules" required></v-text-field>
                  </v-form>
                  <div>
                    <v-btn style="float: right;" :loading="loading" @click="step++" :disabled="!validBasicInfo" class="mt-3 mb-3 white--text elevation-0" color="primary">Další</v-btn>
                    <div style="clear: both;"></div>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 2" step="2">Přihlašovací údaje</v-stepper-step>
              <v-stepper-content step="2">
                <v-card class="elevation-0">
                  <v-form v-model="validLogin" ref="form">
                    <v-text-field v-model="email" prepend-icon="alternate_email" label="E-mail" :rules="[emailrules.required, emailrules.email]" required clearable></v-text-field>
                    <v-text-field v-model="password" prepend-icon="lock"  :append-icon="hidePassword ? 'visibility' : 'visibility_off'" @click:append="() => (hidePassword = !hidePassword)"  :type="hidePassword ? 'password' : 'text'" :label="$t('Signin.password')" 
                                  :rules="passRules" required :loading="(password != null && password.length) ? true : false">
                      <template v-slot:progress>
                        <v-progress-linear v-if="pwdStrength && password && password.length > 0" absolute :value="pwdStrength" :color="''+pwdColor" height="3"></v-progress-linear>
                      </template>
                    </v-text-field>
                  </v-form>
                  <div>
                    <div style="float: right;">
                      <v-btn  @click="step--" text>Předchozí</v-btn>
                      <v-btn :loading="loading" @click="register" :disabled="!validLogin" class="white--text elevation-0" color="primary">Další</v-btn>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-step :complete="step > 3" step="3">Ověření<small v-if="step==3">Na váš e-mail jsme odeslali ověřovací kód</small></v-stepper-step>
              <v-stepper-content step="3">
                <v-card class="elevation-0">
                  <v-form v-model="validVerify" ref="form">
                    <v-text-field v-model="code" label="Ověřovací kód" :rules="requiredRules" required clearable></v-text-field>
                  </v-form>
                  <div>
                    <div style="float: right;">
                      <v-btn  @click="step--" text>Předchozí</v-btn>
                      <v-btn :loading="loading" @click="verify" :disabled="!validVerify" class="white--text elevation-0" color="primary">Další</v-btn>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                </v-card>
              </v-stepper-content>

              <v-stepper-step step="4">Hotovo</v-stepper-step>
              <v-stepper-content step="4">
                <v-card class="elevation-0">
                  <v-card-title>Úspěšně jsme vás zaregistrovali</v-card-title>
                  <v-card-text>Právě jsme vás úspěšně zaregistrovali, nyní již můžete procházet profily herců. Brzy se vám ozveme a nastavíme s vámi plnohodnotný přístup, ve kterém bude možné vytvářet castingy.</v-card-text>
                  <div>
                    <div style="float: right;">
                      <v-btn class="white--text elevation-0" @click="startUsing" color="primary">Přejít na Aircasting</v-btn>
                    </div>
                    <div style="clear: both;"></div>
                  </div>
                </v-card>
              </v-stepper-content>
            </v-stepper>
          </v-card-text>
        </v-card>
      </div>
    </v-layout>
  </v-container>
</template>

<style>
</style>

<script>
import { Auth } from "aws-amplify";
import * as api from './../libs/api'

export default {
    name: 'Login',
    data() {
        return {
          step: 1,
          hidePassword: true,
          name: '',
          company: '',
          phone: '',
          email: '',
          code: '',
          emailrules: {
            required: (value) => !!value || 'Zadejte e-mail',
            email: (value) => {
              const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              return pattern.test(value) || 'Zadejte validni e-mail'
            }
          },
          password: '',
          passRules: [
            (v) => !!v || 'Zadejte heslo',
            (v) => !v || v.length >= 8 || 'Zadejte heslo'
          ],
          requiredRules: [
            (v) => !!v || 'Povinný údaj'
          ],
          showerr: false,
          errmsg: '',
          validBasicInfo: false,
          validLogin: false,
          validVerify: false,
          loading: false,
          resetPassword: null,
          message: {
            show: false
          }
        }
    },
    mounted() {
      this.$nextTick(() => {
        const form = this.$refs.form;
        console.log(form);        
        if (!form)
          return;
        let inputs = form.$el.querySelectorAll('input:not([type=hidden]),textarea:not([type=hidden])');
        setTimeout(() => { 
          for (let input of inputs)
            input.focus(); 
        }, 0);
      });
    },
    computed: {
      pwdStrength() {
        let len = Math.min(60, this.password.length * 6);
        let no = /^(?=.*[0-9])/.test(this.password) ? 20 : 0;
        let up = /^(?=.*[A-Z])/.test(this.password) ? 10 : 0;
        let down = /^(?=.*[a-z])/.test(this.password) ? 10 : 0;
        return len + no + up + down;
      },
      pwdColor() {
        return ['error', 'warning', 'success'][Math.floor(this.pwdStrength / 40)];
      }
    },
    methods: {
      async register() {
        try {
          let email = this.email.toLowerCase();
          await Auth.signUp({
            username: email.trim(),
            password: this.password
          });
          this.step++;
        }
        catch (err) {
          if (err.code === "UsernameExistsException") 
          {
            console.log(err);
            this.showError(this.$t('password.errorAlreadyRegistered'));
          }
          else {
            console.log(err);
            this.showError(this.$t('password.errorGeneric', {code: err.code ? err.code : 'ErrorUnknown'}));
          }
        }
      },
      async verify() {
        try {
          let email = this.email.toLowerCase()
          await Auth.confirmSignUp(email.trim(), this.code)
          await Auth.signIn(email.trim(), this.password);
          await api.register(email.trim(), this.phone, this.name, this.company);

          this.step++;
        }
        catch (err) {
          console.error(err)
          if (err.code === 'CodeMismatchException')
            this.showError(this.$t('password.errorVerify'));
          else
            this.showError(this.$t('password.errorGeneric', {code: err.code ? err.code : 'ErrorUnknown'}));
        }
      },
      async resend() {
        try {
          let email = this.email.toLowerCase()
          await Auth.resendSignUp(email.trim())
        }
        catch (err) {
          console.log(err);
        }      
      },
      showError(text) {
        this.message = {
          show: true,
          color: 'error',
          icon: 'error_outline',
          text: text
        }
      },
      startUsing() {
        window.location.replace("/search");
      }
    }
}
</script>

<style scoped>
  .content {
    background-color: blue !important;
  }
</style>
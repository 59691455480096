<template>
  <div>
  <v-dialog v-model="show" max-width="90%" @keydown.esc="shareNameDialog ? shareNameDialog = false : ($store.state.dialogs.profileDetail.show ? $store.state.dialogs.profileDetail.show = false : show = false)" content-class="pa-0">
    <v-card color="#f6f8fa" min-height="600">
      <v-btn absolute fab top right small text class="dialog_close" @click="show=false"><v-icon size="24" style="line-height: 40px">close</v-icon></v-btn>
      <v-card-title class="headline">
        <v-btn small icon class="my-0 ml-0 mr-2 check_header_button black--text" color="white" v-on:click.stop="checkAll()">
          <v-icon color="black">{{getCheckIcon()}}</v-icon>
        </v-btn> 
        <span>{{title}}</span> <span class="mx-2 grey--text text--lighten-2">&bull;</span><span class="grey--text">{{actors.length}}</span></v-card-title>
      <v-card-text>
        <v-container fluid grid-list-md class="pt-3 pl-0 pr-0">
          <v-layout row wrap style="min-width: 100%">
            <v-flex v-for="(actor, i) in actors" xs12 md3 lg2 xl2 class="pa-2" :key="i">
              <MiniProfile @showProfile="showProfileDetail" @check="checkItem(i)" :checked="isItemChecked(i)" :profile="actor" :showControls="false" :showBottomControls="false" :state="{state: (state === 'eventState' && actor[state] === 'added') ? 'addressed' : actor[state], sex: actor.sex}"></MiniProfile>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog max-width="600" v-model="shareNameDialog" persistent>
      <v-card flat tile class="pt-5">
        <template v-if="shareURL">
          <v-card-text>
            <span style="font-size: 18px">{{$t('ProfileMultiView.sharingLink')}}</span>
            <v-text-field class="mt-4" v-model="shareURL" label="URL">
              <v-icon slot="append" @click="copyURL">{{copying ? `check` : `far fa-clipboard`}}</v-icon>
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="elevation-0" @click="shareNameDialog = false" color="primary">OK</v-btn>
          </v-card-actions>
        </template>
        <template v-else>
          <v-card-text>
            <span style="font-size: 18px">{{$t('ProfileMultiView.sharingName')}}</span>
            <v-text-field v-model="shareName" :label="$t('ProfileMultiView.name')"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="elevation-0" @click="shareProfiles()" color="primary">{{$t('ProfileMultiView.createLink')}}</v-btn>
            <v-btn class="elevation-0" @click="shareNameDialog = false">{{$t('cancel')}}</v-btn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog max-width="600" v-model="emailDialog.show" persistent>
      <v-card flat tile class="pt-5">
        <v-card-text>
          <span style="font-size: 18px">Zadejte email, na který vám můžou herci odpovídat</span>
          <v-text-field v-model="emailDialog.email" label="E-mail"></v-text-field>
          <span style="font-size: 18px">Napište zprávu, kterou chcete odeslat vybraným hercům</span>
          <v-textarea autofocus v-model="emailDialog.content" rows="10" label="Zpráva"></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="elevation-0" color="primary" :disabled="!emailDialog.email || !emailDialog.content || emailDialog.content.length < 3" @click="sendMessage">Odeslat</v-btn>
          <v-btn class="elevation-0" @click="emailDialog.show = false">Storno</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
  <v-alert :value="itemsSelected" dark color="#b3d4fc" class="bottom_alert abs black--text">
    <v-avatar v-for="(selected, s) in selectedAvatars" :key="s" color="white" size="40" class="mr-1">
      <img v-if="selected.url" :src="selected.url">
      <template v-else>{{selected.count}}</template>
    </v-avatar>
    <div class="button_row">
      <div class="content">
        <v-btn small fab light class="ml-1 mr-1 elevation-0" @click="dislikeSelected">
          <v-icon size="24" color="black">not_interested</v-icon>
        </v-btn>
        <v-btn small fab light class="ml-1 mr-1 elevation-0" @click="likeSelected">
          <v-icon size="24" color="black">favorite_border</v-icon>
        </v-btn>
        <v-btn small fab light class="ml-1 mr-1 elevation-0" @click="showShareDialog()">
          <v-icon size="24" color="black">share</v-icon>
        </v-btn>
        <v-btn small fab light class="ml-1 mr-1 elevation-0" @click="showEmailDialog()">
          <v-icon size="24" color="black">mdi-email-outline</v-icon>
        </v-btn>
        <v-menu v-if="false && project" top offset-y>
          <template v-slot:activator="{on}">
            <v-btn v-on="on" small fab light  class="ml-1 mr-1 elevation-0">
              <v-icon size="24" color="black">mdi-account-plus</v-icon>
            </v-btn>
          </template>
          <v-list class="pa-0">
            <v-list-item v-for="role in project.roles" :key="role.roleName" @click="addSelectedToRole(role)">
              <v-list-item-title>{{role.roleName}}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="project.roles.length"></v-divider>
            <v-list-item color="primary" @click="showRoleDialog(null)">
              <v-list-item-title>{{$t('ProfileDetail.addRole')}}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </div>
    <div class="close_alert" style="right: 12px">
      <v-btn small fab light @click="checkedItems = []" class="elevation-0">
        <v-icon color="black">close</v-icon>
      </v-btn>
    </div>
  </v-alert>
  </div>
</template>

<style>
.v-alert.bottom_alert.abs {
  position: fixed;
  width: 100%;
  min-height: 64px;
  bottom: 0px; 
  left: 0px; 
  margin: 0px; 
  padding: 10px 16px 10px 30px;
  z-index: 20000;
}
.v-alert.bottom_alert .v-avatar {
  margin-left: -14px;
}

#app.mobile .v-alert.bottom_alert {
  padding: 10px 16px 10px 40px;
}
#app.mobile .v-alert.bottom_alert .v-avatar {
  margin-left: -24px;
}
.v-alert.bottom_alert .button_row {
  position: absolute;
  height: 60px;
  left: 0px; 
  bottom: 0px;
  width: 100%;
}

.v-alert.bottom_alert .button_row .content {
  display: table;
  margin: 0 auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.v-alert.bottom_alert .close_alert {
  position: absolute; 
  height: 60px; 
  bottom: 0px;
  right: 0px;
}
.v-alert.bottom_alert .close_alert .v-btn {
  margin-top: 12px;
}
</style>

<script>
import * as api from '@/libs/api'
import { mapActions, mapGetters } from 'vuex'
import MiniProfile from '@/widgets/MiniProfile'

export default {
  name: 'ProfileMultiView',
  props: {
    title: {
      type: String,
      default: () => null
    },
    actors: {
      type: Array,
      default: () => []
    },
    state: {
      type: String,
      default: () => null
    },
    value: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    MiniProfile
  },
  data: () => {
    return {
      checkedItems: [],
      shareNameDialog: false,
      shareName: null,
      shareURL: null,
      copying: false,
      emailDialog: {
        show: false,
        content: null,
        email: null
      }
    }
  },
  computed: {
    show: {
      get: function() { return this.value; },
      set: function(value) { 
        this.checkedItems = [];
        this.$emit('input', value); 
      }
    },
    project: function() {
      return this.getCurrentProject();
    },
    itemsSelected: function() {
      return this.checkedItems.length;
    },
    selectedAvatars: function() {
      let result = [];
      let count = 0
      let max = this.$vuetify.breakpoint.lgAndUp ? 10 : (this.$vuetify.breakpoint.mdAndUp ? 5 : 2);
      for (let item of this.checkedItems)
        if (++count <= max)
          result.push({url: this.getImageUrl(this.actors[item], this.actors[item].mainPhoto, 40)});

      if (count > max)
        result.push({count: '+' + (count - max)});

      return result;
    }
  },
  methods: {
    ...mapActions(['showProfileDetail', 'showRoleDialog', 'likeProfile', 'dislikeProfile', 'showInfo']),
    ...mapGetters(['getCurrentProject', 'getLoggedUser']),
    getImageUrl(user, filename, size) {
      return 'https://d3d0zdy5deiwj.cloudfront.net/fit-in/' + size +'x' + size + '/private/' + (user.manager || user.userId) + '/' + filename;
    },
    checkItem: function(i) {
      let index = this.checkedItems.findIndex(c => c == i);
      if (index > -1)
        this.checkedItems.splice(index, 1);
      else
        this.checkedItems.unshift(i);
    },
    isItemChecked: function(i) {
      return this.checkedItems.findIndex(c => c == i) > -1;
    },
    checkAll: function() {
      if (this.checkedItems.length > 0)
        this.checkedItems = [];
      else {
        for (let i in this.actors)
          this.checkedItems.push(i);
      }
    },
    getCheckIcon: function() {
      let ac = 0
      // eslint-disable-next-line no-unused-vars
      for (let _a of this.checkedItems)
        ac++;

      if (ac == 0)
        return 'mdi-checkbox-blank-circle-outline';
      else if (ac == this.actors.length)
        return 'mdi-check-circle';

      return 'mdi-radiobox-marked';
    },
    likeSelected: function() {
      for (let selected of this.checkedItems)
        this.likeProfile(this.actors[selected]);
      this.checkedItems = [];
    },
    dislikeSelected: function() {
      for (let selected of this.checkedItems)
        this.dislikeProfile(this.actors[selected]);
      this.checkedItems = [];
    },
    async shareProfiles() {
      let profiles = [];
      for (let selected of this.checkedItems)
        profiles.push(this.actors[selected].userId);
      
      try {
        const shareId = await api.shareProfiles(this.shareName, profiles);
        this.shareURL = "https://prod.aircasting.cz/profiles/" + shareId;
      }
      catch (err) {
        console.log(err);
      }
    },
    showShareDialog() {
      this.shareName = this.project.projectName + ' - ' + this.title;
      this.shareURL = null;
      this.copying = false;
      this.shareNameDialog = true;
    },
    showEmailDialog() {
      //this.emailDialog = { show: true, content: null }
      
      let actors = [];
      for (let selected of this.checkedItems)
        actors.push(this.actors[selected]);

      console.log(actors)

      if (actors.length)
        this.$store.state.dialogs.sendEmail = { show: true, actors: actors }
    },
    copyURL() {
      this.copying = true;
      navigator.clipboard.writeText(this.shareURL);
      setInterval(()=>{this.copying = false}, 3000);
    }
  }
}
</script>
<template>
  <v-chip :close="close" :color="color" @click="click" @focus="focus" v-model="show" label>
    <span>{{caption}}</span>
    <v-text-field mask="###" :autofocus="from == 0" @focus="focus" v-model="from" color="primary" class="range pa-0" ></v-text-field>
    <span>až</span>
    <v-text-field mask="###" v-model="to" color="primary" class="range pa-0"></v-text-field>
  </v-chip>
</template>

<style>

.v-text-field.range > .v-input__control > .v-input__slot input {
  caret-color: black !important;
  margin-bottom: 0px;
  vertical-align: bottom;
  line-height: 15px;
  margin-top: 15px;
  padding: 5px 0px !important;
  background-color: white;
  text-align: center;
  border-radius: 3px;
  width: 24px !important;
  min-width: 24px !important;
  opacity: 1 !important;
}

.searchbar .v-text-field.range .v-input__slot {
  padding-left: 0px;
  padding-right: 0px;
}

.search-page .v-text-field.v-input--is-focused.range .v-text-field > .v-input__control > .v-input__slot:after,
.search-page .v-text-field.range > .v-input__control > .v-input__slot:after, 
.search-page .v-text-field.range > .v-input__control > .v-input__slot:before {
  content: none;
  display: none;
  border: none !important;
}
.range
{
  color:#002e99 !important;
  width: 24px !important;
  margin-left: 8px;
  margin-top: 9px !important;
}
.range, 
.range label {
  font-size: 13px !important; 
  margin-bottom: 0px !important;
}
.range label {
  padding-top: 2px !important;
}
.range input {
  padding-bottom: 2px !important;
}
.range + span {
  margin-left: 8px;
}
</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ChipRange',
    props: {
      value: {
        type: Boolean,
        default: () => true
      },
      close: {
        type: Boolean,
        default: () => false
      },
      color: {
        type: String,
        default: () => "secondary"
      },
      caption: {
        type: String,
        default: () => ""
      },
      type: {
        type: String,
        default: () => ""
      }
    },
    data: () => {
      return {
      }
    },
    computed: {
      show: {
        get: function() { return this.value; },
        set: function(value) { 
          console.log(value);
          if (typeof value == "boolean") {
            if (value === false) {
              let max = 90;
              switch (this.type) {
                case "weight": max = 200; break;
                case "height": max = 250; break;
                case "salary": max = 250; break;
              }
              this.setRange(0, max);
            }
            this.$emit('input', value); 
          }
        }
      },
      from: {
        get: function() { return this.getRange()[0]; },
        set: function(val) { this.setRange(val, this.to); }
      },
      to: {
        get: function() { return this.getRange()[1]; },
        set: function(val) { this.setRange(this.from, val); }
      }
    },
    methods: {
      ...mapActions(['setSearchFilter']),
      ...mapGetters(['getSearchFilter']),
      click: (e) => {
        e.stopPropagation();
      },
      focus: (e) => {
        e.stopPropagation();
      },
      getRange: function() {
        switch (this.type) {
          case "age": return this.getSearchFilter().age;
          case "weight": return this.getSearchFilter().weight;
          case "height": return this.getSearchFilter().height;
          case "salary": return this.getSearchFilter().salary;
        }
        return [0,10];
      },
      setRange: function(from, to) {
        let filter = this.getSearchFilter();
        switch (this.type) {
          case "age": filter.age = [from, to]; break;
          case "weight": filter.weight = [from, to]; break;
          case "height": filter.height = [from, to]; break;
          case "salary": filter.salary = [from, to]; break;
        }
        this.setSearchFilter(filter);
      }
    }
}
</script>
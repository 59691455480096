<template>
  <v-card class="elevation-0 project_selector" style="height: 100%" color="#f6f7f7">
    <v-card-text class="pl-0 pr-0 pt-0" style="height: 100%; padding-bottom: 64px !important;">
      <div class="project pl-0 pt-0" style="height: 100%; overflow-y: auto;">
        <div class="panel_header">
          <div style="float: left">Účet</div>
          <v-speed-dial :direction="$vuetify.breakpoint.smAndDown ? `bottom` : `left`" class="d-flex mt-1" style="float:right">
            <template v-slot:activator>
              <v-btn small text fab class="elevation-0 round">
                <img width="24" :src="$i18n.locale() == 'en' ? 'assets/us.svg' : 'assets/cz.svg'" />
              </v-btn>
            </template>
            <v-btn v-if="$i18n.locale() == 'en'" fab text small class="elevation-0 round" @click="setLocale('cz')">
              <img width="24" src="assets/cz.svg" />
            </v-btn>
            <v-btn v-else fab text small class="elevation-0 round" @click="setLocale('en')">
              <img width="24" src="assets/us.svg" />
            </v-btn>
          </v-speed-dial>
          <div style="clear: both;"></div>
        </div>
        <div v-if="company" class="text-center" style="padding: 40px 0px; font-size: 36px;" >
          {{company}}
        </div>
        <v-list>
          <v-list-item class="py-2" to="/liked">
            <v-list-item-avatar style="min-width: initial">
              <v-icon :size="22" :color="liked > 0 ? `primary` : null">favorite</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="liked > 0 ? `primary--text` : null">{{$t('Account.likedProfiles')}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <span>{{liked}}</span>
                <v-icon :size="18" color="grey lighten-1" class="d-inline-block">chevron_right</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="ml-4 mr-3"></v-divider>
          <v-list-item class="py-2" to="/disliked">
            <v-list-item-avatar style="min-width: initial">
              <v-icon :size="22" :color="disliked > 0 ? `primary` : null">not_interested</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title :class="disliked > 0 ? `primary--text` : null">{{$t('Account.hiddenProfiles')}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <span>{{disliked}}</span>
                <v-icon :size="18" color="grey lighten-1" class="d-inline-block">chevron_right</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="ml-4 mr-3"></v-divider>
          <v-list-item class="py-2" @click="newTab('readme.html')">
            <v-list-item-avatar style="min-width: initial">
              <v-icon :size="22" color="primary">mdi-clipboard-check</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{$t('Account.readMe')}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <v-icon :size="18" color="grey lighten-1" class="d-inline-block">chevron_right</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="ml-4 mr-3"></v-divider>
          <v-list-item class="py-2" @click="newTab('tips.html')">
            <v-list-item-avatar style="min-width: initial">
              <v-icon :size="22" color="primary">mdi-file-document</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{$t('Account.tipsAndTricks')}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <v-icon :size="18" color="grey lighten-1" class="d-inline-block">chevron_right</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="ml-4 mr-3"></v-divider>
          <v-list-item class="py-2" @click="newTab('service.html')">
            <v-list-item-avatar style="min-width: initial">
              <v-icon :size="22" color="primary">mdi-file-document</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{$t('Account.termsOfService')}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <v-icon :size="18" color="grey lighten-1" class="d-inline-block">chevron_right</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="ml-4 mr-3"></v-divider>
          <v-list-item class="py-2" v-if="false">
            <v-list-item-avatar style="min-width: initial">
              <v-icon :size="22" color="primary">mdi-file-document</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="primary--text">{{$t('Account.agreement')}}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <div>
                <v-icon :size="18" color="grey lighten-1" class="d-inline-block">chevron_right</v-icon>
              </div>
            </v-list-item-action>
          </v-list-item>
          <v-divider class="ml-4 mr-3" v-if="false"></v-divider>
        </v-list>
        <v-container v-if="false">
          <v-layout row wrap align-center>
            <v-flex xs12 sm12 md6>
              <v-card class="text-center pa-2 ma-1" hover to="/calc">
                <v-responsive aspect-ratio="1">
                  <div class="big_button_content">
                    <v-icon size="40" color="primary" class="mb-2">mdi-calculator</v-icon>
                    <div class="primary--text px-1" style="font-size: 18px">{{$t('Account.projectCalculator')}}</div>
                  </div>
                </v-responsive>
              </v-card>
            </v-flex>
            <v-flex xs12 sm12 md6>
              <v-card class="text-center pa-2 ma-1" hover @click="$store.state.dialogs.inviteDialog.show = true">
                <v-responsive aspect-ratio="1">
                  <div class="big_button_content">
                    <v-icon size="40" color="primary" class="mb-2">mdi-account-plus</v-icon>
                    <div class="primary--text px-3" style="font-size: 18px">{{$t('Account.inviteUser')}}</div>
                  </div>
                </v-responsive>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </div>
    </v-card-text>
    <footer class="pane_footer">
      <v-btn class="elevation-0" color="primary" text @click="signOut"><v-icon size="18" class="mr-2">fa-sign-out-alt</v-icon> Odhlásit se</v-btn>
    </footer>
  </v-card>
</template>

<style>
.project_selector footer.pane_footer {
  border-top: 1px solid rgb(228,228,228); 
  width: 100%; 
  height: 64px; 
  position: absolute; 
  bottom: 0px;
}
.project_selector footer.pane_footer .v-btn {
  margin: 12px 0px 0px 11px;
}
.big_button_content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
.panel_header {
  background-color: #f6f8fa;
  border-bottom: 1px solid rgb(228, 228, 228); 
  height: 64px;
  line-height: 64px;
  padding-left: 24px;
  font-weight: bold;
  font-size: 16px;
}
</style>

<script>
import { API, Auth } from "aws-amplify";
import router from '../router'
import { mapGetters } from 'vuex'
export default {
  name: 'ProjectsList',
  data() {
    return {
    }
  },
  computed: {
    liked: function() {
      const user = this.getLoggedUser();
      if (user && user.liked)
        return user.liked.length;
      return 0;
    },
    disliked: function() {
      const user = this.getLoggedUser();
      if (user && user.disliked)
        return user.disliked.length;
      return 0;
    },
    company() {
      const user = this.getLoggedUser();
      if (user)
        return user.companyName;
      
      return null;
    }
  },
  methods: {
    ...mapGetters(['getLikedProfiles', 'getDislikedProfiles', 'getLoggedUser']),
    reset: function() {
      this.$store.state.projects = [];
      this.$store.state.selectedProject = 0;
      this.$store.liked = [];
      this.$store.disliked = [];
      window.localStorage.clear();
    },
    enableVideos: async function() {
      //this.$store.state.projects[this.$store.state.selectedProject].videos = true;
      
      const options = {
        body: {
          cognitoId: (await Auth.currentUserInfo()).id
        },
        response: true
      }
      API.get("production-users", "/production-users", options).then(() => {

      }).catch((err) => {
        console.error(err);
      });
    },
    signOut: async function() {
      try {
        await Auth.signOut();
      }
      catch (e) {
        console.error(e)
      }
      router.push('/signin');
    },
    newTab(url) {
      var win = window.open(url, '_blank');
      win.focus();
    },
    setLocale(locale) {
      this.$i18n.set(locale);
      localStorage.setItem("locale", locale);
      typeof window !== 'undefined' && window.location.reload();
    },
  }
}
</script>